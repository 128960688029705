"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-dialog", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      title: "Upload PDF",
      width: "50%",
      visible: _vm.dialogVisible,
      "before-close": _vm.handleClose
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "pdf-uploader upload-container"
  }, [_c("el-upload", {
    ref: "upload",
    attrs: {
      drag: "",
      accept: ".pdf",
      action: _vm.uploadUrl,
      "file-list": _vm.fileList,
      "on-success": _vm.fileUploadSuccess,
      "on-remove": _vm.onRemove,
      "on-preview": _vm.onPreview,
      limit: 1,
      "before-upload": _vm.beforeUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v(" Drag your PDF file here or click to upload ")]), _c("div", {
    staticClass: "el-upload__tip"
  }, [_vm._v(" Only PDF files are allowed ")])])], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: !_vm.uploadedFileUrl.length
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v(" Submit ")])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;