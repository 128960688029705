"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "First Name",
      prop: "firstName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "firstName",
      placeholder: "First Name"
    },
    model: {
      value: _vm.postForm.user.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm.user, "firstName", $$v);
      },
      expression: "postForm.user.firstName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Last Name",
      prop: "lastName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "lastName",
      required: "",
      placeholder: "Last Name"
    },
    model: {
      value: _vm.postForm.user.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm.user, "lastName", $$v);
      },
      expression: "postForm.user.lastName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Place",
      prop: "place"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "place",
      required: "",
      placeholder: "Place Name"
    },
    model: {
      value: _vm.postForm.place,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "place", $$v);
      },
      expression: "postForm.place"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Collage Name",
      prop: "collegeName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "place",
      required: "",
      placeholder: "College Name"
    },
    model: {
      value: _vm.postForm.college.collegeName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm.college, "collegeName", $$v);
      },
      expression: "postForm.college.collegeName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "description",
      area: "textarea",
      required: "",
      placeholder: "Description"
    },
    model: {
      value: _vm.postForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "description", $$v);
      },
      expression: "postForm.description"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Images",
      prop: "image"
    }
  }, [_c("pan-thumb", {
    attrs: {
      image: _vm.image
    }
  }), _c("br"), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-upload",
      name: "image",
      tyle: "position: absolute;bottom: 15px;margin-left: 40px;"
    },
    on: {
      click: _vm.toggleShow
    }
  }, [_vm._v(" Change Image ")]), _c("avatar-upload", {
    attrs: {
      field: "avatar",
      name: "image"
    },
    on: {
      "crop-success": _vm.cropSuccess,
      close: _vm.onClose,
      "crop-upload-success": _vm.onCropUploadSuccess
    },
    model: {
      value: _vm.showImageUpload,
      callback: function ($$v) {
        _vm.showImageUpload = $$v;
      },
      expression: "showImageUpload"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Hashtag",
      prop: "tagName"
    }
  }, [_vm._l(_vm.dynamicTags, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        closable: "",
        "disable-transitions": false
      },
      on: {
        close: function ($event) {
          return _vm.handleClose(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), _vm.inputVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      blur: _vm.handleInputConfirm
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showInput
    }
  }, [_vm._v(" + New Tag ")])], 2), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;