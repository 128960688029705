"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _users = require("@/api/users");
var _trainer = require("@/api/trainer");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _utils = require("@/utils");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueClassComponent = _interopRequireDefault(require("vue-class-component"));
var _vuexModuleDecorators = require("vuex-module-decorators");
let TrainerDetail = class TrainerDetail extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.postForm = Object.assign({}, _users.defaultUserData);
    this.loading = false;
    this.showImageUpload = false;
    this.slotDialogVisible = false;
    this.renderKey = 0;
    this.listLoading = true;
    this.listQuery = {
      trainerId: Number(this.$route.params.id),
      filter: {}
    };
    this.SlotData = {
      endTime: "",
      startTime: ""
    };
    this.daysList = [{
      label: "Monday",
      value: "1"
    }, {
      label: "Tuesday",
      value: "2"
    }, {
      label: "Wednesday",
      value: "3"
    }, {
      label: "Thursday",
      value: "4"
    }, {
      label: "Friday",
      value: "5"
    }, {
      label: "Sunday",
      value: "6"
    }, {
      label: "Saturday",
      value: "7"
    }];
    this.selectedDays = [];
    // private daySlots:any = {};
    this.daySlots = {
      Monday: {
        slots: [{
          from: "",
          to: ""
        }]
      },
      Tuesday: {
        slots: []
      },
      Wednesday: {
        slots: []
      },
      Thursday: {
        slots: []
      },
      Friday: {
        slots: []
      },
      Satday: {
        slots: []
      },
      Sunday: {
        slots: []
      }
    };
    this.daysForm = {
      selectedDays: []
    };
    this.dayData = [];
    this.timePickerOptions = {
      start: "00:00",
      step: "00:30",
      end: "23:30"
    };
    this.image = "https://via.placeholder.com/154";
    this.rules = {
      endTime: [{
        required: true,
        message: "Please select end time",
        trigger: ["blur", "change"]
      }],
      startTime: [{
        required: true,
        message: "Please select start time",
        trigger: ["blur", "change"]
      }]
    };
  }
  changeSlot(key) {
    this.key = key;
    this.slotDialogVisible = true;
  }
  async addSlots() {
    this.$refs.SlotData.validate(async valid => {
      if (valid) {
        const slotdata = {
          trainerId: Number(this.$route.params.id),
          start_time: this.SlotData.startTime,
          end_time: this.SlotData.endTime,
          day: this.key
        };
        await (0, _trainer.addSlot)(slotdata).then(res => {
          if (res.responseCode == 200) {
            this.$notify({
              title: "Success",
              message: "Slot Added successfully",
              type: "success",
              duration: 2000
            });
            this.slotDialogVisible = false;
            this.getList();
            this.SlotData.startTime = "";
            this.SlotData.endTime = "";
          } else if (res.responseCode == 300) {
            this.$notify({
              title: "Error",
              message: "Time slot overlaps with an existing one.",
              type: "error",
              duration: 2000
            });
            this.slotDialogVisible = false;
            this.getList();
            this.SlotData.startTime = "";
            this.SlotData.endTime = "";
          } else if (res.responseCode == 400) {
            this.$notify({
              title: "Error",
              message: "Error",
              type: "error",
              duration: 2000
            });
            this.slotDialogVisible = false;
            this.getList();
            this.SlotData.startTime = "";
            this.SlotData.endTime = "";
          } else {
            this.slotDialogVisible = false;
          }
          console.log(res.responseCode, "res");
        });
      } else {
        return false;
      }
    });
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _trainer.getSlots)((0, _utils.getQuery)(this.listQuery));
      this.dayData = data.data;
      console.log(this.dayData, "data");
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onClose() {
    this.showImageUpload = false;
  }
  deleteImage() {
    this.postForm.profilePicture = "";
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: "slot"
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        const slodIDData = {
          slotId: data
        };
        (0, _trainer.removeSlot)(slodIDData).then(response => {
          this.getList();
          if (response.responseCode === 300) {
            this.$confirm(this.$tc(`${response.message}`, undefined, {
              name: response.message
            }), this.$tc("table.delete"), {
              confirmButtonText: this.$tc("Go to sessions"),
              confirmButtonClass: "el-button--danger",
              cancelButtonText: "Cancel",
              type: "error"
            }).then(() => {
              this.$router.push("/session/session-slots/" + data);
            });
          } else {
            this.$message({
              type: "success",
              message: this.$tc(data.message)
            });
          }
        }).catch(err => {
          console.log(err);
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], "img." + blob.type.split("/")[1], {
        type: "image/png"
      });
      data.append("file", file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.profilePicture = res;
      });
    });
  }
  get lang() {
    return _app.AppModule.language;
  }
  handleDaySelect() {
    // console.log(this.daysForm.selectedDays,'selected')
    // this.daySlots = null;
    this.daysForm.selectedDays.forEach(day => {
      // this.daySlots[`${day}`] = {
      //   slots: []
      // }
    });
  }
  addSlot(item) {
    this.daySlots[`${item}`].slots.push({
      from: "",
      to: ""
    });
    // console.log(this.daySlots[`${item}`]);
    // Vue.set(this.daySlots[`${item}`], 'slots', [item]);
    // console.log(this.daySlots)
  }
  removeSlot(day, slotIndex) {
    this.daySlots[`${day}`].slots.splice(slotIndex, 1);
  }
  handleRemove() {
    console.log(this.daySlots, "removed");
  }
  created() {
    this.getList();
    // if (this.isEdit) {
    //   const id = this.$route.params && this.$route.params.id;
    //   this.fetchData(parseInt(id));
    // } else {
    //   this.postForm = Object.assign({}, defaultUserData);
    // }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _users.getUserDetailById)(id);
      this.renderKey++;
      // this.postForm.profilePicture=data.profilePicture;
      this.postForm = data;
      // this.getCollegeList();
      this.postForm.profilePicture = this.postForm.profilePicture.trim();
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  updateProfilePictureImage(res) {
    this.postForm.profilePicture = res;
  }
  removeProfilePictureImage() {
    this.postForm.profilePicture = "";
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  handleFilter() {
    // this.listQuery.page = 1;
    // this.getList();
  }
  resetForm() {
    this.renderKey--;
    this.$refs.postForm.resetFields();
    this.postForm.profilePicture = "";
  }
  async saveForm() {
    try {
      this.loading = true;
      //  this.postForm.college = { id: this.postForm.collegeId };
      if (this.isEdit) {
        await (0, _users.updateUser)(this.postForm.id, this.postForm).then(res => {
          this.$router.push("/users/list");
          this.resetForm();
        });
      } else {
        await (0, _users.addUser)(this.postForm).then(res => {
          if (res.responseCode === 300) {
            this.loading = false;
            this.$notify({
              title: "Danger",
              message: "This contact number is already exist.",
              type: "warning",
              duration: 2000
            });
          }
          if (res.responseCode === 301) {
            this.loading = false;
            this.$notify({
              title: "Danger",
              message: "This email is already exist.",
              type: "warning",
              duration: 2000
            });
          }
          if (res.responseCode === 204 || res.responseCode === 200 || res.responseCode === 201) {
            this.loading = false;
            this.$notify({
              title: "Success",
              message: "User saved successfully",
              type: "success",
              duration: 2000
            });
            this.$router.push("/users/list");
          }
        }).catch(err => {
          console.log(err);
        });
      }
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], TrainerDetail.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TrainerDetail.prototype, "saveForm", null);
TrainerDetail = (0, _tslib.__decorate)([_vueClassComponent.default], TrainerDetail);
var _default = exports.default = TrainerDetail;