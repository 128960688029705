"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const documentmasterRouter = {
  path: '/document',
  component: _index.default,
  redirect: 'noredirect',
  name: 'documentmasterManagement.title',
  meta: {
    title: 'documentmasterManagement.title',
    icon: 'map-marker'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/documentmaster/list.vue'))),
    name: 'documentmasterManagement.title',
    meta: {
      title: 'documentmasterManagement.title',
      noCache: true,
      icon: 'cube',
      header: 'List of all categories',
      info: 'All state details are available here. You can search categories by applying filters on fields like state name. After clicking on state name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/documentmaster/manage.vue'))),
    name: 'documentmasterManagement.documentmaster.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'documentmasterManagement.documentmaster.manage',
      noCache: true,
      activeMenu: '/documentmaster/list',
      hidden: true,
      header: 'Edit state',
      info: 'You can edit state here'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/documentmaster/manage.vue'))),
    name: 'documentmasterManagement.documentmaster.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'documentmasterManagement.documentmaster.manage',
      noCache: true,
      activeMenu: '/documentmaster/list',
      hidden: true,
      header: 'Add state',
      info: 'You can create state here'
    }
  }]
};
var _default = exports.default = documentmasterRouter;