"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'tree': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M126.7 90a5 5 0 011.3 3.5V123a5 5 0 01-1.3 3.6c-.8.9-1.9 1.3-3.1 1.3H97.8a4 4 0 01-3-1.3 5 5 0 01-1.3-3.6V93.5c0-1 .2-1.7.6-2.5.4-.8 1-1.4 1.6-1.8a3.8 3.8 0 012.1-.7h9.7V69a3.8 3.8 0 00-.4-1.8 3.6 3.6 0 00-1.1-1.3 2.7 2.7 0 00-1.6-.5H67.9v23.1h9.8c1.1 0 2.1.5 3 1.5a5 5 0 011.2 3.5V123c0 .9-.1 1.7-.5 2.5s-1 1.4-1.6 1.8a3.8 3.8 0 01-2.1.6H51.9a3.8 3.8 0 01-2.1-.6 5 5 0 01-1.7-1.8 5 5 0 01-.6-2.5V93.5a5 5 0 011.3-3.5 4 4 0 013.1-1.5h9.6V65.4H23.6a3 3 0 00-2.4 1c-.6.8-.9 1.6-.9 2.6v19.5H30c1.3 0 2.3.5 3.1 1.5.8 1 1.2 2.2 1.2 3.5V123c0 1.4-.4 2.6-1.2 3.6-.8.9-1.8 1.3-3 1.3H4.2c-.5 0-1 0-1.4-.2a4.1 4.1 0 01-1.1-.7 4.7 4.7 0 01-1-1 5.2 5.2 0 01-.6-1.4A5.6 5.6 0 010 123V93.5l.1-1.3A4 4 0 01.6 91 6.4 6.4 0 012 89.2a3 3 0 011-.5l1.2-.2H14V61.6c0-1 .3-1.8 1-2.5.6-.7 1.3-1 2.2-1h44.3V39.5h-9.6a4 4 0 01-3.1-1.5 5 5 0 01-1.3-3.4V5c0-1.4.4-2.6 1.3-3.6A4 4 0 0151.9.1h25.8c.7 0 1.4.2 2.1.7a5.2 5.2 0 011.6 1.9c.4.7.5 1.6.5 2.4v29.6a5 5 0 01-1.2 3.4c-.9 1-1.9 1.5-3 1.5h-9.8V58h42.8c1 0 1.7.4 2.4 1 .6.8.9 1.6.9 2.6v27h9.6a4 4 0 013.1 1.4z"/>'
  }
});