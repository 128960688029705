"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _tagsView = require("@/store/modules/tags-view");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.message = '';
    this.header = '';
  }
  get cachedViews() {
    return _tagsView.TagsViewModule.cachedViews;
  }
  get key() {
    return this.$route.path;
  }
  created() {
    // this.$route.
  }
  onUrlChange(newVal) {
    var _this$$route$meta, _this$$route$meta2;
    this.message = (_this$$route$meta = this.$route.meta) === null || _this$$route$meta === void 0 ? void 0 : _this$$route$meta.info;
    this.header = (_this$$route$meta2 = this.$route.meta) === null || _this$$route$meta2 === void 0 ? void 0 : _this$$route$meta2.header;
    // Some action
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('$route', {
  immediate: true,
  deep: true
})], default_1.prototype, "onUrlChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AppMain',
  components: {}
})], default_1);
var _default = exports.default = default_1;