// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.admin-textarea textarea {\n  padding-right: 40px;\n  resize: none;\n  border: none;\n  border-radius: 0px;\n  border-bottom: 1px solid #bfcbd9;\n}\n.custom-theme .el-form-item.is-required .el-form-item__label:after {\n  content: \"*\" !important;\n  color: #b3450e !important;\n  margin-right: 4px !important;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#000",
	"menuText": "#fff",
	"menuActiveText": "#90d86a"
};
module.exports = exports;
