"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-width": "220px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Profile Picture",
      prop: "image",
      "label-width": "200px"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      "form-field": _vm.postForm.image
    },
    on: {
      "handle-upload": _vm.updateProfilePictureImage,
      "handle-remove": _vm.removeProfilePictureImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "name",
      placeholder: "Name"
    },
    model: {
      value: _vm.postForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "name", $$v);
      },
      expression: "postForm.name"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "About",
      prop: "about"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "about",
      type: "textarea",
      placeholder: "About"
    },
    model: {
      value: _vm.postForm.about,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "about", $$v);
      },
      expression: "postForm.about"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Calories",
      prop: "calories"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "calories",
      placeholder: "Calories"
    },
    model: {
      value: _vm.postForm.calories,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "calories", $$v);
      },
      expression: "postForm.calories"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Carbs",
      prop: "carbs"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "carbs",
      placeholder: "Carbs"
    },
    model: {
      value: _vm.postForm.carbs,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "carbs", $$v);
      },
      expression: "postForm.carbs"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Protiens",
      prop: "protiens"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "protiens",
      placeholder: "Protiens"
    },
    model: {
      value: _vm.postForm.protiens,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "protiens", $$v);
      },
      expression: "postForm.protiens"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Fat",
      prop: "fat"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "fat",
      placeholder: "Fat"
    },
    model: {
      value: _vm.postForm.fat,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "fat", $$v);
      },
      expression: "postForm.fat"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Steps",
      prop: "steps"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "textarea",
      rows: 3,
      name: "steps",
      placeholder: "Steps"
    },
    model: {
      value: _vm.postForm.steps,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "steps", $$v);
      },
      expression: "postForm.steps"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Cooking Tips",
      prop: "tips"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "textarea",
      name: "tips",
      placeholder: "Tips"
    },
    model: {
      value: _vm.postForm.tips,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "tips", $$v);
      },
      expression: "postForm.tips"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Ingredients",
      prop: "ingredients"
    }
  }, [_vm._l(_vm.dynamicRecipes, function (tag) {
    return _c("el-tag", {
      key: tag,
      attrs: {
        closable: "",
        "disable-transitions": false
      },
      on: {
        close: function ($event) {
          return _vm.handleClose(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), _vm.inputVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      blur: _vm.handleInputConfirm
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInputConfirm.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v;
      },
      expression: "inputValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: _vm.showInput
    }
  }, [_vm._v("+ New Ingredients")])], 2), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Recipes Picture",
      prop: "media",
      "label-width": "200px"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      "form-field": _vm.postForm.media
    },
    on: {
      "handle-upload": _vm.updateMediaPictureImage,
      "handle-remove": _vm.removeMediaPictureImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;