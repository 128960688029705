"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "180px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "App name",
      prop: "appName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "appName",
      required: "",
      placeholder: "App name"
    },
    model: {
      value: _vm.postForm.appName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "appName", $$v);
      },
      expression: "postForm.appName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Version",
      prop: "version"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "version",
      required: "",
      placeholder: "App name"
    },
    model: {
      value: _vm.postForm.version,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "version", $$v);
      },
      expression: "postForm.version"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px",
      width: "100%"
    },
    attrs: {
      label: "Android Version",
      prop: "version"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "version",
      required: "",
      placeholder: "Android Version"
    },
    model: {
      value: _vm.postForm.version,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "version", $$v);
      },
      expression: "postForm.version"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "IOS Version",
      prop: "iosVersion"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "iosVersion",
      placeholder: "IOS version"
    },
    model: {
      value: _vm.postForm.iosVersion,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "iosVersion", $$v);
      },
      expression: "postForm.iosVersion"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Share app message",
      prop: "shareAppMessage"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "shareAppMessage",
      required: "",
      type: "textarea",
      rows: 3,
      placeholder: "Share app message"
    },
    model: {
      value: _vm.postForm.shareAppMessage,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "shareAppMessage", $$v);
      },
      expression: "postForm.shareAppMessage"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Contact number",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "contactNumber",
      min: 1,
      required: "",
      placeholder: "Contact number"
    },
    model: {
      value: _vm.postForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "contactNumber", $$v);
      },
      expression: "postForm.contactNumber"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Contact Us Email",
      prop: "contactUsEmail"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "contactUsEmail",
      required: "",
      placeholder: "Email"
    },
    model: {
      value: _vm.postForm.contactUsEmail,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "contactUsEmail", $$v);
      },
      expression: "postForm.contactUsEmail"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Whatsapp number",
      prop: "whatappNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "whatsappNumber",
      min: 1,
      required: "",
      placeholder: "Whatsapp number"
    },
    model: {
      value: _vm.postForm.whatappNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "whatappNumber", $$v);
      },
      expression: "postForm.whatappNumber"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: " Trainer App Store link",
      prop: "trainerAppStoreLink"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "trainerAppStoreLink",
      placeholder: "Trainer App Store link"
    },
    model: {
      value: _vm.postForm.trainerAppStoreLink,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "trainerAppStoreLink", $$v);
      },
      expression: "postForm.trainerAppStoreLink"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Trainer Play Store link",
      prop: "trainerPlayStoreLink"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "trainerPlayStoreLink",
      placeholder: "Trainer play store link"
    },
    model: {
      value: _vm.postForm.trainerPlayStoreLink,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "trainerPlayStoreLink", $$v);
      },
      expression: "postForm.trainerPlayStoreLink"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Play Store link",
      prop: "playstoreLink"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "playstoreLink",
      placeholder: "Playstore link"
    },
    model: {
      value: _vm.postForm.playstoreLink,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "playstoreLink", $$v);
      },
      expression: "postForm.playstoreLink"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "App Store link",
      prop: "appstoreLink"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "appstoreLink",
      placeholder: "Appstore link"
    },
    model: {
      value: _vm.postForm.appstoreLink,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "appstoreLink", $$v);
      },
      expression: "postForm.appstoreLink"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Trainer Valid Versions",
      prop: ""
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "grid-content bg-purple-dark"
  }, [_vm._l(_vm.trainerVersionList, function (tag) {
    return _c("el-tag", {
      key: tag,
      staticStyle: {
        margin: "0 5px",
        "background-color": "#90d86a",
        color: "white"
      },
      attrs: {
        closable: "",
        disableNetwork: false
      },
      on: {
        close: function ($event) {
          return _vm.handleClose(tag, true);
        }
      }
    }, [_vm._v(_vm._s(tag) + " ")]);
  }), _vm.trainerInputVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      blur: function ($event) {
        return _vm.handleInputConfirm(true);
      }
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInputConfirm(true);
      }
    },
    model: {
      value: _vm.trainerInputValue,
      callback: function ($$v) {
        _vm.trainerInputValue = $$v;
      },
      expression: "trainerInputValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: function ($event) {
        return _vm.showInput(true);
      }
    }
  }, [_vm._v("+ New Version")])], 2)])], 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "User Valid Versions",
      prop: ""
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "grid-content bg-purple-dark"
  }, [_vm._l(_vm.userVersionList, function (tag) {
    return _c("el-tag", {
      key: tag,
      staticStyle: {
        margin: "0 5px",
        "background-color": "#90d86a",
        color: "white"
      },
      attrs: {
        closable: "",
        disableNetwork: false
      },
      on: {
        close: function ($event) {
          return _vm.handleClose(tag, false);
        }
      }
    }, [_vm._v(_vm._s(tag) + " ")]);
  }), _vm.userVersionInputVisible ? _c("el-input", {
    ref: "saveTagInput",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      blur: function ($event) {
        return _vm.handleInputConfirm(false);
      }
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleInputConfirm(false);
      }
    },
    model: {
      value: _vm.userVersionInputValue,
      callback: function ($$v) {
        _vm.userVersionInputValue = $$v;
      },
      expression: "userVersionInputValue"
    }
  }) : _c("el-button", {
    staticClass: "button-new-tag",
    attrs: {
      size: "small"
    },
    on: {
      click: function ($event) {
        return _vm.showInput(false);
      }
    }
  }, [_vm._v("+ New Version")])], 2)])], 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "About us link",
      prop: "aboutUsLink"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "aboutUsLink",
      placeholder: "About Us link"
    },
    model: {
      value: _vm.postForm.aboutUsLink,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "aboutUsLink", $$v);
      },
      expression: "postForm.aboutUsLink"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Privacy policy link",
      prop: "privacyPolicyLink"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "privacyPolicyLink",
      placeholder: "Privacy Policy link"
    },
    model: {
      value: _vm.postForm.privacyPolicyLink,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "privacyPolicyLink", $$v);
      },
      expression: "postForm.privacyPolicyLink"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Terms and conditions link",
      prop: "termsAndConditionsLink"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "termsAndConditionsLink",
      placeholder: "Terms and Conditions link"
    },
    model: {
      value: _vm.postForm.termsAndConditionsLink,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "termsAndConditionsLink", $$v);
      },
      expression: "postForm.termsAndConditionsLink"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    staticStyle: {
      background: "#4d9ad3",
      color: "white"
    },
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;