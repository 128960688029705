"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'eye-on': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<defs/><path pid="0" d="M512 128q69.7 0 135.5 21.2t115.5 55 93.5 74.8 73.7 82 51.6 74.8 32.2 54.9l10 21.3-6.3 13.5q-4 8.5-18.8 34.7t-31.7 51.6-44.3 60-56.9 64.4-69.5 60.1-82.3 51.5-94.9 34.7T512 896q-69.7 0-135.5-21.2T261 820t-93.5-74.3-73.7-81.5-51.6-74.5-32.2-55l-10-21 6.3-13.5q4-8.5 18.8-34.8t31.7-51.8 44.3-60.4 56.9-64.6 69.5-60.4 82.3-51.8 94.9-34.8T512 128zm0 85.3q-46.7 0-91.6 12.4t-81.2 31.8-70.7 47.1-59.6 54.5-48.9 57.7-37.6 52.8-26.4 44q12.4 21.7 26.4 43.5t37.6 52.4 48.9 57 59.6 53.8 70.7 46.7 81.2 31.5 91.6 12.2 91.6-12.4 81.2-31.6 70.7-46.9 59.6-54.2 48.9-57.3 37.6-52.7T928 512q-12.4-21.7-26.4-43.6T864 415.7t-49-57.3-59.6-54.2-70.7-46.9-81.2-31.6-91.6-12.4zm0 128q70.7 0 120.7 50t50 120.7-50 120.7-120.7 50-120.7-50-50-120.7 50-120.7 120.7-50zm0 85.4q-35.3 0-60.3 25t-25 60.3 25 60.3 60.3 25 60.3-25 25-60.3-25-60.3-60.3-25z"/>'
  }
});