"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.editing = false;
  }
  deleteTodo(todo) {
    this.$emit('deleteTodo', todo);
  }
  editTodo({
    todo,
    value
  }) {
    this.$emit('editTodo', {
      todo,
      value
    });
  }
  toggleTodo(todo) {
    this.$emit('toggleTodo', todo);
  }
  doneEdit(e) {
    const value = e.target.value.trim();
    const {
      todo
    } = this;
    if (!value) {
      this.deleteTodo(todo);
    } else if (this.editing) {
      this.editTodo({
        todo,
        value
      });
      this.editing = false;
    }
  }
  cancelEdit(e) {
    e.target.value = this.todo.text;
    this.editing = false;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {
    text: '',
    done: false
  }
})], default_1.prototype, "todo", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'TodoDemo',
  directives: {
    focus: (el, {
      value
    }, {
      context
    }) => {
      if (value) {
        if (context) {
          context.$nextTick(() => {
            el.focus();
          });
        }
      }
    }
  }
})], default_1);
var _default = exports.default = default_1;