"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const trainerRouter = {
  path: '/trainer',
  component: _index.default,
  redirect: 'noredirect',
  name: 'Trainer Management',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'trainerManagement.title',
    icon: 'dumbbell'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */'@/views/trainer/list.vue'))),
    name: 'trainer',
    meta: {
      title: 'trainerManagement.title',
      noCache: true,
      icon: 'dumbbell'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/trainer/manage.vue'))),
    name: 'Edit Admin',
    props: {
      isEdit: true
    },
    meta: {
      title: 'trainerManagement.trainer.manage',
      noCache: true,
      activeMenu: '/trainer/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/trainer/manage.vue'))),
    name: 'Add trainer',
    props: {
      isEdit: false
    },
    meta: {
      title: 'trainerManagement.trainer.manage',
      noCache: true,
      activeMenu: '/trainer/list',
      hidden: true
    }
  }, {
    path: 'slots/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/trainerSlot/TrainerDetail.vue'))),
    name: 'Manage Slot',
    meta: {
      title: 'trainerManagement.trainer.manage',
      noCache: true,
      activeMenu: '/trainer/list',
      hidden: true
    }
  }]
};
var _default = exports.default = trainerRouter;