"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("../../../utils");
var _celebrity = require("@/api/celebrity");
var _common = require("@/api/common");
var _category = require("@/api/category");
var _index = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Uploadvideo/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.redirectionValue = [];
    this.redirectionOption = [{
      id: 0,
      name: 'Category Page'
    }];
    this.showImageUpload = false;
    this.renderKey = 0;
    this.image = 'https://via.placeholder.com/';
    this.postForm = Object.assign({}, _celebrity.defaultCelebrityData);
    this.loading = false;
    this.rules = {
      video: [{
        message: 'Please upload video',
        required: true,
        trigger: 'blur'
      }],
      image: [{
        message: 'Please upload image',
        required: true,
        trigger: 'blur'
      }]
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  // private updateCelebrityImage(res:any){
  //   this.postForm.image=res;
  // }
  //  private removeCelebrityImage(){
  //   this.postForm.image='';
  // }
  updateImage(res) {
    this.postForm.image = res;
  }
  removeImage() {
    this.postForm.image = '';
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  onClose() {
    this.showImageUpload = false;
  }
  updateCelebrityMedia(res) {
    this.postForm.video = res;
  }
  removeCelebrityMedia() {
    this.postForm.video = '';
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'mp4.' + blob.type.split('/')[1], {
        type: 'mp4/'
      });
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.image = res.toString();
      });
    });
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _celebrity.defaultCelebrityData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _celebrity.getCelebrityById)(id);
      this.postForm = data;
      this.renderKey++;
      this.image = data.image;
      // this.fetchValues()
      // Just for test
      const title = 'image';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchValues() {
    this.redirectionValue = await (0, _category.getCategorys)((0, _utils.getQuery)({
      enabled: true,
      isDeleted: false,
      filter: {
        enabled: 'eq',
        isDeleted: 'eq'
      }
    }));
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
    this.renderKey--;
    this.postForm.video = '';
    this.postForm.image = '';
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _celebrity.updateCelebrity)(this.postForm.id, this.postForm);
      } else {
        await (0, _celebrity.addCelebrity)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'Celebrity saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/celebrity/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'CelebrityDetail',
  components: {
    // PanThumb,
    // AvatarUpload,
    UploadImage: _index.default,
    UploadVideo: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;