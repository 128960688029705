"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateWorkoutMaster = exports.updateOrder = exports.getWorkoutMasterInfo = exports.getWorkoutMasterCount = exports.getWorkoutMasterById = exports.getWorkoutMasterByEmail = exports.getWorkoutMaster = exports.deleteWorkoutMaster = exports.defaultWorkoutMasterData = exports.addWorkoutMaster = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultWorkoutMasterData = exports.defaultWorkoutMasterData = {
  id: 0,
  enabled: true,
  name: '',
  image: [],
  status: 0,
  priority: 0
};
const getWorkoutMaster = params => (0, _request.default)({
  url: '/workout-master',
  method: 'get',
  params
});
exports.getWorkoutMaster = getWorkoutMaster;
const getWorkoutMasterById = id => (0, _request.default)({
  url: `/workout-master/${id}`,
  method: 'get'
});
exports.getWorkoutMasterById = getWorkoutMasterById;
const getWorkoutMasterInfo = data => (0, _request.default)({
  url: '/workout-master/me',
  method: 'post',
  data
});
exports.getWorkoutMasterInfo = getWorkoutMasterInfo;
const getWorkoutMasterByEmail = WorkoutMasteremail => (0, _request.default)({
  url: `/workout-master/${WorkoutMasteremail}`,
  method: 'get'
});
exports.getWorkoutMasterByEmail = getWorkoutMasterByEmail;
const updateWorkoutMaster = (id, data) => (0, _request.default)({
  url: `/workout-master/${id}`,
  method: 'patch',
  data
});
exports.updateWorkoutMaster = updateWorkoutMaster;
const deleteWorkoutMaster = id => (0, _request.default)({
  url: `/workout-master/${id}`,
  method: 'delete'
});
exports.deleteWorkoutMaster = deleteWorkoutMaster;
const addWorkoutMaster = data => (0, _request.default)({
  url: '/workout-master/',
  method: 'post',
  data
});
exports.addWorkoutMaster = addWorkoutMaster;
const getWorkoutMasterCount = params => (0, _request.default)({
  url: '/workout-master/count',
  method: 'get',
  params
});
exports.getWorkoutMasterCount = getWorkoutMasterCount;
const updateOrder = data => (0, _request.default)({
  url: '/workout-master/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;