"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _trainingExpertise = require("@/api/training-expertise");
var _admins = require("@/api/admins");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.listQuery = {
      page: 1,
      limit: 10,
      isDeleted: false,
      sort: '',
      filter: {
        enabled: 'eq',
        name: '$contL'
      }
    };
    this.sortOptions = [{
      label: 'Priority Ascending',
      key: 'priority,ASC'
    }, {
      label: 'Priority Descending',
      key: 'priority,DESC'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  created() {
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _trainingExpertise.getTrainingExpertises)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.list.forEach(async admin => {
        if (admin.createdBy) {
          var user = await (0, _admins.getAdminById)(admin.createdBy);
          if (user) {
            admin.createdByFullName = user.fullName;
          } else {
            admin.createdByFullName = 'Unknown';
          }
        } else {
          admin.createdByFullName = 'Unknown';
        }
      });
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  async handleIsDelete(row) {
    this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(async () => {
      const data = row;
      data.isDeleted = true;
      await (0, _trainingExpertise.updateTrainingExpertise)(row.id, data);
      this.$message({
        type: 'success',
        message: 'Delete completed'
      });
      this.getList();
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc('table.deleteConfirmation', undefined, {
        name: data.name
      }), this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => {
        (0, _trainingExpertise.deleteTrainingExpertise)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      });
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'CategoryList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;