"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateMembership = exports.getMembershipInfo = exports.getMembershipCount = exports.getMembershipById = exports.getMembershipByEmail = exports.getMembership = exports.deleteMembership = exports.addMembership = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
// import { IMembershipData } from './types'
// export const defaultMembershipData: IMembershipData = {
//   id: 0,
//   enabled: true,
//   clickCount: 0,
//   createdBy: 0,
//   image: '',
//   redirectionOption: null,
//   redirectionValue: null,
//   priority: 0
// }
const getMembership = params => (0, _request.default)({
  url: '/membership-enrollment',
  method: 'get',
  params
});
exports.getMembership = getMembership;
const getMembershipById = id => (0, _request.default)({
  url: `/membership-enrollment/${id}`,
  method: 'get'
});
exports.getMembershipById = getMembershipById;
const getMembershipInfo = data => (0, _request.default)({
  url: '/membership-enrollment/me',
  method: 'post',
  data
});
exports.getMembershipInfo = getMembershipInfo;
const getMembershipByEmail = Membershipemail => (0, _request.default)({
  url: `/membership-enrollment/${Membershipemail}`,
  method: 'get'
});
exports.getMembershipByEmail = getMembershipByEmail;
const updateMembership = (id, data) => (0, _request.default)({
  url: `/membership-enrollment/${id}`,
  method: 'patch',
  data
});
exports.updateMembership = updateMembership;
const deleteMembership = Membershipname => (0, _request.default)({
  url: `/membership-enrollment/${Membershipname}`,
  method: 'delete'
});
exports.deleteMembership = deleteMembership;
const addMembership = data => (0, _request.default)({
  url: '/membership-enrollment/',
  method: 'post',
  data
});
exports.addMembership = addMembership;
const getMembershipCount = params => (0, _request.default)({
  url: '/membership-enrollment/count',
  method: 'get',
  params
});
exports.getMembershipCount = getMembershipCount;
const updateOrder = data => (0, _request.default)({
  url: '/membership-enrollment/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;