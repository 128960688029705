"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("h3", [_vm._v("Personal Details")]), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Profile Picture",
      prop: "profilePicture",
      "label-width": "200px"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      "form-field": _vm.postForm.profilePicture
    },
    on: {
      "handle-upload": _vm.updateProfilePictureImage,
      "handle-remove": _vm.removeProfilePictureImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Name",
      prop: "fullName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "fullName",
      placeholder: "Full Name"
    },
    model: {
      value: _vm.postForm.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "fullName", $$v);
      },
      expression: "postForm.fullName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Contact Number",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      maxlength: "10",
      name: "contactNumber",
      placeholder: "Contact Number"
    },
    model: {
      value: _vm.postForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "contactNumber", $$v);
      },
      expression: "postForm.contactNumber"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Email ID",
      prop: "email"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "email",
      placeholder: "Email"
    },
    model: {
      value: _vm.postForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "email", $$v);
      },
      expression: "postForm.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Age",
      prop: "age"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      maxlength: "10",
      name: "age",
      placeholder: "Age"
    },
    model: {
      value: _vm.postForm.age,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "age", $$v);
      },
      expression: "postForm.age"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Gender",
      prop: "gender"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.postForm.gender,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "gender", $$v);
      },
      expression: "postForm.gender"
    }
  }, _vm._l(_vm.genderList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1)], 1)], 1)], 1), _c("h2", [_vm._v("Plan Details")]), _c("el-card", [_c("div", {
    staticClass: "container"
  }, [_c("span", [_vm._v(" Plan name (Monday, wednesday, friday) ")]), _c("el-row", {
    staticStyle: {
      "background-color": "#F5F5F5"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", [_c("span", [_vm._v("Type:")])]), _c("el-col", [_c("span", [_vm._v("Total sessions:")])]), _c("el-col", [_c("span", [_vm._v("total nutrition follow ups :")])]), _c("el-col", [_c("span", [_vm._v("Total plan freeze:")])]), _c("el-col", [_c("span", [_vm._v("total cancel:")])])], 1)], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;