"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _users = require("@/api/users");
var _chalenges = require("@/api/chalenges");
var _reminder = require("@/api/reminder");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.reportlist = [];
    this.feedlist = [];
    this.feedtype = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.dialogVisibility = false;
    this.dialogVisible = false;
    this.dialogLoading = false;
    this.statusDialogVisible = false;
    this.statusDialogLoading = false;
    this.fullscreenLoading = false;
    this.dialogDriverLicenseVisible = false;
    this.dialogBusinessLicenseVisible = false;
    this.dialogViewMoreDetail = false;
    this.status = 0;
    this.showCreatedDate = false;
    this.reason = "";
    // activeTab: any = 'inspiration';
    this.userData = Object.assign({}, _users.defaultUserData);
    this.postCount = [];
    this.userDetail = [];
    this.languageDetail = [];
    this.challengesDetail = [];
    this.remenderDetail = [];
    this.UserDetailsdrawer = false;
    this.UserDetaisdrawerTitle = "Users Details";
    this.UserChallengesdrawer = false;
    this.UserChallengesdrawerTitle = "Users Challenges Details";
    this.UserRemenderdrawer = false;
    this.UserRemenderdrawerTitle = "Users Remenders Details";
    this.maleheight = 0;
    this.femaleheight = 0;
    this.listQuery = {
      sort: "id,DESC",
      page: 1,
      limit: 10,
      enabled: true,
      // type:1,
      contactNumber: undefined,
      filter: {
        enabled: "eq",
        fullName: "$contL",
        contactNumber: "$contL"
      }
    };
    this.eventQuery = {
      page: 1,
      type: 1,
      feedId: undefined,
      filter: {
        feedId: "$eq",
        enabled: "eq",
        type: "eq"
      }
    };
    this.countQuery = {
      // page: 1,
      // type: 1,
      // userId: undefined,
      filter: {
        // userId: "$eq",
        enabled: "eq"
      }
    };
    this.reportQuery = {
      userId: undefined,
      filter: {
        userId: "$eq"
      }
    };
    this.languageQuery = {
      sort: "id,DESC",
      userId: undefined,
      filter: {
        userId: "eq",
        enabled: "eq"
      }
    };
    this.challengesQuery = {
      sort: "id,DESC",
      userId: undefined,
      filter: {
        userId: "eq",
        enabled: "eq"
      }
    };
    // inspiration;
    // hire designer
    // service provider
    this.reasonList = [];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.statusList = [{
      label: "Pending",
      value: 0
    }, {
      label: "In Progress",
      value: 1
    }, {
      label: "Approved",
      value: 2
    }, {
      label: "Rejected",
      value: 3
    }];
    this.sortOptions = [{
      label: "Id, ASC",
      value: "id,ASC"
    }, {
      label: "Id, DESC",
      value: "id,DESC"
    }];
  }
  created() {
    var _this$$route$query;
    if ((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.page) {
      var _this$$route$query2;
      this.listQuery.page = Number((_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.page);
    }
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      this.$router.push({
        path: this.$route.fullPath,
        query: {
          page: String(this.listQuery.page)
        }
      });
      const data = await (0, _users.getUsers)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.languageDetail = this.list;
      console.log("Language list is:", this.languageDetail);
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  OpenStatus(row) {
    this.userData = row;
    this.statusDialogVisible = true;
  }
  // private handleClose(done: any) {
  //   this.$confirm("Are you sure to close this dialog?")
  //     .then((_) => {
  //       done();
  //     })
  //     .catch((_) => {});
  // }
  async getAllList() {
    try {
      this.listLoading = true;
      const data = await (0, _users.getUsers)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getSingleUsers(id) {
    const data = await (0, _users.getUserDetailById)(id);
    this.userDetail = data;
    ;
    // Assuming data.height is always a number
    this.maleheight = Number(data.height);
    this.femaleheight = Number(data.height);
    if (Number.isInteger(this.maleheight)) {
      this.maleIbw = 50 + 0.91 * (this.maleheight - 152.4);
      this.femaleIbw = 45.5 + 0.91 * (this.femaleheight - 152.4);
    } else {
      const [feet, inches] = data.height.toString().split(".").map(Number);
      const heightInCentimeters = feet * 30.48 + inches * 2.54;
      const heightInCm = Math.round(heightInCentimeters);
      this.maleIbw = (50 + 0.91 * (heightInCm - 152.4)).toFixed(2);
      this.femaleIbw = (45.5 + 0.91 * (heightInCm - 152.4)).toFixed(2);
    }
    this.UserDetailsdrawer = true;
    this.languageQuery.userId = id;
  }
  async getChallengesDetails(id) {
    this.challengesQuery.userId = id;
    const data = await (0, _chalenges.getChallenge)((0, _index2.getQuery)(this.challengesQuery));
    this.challengesDetail = data;
    // this.challengesDetail.forEach((res:any)=>{
    // })
    this.UserChallengesdrawer = true;
    // this.languageQuery.userId = id;
  }
  async getRemenderDetails(id) {
    ;
    this.challengesQuery.userId = id;
    const data = await (0, _reminder.getReminder)((0, _index2.getQuery)(this.challengesQuery));
    this.remenderDetail = data;
    this.UserRemenderdrawer = true;
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _users.deleteUser)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  async handleClose() {
    this.UserDetailsdrawer = false;
  }
  async detailsDrawerClose() {
    this.UserChallengesdrawer = false;
  }
  async remenderDrawerClose() {
    this.UserRemenderdrawer = false;
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  FeedData(Id) {
    // this.listQuery.Id = this.list.feedId;
    //  this.listQuery.Id = this.list.feedId;
    console.log("user id is:", Id);
  }
  handleDownload() {
    this.downloadLoading = true;
    this.list.forEach(order => {
      order.type = order.type == 0 ? "Student" : "Off-Campus";
    });
    const tHeader = [" ID", "First Name", "Last Name", "Contact Number",
    // 'Email',
    "CollegeName", "Gender", "User Types"];
    const filterVal = ["id", "firstName", "lastName", "contactNumber",
    // 'email',
    "college.collegeName", "gender", "type"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, "Users Details");
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "usersList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;