"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSaveArticle = exports.updateOrder = exports.getSaveArticles = exports.getSaveArticleInfo = exports.getSaveArticleCount = exports.getSaveArticleById = exports.getSaveArticleByEmail = exports.deleteSaveArticle = exports.defaultSaveArticaleData = exports.addSaveArticle = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSaveArticaleData = exports.defaultSaveArticaleData = {
  id: 0,
  enabled: true,
  priority: 0,
  adminId: null,
  articleId: null,
  title: '',
  desc: '',
  savedByUserIds: [],
  createdBy: 0
};
const getSaveArticles = params => (0, _request.default)({
  url: '/save-article',
  method: 'get',
  params
});
exports.getSaveArticles = getSaveArticles;
const getSaveArticleById = id => (0, _request.default)({
  url: `/save-article/${id}`,
  method: 'get'
});
exports.getSaveArticleById = getSaveArticleById;
const getSaveArticleInfo = data => (0, _request.default)({
  url: '/save-article/me',
  method: 'post',
  data
});
exports.getSaveArticleInfo = getSaveArticleInfo;
const getSaveArticleByEmail = Articleemail => (0, _request.default)({
  url: `/save-article/${Articleemail}`,
  method: 'get'
});
exports.getSaveArticleByEmail = getSaveArticleByEmail;
const updateSaveArticle = (id, data) => (0, _request.default)({
  url: `/save-article/${id}`,
  method: 'patch',
  data
});
exports.updateSaveArticle = updateSaveArticle;
const deleteSaveArticle = Articlename => (0, _request.default)({
  url: `/save-article/${Articlename}`,
  method: 'delete'
});
exports.deleteSaveArticle = deleteSaveArticle;
const addSaveArticle = data => (0, _request.default)({
  url: '/save-article/',
  method: 'post',
  data
});
exports.addSaveArticle = addSaveArticle;
const getSaveArticleCount = params => (0, _request.default)({
  url: '/save-article/count',
  method: 'get',
  params
});
exports.getSaveArticleCount = getSaveArticleCount;
const updateOrder = data => (0, _request.default)({
  url: '/save-article/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;