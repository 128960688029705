"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultGroupData = void 0;
var _compat = _interopRequireDefault(require("firebase/compat"));
const defaultGroupData = exports.defaultGroupData = {
  groupIcon: '',
  groupName: '',
  lastMessage: 'No messages yet',
  // lastMessageUserId: undefined, // Default user reference
  // lastUpdateTimestamp: undefined, // Epoch time as a default
  // lastUserId: undefined, // Default user ID
  timestamp: _compat.default.firestore.FieldValue.serverTimestamp()
};