"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateContactFollowup = exports.getContactFollowupsCount = exports.getContactFollowups = exports.getContactFollowupInfo = exports.getContactFollowupById = exports.getContactFollowupByEmail = exports.deleteContactFollowup = exports.defaultContactFollowupData = exports.addContactFollowup = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultContactFollowupData = exports.defaultContactFollowupData = {
  id: 0,
  enabled: true,
  contactedDate: '',
  userId: 0,
  nextDate: '',
  message: ''
};
const getContactFollowups = params => (0, _request.default)({
  url: '/follow-up',
  method: 'get',
  params
});
exports.getContactFollowups = getContactFollowups;
const getContactFollowupById = id => (0, _request.default)({
  url: `/follow-up/${id}`,
  method: 'get'
});
exports.getContactFollowupById = getContactFollowupById;
const getContactFollowupInfo = data => (0, _request.default)({
  url: '/follow-up/me',
  method: 'post',
  data
});
exports.getContactFollowupInfo = getContactFollowupInfo;
const getContactFollowupByEmail = Enquiryemail => (0, _request.default)({
  url: `/follow-up/${Enquiryemail}`,
  method: 'get'
});
exports.getContactFollowupByEmail = getContactFollowupByEmail;
const updateContactFollowup = (id, data) => (0, _request.default)({
  url: `/follow-up/${id}`,
  method: 'patch',
  data
});
exports.updateContactFollowup = updateContactFollowup;
const deleteContactFollowup = Enquiryname => (0, _request.default)({
  url: `/follow-up/${Enquiryname}`,
  method: 'delete'
});
exports.deleteContactFollowup = deleteContactFollowup;
const addContactFollowup = data => (0, _request.default)({
  url: '/follow-up/',
  method: 'post',
  data
});
exports.addContactFollowup = addContactFollowup;
const getContactFollowupsCount = params => (0, _request.default)({
  url: '/follow-up/count',
  method: 'get',
  params
});
exports.getContactFollowupsCount = getContactFollowupsCount;