"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let VideoUploadDialog = class VideoUploadDialog extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.uploadUrl = process.env.VUE_APP_BASE_API + 'common/upload';
    this.uploadedFileUrl = '';
    this.uploadedFileName = '';
    this.fileList = [];
  }
  handleClose() {
    this.fileList = [];
    this.closeDialog();
  }
  closeDialog() {
    this.$emit('update:dialogVisible', false);
  }
  fileUploadSuccess(response, file, fileList) {
    console.log('uploaded file:', file);
    this.uploadedFileUrl = response;
    this.uploadedFileName = file.name;
  }
  onPreview(file) {
    console.log('file', file);
    window.open(file.response, '_blank');
  }
  onRemove(file, fileList) {
    console.log('fileList:', fileList);
    this.fileList = fileList;
    this.uploadedFileUrl = '';
    this.uploadedFileName = file.name;
  }
  beforeUpload(file) {
    const isVideo = ['video/mp4', 'video/avi', 'video/quicktime'].includes(file.type);
    if (!isVideo) {
      this.$message.error('Only video files are allowed!');
      return false;
    }
    return true;
  }
  handleSubmit() {
    console.log('Submitting form with video:', this.fileList[0]);
    this.onSubmit(this.uploadedFileUrl, this.uploadedFileName);
    this.closeDialog();
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], VideoUploadDialog.prototype, "dialogVisible", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  type: Function,
  required: true
})], VideoUploadDialog.prototype, "onSubmit", void 0);
VideoUploadDialog = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'VideoUploadDialog'
})], VideoUploadDialog);
var _default = exports.default = VideoUploadDialog;