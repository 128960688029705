"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateLanguageKeysValue = exports.updateKeysValue = exports.updateKeys = exports.getKeyss = exports.getKeysValueById = exports.getKeysValue = exports.getKeysById = exports.deleteKeys = exports.defaultKeysValueData = exports.defaultKeysData = exports.createKeys = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultKeysData = exports.defaultKeysData = {
  id: 0,
  defaultValue: '',
  name: ''
};
const defaultKeysValueData = exports.defaultKeysValueData = {
  id: 0,
  defaultValue: '',
  languageValue: ''
};
const getKeyss = params => (0, _request.default)({
  url: '/key-master',
  method: 'get',
  params
});
exports.getKeyss = getKeyss;
const getKeysById = id => (0, _request.default)({
  url: `/key-master/${id}`,
  method: 'get'
});
exports.getKeysById = getKeysById;
const updateKeys = (id, data) => (0, _request.default)({
  url: `/key-master/${id}`,
  method: 'patch',
  data
});
exports.updateKeys = updateKeys;
const createKeys = data => (0, _request.default)({
  url: '/key-master/add',
  method: 'post',
  data
});
exports.createKeys = createKeys;
const getKeysValue = params => (0, _request.default)({
  url: '/languages-key-value',
  method: 'get',
  params
});
exports.getKeysValue = getKeysValue;
const getKeysValueById = id => (0, _request.default)({
  url: `/languages-key-value/${id}`,
  method: 'get'
});
exports.getKeysValueById = getKeysValueById;
const updateKeysValue = (id, data) => (0, _request.default)({
  url: `/languages-key-value/${id}`,
  method: 'patch',
  data
});
exports.updateKeysValue = updateKeysValue;
const updateLanguageKeysValue = data => (0, _request.default)({
  url: `/languages-key-value/update-defaultValue`,
  method: 'post',
  data
});
exports.updateLanguageKeysValue = updateLanguageKeysValue;
const deleteKeys = data => (0, _request.default)({
  url: `/languages-key-value/deleteKey`,
  method: 'post',
  data
});
exports.deleteKeys = deleteKeys;