"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let PdfUploadDialog = class PdfUploadDialog extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.uploadUrl = process.env.VUE_APP_BASE_API + 'common/upload';
    this.uploadedFileUrl = '';
    this.uploadedFileName = '';
    this.fileList = [];
  }
  handleClose() {
    this.fileList = []; // Clear file list on close
    this.closeDialog();
  }
  closeDialog() {
    this.$emit('update:dialogVisible', false); // Emit event to close dialog
  }
  fileUploadSuccess(response, file, fileList) {
    console.log('uploaded file: ', file);
    this.uploadedFileUrl = response;
    this.uploadedFileName = file.name;
  }
  onPreview(file) {
    console.log('file', file);
    window.open(file.response, '_blank');
  }
  // on-remove
  onRemove(file, fileList) {
    console.log('fileList: ', fileList);
    this.fileList = fileList;
    this.uploadedFileUrl = '';
    this.uploadedFileName = file.name;
  }
  beforeUpload(file) {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      this.$message.error('Only PDF files are allowed!');
      return false;
    }
    return true;
  }
  handleSubmit() {
    console.log('Submitting form with PDF:', this.fileList[0]);
    debugger;
    this.onSubmit(this.uploadedFileUrl, this.uploadedFileName); // Call the parent's method directly
    this.closeDialog();
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], PdfUploadDialog.prototype, "dialogVisible", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  type: Function,
  required: true
})], PdfUploadDialog.prototype, "onSubmit", void 0);
PdfUploadDialog = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PdfUploadDialog'
})], PdfUploadDialog);
var _default = exports.default = PdfUploadDialog;