"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupModule = void 0;
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _store = _interopRequireDefault(require("@/store"));
let Group = class Group extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.groupDetails = null;
  }
  SET_GROUP_DETAILS(group) {
    this.groupDetails = group;
  }
  CLEAR_GROUP_DETAILS() {
    this.groupDetails = null;
  }
  saveGroupDetails(group) {
    this.SET_GROUP_DETAILS(group);
  }
  clearGroupDetails() {
    this.CLEAR_GROUP_DETAILS();
  }
  getGroupDetails() {
    return this.groupDetails;
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Group.prototype, "SET_GROUP_DETAILS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Group.prototype, "CLEAR_GROUP_DETAILS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Group.prototype, "saveGroupDetails", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Group.prototype, "clearGroupDetails", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Group.prototype, "getGroupDetails", null);
Group = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'group'
})], Group);
const GroupModule = exports.GroupModule = (0, _vuexModuleDecorators.getModule)(Group);