"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _applicant = require("@/api/applicant");
var _contactus = require("@/api/contactus");
var _product = require("@/api/product");
var _utils = require("@/utils");
var _BarChart = _interopRequireDefault(require("./BarChart.vue"));
var _admins = require("@/api/admins");
var _Chart = _interopRequireDefault(require("./Chart.vue"));
var _BarCharts = _interopRequireDefault(require("./BarCharts.vue"));
var _LineChart = _interopRequireDefault(require("../components/LineChart.vue"));
var _PieChart = _interopRequireDefault(require("./PieChart.vue"));
var _RadarChart = _interopRequireDefault(require("./RadarChart.vue"));
var _admin = require("@/store/modules/admin");
var _users = require("@/api/users");
var _celebrity = require("@/api/celebrity");
const lineChartData = {
  userCounts: {
    actualData: [120, 82, 91, 154, 162, 140, 145],
    expectedData: [200, 192, 120, 144, 160, 130, 140]
  }
};
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.listLoading = true;
    this.AdminCount = 0;
    this.ProductsCount = 0;
    this.enquiryCount = 0;
    this.trainerCount = 0;
    this.nutrationistCount = 0;
    this.UsersApprovedCount = 0;
    this.InfluencerApprovedCount = 0;
    this.InfluencerRejectedCount = 0;
    this.UsersRejectedCount = 0;
    this.InfluencerCount = 0;
    this.coachTotal = 0;
    this.playerTotal = 0;
    this.videosTotal = 0;
    this.learningTotal = 0;
    this.teamsTotal = 0;
    this.Users = 0;
    this.UsersCount = 0;
    this.CelebrityCount = 0;
    //  private xData: any = null;
    // private yData: any = null;
    this.cuisinesCount = 0;
    this.stylesCount = 0;
    this.eventsCount = 0;
    this.lineChartData = lineChartData.userCounts;
    this.myRole = _admin.AdminModule.roles[0];
    this.listQuery = {
      userRoleId: 1,
      filter: {
        userRoleId: "eq"
      }
    };
  }
  handleSetLineChartData(type) {
    this.$emit("handleSetLineChartData", type);
  }
  created() {
    // this.getAdminsCount();
    this.getEnquiryCount();
    this.getTrainerCount();
    this.getUsersCount();
    this.getCelebrityCount();
    // this.getUsersApprovedCount();
    // this.getUsersRejectedCount();
    // this.getInfluencerApprovedCount();
    // this.getInfluencerRejectedCount();
    // this.getProductsCount();
    // this.getLeadsCount();
    // this.getStats()
  }
  // private async getStats() {
  //   try {
  //     this.listLoading = true
  //     
  //     const data: any = await getAdminStats()
  //     this.barChartData = data.cuisines
  //     
  //     this.listLoading = false
  //   } catch (error) {
  //     this.listLoading = false
  //   }
  // }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _applicant.getApplicant)((0, _utils.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  // private async getAdminsCount() {
  //   try {
  //     this.listLoading = true;
  //     const data: any = await getAdmins(
  //       getQuery({
  //         sort: "id,DESC",
  //         enabled:true,
  //          adminsRoleId:2,
  //         filter: {
  //          enabled:'eq',
  //           adminsRoleId:'eq',
  //         },
  //       })
  //     );
  //     this.AdminCount = data.length;
  //     this.listLoading = false;
  //   } catch (error) {
  //     this.listLoading = false;
  //   }
  // }
  async getUsersCount() {
    try {
      this.listLoading = true;
      const data = await (0, _users.getUsers)((0, _utils.getQuery)({
        sort: "id,DESC",
        enabled: true,
        filter: {
          enabled: "eq"
        }
      }));
      this.UsersCount = data.length;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getCelebrityCount() {
    try {
      this.listLoading = true;
      const data = await (0, _celebrity.getCelebrity)((0, _utils.getQuery)({
        sort: "id,DESC",
        enabled: true,
        filter: {
          enabled: "eq"
        }
      }));
      this.CelebrityCount = data.length;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getEnquiryCount() {
    try {
      this.listLoading = true;
      const data = await (0, _contactus.getContactUs)((0, _utils.getQuery)({
        sort: "id,DESC",
        enabled: true,
        filter: {
          enabled: "eq"
        }
      }));
      this.enquiryCount = data.length;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getTrainerCount() {
    try {
      this.listLoading = true;
      ;
      const data = await (0, _admins.getAdmins)((0, _utils.getQuery)({
        sort: "id,DESC",
        enabled: true,
        // adminsRoleId:2,
        // filter: {
        //   enabled:'eq',
        //   adminsRoleId:'eq'
        // },
        filter: [
          // { enabled: "eq", adminsRoleId: 1 },
          // { enabled: "eq", adminsRoleId: 2 }, // Filter for trainerCount
          // { enabled: "eq", adminsRoleId:3 }, // Filter for nutrationistCount
        ]
      }));
      // this.trainerCount = data.length;
      // this.nutrationistCount=data.length
      this.AdminCount = data.filter(item => item.adminsRoleId === 1).length;
      this.trainerCount = data.filter(item => item.adminsRoleId === 2).length;
      this.nutrationistCount = data.filter(item => item.adminsRoleId === 3).length;
      ;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getProductsCount() {
    try {
      this.listLoading = true;
      this.ProductsCount = await (0, _product.getProductCount)({
        enabled: "eq"
      });
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "PanelGroup",
  components: {
    CountTo: _vueCountTo.default,
    LineChart: _LineChart.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default,
    BarCharts: _BarCharts.default,
    Chart: _Chart.default,
    RadarChart: _RadarChart.default
  }
})], default_1);
var _default = exports.default = default_1;