"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateCategory = exports.getCategorysCount = exports.getCategorys = exports.getCategoryInfo = exports.getCategoryById = exports.getCategoryByEmail = exports.deleteCategory = exports.defaultCategoryData = exports.addCategory = exports.StoreStoreCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCategoryData = exports.defaultCategoryData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  priority: 0
};
const getCategorys = params => (0, _request.default)({
  url: '/categories',
  method: 'get',
  params
});
exports.getCategorys = getCategorys;
const getCategoryById = id => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'get'
});
exports.getCategoryById = getCategoryById;
const getCategoryInfo = data => (0, _request.default)({
  url: '/categories/me',
  method: 'post',
  data
});
exports.getCategoryInfo = getCategoryInfo;
const getCategoryByEmail = StoreCategoryemail => (0, _request.default)({
  url: `/categories/${StoreCategoryemail}`,
  method: 'get'
});
exports.getCategoryByEmail = getCategoryByEmail;
const updateCategory = (id, data) => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'patch',
  data
});
exports.updateCategory = updateCategory;
const StoreStoreCategory = (id, data) => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'patch',
  data
});
exports.StoreStoreCategory = StoreStoreCategory;
const deleteCategory = StoreCategoryname => (0, _request.default)({
  url: `/categories/${StoreCategoryname}`,
  method: 'delete'
});
exports.deleteCategory = deleteCategory;
const addCategory = data => (0, _request.default)({
  url: '/categories',
  method: 'post',
  data
});
exports.addCategory = addCategory;
const getCategorysCount = params => (0, _request.default)({
  url: '/categories/count',
  method: 'get',
  params
});
exports.getCategorysCount = getCategorysCount;
const updateOrder = data => (0, _request.default)({
  url: '/categories/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;