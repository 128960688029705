"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _Todo = _interopRequireDefault(require("./Todo.vue"));
const STORAGE_KEY = 'todos';
const filters = {
  all: todos => todos,
  active: todos => todos.filter(todo => !todo.done),
  completed: todos => todos.filter(todo => todo.done)
};
const defalutList = [{
  text: 'star this repository',
  done: false
}, {
  text: 'fork this repository',
  done: false
}, {
  text: 'follow author',
  done: false
}, {
  text: 'vue-typescript-admin-template',
  done: true
}, {
  text: 'vue',
  done: true
}, {
  text: 'element-ui',
  done: true
}, {
  text: 'axios',
  done: true
}, {
  text: 'webpack',
  done: true
}];
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.visibility = 'all';
    this.todos = defalutList;
  }
  get allChecked() {
    return this.todos.every(todo => todo.done);
  }
  get filters() {
    return filters;
  }
  get filteredTodos() {
    return filters[this.visibility](this.todos);
  }
  get remaining() {
    return this.todos.filter(todo => !todo.done).length;
  }
  setLocalStorage() {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.todos));
  }
  addTodo(e) {
    const text = e.target.value;
    if (text.trim()) {
      this.todos.push({
        text,
        done: false
      });
      this.setLocalStorage();
    }
    e.target.value = '';
  }
  toggleTodo(todo) {
    todo.done = !todo.done;
    this.setLocalStorage();
  }
  deleteTodo(todo) {
    this.todos.splice(this.todos.indexOf(todo), 1);
    this.setLocalStorage();
  }
  editTodo({
    todo,
    value
  }) {
    todo.text = value;
    this.setLocalStorage();
  }
  clearCompleted() {
    this.todos = this.todos.filter(todo => !todo.done);
    this.setLocalStorage();
  }
  toggleAll({
    done
  }) {
    this.todos.forEach(todo => {
      todo.done = done;
      this.setLocalStorage();
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'TodoList',
  components: {
    Todo: _Todo.default
  },
  filters: {
    pluralize: (n, s) => n === 1 ? s : s + 's',
    capitalize: s => s.charAt(0).toUpperCase() + s.slice(1)
  }
})], default_1);
var _default = exports.default = default_1;