"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateIncludeMaster = exports.getIncludeMasterCount = exports.getIncludeMasterById = exports.getIncludeMaster = exports.getInclideMasterInfo = exports.deleteIncludeMaster = exports.defaultPlanTypeMasterData = exports.addIncludeMaster = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultPlanTypeMasterData = exports.defaultPlanTypeMasterData = {
  id: 0,
  enabled: true,
  name: ''
};
const getIncludeMaster = params => (0, _request.default)({
  url: '/plan-type',
  method: 'get',
  params
});
exports.getIncludeMaster = getIncludeMaster;
const getIncludeMasterById = id => (0, _request.default)({
  url: `/plan-type/${id}`,
  method: 'get'
});
exports.getIncludeMasterById = getIncludeMasterById;
const getInclideMasterInfo = data => (0, _request.default)({
  url: '/plan-type/me',
  method: 'post',
  data
});
exports.getInclideMasterInfo = getInclideMasterInfo;
const updateIncludeMaster = (id, data) => (0, _request.default)({
  url: `/plan-type/${id}`,
  method: 'patch',
  data
});
exports.updateIncludeMaster = updateIncludeMaster;
const deleteIncludeMaster = id => (0, _request.default)({
  url: `/plan-type/${id}`,
  method: 'delete'
});
exports.deleteIncludeMaster = deleteIncludeMaster;
const addIncludeMaster = data => (0, _request.default)({
  url: '/plan-type/',
  method: 'post',
  data
});
exports.addIncludeMaster = addIncludeMaster;
const getIncludeMasterCount = () => (0, _request.default)({
  url: '/plan-type/count',
  method: 'get'
});
exports.getIncludeMasterCount = getIncludeMasterCount;
const updateOrder = data => (0, _request.default)({
  url: '/plan-type/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;