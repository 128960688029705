"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateArticle = exports.getArticles = exports.getArticleInfo = exports.getArticleCount = exports.getArticleById = exports.getArticleByEmail = exports.deleteArticle = exports.defaultArticaleData = exports.addArticle = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultArticaleData = exports.defaultArticaleData = {
  id: 0,
  enabled: true,
  priority: 0,
  adminId: null,
  title: '',
  desc: '',
  image: '',
  savedByUserIds: [],
  createdBy: 0
};
const getArticles = params => (0, _request.default)({
  url: '/article',
  method: 'get',
  params
});
exports.getArticles = getArticles;
const getArticleById = id => (0, _request.default)({
  url: `/article/${id}`,
  method: 'get'
});
exports.getArticleById = getArticleById;
const getArticleInfo = data => (0, _request.default)({
  url: '/article/me',
  method: 'post',
  data
});
exports.getArticleInfo = getArticleInfo;
const getArticleByEmail = Articleemail => (0, _request.default)({
  url: `/article/${Articleemail}`,
  method: 'get'
});
exports.getArticleByEmail = getArticleByEmail;
const updateArticle = (id, data) => (0, _request.default)({
  url: `/article/${id}`,
  method: 'patch',
  data
});
exports.updateArticle = updateArticle;
const deleteArticle = Articlename => (0, _request.default)({
  url: `/article/${Articlename}`,
  method: 'delete'
});
exports.deleteArticle = deleteArticle;
const addArticle = data => (0, _request.default)({
  url: '/article/',
  method: 'post',
  data
});
exports.addArticle = addArticle;
const getArticleCount = params => (0, _request.default)({
  url: '/article/count',
  method: 'get',
  params
});
exports.getArticleCount = getArticleCount;
const updateOrder = data => (0, _request.default)({
  url: '/article/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;