"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const adminRouter = {
  path: '/admins',
  component: _index.default,
  redirect: 'noredirect',
  name: 'Admin Management',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'adminManagement.title',
    icon: 'user'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */'@/views/admin/list.vue'))),
    name: 'Admins',
    meta: {
      title: 'adminManagement.admins.title',
      noCache: true,
      icon: 'user-plus',
      header: 'List of all admins',
      info: 'All admin details are available here.  You can search user information by applying filters on fields like name, email, mobile number, gender etc. Green dot represents active admin. Red dot represents disabled admin'
    }
  }, {
    path: 'logs',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/admin/adminlogs.vue'))),
    name: 'adminManagement.logs.title',
    meta: {
      title: 'adminManagement.logs.title',
      noCache: true,
      icon: 'tennis-ball',
      hidden: true,
      info: 'Coach logs'
    }
  }, {
    path: 'logs/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/admin/adminlog.vue'))),
    name: 'adminManagement.logs.title',
    meta: {
      title: 'adminManagement.logs.title',
      noCache: true,
      icon: 'tennis-ball',
      hidden: true,
      info: 'Coach logs'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/admin/manage.vue'))),
    name: 'Edit Admin',
    props: {
      isEdit: true
    },
    meta: {
      title: 'adminManagement.admins.manage',
      noCache: true,
      activeMenu: '/admin/list',
      hidden: true,
      header: 'Edit Admin',
      info: 'We can edit admin here, we can also assign role to a particular admin'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/admin/manage.vue'))),
    name: 'Add Admin',
    props: {
      isEdit: false
    },
    meta: {
      title: 'adminManagement.admins.manage',
      noCache: true,
      activeMenu: '/admin/list',
      hidden: true,
      header: 'Add Admin',
      info: 'We can create admin here, we can also assign role to a particular admin'
    }
  }, {
    path: '/admin-roles/list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-roles" */'@/views/admin/admin-roles/list.vue'))),
    name: 'Roles',
    meta: {
      title: 'adminManagement.roles.title',
      noCache: true,
      icon: 'user',
      hidden: true
    }
  }, {
    path: '/admin-roles/add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-roles" */'@/views/admin/admin-roles/manage.vue'))),
    name: 'Add Role',
    props: {
      isEdit: false
    },
    meta: {
      title: 'adminManagement.roles.manage',
      noCache: true,
      hidden: true
    }
  }, {
    path: '/admin-roles/edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-roles" */'@/views/admin/admin-roles/manage.vue'))),
    name: 'Edit Role',
    props: {
      isEdit: true
    },
    meta: {
      title: 'adminManagement.roles.manage',
      noCache: true,
      hidden: true
    }
  }]
};
var _default = exports.default = adminRouter;