"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("span", [_c("el-switch", {
    on: {
      change: function ($event) {
        _vm.centerDialogVisible = true;
      }
    },
    model: {
      value: _vm.toggleValue,
      callback: function ($$v) {
        _vm.toggleValue = $$v;
      },
      expression: "toggleValue"
    }
  }), _c("el-dialog", {
    attrs: {
      title: "Enable/Disable",
      visible: _vm.centerDialogVisible,
      "before-close": _vm.handleClickedNo,
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function ($event) {
        _vm.centerDialogVisible = $event;
      }
    }
  }, [_c("center", [_c("span", [_vm._v("Are you sure you want to " + _vm._s(_vm.value ? "Disable" : "Enable") + "?")])]), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.toggleEnable
    }
  }, [_vm._v("Yes")]), _c("el-button", {
    on: {
      click: _vm.handleClickedNo
    }
  }, [_vm._v("No ")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;