"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
const tagSpacing = 4;
let default_1 = class extends _vuePropertyDecorator.Vue {
  handleScroll(e) {
    const eventDelta = e.wheelDelta || -e.deltaY * 40;
    const scrollContainer = this.$refs.scrollContainer;
    const scrollWrapper = scrollContainer.$refs.wrap;
    scrollWrapper.scrollLeft = scrollWrapper.scrollLeft + eventDelta / 4;
  }
  moveToTarget(currentTag) {
    var _this$$parent;
    const scrollContainer = this.$refs.scrollContainer;
    const container = scrollContainer.$el;
    const containerWidth = container.offsetWidth;
    const scrollWrapper = scrollContainer.$refs.wrap;
    const tagList = (_this$$parent = this.$parent) === null || _this$$parent === void 0 ? void 0 : _this$$parent.$refs.tag;
    let firstTag = null;
    let lastTag = null;
    // find first tag and last tag
    if (tagList.length > 0) {
      firstTag = tagList[0];
      lastTag = tagList[tagList.length - 1];
    }
    if (firstTag === currentTag) {
      scrollWrapper.scrollLeft = 0;
    } else if (lastTag === currentTag) {
      scrollWrapper.scrollLeft = scrollWrapper.scrollWidth - containerWidth;
    } else {
      // find preTag and nextTag
      const currentIndex = tagList.findIndex(item => item === currentTag);
      const prevTag = tagList[currentIndex - 1];
      const nextTag = tagList[currentIndex + 1];
      // the tag's offsetLeft after of nextTag
      const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagSpacing;
      // the tag's offsetLeft before of prevTag
      const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagSpacing;
      if (afterNextTagOffsetLeft > scrollWrapper.scrollLeft + containerWidth) {
        scrollWrapper.scrollLeft = afterNextTagOffsetLeft - containerWidth;
      } else if (beforePrevTagOffsetLeft < scrollWrapper.scrollLeft) {
        scrollWrapper.scrollLeft = beforePrevTagOffsetLeft;
      }
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ScrollPane'
})], default_1);
var _default = exports.default = default_1;