"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateChallenge = exports.getChallengeInfo = exports.getChallengeCount = exports.getChallengeById = exports.getChallengeByEmail = exports.getChallenge = exports.deleteChallenge = exports.defaultChalengesData = exports.addChallenge = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultChalengesData = exports.defaultChalengesData = {
  id: 0,
  enabled: true,
  name: '',
  priority: 0,
  startDate: '',
  duration: undefined,
  durationType: undefined,
  desc: '',
  userId: null,
  adminId: null
};
const getChallenge = params => (0, _request.default)({
  url: '/challenge',
  method: 'get',
  params
});
exports.getChallenge = getChallenge;
const getChallengeById = id => (0, _request.default)({
  url: `/challenge/${id}`,
  method: 'get'
});
exports.getChallengeById = getChallengeById;
const getChallengeInfo = data => (0, _request.default)({
  url: '/challenge/me',
  method: 'post',
  data
});
exports.getChallengeInfo = getChallengeInfo;
const getChallengeByEmail = Challengeemail => (0, _request.default)({
  url: `/challenge/${Challengeemail}`,
  method: 'get'
});
exports.getChallengeByEmail = getChallengeByEmail;
const updateChallenge = (id, data) => (0, _request.default)({
  url: `/challenge/${id}`,
  method: 'patch',
  data
});
exports.updateChallenge = updateChallenge;
const deleteChallenge = id => (0, _request.default)({
  url: `/challenge/${id}`,
  method: 'delete'
});
exports.deleteChallenge = deleteChallenge;
const addChallenge = data => (0, _request.default)({
  url: '/challenge/',
  method: 'post',
  data
});
exports.addChallenge = addChallenge;
const getChallengeCount = params => (0, _request.default)({
  url: '/challenge/count',
  method: 'get',
  params
});
exports.getChallengeCount = getChallengeCount;
const updateOrder = data => (0, _request.default)({
  url: '/challenge/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;