import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=d0d0b35a"
import script from "./manage.vue?vue&type=script&lang=ts"
export * from "./manage.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\algoocean\\askknatural-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d0d0b35a')) {
      api.createRecord('d0d0b35a', component.options)
    } else {
      api.reload('d0d0b35a', component.options)
    }
    module.hot.accept("./manage.vue?vue&type=template&id=d0d0b35a", function () {
      api.rerender('d0d0b35a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/diseases/manage.vue"
export default component.exports