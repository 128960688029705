"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _tagsView = require("@/store/modules/tags-view");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _adminsRoles = require("@/api/adminsRoles");
var _utils = require("../../../utils");
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _admin = require("@/store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.image = this.user.avatar ? this.user.avatar : 'https://stumpz.s3.ap-south-1.amazonaws.com/1611434872392-img.png';
    this.showImageUpload = false;
    this.changePasswordLoading = false;
    this.myRole = _admin.AdminModule.roles;
    this.roleList = [];
    this.dialogFormVisible = false;
    this.postForm = Object.assign({}, _admins.defaultAdminData);
    this.loading = false;
    this.genderList = [{
      label: 'Female',
      value: 'Female'
    }, {
      label: 'Male',
      value: 'Male'
    }, {
      label: 'Others',
      value: 'Others'
    }];
    this.changePasswordForm = {
      password: '',
      newPassword: '',
      confirmPassword: ''
    };
    this.validatenewPassword = this.validate;
    this.changePassRules = {
      password: [{
        required: true,
        message: 'Please enter current password',
        trigger: 'blur'
      }, {
        pattern: '^[a-zA-Z0-9_]',
        message: "Space not allow at start of password ",
        trigger: ["blur", "change"]
      }, {
        min: 8,
        message: 'Minimum Length should be 8 Digits',
        trigger: ['blur', 'change']
      }],
      newPassword: [{
        required: true,
        message: 'Please enter new password',
        trigger: 'blur'
      }, {
        pattern: '^[a-zA-Z0-9_]',
        message: "Space not allow at start of new password ",
        trigger: ["blur", "change"]
      },
      // {
      //   pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])',
      //   message: 'enter correct password pattern',
      //   trigger: ['blur', 'change']
      // },
      {
        min: 8,
        message: 'Minimum Length should be 8 Digits',
        trigger: ['blur', 'change']
      }
      // {
      //   validator: this.validatePassword,
      //   trigger: 'blur',
      //   message: 'Password is required'
      // }
      ],
      confirmPassword: [{
        required: true,
        message: 'Please enter confirm password',
        trigger: 'blur'
      }, {
        pattern: '^[a-zA-Z0-9_]',
        message: "Space not allow at start of confirm password ",
        trigger: ["blur", "change"]
      },
      // {
      //   pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])',
      //   message: 'enter correct password pattern',
      //   trigger: ['blur', 'change']
      // },
      {
        min: 8,
        message: 'Minimum Length should be 8 Digits',
        trigger: ['blur', 'change']
      }, {
        validator: this.validateConfirmPassword,
        trigger: 'blur',
        message: 'new password and confirm password does not match'
      }]
    };
    this.rules = {
      fullName: [{
        required: true,
        message: 'Please enter first name',
        trigger: ['blur', 'change']
      }, {
        min: 3,
        message: 'Length should be greater than 3',
        trigger: ['blur', 'change']
      }, {
        pattern: '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
        message: 'First Name should contain characters only',
        trigger: ['blur', 'change']
      }],
      lastName: [{
        required: true,
        message: 'Please enter last name',
        trigger: ['blur', 'change']
      }, {
        min: 3,
        message: 'Length should be greater than 3',
        trigger: ['blur', 'change']
      }, {
        pattern: '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
        message: 'Last Name should contain characters only',
        trigger: ['blur', 'change']
      }],
      contactNumber: [{
        required: true,
        message: 'Please enter mobile number',
        trigger: 'blur'
      }, {
        trigger: ['blur', 'change'],
        pattern: '[123456789][0-9]*$',
        message: 'Mobile number should contain number only'
      }, {
        max: 10,
        min: 10,
        message: 'Length should be of 10 Digits',
        trigger: ['blur', 'change']
      }],
      email: [{
        required: true,
        message: 'please enter e-mail',
        trigger: 'blur'
      }, {
        min: 10,
        max: 50,
        message: 'Length should be between 10-50 characters',
        trigger: ['blur', 'change']
      }, {
        type: 'email',
        message: 'Please enter correct email address',
        trigger: ['blur', 'change']
      }],
      password: [{
        required: true,
        message: 'Please enter password',
        trigger: 'blur'
      }, {
        min: 8,
        max: 15,
        message: 'Length should be 8 to 15',
        trigger: ['blur', 'change']
      }],
      gender: [{
        required: true,
        message: 'Please select gender',
        trigger: ['blur', 'change']
      }],
      adminsRoleId: [{
        required: true,
        message: 'Please select role',
        trigger: ['blur', 'change']
      }]
    };
  }
  created() {
    this.fetchRoles();
    this.fetchData(_admin.AdminModule.id);
  }
  async fetchData(id) {
    try {
      const data = await (0, _admins.getAdminById)(id);
      this.postForm = data;
      this.image = data.avatar;
      // Just for test
      const title = this.postForm.email;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  async fetchRoles() {
    try {
      // var role = this.myRole[0]
      // if (role === 'superadmin') {
      this.roleList = await (0, _adminsRoles.getAdminsRoles)((0, _utils.getQuery)({
        enabled: true,
        sort: 'name,ASC',
        filter: {
          enabled: 'eq'
        }
      }));
      // }
      // if (role === 'Admin') {
      //   this.roleList = await getAdminsRoles(
      //     getQuery({
      //       enabled: true,
      //       sort: 'name,ASC',
      //       id: 3,
      //       filter: {
      //         enabled: 'eq',
      //         id: 'eq'
      //       }
      //     }))
      // }
    } catch (err) {
      Promise.reject(err);
    }
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  onClose() {
    this.showImageUpload = false;
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'img.' + blob.type.split('/')[1], {
        type: 'image/png'
      });
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.profile = res;
      });
    });
  }
  submit() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.postForm.adminsRole.id = this.postForm.adminsRoleId;
      // delete this.postForm.password
      await (0, _admins.updateAdmin)(this.postForm.id, this.postForm);
      this.$notify({
        title: 'Success',
        message: 'Admin saved successfully',
        type: 'success',
        duration: 2000
      });
      window.location.reload();
    } catch (err) {
      this.loading = false;
    }
  }
  validateConfirmPassword(rule, value, callback) {
    if (this.changePasswordForm.newPassword !== this.changePasswordForm.confirmPassword) {
      callback(new Error('new password and confirm password does not match'));
    } else {
      callback();
    }
  }
  validate(rule, value, callback) {
    if (this.changePasswordForm.newPassword.length > 1) {
      if (this.changePasswordForm.newPassword != value) {
        callback(new Error('Passwords does not match'));
      }
    } else {
      callback();
    }
  }
  async changePassword() {
    this.$refs.changePasswordForm.validate(async valid => {
      if (this.changePasswordForm.newPassword === this.changePasswordForm.confirmPassword) {
        if (valid) {
          this.changePasswordLoading = true;
          const data = {
            oldPassword: this.changePasswordForm.password,
            newPassword: this.changePasswordForm.newPassword
          };
          const response = await (0, _admins.UpdatePassword)(data).then(res => {
            if (res.responseCode == 200) {
              this.$message({
                message: 'password changed successfully.',
                type: 'success'
              });
              this.changePasswordLoading = false;
              this.dialogFormVisible = false;
              this.$refs.changePasswordForm.resetFields();
            } else if (res.responseCode == 300) {
              this.changePasswordLoading = false;
              this.$message.error('Enter correct current password');
            }
          }).catch(res => {
            this.changePasswordLoading = false;
            this.$message.error('Some error occured');
          });
        } else {
          // this.$message({
          //   message: '',
          //   type: 'error'
          // })
          return false;
        }
      } else {
        this.$message({
          message: 'Password doesn\'t match',
          type: 'error'
        });
        return false;
      }
    });
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "user", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Account',
  components: {
    AvatarUpload: _index.default,
    PanThumb: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;