"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _firebase = require("@/config/firebase.config");
var _admin = require("@/store/modules/admin");
var _group = require("@/store/modules/group");
var _axios = _interopRequireDefault(require("axios"));
var _app = _interopRequireDefault(require("firebase/compat/app"));
var _moment = _interopRequireDefault(require("moment"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _services = require("./services");
var _PdfUploadDialog = _interopRequireDefault(require("./components/PdfUploadDialog.vue"));
var _VideoUploadDialog = _interopRequireDefault(require("./components/VideoUploadDialog.vue"));
var _MessageWrapper = _interopRequireDefault(require("./components/MessageWrapper.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.app = _app.default.initializeApp(_firebase.firebaseConfig);
    this.db = null;
    this.messages = [];
    this.message = '';
    this.srcList = []; // to store images array from chat
    this.audioChunks = []; // to store audio files in chats
    this.type = 0;
    this.chatLoading = false;
    this.observer = null;
    this.firstName = '';
    this.lastName = '';
    this.profilePic = '';
    this.loggedInUserId = _admin.AdminModule.id;
    this.groupInfo = null;
    this.dialogImageUrl = '';
    this.image = 'https://via.placeholder.com/154';
    this.fileList = [];
    this.editList = [];
    this.uploadImageDialog = false;
    this.uploadDocDialog = false;
    this.uploadVideoDialog = false;
    this.imagelist = [];
    this.dialogVisible = false;
    this.uploadingimage = true;
    this.confirmuploadbtn = false;
    this.members = [];
    this.uploadedPdfName = '';
    this.uploadedVideoName = '';
    this.uploadedVideo = '';
    this.uploadedPdf = '';
    this.uploadedAudio = '';
    this.postForm = {
      image: []
    };
    this.chatNotification = {
      id: 0,
      userName: '',
      message: '',
      type: 0,
      groupChatId: '',
      profilePicture: ''
    };
  }
  handleDialogUploadVisible(event) {
    console.log('event: ', event);
    this.uploadDocDialog = !this.uploadDocDialog;
  }
  handleUploadVideoDialogVisible(event) {
    console.log('event: ', event);
    this.uploadVideoDialog = !this.uploadVideoDialog;
  }
  async created() {
    var _this$groupInfo, _this$groupInfo2;
    this.chatLoading = true;
    this.db = _app.default.firestore();
    const id = this.$route.params && this.$route.params.id;
    const adminId = _admin.AdminModule.id.toString();
    this.admin = _admin.AdminModule;
    console.log(this.admin, 'admin info');
    // this.members = GroupModule.groupDetails?.groupMembers
    // const groupDetails = await fetchConversationById(id);
    this.groupInfo = _group.GroupModule.groupDetails;
    this.members = await (0, _services.getChatMembers)((_this$groupInfo = this.groupInfo) !== null && _this$groupInfo !== void 0 && _this$groupInfo.id ? (_this$groupInfo2 = this.groupInfo) === null || _this$groupInfo2 === void 0 ? void 0 : _this$groupInfo2.id : '');
    const groupData = this.groupInfo;
    if (groupData) {
      groupData.groupMembers = this.members;
      _group.GroupModule.saveGroupDetails(groupData);
    }
    this.observer = this.db.collection('conversation').doc(id).collection('messages').orderBy('timestamp', 'asc').onSnapshot(querySnap => {
      this.messages = querySnap.docs.map(doc => doc.data());
      console.log('messages', this.messages);
      this.messages.map(msg => {
        // to show images to user on click
        if (msg.type == 1) {
          this.srcList.push(msg.image);
        }
        const timestamp = msg.timestamp.toDate();
        const formattedTimestamp = (0, _moment.default)(timestamp).format('DD-MM-YYYY HH:mm:ss');
        msg.timestamp = formattedTimestamp; // ;
      });
      this.scrollBottom();
    });
    this.chatLoading = false;
    await (0, _services.resetReadCountNew)(id, Number(adminId));
    setTimeout(time => {
      let lastChatBox = document.querySelectorAll('.message');
      lastChatBox = lastChatBox[lastChatBox.length - 1];
      lastChatBox.scrollIntoView({
        behavior: 'smooth'
      });
    }, 700);
  }
  beforeDestroy() {
    this.observer();
  }
  scrollBottom() {
    let lastChatBox = document.querySelectorAll('.message');
    lastChatBox = lastChatBox[lastChatBox.length - 1];
    lastChatBox.scrollIntoView({
      behavior: 'smooth'
    });
  }
  sentOrReceived(msg) {
    return msg.senderId == _admin.AdminModule.id ? 'sent' : 'received';
  }
  sentOrReceivedUserImage(msg) {
    var _this$members;
    let profilePic = '';
    (_this$members = this.members) === null || _this$members === void 0 || _this$members.forEach(member => {
      if (msg.senderId == member.id) {
        profilePic = member.profilePic;
      }
    });
    return profilePic;
  }
  sentOrReceivedUserName(msg) {
    var _this$members2;
    let username = '';
    (_this$members2 = this.members) === null || _this$members2 === void 0 || _this$members2.forEach(member => {
      if (msg.senderId == member.id) {
        username = member.name;
      }
    });
    return username;
  }
  handleImage() {
    if (Array.isArray(this.postForm.image)) {
      return this.postForm.image.length > 0 ? this.postForm.image[0] : '';
    }
    return this.postForm.image;
  }
  getMediaType(mediaType) {
    switch (mediaType) {
      case 1:
        return 'image';
      case 2:
        return 'audio';
      case 3:
        return 'video';
      case 4:
        return 'pdf';
      default:
        return 'Unknown media type';
      // Handle cases where the input number doesn't match any type
    }
  }
  async sendMessage() {
    const id = this.$route.params && this.$route.params.id;
    const adminId = _admin.AdminModule.id.toString();
    let messagetemp = this.message;
    this.message = '';
    const messageInfo = {
      senderId: parseInt(this.admin.id.toString()),
      text: messagetemp,
      role: this.admin.roleId,
      type: this.type,
      timestamp: _app.default.firestore.FieldValue.serverTimestamp()
    };
    //Image
    if (this.type == 1) {
      messageInfo.type = 1;
      messageInfo.image = this.handleImage();
      this.chatNotification.type = 1;
    }
    //Audio
    if (this.type == 2) {
      messageInfo.type = 2;
      messageInfo.text = 'Audio';
      messageInfo.audio = this.uploadedAudio;
      this.chatNotification.type = 2;
    }
    //Video
    if (this.type == 3) {
      messageInfo.type = 3;
      this.chatNotification.type = 3;
      this.chatNotification;
      messageInfo.text = this.uploadedVideoName;
      messageInfo.video = this.uploadedVideo;
    }
    //PDF
    if (this.type == 4) {
      messageInfo.type = 4;
      this.chatNotification.type = 4;
      messageInfo.text = this.uploadedPdfName;
      messageInfo.pdf = this.uploadedPdf;
    }
    if (messagetemp != '' || messageInfo.image != '') {
      await (0, _services.updateUnreadCount)(id, Number(adminId));
      if (messageInfo.type == 0) {
        await (0, _services.updateLastMessage)(id, messagetemp, Number(adminId), _admin.AdminModule.name, messageInfo.type);
      } else {
        await (0, _services.updateLastMessage)(id, this.getMediaType(messageInfo.type), Number(adminId), _admin.AdminModule.name, messageInfo.type);
      }
      try {
        const chatRes = await this.db.collection('conversation').doc(id).collection('messages').add(messageInfo);
        console.log('chat res: ' + chatRes);
      } catch (error) {
        const er = error;
        console.log(er.message);
      }
      let notificationPayload;
      notificationPayload = {
        text: messageInfo.text,
        members: this.members ? this.members : [],
        conversation: this.groupInfo
      };
      if (messageInfo.type == 1) {
        notificationPayload.text = 'sent an image';
      }
      if (messageInfo.type == 4) {
        notificationPayload.text = 'sent a pdf';
      }
      if (messageInfo.type == 2) {
        notificationPayload.text = 'sent an audio message';
      }
      if (messageInfo.type == 3) {
        notificationPayload.text = 'sent a video message';
      }
      await (0, _services.sendMessageNotification)(notificationPayload.text, notificationPayload.members, notificationPayload.conversation);
      this.scrollBottom();
      messagetemp = '';
      messageInfo.text = '';
      messageInfo.type = 0;
      this.type = 0;
      // console.log('img uploaded',messagetemp,this.type,messageInfo)
    }
    // this.$refs["scrollable"d].scrollIntoView({ behavior: "smooth" });
  }
  async uploadImageChat() {
    this.imagelist.forEach(async img => {
      this.postForm.image = img.toString();
      this.type = 1;
      console.log('img uploading', img);
      await this.sendMessage();
    });
    // for (let i = 0; i < this.imagelist.length; i++) {
    //   this.message = this.imagelist[i].toString();
    //   this.type = 1;
    //   this.sendMessage();
    // }
    this.chatLoading = false;
    this.imagelist = [];
    this.fileList = [];
    this.editList = [];
    this.handleCancel(); // to empty filelist and display dragger
  }
  async sendDocMessage(docResponse, docName) {
    this.type = 4;
    this.uploadedPdfName = docName;
    this.uploadedPdf = docResponse;
    await this.sendMessage();
  }
  async sendVideoMessage(docResponse, docName) {
    this.uploadedVideo = docResponse;
    this.type = 3;
    this.uploadedVideoName = docName;
    await this.sendMessage();
  }
  async uploadImages(file, fileList) {
    const img = new Image();
    img.onload = () => {
      this.fileList = fileList;
      this.editList.push(file);
      const imgDragger = document.querySelector('.el-upload--picture-card ');
      if (this.fileList.length >= 3) {
        imgDragger.style.display = 'none';
      } else {
        imgDragger.style.display = 'block';
      }
    };
    img.src = file.url;
  }
  beforeUpload(file) {
    const _this = this;
    const isSize = new Promise(function (resolve, reject) {
      const width = 654;
      const height = 270;
      const img = new Image();
      img.onload = function () {
        const valid = img.width === width && img.height === height;
        valid ? resolve() : reject();
      };
      img.src = file.src;
    }).then(() => {
      return file;
    }, () => {
      _this.$message.error('The image size is limited to 654 x 270, the size cannot exceed 1MB');
      return Promise.reject();
    });
    return isSize;
    const isJPG = file.type === 'image/jpeg';
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPG) {
      this.$message.error('Avatar picture must be JPG format!');
    }
    if (!isLt2M) {
      this.$message.error('Avatar picture size can not exceed 2MB!');
    }
    return isJPG && isLt2M;
  }
  async finalUpload() {
    // (this.isEdit)
    this.confirmuploadbtn = true;
    this.chatLoading = true;
    this.uploadImageDialog = false;
    this.dialogVisible = false;
    this.confirmuploadbtn = false;
    const formList = [];
    const callList = [];
    this.editList.forEach(file => {
      var fileObj = file.raw;
      var form = new FormData();
      form.append('file', fileObj);
      formList.push(form);
    });
    await formList.forEach(async form => {
      callList.push((0, _common.uploadFile)(form, async event => {}));
    });
    await _axios.default.all(callList).then(async res => {
      res.forEach(response => {
        this.imagelist.push(response);
      });
    });
    await this.uploadImageChat();
  }
  handleRemove(file) {
    this.fileList.forEach((photo, index) => {
      if (photo.name === file.name) {
        this.fileList.splice(index, 1);
        this.imagelist.splice(index, 1); // this.photos.splice(index, 1)
      }
    });
    const imgDragger = document.querySelector('.el-upload--picture-card ');
    if (this.fileList.length >= 3) {
      imgDragger.style.display = 'none';
    } else {
      imgDragger.style.display = 'block';
    }
  }
  updateImage(res) {
    // ;
    this.postForm.image = res;
  }
  handleClose() {
    this.uploadImageDialog = false;
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  uploadImageBeforeUpload(file) {
    const file1 = !!(file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png');
    if (!file1) {
      this.$message.error('Only Image file upload');
    }
    return file1;
  }
  handleCloseReferenceTest(tag) {
    this.postForm.image = [];
  }
  handleCancel() {
    this.fileList = [];
    this.postForm.image = [];
    this.imagelist = [];
    const imgDragger = document.querySelector('.el-upload--picture-card ');
    imgDragger.style.display = 'block';
  }
  async recordAudio() {
    this.handlerecordClick();
    var temp = this;
    const dataArray = [];
    const start = document.getElementById('btnStart');
    const stop = document.getElementById('btnStop');
    const aud = document.getElementById('audio-preview');
    // var options = {
    //   audioBitsPerSecond: 128000,
    //   mimeType: "audio/mp3",
    // };
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    }).then(function (mediaStreamObj) {
      var mediaRecorder = new MediaRecorder(mediaStreamObj);
      // start.addEventListener("click", function (ev: any) {
      mediaRecorder.start();
      // });
      stop.addEventListener('click', function (ev) {
        mediaRecorder.stop();
      });
      mediaRecorder.ondataavailable = function (ev) {
        dataArray.push(ev.data);
      };
      mediaRecorder.onstop = async function (ev) {
        const audioData = new Blob(dataArray, {
          type: 'audio/m4a; codecs=0'
        });
        temp.audioPreview = URL.createObjectURL(audioData);
        aud.src = temp.audioPreview;
        temp.myFile = new File([audioData], 'audio.m4a', {
          type: audioData.type
        });
        temp.handleEndrecordClick();
        temp.handlePreviewAudio();
        mediaStreamObj.getAudioTracks().forEach(track => {
          track.stop();
        });
        mediaRecorder = null;
      };
    }).catch(function (err) {
      console.log(err.name, err.message);
    });
  }
  handlerecordClick() {
    const elm = document.getElementById('record-container');
    elm.style.top = '-30px';
    elm.style.zIndex = '0';
  }
  handleEndrecordClick() {
    const elm = document.getElementById('record-container');
    elm.style.top = '40px';
    elm.style.zIndex = '-10';
  }
  async handleSendAudio() {
    var form = new FormData();
    form.append('file', this.myFile);
    await (0, _common.uploadFile)(form, event => {
      // console.log(event);
    }).then(res => {
      this.handleCancelAudio();
      this.uploadedAudio = res;
      this.type = 2;
      this.sendMessage();
      this.handleEmptyaudioData();
    });
  }
  handleEmptyaudioData() {
    this.myFile = null;
    this.audioPreview = null;
  }
  handlePreviewAudio() {
    const audiopreviewcontainer = document.getElementById('audio-preview-container');
    audiopreviewcontainer.style.display = 'block';
    setTimeout(time => {
      audiopreviewcontainer.style.top = '-130px';
    }, 100);
  }
  handleCancelAudio() {
    const audiopreviewcontainer = document.getElementById('audio-preview-container');
    audiopreviewcontainer.style.top = '150px';
    setTimeout(time => {
      audiopreviewcontainer.style.display = 'none';
    }, 2000);
    this.handleEmptyaudioData();
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'chat',
  components: {
    PdfUploadDialog: _PdfUploadDialog.default,
    VideoUploadDialog: _VideoUploadDialog.default,
    MessageWrapper: _MessageWrapper.default
  }
})], default_1);
var _default = exports.default = default_1;