"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const usersRouter = {
  path: "/users",
  component: _index.default,
  redirect: "noredirect",
  name: "users Management",
  meta: {
    roles: ["superadmin", "admin"],
    title: "usersManagement.title",
    icon: "users-alt"
  },
  children: [{
    path: "list",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */"@/views/users/list.vue"))),
    name: "users",
    meta: {
      title: "usersManagement.title",
      noCache: true,
      icon: "users-alt"
    }
  }, {
    path: "edit/:id(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/users/manage.vue"))),
    name: "Edit Admin",
    props: {
      isEdit: true
    },
    meta: {
      title: "usersManagement.users.manage",
      noCache: true,
      activeMenu: "/users/list",
      hidden: true
    }
  }, {
    path: "add",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/users/manage.vue"))),
    name: "Add users",
    props: {
      isEdit: false
    },
    meta: {
      title: "usersManagement.users.manage",
      noCache: true,
      activeMenu: "/users/list",
      hidden: true
    }
  }, {
    path: "membership/:id(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/users/membership.vue"))),
    name: "Edit Admin",
    meta: {
      title: "usersManagement.users.member",
      noCache: true,
      activeMenu: "/users/list",
      hidden: true
    }
  }]
};
var _default = exports.default = usersRouter;