"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUser = exports.updateStatus = exports.register = exports.logout = exports.login = exports.logPost = exports.getUserById = exports.getUserByEmail = exports.getUserBalance = exports.getLogs = exports.getCompany = exports.getApplicantcount = exports.getApplicant = exports.deleteUser = exports.defaultUserData = exports.checkUserExist = exports.checkEmailExist = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultUserData = exports.defaultUserData = {
  id: 0,
  enabled: true,
  isDeleted: false,
  name: '',
  email: '',
  contactNumber: '',
  isInfluencer: false,
  pincode: '',
  profession: '',
  pxlcode: '',
  kycStatus: 0,
  referncecode: '',
  cityId: null,
  stateId: null,
  address: ''
};
const getApplicant = params => (0, _request.default)({
  url: '/applicant',
  method: 'get',
  params
});
exports.getApplicant = getApplicant;
const getApplicantcount = params => (0, _request.default)({
  url: '/applicant/count',
  method: 'get',
  params
});
exports.getApplicantcount = getApplicantcount;
const checkUserExist = data => (0, _request.default)({
  url: '/applicant/username-check',
  method: 'post',
  data
});
exports.checkUserExist = checkUserExist;
const checkEmailExist = data => (0, _request.default)({
  url: '/applicant/email-check',
  method: 'post',
  data
});
exports.checkEmailExist = checkEmailExist;
const getUserById = id => (0, _request.default)({
  url: `/applicant/${id}`,
  method: 'get'
});
exports.getUserById = getUserById;
const getUserByEmail = adminemail => (0, _request.default)({
  url: `/applicant/${adminemail}`,
  method: 'get'
});
exports.getUserByEmail = getUserByEmail;
const getUserBalance = id => (0, _request.default)({
  url: `/applicant/${id}`,
  method: 'get'
});
exports.getUserBalance = getUserBalance;
const updateUser = (id, data) => (0, _request.default)({
  url: `/applicant/${id}`,
  method: 'patch',
  data
});
exports.updateUser = updateUser;
const deleteUser = adminname => (0, _request.default)({
  url: `/applicant/${adminname}`,
  method: 'delete'
});
exports.deleteUser = deleteUser;
const updateStatus = data => (0, _request.default)({
  url: `/applicant/update`,
  method: 'patch',
  data
});
exports.updateStatus = updateStatus;
const login = data => (0, _request.default)({
  url: '/applicant/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: '/applicant/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/applicant/add',
  method: 'post',
  data
});
exports.register = register;
const getCompany = params => (0, _request.default)({
  url: '/applicant-roles',
  method: 'get',
  params
});
exports.getCompany = getCompany;
const logPost = data => (0, _request.default)({
  url: '/user-logs',
  method: 'post',
  data
});
exports.logPost = logPost;
const getLogs = params => (0, _request.default)({
  url: '/user-logs',
  method: 'get',
  params
});
exports.getLogs = getLogs;