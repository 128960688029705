"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadExcel = exports.updatesessions = exports.updateSessionMaster = exports.updateOrder = exports.getSessionSlots = exports.getSessionMasters = exports.getSessionMasterById = exports.getRoutes = exports.deletesessions = exports.deleteSessionMaster = exports.defaultSessionMasterData = exports.createSessionMaster = exports.addSessionMaster = exports.BookSession = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSessionMasterData = exports.defaultSessionMasterData = {
  id: 0,
  name: '',
  userTransactionId: null,
  userId: null,
  adminId: null,
  noOfUSession: null,
  startDate: '',
  day: '',
  type: null,
  rescheduledBy: ''
};
const getSessionMasters = params => (0, _request.default)({
  url: '/user-session-master',
  method: 'get',
  params
});
exports.getSessionMasters = getSessionMasters;
const getSessionSlots = params => (0, _request.default)({
  url: '/user-session-master/get-trainers',
  method: 'get',
  params
});
exports.getSessionSlots = getSessionSlots;
const getSessionMasterById = id => (0, _request.default)({
  url: `/user-session-master/${id}`,
  method: 'get'
});
exports.getSessionMasterById = getSessionMasterById;
const updateSessionMaster = (id, data) => (0, _request.default)({
  url: `/user-session-master/${id}`,
  method: 'patch',
  data
});
exports.updateSessionMaster = updateSessionMaster;
const createSessionMaster = data => (0, _request.default)({
  url: '/user-session-master/add',
  method: 'post',
  data
});
exports.createSessionMaster = createSessionMaster;
const addSessionMaster = data => (0, _request.default)({
  url: '/user-session-master',
  method: 'post',
  data
});
exports.addSessionMaster = addSessionMaster;
const getRoutes = params => (0, _request.default)({
  url: '/routes',
  method: 'get',
  params
});
exports.getRoutes = getRoutes;
const updateOrder = data => (0, _request.default)({
  url: '/user-session-master/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;
const uploadExcel = (id, data) => (0, _request.default)({
  url: `/user-session-master-key-value/upload/${id}`,
  method: 'post',
  data
});
// export const deleteSessionMaster = (data:any) =>
// request({
//   url: `/user-session-master-key-value/deleteSessionMaster`,
//   method: 'post',
//   data
// })
exports.uploadExcel = uploadExcel;
const deleteSessionMaster = id => (0, _request.default)({
  url: `/user-session-master/${id}`,
  method: 'delete'
});
exports.deleteSessionMaster = deleteSessionMaster;
const BookSession = data => (0, _request.default)({
  url: '/user-session-master/create-sessions',
  method: 'post',
  data
});
exports.BookSession = BookSession;
const deletesessions = id => (0, _request.default)({
  url: `session/${id}`,
  method: 'delete'
});
exports.deletesessions = deletesessions;
const updatesessions = (id, data) => (0, _request.default)({
  url: `session/${id}`,
  method: 'patch',
  data
});
exports.updatesessions = updatesessions;