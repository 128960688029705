"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.listQuery = {
      page: 1,
      limit: 10,
      adminId: 0,
      sort: 'id,DESC',
      filter: {
        adminId: 'eq'
      }
    };
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    this.genderList = [{
      label: 'Female',
      value: 'female'
    }, {
      label: 'Male',
      value: 'male'
    }, {
      label: 'Other',
      value: 'other'
    }];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  created() {
    const id = Number(this.$route.params.id);
    this.listQuery.adminId = id;
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _admins.getLogsAdmin)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
      this.$message(error.message);
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  // private handleDelete(data: any) {
  //
  //   if (data) {
  //     this.$confirm(
  //       this.$tc('table.deleteConfirmation', undefined, { name: data.name }),
  //       this.$tc('table.delete'),
  //       {
  //         confirmButtonText: this.$tc('table.delete'),
  //         confirmButtonClass: 'el-button--danger',
  //         cancelButtonText: 'Cancel',
  //         type: 'error'
  //       }
  //     )
  //       .then(() => {
  //         deleteUser(data).then(deleted => {
  //           this.getList()
  //         })
  //       })
  //       .catch(() => {
  //         this.$message({
  //           type: 'info',
  //           message: this.$tc('table.deleteCanceled')
  //         })
  //       })
  //   }
  // }
  // private async getAllList() {
  //   try {
  //     this.listLoading = true
  //     const data: any = await getUsers(getQuery(this.listQuery))
  //     this.list = data.data
  //     this.total = data.total
  //     this.listLoading = false
  //   } catch (error) {
  //     this.listLoading = false
  //     this.$message(error.message)
  //   }
  // }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  async handleDownload(paid) {
    this.downloadLoading = true;
    const fliterData = [];
    const page = this.listQuery.page;
    const limit = this.listQuery.limit;
    this.listQuery.page = 1;
    this.listQuery.limit = 100000;
    // const dataAll: any = await getUsers(getQuery(this.listQuery))
    // const listAll:IUsersData[] = dataAll.data
    this.listQuery.page = page;
    this.listQuery.limit = limit;
    // this.total = data.total;
    // if (paid) {
    //   fliterData = listAll.filter(list => {
    //     return new Date(list.planExipiryDate) > new Date();
    //   });
    // } else {
    //   fliterData = listAll.filter(list => {
    //     return new Date(list.planExipiryDate) < new Date();
    //   });
    // }
    const tHeader = ['id', 'fullName', 'email', 'contactNumber', 'type', 'isMonthlyNewsletter', 'planExipiryDate'];
    const filterVal = ['id', 'fullName', 'email', 'contactNumber', 'type', 'isMonthlyNewsletter', 'planExipiryDate'];
    const data = (0, _index2.formatJson)(filterVal, fliterData);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.users.title'));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AdminLogList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;