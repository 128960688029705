"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("h3", [_vm._v("Personal Details")]), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Profile Picture",
      prop: "profilePicture",
      "label-width": "200px"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      "form-field": _vm.postForm.profilePicture
    },
    on: {
      "handle-upload": _vm.updateProfilePictureImage,
      "handle-remove": _vm.removeProfilePictureImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Name",
      prop: "fullName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "fullName",
      placeholder: "Full Name"
    },
    model: {
      value: _vm.postForm.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "fullName", $$v);
      },
      expression: "postForm.fullName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Contact Number",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      maxlength: "10",
      name: "contactNumber",
      placeholder: "Contact Number"
    },
    model: {
      value: _vm.postForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "contactNumber", $$v);
      },
      expression: "postForm.contactNumber"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Email ID",
      prop: "email"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "email",
      placeholder: "Email"
    },
    model: {
      value: _vm.postForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "email", $$v);
      },
      expression: "postForm.email"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Age",
      prop: "age"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      maxlength: "10",
      name: "age",
      placeholder: "Age"
    },
    model: {
      value: _vm.postForm.age,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "age", $$v);
      },
      expression: "postForm.age"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Gender",
      prop: "gender"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.postForm.gender,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "gender", $$v);
      },
      expression: "postForm.gender"
    }
  }, _vm._l(_vm.genderList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c("h3", [_vm._v("Communication details")]), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Country",
      prop: "countryId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.postForm.countryId,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "countryId", $$v);
      },
      expression: "postForm.countryId"
    }
  }, _vm._l(_vm.countryList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Language",
      prop: "languageIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.languageIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "languageIds", $$v);
      },
      expression: "postForm.languageIds"
    }
  }, _vm._l(_vm.languageList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("h3", [_vm._v("Assigned trainers")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Nutritionist",
      prop: "countryId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.postForm.countryId,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "countryId", $$v);
      },
      expression: "postForm.countryId"
    }
  }, _vm._l(_vm.countryList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Strength Trainer",
      prop: "languageIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.languageIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "languageIds", $$v);
      },
      expression: "postForm.languageIds"
    }
  }, _vm._l(_vm.languageList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("h3", [_vm._v("Fitness details")]), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Current weight",
      prop: "weight"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "weight",
      placeholder: "Weight"
    },
    model: {
      value: _vm.postForm.weight,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "weight", $$v);
      },
      expression: "postForm.weight"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select weight Type",
      prop: "weightType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.postForm.weightType,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "weightType", $$v);
      },
      expression: "postForm.weightType"
    }
  }, _vm._l(_vm.weightTypeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Current height",
      prop: "height"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "height",
      placeholder: "Height"
    },
    model: {
      value: _vm.postForm.height,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "height", $$v);
      },
      expression: "postForm.height"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select height Type",
      prop: "heightType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.postForm.heightType,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "heightType", $$v);
      },
      expression: "postForm.heightType"
    }
  }, _vm._l(_vm.heighTypeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Ideal body weight",
      prop: "idealWeight"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "height",
      placeholder: "Ideal Body Weight"
    },
    model: {
      value: _vm.postForm.idealWeight,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "idealWeight", $$v);
      },
      expression: "postForm.idealWeight"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select Type",
      prop: "idealWeightType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.postForm.idealWeightType,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "idealWeightType", $$v);
      },
      expression: "postForm.idealWeightType"
    }
  }, _vm._l(_vm.weightTypeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Menstruation Cycle From",
      prop: "cycleFrom"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "Pick a day"
    },
    model: {
      value: _vm.postForm.cycleFrom,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "cycleFrom", $$v);
      },
      expression: "postForm.cycleFrom"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Menstruation Cycle To",
      prop: "cycleTo"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "Pick a day"
    },
    model: {
      value: _vm.postForm.cycleTo,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "cycleTo", $$v);
      },
      expression: "postForm.cycleTo"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Goals",
      prop: "workoutIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.workoutIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "workoutIds", $$v);
      },
      expression: "postForm.workoutIds"
    }
  }, _vm._l(_vm.workOutList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Medical Conditions",
      prop: "diseaseIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.diseaseIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "diseaseIds", $$v);
      },
      expression: "postForm.diseaseIds"
    }
  }, _vm._l(_vm.diseaseList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1)], 1)], 1)], 1)], 1), _c("h2", [_vm._v("Challenges")]), _c("el-table", {
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.challengesList,
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "80",
      align: "center",
      label: "Sr No",
      prop: "id"
    }
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      label: "Challenge name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_vm._v(_vm._s(scope.row.name))])], 1)], 1), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "center",
      label: "Description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("b", [_vm._v(_vm._s(scope.row.desc))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      label: "Trainer name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("b", [_vm._v(_vm._s(scope.row.user.fullName))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      label: "Start date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("b", [_vm._v(_vm._s(scope.row.startDate))])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      label: "Duration"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("b", [_vm._v(_vm._s(scope.row.duration) + " weeks")])])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "200",
      align: "center",
      label: "Status",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("button", {
          style: {
            backgroundColor: scope.row.status === 0 ? "#FFFDD3" : scope.row.status === 1 ? "#A8FFB182" : scope.row.status === 2 ? "#11111133" : "grey",
            color: scope.row.status === 0 ? "#FFB715" : scope.row.status === 1 ? "#11A720" : scope.row.status === 2 ? "#5C5B5E" : "white"
          }
        }, [_vm._v(" " + _vm._s(scope.row.status === 0 ? "Ongoing" : scope.row.status === 1 ? "Completed" : scope.row.status === 2 ? "Plan freezed" : "Other") + " ")])])], 1)];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;