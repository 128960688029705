"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMembersToGroup = addMembersToGroup;
exports.editGroupDetails = editGroupDetails;
exports.fetchAllConversations = fetchAllConversations;
exports.fetchConversationById = fetchConversationById;
exports.fetchMessages = fetchMessages;
exports.getChatMembers = getChatMembers;
exports.getUserById = getUserById;
exports.removeMembersFromGroup = removeMembersFromGroup;
exports.resetReadCount = resetReadCount;
exports.resetReadCountNew = resetReadCountNew;
exports.sendMessageNotification = sendMessageNotification;
exports.subscribeToUserConversations = subscribeToUserConversations;
exports.updateLastMessage = updateLastMessage;
exports.updateUnreadCount = updateUnreadCount;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _notifications = require("@/api/notifications");
var _firebase = require("@/config/firebase.config");
var _admin = require("@/store/modules/admin");
var _app = require("firebase/app");
var _firestore = require("firebase/firestore");
var _moment = _interopRequireDefault(require("moment"));
const app = (0, _app.initializeApp)(_firebase.firebaseConfig);
const firestore = (0, _firestore.getFirestore)(app);
// Fetch all document data concurrently
function subscribeToUserConversations(userId, userRoleId, groupName, pageNumber, skip, updateConversations) {
  console.log('group name: ', groupName);
  const conversations = [];
  const memberSnapshotStream = (0, _firestore.query)((0, _firestore.collectionGroup)(firestore, "members"), (0, _firestore.where)("userId", "==", userId), (0, _firestore.where)("role", "==", userRoleId), (0, _firestore.orderBy)("timestamp", "desc"));
  (0, _firestore.onSnapshot)(memberSnapshotStream, async memberSnapshot => {
    const chatIds = [];
    const chats = [];
    memberSnapshot.docs.forEach(doc => {
      chatIds.push(doc.ref.parent.parent.id);
      const docRef = doc.ref.parent.parent;
      if (docRef) {
        (0, _firestore.getDoc)(docRef).then(docSnapshot => {
          if (docSnapshot.exists()) {
            // Document exists, extract the data
            const docData = docSnapshot.data();
            console.log("Document Data:", docData);
          } else {
            // Document does not exist
            console.log("No such document!");
          }
        }).catch(error => {
          console.error("Error getting document:", error);
        });
      }
      chats.push(doc.ref.parent.parent);
    });
    console.log('chats', chats);
    if (chatIds.length > 0) {
      // Initialize pagination variables
      let lastVisibleDoc = null;
      let allConversations = [];
      // Function to calculate the index for the page number
      const getPaginatedChatIds = () => {
        const startIndex = (pageNumber - 1) * skip;
        return chatIds.slice(startIndex, startIndex + skip);
      };
      // Fetch all conversations when groupName is provided
      const fetchAllConversations = async () => {
        const chatQuery = (0, _firestore.query)((0, _firestore.collection)(firestore, "conversation"), (0, _firestore.where)("__name__", "in", chatIds) // Fetch all conversations for these chatIds
        );
        (0, _firestore.onSnapshot)(chatQuery, chatSnapshot => {
          let allConversationsFetched = chatSnapshot.docs.map(doc => {
            var _docData$lastUpdateTi;
            const docData = doc.data();
            const timestamp = docData === null || docData === void 0 || (_docData$lastUpdateTi = docData.lastUpdateTimestamp) === null || _docData$lastUpdateTi === void 0 ? void 0 : _docData$lastUpdateTi.toDate();
            const formattedTimestamp = (0, _moment.default)(timestamp).format("DD-MM-YYYY HH:mm:ss");
            return {
              groupName: docData.groupName,
              image: docData.groupIcon,
              id: doc.id,
              tags: "/inbox/chat/" + doc.id,
              unreadCountCurrentUser: getUnreadCountByGroupIdNew(docData.unreadCount, _admin.AdminModule.id),
              unreadCount: docData.unreadCount,
              lastMessage: docData.lastMessage,
              lastMessageImage: docData.image,
              groupMembers: [],
              lastUserId: docData.lastUserId,
              lastUserName: docData.lastUserName || '',
              lastMessageTime: formattedTimestamp
            };
          }).filter(item => item !== null);
          // Apply groupName filter after fetching all conversations
          if (groupName) {
            allConversationsFetched = allConversationsFetched.filter(conversation => conversation.groupName >= groupName && conversation.groupName <= groupName + "\uf8ff");
          }
          // Sort conversations by lastMessageTime
          allConversationsFetched.sort((a, b) => {
            return (0, _moment.default)(b.lastMessageTime, "DD-MM-YYYY HH:mm:ss").valueOf() - (0, _moment.default)(a.lastMessageTime, "DD-MM-YYYY HH:mm:ss").valueOf();
          });
          updateConversations(allConversationsFetched);
        }, error => {
          console.error("Error fetching conversations:", error);
        });
      };
      // If groupName is provided, fetch all conversations and apply the filter after
      if (groupName) {
        await fetchAllConversations();
      } else {
        // If groupName is not provided, apply pagination
        const fetchConversations = async () => {
          let chatQuery = (0, _firestore.query)((0, _firestore.collection)(firestore, "conversation"), (0, _firestore.where)("__name__", "in", getPaginatedChatIds()) // Get paginated chatIds
          );
          // Apply pagination (limit to 'skip' and start after last document if available)
          if (lastVisibleDoc) {
            chatQuery = (0, _firestore.query)(chatQuery, (0, _firestore.startAfter)(lastVisibleDoc));
          } else {
            chatQuery = (0, _firestore.query)(chatQuery, (0, _firestore.limit)(skip)); // Limit based on the 'limit' parameter
          }
          (0, _firestore.onSnapshot)(chatQuery, chatSnapshot => {
            const chunkConversations = chatSnapshot.docs.map(doc => {
              var _docData$lastUpdateTi2;
              const docData = doc.data();
              const timestamp = docData === null || docData === void 0 || (_docData$lastUpdateTi2 = docData.lastUpdateTimestamp) === null || _docData$lastUpdateTi2 === void 0 ? void 0 : _docData$lastUpdateTi2.toDate();
              const formattedTimestamp = (0, _moment.default)(timestamp).format("DD-MM-YYYY HH:mm:ss");
              return {
                groupName: docData.groupName,
                image: docData.groupIcon,
                id: doc.id,
                tags: "/inbox/chat/" + doc.id,
                unreadCountCurrentUser: getUnreadCountByGroupIdNew(docData.unreadCount, _admin.AdminModule.id),
                unreadCount: docData.unreadCount,
                lastMessage: docData.lastMessage,
                lastMessageImage: docData.image,
                groupMembers: [],
                lastUserId: docData.lastUserId,
                lastUserName: docData.lastUserName || '',
                lastMessageTime: formattedTimestamp
              };
            }).filter(item => item !== null);
            allConversations = allConversations.concat(chunkConversations);
            // Check if there are more documents to paginate
            if (chatSnapshot.docs.length > 0) {
              lastVisibleDoc = chatSnapshot.docs[chatSnapshot.docs.length - 1];
              // If there's more data, keep paginating
              fetchConversations();
            } else {
              // No more data to fetch, update the conversations
              // Sort conversations by lastMessageTime
              allConversations.sort((a, b) => {
                return (0, _moment.default)(b.lastMessageTime, "DD-MM-YYYY HH:mm:ss").valueOf() - (0, _moment.default)(a.lastMessageTime, "DD-MM-YYYY HH:mm:ss").valueOf();
              });
              updateConversations(allConversations);
            }
          }, error => {
            console.error("Error fetching conversations:", error);
          });
        };
        // Start the first page of results for when groupName is not provided
        fetchConversations();
      }
    }
  }, error => {
    console.error("Error fetching members:", error);
  });
}
function fetchAllConversations(groupName, pageNumber, skip, updateConversations) {
  console.log('group name: ', groupName);
  // Initialize pagination variables
  let allConversations = [];
  // Calculate the starting index for pagination
  debugger;
  if (pageNumber < 2) {
    _admin.AdminModule.setLastVisibleDoc(null);
  }
  let lastVisibleDoc = _admin.AdminModule.lastVisibleDoc;
  console.log('lastVisibleDoc 1', lastVisibleDoc);
  const startIndex = (pageNumber - 1) * skip;
  console.log('skip', skip);
  console.log('startIndex', startIndex);
  // Fetch conversations directly from the "conversation" collection
  const fetchConversations = async () => {
    let chatQuery = (0, _firestore.query)((0, _firestore.collection)(firestore, "conversation"));
    // Apply pagination (start after the last document if available)
    // if (lastVisibleDoc) {
    //   console.log('lastVisibleDoc', lastVisibleDoc);
    //   chatQuery = query(chatQuery, startAfter(lastVisibleDoc),
    //     limit(skip)); // Start after the last visible doc
    // }
    // else {
    //   chatQuery = query(chatQuery, limit(skip));
    // }
    console.log('chatQuery', chatQuery);
    let currentlastVisibleDoc;
    (0, _firestore.onSnapshot)(chatQuery, chatSnapshot => {
      let allConversationsFetched = chatSnapshot.docs.map(doc => {
        var _docData$lastUpdateTi3;
        const docData = doc.data();
        const timestamp = docData === null || docData === void 0 || (_docData$lastUpdateTi3 = docData.lastUpdateTimestamp) === null || _docData$lastUpdateTi3 === void 0 ? void 0 : _docData$lastUpdateTi3.toDate();
        const formattedTimestamp = (0, _moment.default)(timestamp).format("DD-MM-YYYY HH:mm:ss");
        if (docData) {
          console.log('docData', currentlastVisibleDoc);
          currentlastVisibleDoc = docData;
        }
        return {
          groupName: docData.groupName,
          image: docData.groupIcon,
          id: doc.id,
          tags: "/inbox/chat/" + doc.id,
          unreadCountCurrentUser: getUnreadCountByGroupIdNew(docData.unreadCount, _admin.AdminModule.id),
          unreadCount: docData.unreadCount,
          lastMessage: docData.lastMessage,
          lastMessageImage: docData.image,
          groupMembers: [],
          lastUserId: docData.lastUserId,
          lastUserName: docData.lastUserName || '',
          lastMessageTime: formattedTimestamp
        };
      }).filter(item => item !== null);
      console.log('after docMap: ', currentlastVisibleDoc);
      // AdminModule.setLastVisibleDoc(currentlastVisibleDoc);
      _admin.AdminModule.setLastVisibleDoc(chatSnapshot.docs[chatSnapshot.docs.length - 1]);
      console.log('last visible doc: ', _admin.AdminModule.lastVisibleDoc);
      // Apply groupName filter if it's provided
      if (groupName) {
        const lowerCaseGroupName = groupName.toLowerCase();
        allConversationsFetched = allConversationsFetched.filter(conversation => conversation.groupName.toLowerCase() >= lowerCaseGroupName && conversation.groupName.toLowerCase() <= lowerCaseGroupName + "\uf8ff");
      }
      // Sort conversations by lastMessageTime
      allConversationsFetched.sort((a, b) => {
        return (0, _moment.default)(b.lastMessageTime, "DD-MM-YYYY HH:mm:ss").valueOf() - (0, _moment.default)(a.lastMessageTime, "DD-MM-YYYY HH:mm:ss").valueOf();
      });
      // After fetching the first batch, update the lastVisibleDoc to paginate
      if (chatSnapshot.docs.length > 0) {
        lastVisibleDoc = chatSnapshot.docs[chatSnapshot.docs.length - 1];
      }
      // Update the conversations with the fetched data
      updateConversations(allConversationsFetched);
    }, error => {
      console.error("Error fetching conversations:", error);
    });
  };
  // Fetch conversations directly
  fetchConversations();
}
function getUnreadCountByGroupIdNew(unreadCountMap, userId) {
  ;
  // Check if the parameters are null, undefined, or empty
  if (!unreadCountMap || userId === null || userId === undefined) {
    return 0;
  }
  // Retrieve the unread count for the specific userId from the object
  const unreadCount = unreadCountMap[userId];
  // Return the unread count if it exists, otherwise return 0
  return unreadCount !== undefined ? unreadCount : 0;
}
async function updateUnreadCount(conversationId, userId) {
  try {
    // Get the snapshot of the 'members' collection within the 'conversation'
    const membersCollection = (0, _firestore.collection)(firestore, "conversation", conversationId, "members");
    const memberSnapshot = await (0, _firestore.getDocs)(membersCollection);
    // Iterate through the documents
    memberSnapshot.docs.forEach(async docSnapshot => {
      const memberData = docSnapshot.data();
      // Check if the userId does not match the current user
      if (memberData.userId !== userId) {
        // Update the unread count field
        const memberDocRef = (0, _firestore.doc)(firestore, "conversation", conversationId, "members", docSnapshot.id);
        await (0, _firestore.updateDoc)(memberDocRef, {
          unreadCount: (0, _firestore.increment)(1)
        });
      }
    });
    // Optionally update the lastMessage field here if needed
  } catch (error) {
    console.error("Error updating unread count:", error);
  }
}
async function updateLastMessage(conversationId, newLastMessage, userId, lastUserName,
// New argument for lastUserName
type) {
  try {
    // Get a reference to the Firestore document
    const docRef = (0, _firestore.doc)(firestore, "conversation", conversationId);
    // Fetch the current document data
    const docSnapshot = await (0, _firestore.getDoc)(docRef);
    const docData = docSnapshot.data();
    debugger;
    if (docData) {
      // Retrieve the unreadCountMap
      const unreadCountMap = new Map(Object.entries(docData.unreadCount || {}));
      debugger;
      // Update the unread counts: increment all by 1, and reset for the specific userId
      unreadCountMap.forEach((count, key) => {
        unreadCountMap.set(key, count + 1);
      });
      unreadCountMap.set(userId.toString(), 0);
      console.log(Object.fromEntries(unreadCountMap));
      debugger;
      // Prepare the data to update
      const updateData = {
        unreadCount: Object.fromEntries(unreadCountMap),
        lastUserId: userId,
        lastUserName: lastUserName,
        lastUpdateTimestamp: (0, _firestore.serverTimestamp)()
      };
      if (type === 1) {
        updateData.lastMessage = "image";
        updateData.image = "";
      } else {
        updateData.lastMessage = newLastMessage;
        updateData.image = "";
      }
      // Update the Firestore document
      await (0, _firestore.updateDoc)(docRef, updateData);
    }
  } catch (error) {
    console.error("Error updating last message:", error);
  }
}
async function resetReadCount(conversationId, userId) {
  try {
    // Create a query to find the member document with the specified userId
    const memberQuery = (0, _firestore.query)((0, _firestore.collection)(firestore, "conversation", conversationId, "members"), (0, _firestore.where)("userId", "==", userId));
    // Execute the query to get the matching documents
    const memberSnapshot = await (0, _firestore.getDocs)(memberQuery);
    console.log('memberSnapshot', memberSnapshot);
    // Update the unreadCount field for each document found
    const updatePromises = memberSnapshot.docs.map(doc => (0, _firestore.updateDoc)(doc.ref, {
      unreadCount: 0
    }));
    await Promise.all(updatePromises);
  } catch (error) {
    console.error("Error resetting read count:", error);
  }
}
async function resetReadCountNew(groupId, userId) {
  try {
    // Reference to the group document
    const groupDocRef = (0, _firestore.doc)(firestore, "conversation", groupId);
    // Retrieve the group document to access the unreadCount map
    const groupSnapshot = await (0, _firestore.getDoc)(groupDocRef);
    if (groupSnapshot.exists()) {
      // Get the current unreadCount map
      const groupData = groupSnapshot.data();
      const unreadCount = groupData.unreadCount || {};
      // Set the unread count for the specified userId to 0
      unreadCount[userId] = 0;
      // Update the group document with the modified unreadCount map
      await (0, _firestore.updateDoc)(groupDocRef, {
        unreadCount
      });
    } else {
      console.error("Group document does not exist.");
    }
  } catch (error) {
    console.error("Error resetting read count:", error);
  }
}
function fetchMessages(conversationId, updateMessages) {
  try {
    const messagesCollection = (0, _firestore.collection)(firestore, "conversation", conversationId, "messages");
    const messagesQuery = (0, _firestore.query)(messagesCollection, (0, _firestore.orderBy)("timestamp", "asc"));
    (0, _firestore.onSnapshot)(messagesQuery, querySnapshot => {
      const messages = querySnapshot.docs.map(doc => doc.data());
      console.log(messages);
      updateMessages(messages);
    }, error => {
      console.error("Error fetching messages:", error);
    });
  } catch (error) {
    console.error("Error setting up message listener:", error);
  }
}
async function fetchConversationById(conversationId) {
  try {
    const docRef = (0, _firestore.doc)(firestore, "conversation", conversationId);
    const docSnap = await (0, _firestore.getDoc)(docRef);
    if (docSnap.exists()) {
      const docData = docSnap.data();
      const conversation = {
        groupName: docData.groupName,
        image: docData.groupIcon,
        id: docSnap.id,
        tags: "/inbox/chat/" + docSnap.id,
        unreadCount: docData.unreadCount || 0
      };
      console.log("Conversation", conversation);
      return conversation;
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching conversation:", error);
    throw new Error("Failed to fetch conversation");
  }
}
async function getChatMembers(conversationId) {
  const members = [];
  const memberSnapshot = await (0, _firestore.getDocs)((0, _firestore.collection)(firestore, "conversation", conversationId, "members"));
  for (const element of memberSnapshot.docs) {
    const userQuery = (0, _firestore.query)((0, _firestore.collection)(firestore, "users"), (0, _firestore.where)("id", "==", element.get("userId")), (0, _firestore.where)("role", "==", element.get("role")), (0, _firestore.limit)(1));
    const userSnapshot = await (0, _firestore.getDocs)(userQuery);
    if (userSnapshot.docs.length > 0) {
      const userDoc = userSnapshot.docs[0];
      const memberModel = {
        id: userDoc.get("id"),
        name: userDoc.get("name"),
        role: userDoc.get("role"),
        profilePic: userDoc.get("profilePic"),
        unreadCount: element.data().hasOwnProperty("unreadCount") ? Number(element.get("unreadCount")) : 0
      };
      members.push(memberModel);
    }
  }
  return members;
}
async function editGroupDetails(conversationId, newDetails) {
  try {
    const docRef = (0, _firestore.doc)(firestore, "conversation", conversationId);
    await (0, _firestore.updateDoc)(docRef, newDetails);
    console.log("Group details updated successfully");
  } catch (error) {
    console.error("Error updating group details:", error);
    throw new Error("Failed to update group details");
  }
}
async function removeMembersFromGroup(conversationId, memberIds) {
  try {
    const membersCollection = (0, _firestore.collection)(firestore, "conversation", conversationId, "members");
    if (memberIds) {
      for (const memberId of memberIds) {
        const memberDocRef = (0, _firestore.doc)(membersCollection, memberId.toString());
        await (0, _firestore.deleteDoc)(memberDocRef);
      }
    }
    console.log("Members removed successfully");
  } catch (error) {
    console.error("Error removing members:", error);
    throw new Error("Failed to remove members");
  }
}
async function addMembersToGroup(conversationId, newMembers) {
  try {
    const membersCollection = (0, _firestore.collection)(firestore, "conversation", conversationId, "members");
    for (const member of newMembers) {
      const memberDocRef = (0, _firestore.doc)(membersCollection, member.id.toString());
      await (0, _firestore.setDoc)(memberDocRef, {
        userId: member.id,
        role: member.role,
        unreadCount: 0,
        profilePic: member.profilePic
      });
    }
    console.log("Members added successfully");
  } catch (error) {
    console.error("Error adding members:", error);
    throw new Error("Failed to add members");
  }
}
async function getUserById(userId) {
  try {
    // Create a query to fetch user by the field id
    const userQuery = (0, _firestore.query)((0, _firestore.collection)(firestore, "users"), (0, _firestore.where)("id", "==", userId));
    const userSnapshot = await (0, _firestore.getDocs)(userQuery);
    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0];
      const userData = userDoc.data();
      const user = {
        id: Number(userData.id),
        name: userData.name,
        profilePic: userData.profilePic,
        role: userData.role ? Number(userData.role) : null
      };
      return user;
    } else {
      console.error("User not found");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user:", error);
    return null;
  }
}
function getCurrentUserName(members, lastMessageUserId) {
  let userName = "";
  members.forEach(member => {
    if (member.id == lastMessageUserId) {
      userName = member.name;
    }
  });
  return userName;
}
async function sendMessageNotification(text, members, conversation) {
  const users = [];
  const admins = [];
  members.forEach(member => {
    if (member.role === 0 && member.id !== null) {
      users.push({
        id: member.id
      });
    } else if (member.id !== null) {
      admins.push({
        id: member.id
      });
    }
  });
  const data = {
    image: conversation === null || conversation === void 0 ? void 0 : conversation.image,
    users: users,
    admins: admins,
    title: conversation === null || conversation === void 0 ? void 0 : conversation.groupName,
    message: `${_admin.AdminModule.name} : ${text}`
  };
  ;
  try {
    await (0, _notifications.sendChatNotifications)(data);
  } catch (error) {
    console.error("Error sending notifications:", error);
  }
}