"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatesessionsstatus = exports.updatesessionsAddress = exports.updatesessions = exports.rescheduleSession = exports.getsessionscount = exports.getsessionsDetailById = exports.getsessionsById = exports.getsessions = exports.getSessionsData = exports.deletesessions = exports.defaultSessionData = exports.cancelSession = exports.addsessions = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSessionData = exports.defaultSessionData = {
  id: 0,
  enabled: true,
  priority: 0,
  title: '',
  date: '',
  from: '',
  to: '',
  userId: null,
  adminId: null,
  status: 0,
  isReschedule: false
};
const getsessions = params => (0, _request.default)({
  url: 'session',
  method: 'get',
  params
});
exports.getsessions = getsessions;
const addsessions = data => (0, _request.default)({
  url: 'session',
  method: 'post',
  data
});
exports.addsessions = addsessions;
const getsessionscount = () => (0, _request.default)({
  url: 'session/count-user',
  method: 'get'
});
exports.getsessionscount = getsessionscount;
const getsessionsById = id => (0, _request.default)({
  url: `session/detail/${id}`,
  method: 'get'
});
exports.getsessionsById = getsessionsById;
const getsessionsDetailById = id => (0, _request.default)({
  url: `session/${id}`,
  method: 'get'
});
exports.getsessionsDetailById = getsessionsDetailById;
const updatesessions = (id, data) => (0, _request.default)({
  url: `session/${id}`,
  method: 'patch',
  data
});
exports.updatesessions = updatesessions;
const updatesessionsAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updatesessionsAddress = updatesessionsAddress;
const updatesessionsstatus = data => (0, _request.default)({
  url: `session/update-status`,
  method: 'post',
  data
});
exports.updatesessionsstatus = updatesessionsstatus;
const rescheduleSession = data => (0, _request.default)({
  url: `user-session-master/reschedule-session`,
  method: 'put',
  data
});
exports.rescheduleSession = rescheduleSession;
const cancelSession = data => (0, _request.default)({
  url: `user-session-master/cancel-session`,
  method: 'put',
  data
});
exports.cancelSession = cancelSession;
const deletesessions = id => (0, _request.default)({
  url: `session/${id}`,
  method: 'delete'
});
exports.deletesessions = deletesessions;
const getSessionsData = params => (0, _request.default)({
  url: `session/session-count`,
  method: 'get',
  params
});
exports.getSessionsData = getSessionsData;