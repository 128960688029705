"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePlanStatus = exports.updatePlanAddress = exports.updatePlan = exports.register = exports.logout = exports.login = exports.logPost = exports.getPlanscount = exports.getPlans = exports.getPlanReportbyGender = exports.getPlanDetailById = exports.getPlanByIdAddress = exports.getPlanById = exports.getPlanByEmail = exports.getLogs = exports.getCompany = exports.deletePlan = exports.defaultPlanData = exports.checkPlanExist = exports.checkEmailExist = exports.addPlan = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultPlanData = exports.defaultPlanData = {
  id: 0,
  enabled: true,
  isDeleted: false,
  title: '',
  image: '',
  innerImage: '',
  tags: [],
  type: null,
  gst: null,
  noOfMonths: null,
  noOfDays: null,
  isFree: false,
  price: undefined,
  discountPrice: '',
  description: [],
  priority: 0,
  planType: [{}],
  totalSession: 0,
  planTypeIds: [],
  noOfDaysCancelation: null,
  noOfDaysReschedule: null,
  totalNutrientSession: 0,
  totalTrainingSession: 0
};
const getPlans = params => (0, _request.default)({
  url: '/plan',
  method: 'get',
  params
});
exports.getPlans = getPlans;
const addPlan = data => (0, _request.default)({
  url: '/plan',
  method: 'post',
  data
});
exports.addPlan = addPlan;
const getPlanscount = () => (0, _request.default)({
  url: '/plan/count-user',
  method: 'get'
});
exports.getPlanscount = getPlanscount;
const checkPlanExist = data => (0, _request.default)({
  url: '/plan/username-check',
  method: 'post',
  data
});
exports.checkPlanExist = checkPlanExist;
const checkEmailExist = data => (0, _request.default)({
  url: '/plan/email-check',
  method: 'post',
  data
});
exports.checkEmailExist = checkEmailExist;
const getPlanById = id => (0, _request.default)({
  url: `/plan/detail/${id}`,
  method: 'get'
});
exports.getPlanById = getPlanById;
const getPlanDetailById = id => (0, _request.default)({
  url: `/plan/${id}`,
  method: 'get'
});
exports.getPlanDetailById = getPlanDetailById;
const getPlanByIdAddress = id => (0, _request.default)({
  url: `/plan/detail/${id}`,
  method: 'get'
});
exports.getPlanByIdAddress = getPlanByIdAddress;
const getPlanByEmail = adminemail => (0, _request.default)({
  url: `/plan/${adminemail}`,
  method: 'get'
});
exports.getPlanByEmail = getPlanByEmail;
const updatePlan = (id, data) => (0, _request.default)({
  url: `/plan/${id}`,
  method: 'patch',
  data
});
exports.updatePlan = updatePlan;
const updatePlanAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updatePlanAddress = updatePlanAddress;
const updatePlanStatus = data => (0, _request.default)({
  url: `/plan/update-status`,
  method: 'post',
  data
});
exports.updatePlanStatus = updatePlanStatus;
const deletePlan = adminname => (0, _request.default)({
  url: `/plan/${adminname}`,
  method: 'delete'
});
exports.deletePlan = deletePlan;
const login = data => (0, _request.default)({
  url: '/plan/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: '/plan/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/plan/add',
  method: 'post',
  data
});
exports.register = register;
const getCompany = params => (0, _request.default)({
  url: '/plan-roles',
  method: 'get',
  params
});
exports.getCompany = getCompany;
const logPost = data => (0, _request.default)({
  url: '/user-logs',
  method: 'post',
  data
});
exports.logPost = logPost;
const getLogs = params => (0, _request.default)({
  url: '/user-logs',
  method: 'get',
  params
});
exports.getLogs = getLogs;
const getPlanReportbyGender = params => (0, _request.default)({
  url: '/plan/gender-count',
  method: 'get',
  params
});
exports.getPlanReportbyGender = getPlanReportbyGender;