"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyOtp = exports.updateAdmin = exports.sendOtp = exports.register = exports.logout = exports.login = exports.logPostAdmin = exports.getLogsAdmin = exports.getAdmins = exports.getAdminStats = exports.getAdminInfo = exports.getAdminById = exports.getAdminByEmail = exports.forgotPassword = exports.deleteAdmin = exports.defaultAdminData = exports.changePasswordLink = exports.changePassword = exports.UpdatePassword = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultAdminData = exports.defaultAdminData = {
  id: 0,
  enabled: true,
  fullName: '',
  email: '',
  contactNumber: '',
  password: '',
  gender: '',
  profile: '',
  lastLogin: '',
  adminsRoleId: null,
  createdByFullName: '',
  employeeCode: null,
  pxlCode: null,
  pxlCodeId: null,
  adminsRole: {
    id: null,
    name: ''
  },
  workoutId: null,
  workout: [{}],
  registeredAs: null
};
const getAdmins = params => (0, _request.default)({
  url: '/admins',
  method: 'get',
  params
});
exports.getAdmins = getAdmins;
const getAdminStats = () => (0, _request.default)({
  url: '/admins/statistic',
  method: 'get'
});
exports.getAdminStats = getAdminStats;
const getAdminById = id => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'get'
});
// export const getAdminInfo = (data: any) =>
//   request({
//     url: '/admins/me',
//     method: 'post',
//     data
//   })
exports.getAdminById = getAdminById;
const getAdminByEmail = adminemail => (0, _request.default)({
  url: `/admins/${adminemail}`,
  method: 'get'
});
exports.getAdminByEmail = getAdminByEmail;
const updateAdmin = (id, data) => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'patch',
  data
});
exports.updateAdmin = updateAdmin;
const deleteAdmin = adminname => (0, _request.default)({
  url: `/admins/${adminname}`,
  method: 'delete'
});
exports.deleteAdmin = deleteAdmin;
const login = data => (0, _request.default)({
  url: '/admins/login',
  method: 'post',
  data
});
exports.login = login;
const forgotPassword = data => (0, _request.default)({
  url: '/admins/forgot',
  method: 'post',
  data
});
exports.forgotPassword = forgotPassword;
const sendOtp = data => (0, _request.default)({
  url: '/admins/send-otp',
  method: 'post',
  data
});
exports.sendOtp = sendOtp;
const verifyOtp = data => (0, _request.default)({
  url: '/admins/verify-otp',
  method: 'post',
  data
});
exports.verifyOtp = verifyOtp;
const getAdminInfo = data => (0, _request.default)({
  url: '/admins/me',
  method: 'post',
  data
});
exports.getAdminInfo = getAdminInfo;
const changePassword = data => (0, _request.default)({
  url: '/admins/reset-password',
  method: 'post',
  data
});
exports.changePassword = changePassword;
const UpdatePassword = data => (0, _request.default)({
  url: '/admins/change-password',
  method: 'put',
  data
});
exports.UpdatePassword = UpdatePassword;
const changePasswordLink = data => (0, _request.default)({
  url: '/admins/check-link',
  method: 'post',
  data
});
exports.changePasswordLink = changePasswordLink;
const logout = () => (0, _request.default)({
  url: '/admins/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/admins/',
  method: 'post',
  data
});
exports.register = register;
const logPostAdmin = data => (0, _request.default)({
  url: '/admin-logs',
  method: 'post',
  data
});
exports.logPostAdmin = logPostAdmin;
const getLogsAdmin = params => (0, _request.default)({
  url: '/admin-logs',
  method: 'get',
  params
});
exports.getLogsAdmin = getLogsAdmin;