"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-card", {
    staticClass: "box-card-component",
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_c("div", {
    staticClass: "box-card-header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("img", {
    attrs: {
      src: "https://wpimg.wallstcn.com/e7d23d71-cf19-4b90-a1cc-f56af8c0903d.png"
    }
  })]), _c("div", {
    staticStyle: {
      position: "relative"
    }
  }, [_c("pan-thumb", {
    staticClass: "panThumb",
    attrs: {
      image: _vm.avatar
    }
  }), _c("mallki", {
    staticClass: "mallki-text",
    attrs: {
      text: "vue-typescript-admin"
    }
  }), _c("div", {
    staticClass: "progress-item",
    staticStyle: {
      "padding-top": "35px"
    }
  }, [_c("span", [_vm._v("Vue")]), _c("el-progress", {
    attrs: {
      percentage: 51
    }
  })], 1), _c("div", {
    staticClass: "progress-item"
  }, [_c("span", [_vm._v("Typescript")]), _c("el-progress", {
    attrs: {
      percentage: 45
    }
  })], 1), _c("div", {
    staticClass: "progress-item"
  }, [_c("span", [_vm._v("Css")]), _c("el-progress", {
    attrs: {
      percentage: 4
    }
  })], 1), _c("div", {
    staticClass: "progress-item"
  }, [_c("span", [_vm._v("ESLint")]), _c("el-progress", {
    attrs: {
      percentage: 100,
      status: "success"
    }
  })], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;