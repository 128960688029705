"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _echarts = _interopRequireDefault(require("echarts"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueClassComponent = require("vue-class-component");
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
let default_1 = class default_1 extends (0, _vueClassComponent.mixins)(_resize.default) {
  onChartDataChange(value) {
    this.setOptions(value);
  }
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  initChart() {
    this.chart = _echarts.default.init(this.$el, 'macarons');
    this.setOptions(this.chartData);
  }
  setOptions(chartData) {
    if (this.chart) {
      this.chart.setOption({
        xAxis: {
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: 8
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: ['users', 'videos']
        },
        series: [{
          name: 'users',
          itemStyle: {
            color: '#FF005A',
            lineStyle: {
              color: '#FF005A',
              width: 2
            }
          },
          smooth: true,
          type: 'line',
          data: chartData.actualData,
          animationDuration: 2800,
          animationEasing: 'cubicInOut'
        }, {
          name: 'videos',
          smooth: true,
          type: 'line',
          itemStyle: {
            color: '#3888fa',
            lineStyle: {
              color: '#3888fa',
              width: 2
            },
            areaStyle: {
              color: '#f3f8ff'
            }
          },
          data: chartData.expectedData,
          animationDuration: 2800,
          animationEasing: 'quadraticOut'
        }]
      });
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "chartData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'chart'
})], default_1.prototype, "className", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '100%'
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '350px'
})], default_1.prototype, "height", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('chartData', {
  deep: true
})], default_1.prototype, "onChartDataChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'LineChart'
})], default_1);
var _default = exports.default = default_1;