"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleClass = exports.removeClass = exports.parseTime = exports.hasClass = exports.getQuery = exports.getDescendantProp = exports.formatJson = exports.arrangeLetters = exports.addClass = void 0;
require("core-js/modules/es.array.push.js");
// Parse the time to string
const parseTime = (time, cFormat) => {
  if (time === undefined) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      return '0' + value;
    }
    return String(value) || '0';
  });
  return timeStr;
};
// Format and filter json data using filterKeys array
exports.parseTime = parseTime;
const formatJson = (filterKeys, jsonData) => jsonData.map(data => filterKeys.map(key => {
  if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
    return parseTime(data[key]);
  } else if (key === 'enabled') {
    return data[key] ? 'enabled' : 'disabled';
  } else if (key.indexOf('.') > 0) {
    return getDescendantProp(data, key);
    // return data[key.substr(0, key.indexOf('.'))][key.substr(key.indexOf('.') + 1)]
  } else {
    return data[key];
  }
}));
// Check if an element has a class
exports.formatJson = formatJson;
const hasClass = (ele, className) => {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};
// Add class to element
exports.hasClass = hasClass;
const addClass = (ele, className) => {
  if (!hasClass(ele, className)) ele.className += ' ' + className;
};
// Remove class from element
exports.addClass = addClass;
const removeClass = (ele, className) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
};
// Toggle class for the selected element
exports.removeClass = removeClass;
const toggleClass = (ele, className) => {
  if (!ele || !className) {
    return;
  }
  let classString = ele.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  ele.className = classString;
};
exports.toggleClass = toggleClass;
const getQuery = query => {
  const processedQuery = Object.assign({}, query);
  // set filter as empty
  processedQuery.filter = [];
  // check for filter
  if ('filter' in query) {
    // loop over fields and check for values
    Object.entries(query.filter).forEach(([field, condition]) => {
      // check if field value has be set
      if (field in query && [undefined, ''].indexOf(query[field]) === -1) {
        if (field.indexOf('_') >= 0) {
          var field_temp = field.split('_')[0];
          processedQuery.filter.push(field_temp + '||' + condition + '||' + '"' + processedQuery[field] + '"');
        } else {
          processedQuery.filter.push(field + '||' + condition + '||' + '"' + processedQuery[field] + '"');
        }
        delete processedQuery[field];
      }
    });
  }
  return processedQuery;
};
exports.getQuery = getQuery;
const getDescendantProp = (obj, desc) => {
  var arr = desc.split('.');
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};
exports.getDescendantProp = getDescendantProp;
const arrangeLetters = word => {
  //return word.charAt(0).toUpperCase() + word.slice(1);
  word = word.toLowerCase();
  const words = word.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
};
exports.arrangeLetters = arrangeLetters;