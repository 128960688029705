"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const countryRouter = {
  path: '/country',
  component: _index.default,
  redirect: 'noredirect',
  name: 'countryManagement.title',
  meta: {
    title: 'countryManagement.title',
    icon: 'map-marker'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/country/list.vue'))),
    name: 'countryManagement.title',
    meta: {
      title: 'countryManagement.title',
      noCache: true,
      icon: 'archway',
      header: 'List of all categories',
      info: 'All country details are available here. You can search categories by applying filters on fields like country name. After clicking on country name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/country/manage.vue'))),
    name: 'countryManagement.country.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'countryManagement.country.manage',
      noCache: true,
      activeMenu: '/country/list',
      hidden: true,
      header: 'Edit country',
      info: 'You can edit country here'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/country/manage.vue'))),
    name: 'countryManagement.country.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'countryManagement.country.manage',
      noCache: true,
      activeMenu: '/country/list',
      hidden: true,
      header: 'Add country',
      info: 'You can create country here'
    }
  }]
};
var _default = exports.default = countryRouter;