"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const cityRouter = {
  path: '/city',
  component: _index.default,
  redirect: 'noredirect',
  name: 'cityManagement.title',
  meta: {
    title: 'cityManagement.title',
    icon: 'map-marker',
    hidden: true
  },
  children: [{
    path: 'list/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/city/list.vue'))),
    name: 'cityManagement.title',
    meta: {
      title: 'cityManagement.title',
      noCache: true,
      icon: 'home',
      header: 'List of all categories',
      info: 'All city details are available here. You can search categories by applying filters on fields like city name. After clicking on city name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/city/manage.vue'))),
    name: 'cityManagement.city.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'cityManagement.city.manage',
      noCache: true,
      activeMenu: '/city/list',
      hidden: true,
      header: 'Edit city',
      info: 'You can edit city here'
    }
  }, {
    path: 'add/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/city/manage.vue'))),
    name: 'cityManagement.city.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'cityManagement.city.manage',
      noCache: true,
      activeMenu: '/city/list',
      hidden: true,
      header: 'Add city',
      info: 'You can create city here'
    }
  }]
};
var _default = exports.default = cityRouter;