"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'guide-2': {
    width: 1000,
    height: 1000,
    viewBox: '0 0 1000 1000',
    data: '<path pid="0" d="M11.6 547.9l282.8 126.4L703.7 291l137-128.3-479.5 551.5L724 860.6a16.8 16.8 0 0021.9-10.7v-.6l254-849L10.4 514.7c-8.7 4.7-11.8 15.3-8 24.7 2.4 4 5.5 7.3 9.3 8.6zm349 451.7L501.7 838l-141-61.2v222.8z"/>'
  }
});