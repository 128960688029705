"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _screenfull = _interopRequireDefault(require("screenfull"));
var _vuePropertyDecorator = require("vue-property-decorator");
const sf = _screenfull.default;
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isFullscreen = false;
  }
  mounted() {
    if (sf.isEnabled) {
      sf.on('change', this.change);
    }
  }
  beforeDestory() {
    if (sf.isEnabled) {
      sf.off('change', this.change);
    }
  }
  change() {
    if (sf.isEnabled) {
      this.isFullscreen = sf.isFullscreen;
    }
  }
  click() {
    if (!sf.isEnabled) {
      this.$message({
        message: 'you browser can not work',
        type: 'warning'
      });
      return false;
    }
    sf.toggle();
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Screenfull'
})], default_1);
var _default = exports.default = default_1;