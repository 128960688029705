"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _article = require("@/api/article");
var _savearticle = require("@/api/savearticle");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
// import { getService } from '@/api/service'
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    // private fetchUsers:any;
    this.serviceLoading = false;
    this.usersList = [];
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.postForm = Object.assign({
      title: 0,
      desc: 0
    });
    this.statusDialogVisible = false;
    this.showCreatedDate = false;
    this.fullscreenLoading = false;
    this.articleDetail = [];
    this.addAccess = true;
    this.listQuery = {
      page: 1,
      limit: 10,
      title: "",
      desc: "",
      sort: "id,DESC",
      filter: {
        title: "$contL",
        desc: "$contL",
        enabled: "eq"
      }
    };
    this.articlelistQuery = {
      // page: 1,
      // limit: 10,
      // articleId: undefined,
      sort: "id,DESC",
      filter: {
        // articleId: "eq",
        enabled: "eq"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.typeList = [{
      label: "Credit",
      value: 0
    }, {
      label: "Debit",
      value: 1
    }];
  }
  OpenStatus() {
    // this.leadData = row;
    this.statusDialogVisible = true;
  }
  created() {
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _article.getArticles)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    // this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  async getSingleUsers(data1) {
    this.postForm.articleId = data1.id;
    const data = await (0, _savearticle.getSaveArticles)((0, _index2.getQuery)(this.articlelistQuery));
    this.articleDetail = data;
    // this.drawer = true;
    // this.articlelistQuery.articleId = articleId;
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _article.deleteArticle)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Name", "Status", "timestamp"];
    const filterVal = ["id", "name", "enabled", "createdTimestamp"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.adminManagement.roles.title"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "transactionList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;