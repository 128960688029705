// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.custom-theme .el-form-item.is-required .el-form-item__label:after {\n  content: \"*\" !important;\n  color: #b3450e !important;\n  margin-right: 4px !important;\n}\n.createPost-container {\n  position: relative;\n}\n.createPost-container .createPost-main-container {\n  padding: 40px 45px 20px 50px;\n}\n.createPost-container .createPost-main-container .postInfo-container {\n  position: relative;\n  margin-bottom: 10px;\n}\n.createPost-container .createPost-main-container .postInfo-container:after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n.createPost-container .createPost-main-container .postInfo-container .postInfo-container-item {\n  float: left;\n}\n.createPost-container .word-counter {\n  width: 40px;\n  position: absolute;\n  right: 10px;\n  top: 0px;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#000",
	"menuText": "#fff",
	"menuActiveText": "#90d86a"
};
module.exports = exports;
