"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _path = _interopRequireDefault(require("path"));
var _fuse = _interopRequireDefault(require("fuse.js"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _permission = require("@/store/modules/permission");
var _lang = _interopRequireDefault(require("@/lang"));
// A lightweight fuzzy-search module

// Internationalization
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.search = '';
    this.show = false;
    this.options = [];
    this.searchPool = [];
  }
  get routes() {
    return _permission.PermissionModule.routes;
  }
  get lang() {
    return _app.AppModule.language;
  }
  onLangChange() {
    this.searchPool = this.generateRoutes(this.routes);
  }
  onRoutesChange() {
    this.searchPool = this.generateRoutes(this.routes);
  }
  onSearchPoolChange(value) {
    this.initFuse(value);
  }
  onShowChange(value) {
    if (value) {
      document.body.addEventListener('click', this.close);
    } else {
      document.body.removeEventListener('click', this.close);
    }
  }
  mounted() {
    this.searchPool = this.generateRoutes(this.routes);
  }
  click() {
    this.show = !this.show;
    if (this.show) {
      this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.focus();
    }
  }
  close() {
    this.$refs.headerSearchSelect && this.$refs.headerSearchSelect.blur();
    this.options = [];
    this.show = false;
  }
  change(route) {
    this.$router.push(route.path);
    this.search = '';
    this.options = [];
    this.$nextTick(() => {
      this.show = false;
    });
  }
  initFuse(list) {
    this.fuse = new _fuse.default(list, {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: [{
        name: 'title',
        weight: 0.7
      }, {
        name: 'path',
        weight: 0.3
      }]
    });
  }
  // Filter out the routes that can be displayed in the sidebar
  // And generate the internationalized title
  generateRoutes(routes, basePath = '/', prefixTitle = []) {
    let res = [];
    for (const router of routes) {
      // skip hidden router
      if (router.meta && router.meta.hidden) {
        continue;
      }
      const data = {
        path: _path.default.resolve(basePath, router.path),
        meta: {
          title: [...prefixTitle]
        }
      };
      if (router.meta && router.meta.title) {
        // generate internationalized title
        const i18ntitle = _lang.default.t(`route.${router.meta.title}`).toString();
        data.meta.title = [...data.meta.title, i18ntitle];
        if (router.redirect !== 'noRedirect') {
          // only push the routes with title
          // special case: need to exclude parent router without redirect
          res.push(data);
        }
      }
      // recursive child routes
      if (router.children) {
        const tempRoutes = this.generateRoutes(router.children, data.path, data.meta.title);
        if (tempRoutes.length >= 1) {
          res = [...res, ...tempRoutes];
        }
      }
    }
    return res;
  }
  querySearch(query) {
    if (query !== '') {
      if (this.fuse) {
        this.options = this.fuse.search(query);
      }
    } else {
      this.options = [];
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('lang')], default_1.prototype, "onLangChange", null);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('routes')], default_1.prototype, "onRoutesChange", null);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('searchPool')], default_1.prototype, "onSearchPoolChange", null);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('show')], default_1.prototype, "onShowChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'HeaderSearch'
})], default_1);
var _default = exports.default = default_1;