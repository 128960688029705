"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ccdata = void 0;
const ccdata = exports.ccdata = [{
  "country_code": "IN",
  "name": "India",
  "calling_code": "91",
  "flag": './flags/ind.png'
}, {
  "country_code": "AF",
  "name": "Afghanistan",
  "calling_code": "93",
  "flag": "./flags/afg.png"
}, {
  "country_code": "AX",
  "name": "Åland Islands",
  "calling_code": "358",
  "flag": "./flags/ala.png"
}, {
  "country_code": "AL",
  "name": "Albania",
  "calling_code": "355",
  "flag": "./flags/alb.png"
}, {
  "country_code": "DZ",
  "name": "Algeria",
  "calling_code": "213",
  "flag": "./flags/dza.png"
}, {
  "country_code": "AS",
  "name": "American Samoa",
  "calling_code": "1684",
  "flag": "./flags/asm.png"
}, {
  "country_code": "AD",
  "name": "Andorra",
  "calling_code": "376",
  "flag": "./flags/and.png"
}, {
  "country_code": "AO",
  "name": "Angola",
  "calling_code": "244",
  "flag": "./flags/ago.png"
}, {
  "country_code": "AI",
  "name": "Anguilla",
  "calling_code": "1264",
  "flag": "./flags/aia.png"
}, {
  "country_code": "AQ",
  "name": "Antarctica",
  "calling_code": "672",
  "flag": "./flags/ata.png"
}, {
  "country_code": "AG",
  "name": "Antigua and Barbuda",
  "calling_code": "1268",
  "flag": "./flags/atg.png"
}, {
  "country_code": "AR",
  "name": "Argentina",
  "calling_code": "54",
  "flag": "./flags/arg.png"
}, {
  "country_code": "AM",
  "name": "Armenia",
  "calling_code": "374",
  "flag": "./flags/arm.png"
}, {
  "country_code": "AW",
  "name": "Aruba",
  "calling_code": "297",
  "flag": "./flags/abw.png"
}, {
  "country_code": "AU",
  "name": "Australia",
  "calling_code": "61",
  "flag": "./flags/aus.png"
}, {
  "country_code": "AT",
  "name": "Austria",
  "calling_code": "43",
  "flag": "./flags/aut.png"
}, {
  "country_code": "AZ",
  "name": "Azerbaijan",
  "calling_code": "994",
  "flag": "./flags/aze.png"
}, {
  "country_code": "BS",
  "name": "Bahamas",
  "calling_code": "1242",
  "flag": "./flags/bhs.png"
}, {
  "country_code": "BH",
  "name": "Bahrain",
  "calling_code": "973",
  "flag": "./flags/bhr.png"
}, {
  "country_code": "BD",
  "name": "Bangladesh",
  "calling_code": "880",
  "flag": "./flags/bgd.png"
}, {
  "country_code": "BB",
  "name": "Barbados",
  "calling_code": "1246",
  "flag": "./flags/brb.png"
}, {
  "country_code": "BY",
  "name": "Belarus",
  "calling_code": "375",
  "flag": "./flags/blr.png"
}, {
  "country_code": "BE",
  "name": "Belgium",
  "calling_code": "32",
  "flag": "./flags/bel.png"
}, {
  "country_code": "BZ",
  "name": "Belize",
  "calling_code": "501",
  "flag": "./flags/blz.png"
}, {
  "country_code": "BJ",
  "name": "Benin",
  "calling_code": "229",
  "flag": "./flags/ben.png"
}, {
  "country_code": "BM",
  "name": "Bermuda",
  "calling_code": "1441",
  "flag": "./flags/bmu.png"
}, {
  "country_code": "BT",
  "name": "Bhutan",
  "calling_code": "975",
  "flag": "./flags/btn.png"
}, {
  "country_code": "BO",
  "name": "Bolivia (Plurinational State of)",
  "calling_code": "591",
  "flag": "./flags/bol.png"
}, {
  "country_code": "BQ",
  "name": "Bonaire, Sint Eustatius and Saba",
  "calling_code": "5997",
  "flag": "./flags/bes.png"
}, {
  "country_code": "BA",
  "name": "Bosnia and Herzegovina",
  "calling_code": "387",
  "flag": "./flags/bih.png"
}, {
  "country_code": "BW",
  "name": "Botswana",
  "calling_code": "267",
  "flag": "./flags/bwa.png"
}, {
  "country_code": "BV",
  "name": "Bouvet Island",
  "calling_code": "55",
  "flag": "./flags/bvt.png"
}, {
  "country_code": "BR",
  "name": "Brazil",
  "calling_code": "55",
  "flag": "./flags/bra.png"
}, {
  "country_code": "IO",
  "name": "British Indian Ocean Territory",
  "calling_code": "246",
  "flag": "./flags/iot.png"
}, {
  "country_code": "UM",
  "name": "United States Minor Outlying Islands",
  "calling_code": "246",
  "flag": "./flags/umi.png"
}, {
  "country_code": "VG",
  "name": "Virgin Islands (British)",
  "calling_code": "1284",
  "flag": "./flags/vgb.png"
}, {
  "country_code": "VI",
  "name": "Virgin Islands (U.S.)",
  "calling_code": "1 340",
  "flag": "./flags/vir.png"
}, {
  "country_code": "BN",
  "name": "Brunei Darussalam",
  "calling_code": "673",
  "flag": "./flags/brn.png"
}, {
  "country_code": "BG",
  "name": "Bulgaria",
  "calling_code": "359",
  "flag": "./flags/bgr.png"
}, {
  "country_code": "BF",
  "name": "Burkina Faso",
  "calling_code": "226",
  "flag": "./flags/bfa.png"
}, {
  "country_code": "BI",
  "name": "Burundi",
  "calling_code": "257",
  "flag": "./flags/bdi.png"
}, {
  "country_code": "KH",
  "name": "Cambodia",
  "calling_code": "855",
  "flag": "./flags/khm.png"
}, {
  "country_code": "CM",
  "name": "Cameroon",
  "calling_code": "237",
  "flag": "./flags/cmr.png"
}, {
  "country_code": "CA",
  "name": "Canada",
  "calling_code": "1",
  "flag": "./flags/can.png"
}, {
  "country_code": "CV",
  "name": "Cabo Verde",
  "calling_code": "238",
  "flag": "./flags/cpv.png"
}, {
  "country_code": "KY",
  "name": "Cayman Islands",
  "calling_code": "1345",
  "flag": "./flags/cym.png"
}, {
  "country_code": "CF",
  "name": "Central African Republic",
  "calling_code": "236",
  "flag": "./flags/caf.png"
}, {
  "country_code": "TD",
  "name": "Chad",
  "calling_code": "235",
  "flag": "./flags/tcd.png"
}, {
  "country_code": "CL",
  "name": "Chile",
  "calling_code": "56",
  "flag": "./flags/chl.png"
}, {
  "country_code": "CN",
  "name": "China",
  "calling_code": "86",
  "flag": "./flags/chn.png"
}, {
  "country_code": "CX",
  "name": "Christmas Island",
  "calling_code": "61",
  "flag": "./flags/cxr.png"
}, {
  "country_code": "CC",
  "name": "Cocos (Keeling) Islands",
  "calling_code": "61",
  "flag": "./flags/cck.png"
}, {
  "country_code": "CO",
  "name": "Colombia",
  "calling_code": "57",
  "flag": "./flags/col.png"
}, {
  "country_code": "KM",
  "name": "Comoros",
  "calling_code": "269",
  "flag": "./flags/com.png"
}, {
  "country_code": "CG",
  "name": "Congo",
  "calling_code": "242",
  "flag": "./flags/cog.png"
}, {
  "country_code": "CD",
  "name": "Congo (Democratic Republic of the)",
  "calling_code": "243",
  "flag": "./flags/cod.png"
}, {
  "country_code": "CK",
  "name": "Cook Islands",
  "calling_code": "682",
  "flag": "./flags/cok.png"
}, {
  "country_code": "CR",
  "name": "Costa Rica",
  "calling_code": "506",
  "flag": "./flags/cri.png"
}, {
  "country_code": "HR",
  "name": "Croatia",
  "calling_code": "385",
  "flag": "./flags/hrv.png"
}, {
  "country_code": "CU",
  "name": "Cuba",
  "calling_code": "53",
  "flag": "./flags/cub.png"
}, {
  "country_code": "CW",
  "name": "Curaçao",
  "calling_code": "599",
  "flag": "./flags/cuw.png"
}, {
  "country_code": "CY",
  "name": "Cyprus",
  "calling_code": "357",
  "flag": "./flags/cyp.png"
}, {
  "country_code": "CZ",
  "name": "Czech Republic",
  "calling_code": "420",
  "flag": "./flags/cze.png"
}, {
  "country_code": "DK",
  "name": "Denmark",
  "calling_code": "45",
  "flag": "./flags/dnk.png"
}, {
  "country_code": "DJ",
  "name": "Djibouti",
  "calling_code": "253",
  "flag": "./flags/dji.png"
}, {
  "country_code": "DM",
  "name": "Dominica",
  "calling_code": "1767",
  "flag": "./flags/dma.png"
}, {
  "country_code": "DO",
  "name": "Dominican Republic",
  "calling_code": "1809",
  "flag": "./flags/dom.png"
}, {
  "country_code": "EC",
  "name": "Ecuador",
  "calling_code": "593",
  "flag": "./flags/ecu.png"
}, {
  "country_code": "EG",
  "name": "Egypt",
  "calling_code": "20",
  "flag": "./flags/egy.png"
}, {
  "country_code": "SV",
  "name": "El Salvador",
  "calling_code": "503",
  "flag": "./flags/slv.png"
}, {
  "country_code": "GQ",
  "name": "Equatorial Guinea",
  "calling_code": "240",
  "flag": "./flags/gnq.png"
}, {
  "country_code": "ER",
  "name": "Eritrea",
  "calling_code": "291",
  "flag": "./flags/eri.png"
}, {
  "country_code": "EE",
  "name": "Estonia",
  "calling_code": "372",
  "flag": "./flags/est.png"
}, {
  "country_code": "ET",
  "name": "Ethiopia",
  "calling_code": "251",
  "flag": "./flags/eth.png"
}, {
  "country_code": "FK",
  "name": "Falkland Islands (Malvinas)",
  "calling_code": "500",
  "flag": "./flags/flk.png"
}, {
  "country_code": "FO",
  "name": "Faroe Islands",
  "calling_code": "298",
  "flag": "./flags/fro.png"
}, {
  "country_code": "FJ",
  "name": "Fiji",
  "calling_code": "679",
  "flag": "./flags/fji.png"
}, {
  "country_code": "FI",
  "name": "Finland",
  "calling_code": "358",
  "flag": "./flags/fin.png"
}, {
  "country_code": "FR",
  "name": "France",
  "calling_code": "33",
  "flag": "./flags/fra.png"
}, {
  "country_code": "GF",
  "name": "French Guiana",
  "calling_code": "594",
  "flag": "./flags/guf.png"
}, {
  "country_code": "PF",
  "name": "French Polynesia",
  "calling_code": "689",
  "flag": "./flags/pyf.png"
}, {
  "country_code": "TF",
  "name": "French Southern Territories",
  "calling_code": "262",
  "flag": "./flags/atf.png"
}, {
  "country_code": "GA",
  "name": "Gabon",
  "calling_code": "241",
  "flag": "./flags/gab.png"
}, {
  "country_code": "GM",
  "name": "Gambia",
  "calling_code": "220",
  "flag": "./flags/gmb.png"
}, {
  "country_code": "GE",
  "name": "Georgia",
  "calling_code": "995",
  "flag": "./flags/geo.png"
}, {
  "country_code": "DE",
  "name": "Germany",
  "calling_code": "49",
  "flag": "./flags/deu.png"
}, {
  "country_code": "GH",
  "name": "Ghana",
  "calling_code": "233",
  "flag": "./flags/gha.png"
}, {
  "country_code": "GI",
  "name": "Gibraltar",
  "calling_code": "350",
  "flag": "./flags/gib.png"
}, {
  "country_code": "GR",
  "name": "Greece",
  "calling_code": "30",
  "flag": "./flags/grc.png"
}, {
  "country_code": "GL",
  "name": "Greenland",
  "calling_code": "299",
  "flag": "./flags/grl.png"
}, {
  "country_code": "GD",
  "name": "Grenada",
  "calling_code": "1473",
  "flag": "./flags/grd.png"
}, {
  "country_code": "GP",
  "name": "Guadeloupe",
  "calling_code": "590",
  "flag": "./flags/glp.png"
}, {
  "country_code": "GU",
  "name": "Guam",
  "calling_code": "1671",
  "flag": "./flags/gum.png"
}, {
  "country_code": "GT",
  "name": "Guatemala",
  "calling_code": "502",
  "flag": "./flags/gtm.png"
}, {
  "country_code": "GG",
  "name": "Guernsey",
  "calling_code": "44",
  "flag": "./flags/ggy.png"
}, {
  "country_code": "GN",
  "name": "Guinea",
  "calling_code": "224",
  "flag": "./flags/gin.png"
}, {
  "country_code": "GW",
  "name": "Guinea-Bissau",
  "calling_code": "245",
  "flag": "./flags/gnb.png"
}, {
  "country_code": "GY",
  "name": "Guyana",
  "calling_code": "592",
  "flag": "./flags/guy.png"
}, {
  "country_code": "HT",
  "name": "Haiti",
  "calling_code": "509",
  "flag": "./flags/hti.png"
}, {
  "country_code": "HM",
  "name": "Heard Island and McDonald Islands",
  "calling_code": "672",
  "flag": "./flags/hmd.png"
}, {
  "country_code": "VA",
  "name": "Holy See",
  "calling_code": "379",
  "flag": "./flags/vat.png"
}, {
  "country_code": "HN",
  "name": "Honduras",
  "calling_code": "504",
  "flag": "./flags/hnd.png"
}, {
  "country_code": "HK",
  "name": "Hong Kong",
  "calling_code": "852",
  "flag": "./flags/hkg.png"
}, {
  "country_code": "HU",
  "name": "Hungary",
  "calling_code": "36",
  "flag": "./flags/hun.png"
}, {
  "country_code": "IS",
  "name": "Iceland",
  "calling_code": "354",
  "flag": "./flags/isl.png"
}, {
  "country_code": "IN",
  "name": "India",
  "calling_code": "91",
  "flag": './flags/ind.png'
}, {
  "country_code": "ID",
  "name": "Indonesia",
  "calling_code": "62",
  "flag": "./flags/idn.png"
}, {
  "country_code": "CI",
  "name": "Côte d'Ivoire",
  "calling_code": "225",
  "flag": "./flags/civ.png"
}, {
  "country_code": "IR",
  "name": "Iran (Islamic Republic of)",
  "calling_code": "98",
  "flag": "./flags/irn.png"
}, {
  "country_code": "IQ",
  "name": "Iraq",
  "calling_code": "964",
  "flag": "./flags/irq.png"
}, {
  "country_code": "IE",
  "name": "Ireland",
  "calling_code": "353",
  "flag": "./flags/irl.png"
}, {
  "country_code": "IM",
  "name": "Isle of Man",
  "calling_code": "44",
  "flag": "./flags/imn.png"
}, {
  "country_code": "IL",
  "name": "Israel",
  "calling_code": "972",
  "flag": "./flags/isr.png"
}, {
  "country_code": "IT",
  "name": "Italy",
  "calling_code": "39",
  "flag": "./flags/ita.png"
}, {
  "country_code": "JM",
  "name": "Jamaica",
  "calling_code": "1876",
  "flag": "./flags/jam.png"
}, {
  "country_code": "JP",
  "name": "Japan",
  "calling_code": "81",
  "flag": "./flags/jpn.png"
}, {
  "country_code": "JE",
  "name": "Jersey",
  "calling_code": "44",
  "flag": "./flags/jey.png"
}, {
  "country_code": "JO",
  "name": "Jordan",
  "calling_code": "962",
  "flag": "./flags/jor.png"
}, {
  "country_code": "KZ",
  "name": "Kazakhstan",
  "calling_code": "76",
  "flag": "./flags/kaz.png"
}, {
  "country_code": "KE",
  "name": "Kenya",
  "calling_code": "254",
  "flag": "./flags/ken.png"
}, {
  "country_code": "KI",
  "name": "Kiribati",
  "calling_code": "686",
  "flag": "./flags/kir.png"
}, {
  "country_code": "KW",
  "name": "Kuwait",
  "calling_code": "965",
  "flag": "./flags/kwt.png"
}, {
  "country_code": "KG",
  "name": "Kyrgyzstan",
  "calling_code": "996",
  "flag": "./flags/kgz.png"
}, {
  "country_code": "LA",
  "name": "Lao People's Democratic Republic",
  "calling_code": "856",
  "flag": "./flags/lao.png"
}, {
  "country_code": "LV",
  "name": "Latvia",
  "calling_code": "371",
  "flag": "./flags/lva.png"
}, {
  "country_code": "LB",
  "name": "Lebanon",
  "calling_code": "961",
  "flag": "./flags/lbn.png"
}, {
  "country_code": "LS",
  "name": "Lesotho",
  "calling_code": "266",
  "flag": "./flags/lso.png"
}, {
  "country_code": "LR",
  "name": "Liberia",
  "calling_code": "231",
  "flag": "./flags/lbr.png"
}, {
  "country_code": "LY",
  "name": "Libya",
  "calling_code": "218",
  "flag": "./flags/lby.png"
}, {
  "country_code": "LI",
  "name": "Liechtenstein",
  "calling_code": "423",
  "flag": "./flags/lie.png"
}, {
  "country_code": "LT",
  "name": "Lithuania",
  "calling_code": "370",
  "flag": "./flags/ltu.png"
}, {
  "country_code": "LU",
  "name": "Luxembourg",
  "calling_code": "352",
  "flag": "./flags/lux.png"
}, {
  "country_code": "MO",
  "name": "Macao",
  "calling_code": "853",
  "flag": "./flags/mac.png"
}, {
  "country_code": "MK",
  "name": "Macedonia (the former Yugoslav Republic of)",
  "calling_code": "389",
  "flag": "./flags/mkd.png"
}, {
  "country_code": "MG",
  "name": "Madagascar",
  "calling_code": "261",
  "flag": "./flags/mdg.png"
}, {
  "country_code": "MW",
  "name": "Malawi",
  "calling_code": "265",
  "flag": "./flags/mwi.png"
}, {
  "country_code": "MY",
  "name": "Malaysia",
  "calling_code": "60",
  "flag": "./flags/mys.png"
}, {
  "country_code": "MV",
  "name": "Maldives",
  "calling_code": "960",
  "flag": "./flags/mdv.png"
}, {
  "country_code": "ML",
  "name": "Mali",
  "calling_code": "223",
  "flag": "./flags/mli.png"
}, {
  "country_code": "MT",
  "name": "Malta",
  "calling_code": "356",
  "flag": "./flags/mlt.png"
}, {
  "country_code": "MH",
  "name": "Marshall Islands",
  "calling_code": "692",
  "flag": "./flags/mhl.png"
}, {
  "country_code": "MQ",
  "name": "Martinique",
  "calling_code": "596",
  "flag": "./flags/mtq.png"
}, {
  "country_code": "MR",
  "name": "Mauritania",
  "calling_code": "222",
  "flag": "./flags/mrt.png"
}, {
  "country_code": "MU",
  "name": "Mauritius",
  "calling_code": "230",
  "flag": "./flags/mus.png"
}, {
  "country_code": "YT",
  "name": "Mayotte",
  "calling_code": "262",
  "flag": "./flags/myt.png"
}, {
  "country_code": "MX",
  "name": "Mexico",
  "calling_code": "52",
  "flag": "./flags/mex.png"
}, {
  "country_code": "FM",
  "name": "Micronesia (Federated States of)",
  "calling_code": "691",
  "flag": "./flags/fsm.png"
}, {
  "country_code": "MD",
  "name": "Moldova (Republic of)",
  "calling_code": "373",
  "flag": "./flags/mda.png"
}, {
  "country_code": "MC",
  "name": "Monaco",
  "calling_code": "377",
  "flag": "./flags/mco.png"
}, {
  "country_code": "MN",
  "name": "Mongolia",
  "calling_code": "976",
  "flag": "./flags/mng.png"
}, {
  "country_code": "ME",
  "name": "Montenegro",
  "calling_code": "382",
  "flag": "./flags/mne.png"
}, {
  "country_code": "MS",
  "name": "Montserrat",
  "calling_code": "1664",
  "flag": "./flags/msr.png"
}, {
  "country_code": "MA",
  "name": "Morocco",
  "calling_code": "212",
  "flag": "./flags/mar.png"
}, {
  "country_code": "MZ",
  "name": "Mozambique",
  "calling_code": "258",
  "flag": "./flags/moz.png"
}, {
  "country_code": "MM",
  "name": "Myanmar",
  "calling_code": "95",
  "flag": "./flags/mmr.png"
}, {
  "country_code": "NA",
  "name": "Namibia",
  "calling_code": "264",
  "flag": "./flags/nam.png"
}, {
  "country_code": "NR",
  "name": "Nauru",
  "calling_code": "674",
  "flag": "./flags/nru.png"
}, {
  "country_code": "NP",
  "name": "Nepal",
  "calling_code": "977",
  "flag": "./flags/npl.png"
}, {
  "country_code": "NL",
  "name": "Netherlands",
  "calling_code": "31",
  "flag": "./flags/nld.png"
}, {
  "country_code": "NC",
  "name": "New Caledonia",
  "calling_code": "687",
  "flag": "./flags/ncl.png"
}, {
  "country_code": "NZ",
  "name": "New Zealand",
  "calling_code": "64",
  "flag": "./flags/nzl.png"
}, {
  "country_code": "NI",
  "name": "Nicaragua",
  "calling_code": "505",
  "flag": "./flags/nic.png"
}, {
  "country_code": "NE",
  "name": "Niger",
  "calling_code": "227",
  "flag": "./flags/ner.png"
}, {
  "country_code": "NG",
  "name": "Nigeria",
  "calling_code": "234",
  "flag": "./flags/nga.png"
}, {
  "country_code": "NU",
  "name": "Niue",
  "calling_code": "683",
  "flag": "./flags/niu.png"
}, {
  "country_code": "NF",
  "name": "Norfolk Island",
  "calling_code": "672",
  "flag": "./flags/nfk.png"
}, {
  "country_code": "KP",
  "name": "Korea (Democratic People's Republic of)",
  "calling_code": "850",
  "flag": "./flags/prk.png"
}, {
  "country_code": "MP",
  "name": "Northern Mariana Islands",
  "calling_code": "1670",
  "flag": "./flags/mnp.png"
}, {
  "country_code": "NO",
  "name": "Norway",
  "calling_code": "47",
  "flag": "./flags/nor.png"
}, {
  "country_code": "OM",
  "name": "Oman",
  "calling_code": "968",
  "flag": "./flags/omn.png"
}, {
  "country_code": "PK",
  "name": "Pakistan",
  "calling_code": "92",
  "flag": "./flags/pak.png"
}, {
  "country_code": "PW",
  "name": "Palau",
  "calling_code": "680",
  "flag": "./flags/plw.png"
}, {
  "country_code": "PS",
  "name": "Palestine, State of",
  "calling_code": "970",
  "flag": "./flags/pse.png"
}, {
  "country_code": "PA",
  "name": "Panama",
  "calling_code": "507",
  "flag": "./flags/pan.png"
}, {
  "country_code": "PG",
  "name": "Papua New Guinea",
  "calling_code": "675",
  "flag": "./flags/png.png"
}, {
  "country_code": "PY",
  "name": "Paraguay",
  "calling_code": "595",
  "flag": "./flags/pry.png"
}, {
  "country_code": "PE",
  "name": "Peru",
  "calling_code": "51",
  "flag": "./flags/per.png"
}, {
  "country_code": "PH",
  "name": "Philippines",
  "calling_code": "63",
  "flag": "./flags/phl.png"
}, {
  "country_code": "PN",
  "name": "Pitcairn",
  "calling_code": "64",
  "flag": "./flags/pcn.png"
}, {
  "country_code": "PL",
  "name": "Poland",
  "calling_code": "48",
  "flag": "./flags/pol.png"
}, {
  "country_code": "PT",
  "name": "Portugal",
  "calling_code": "351",
  "flag": "./flags/prt.png"
}, {
  "country_code": "PR",
  "name": "Puerto Rico",
  "calling_code": "1787",
  "flag": "./flags/pri.png"
}, {
  "country_code": "QA",
  "name": "Qatar",
  "calling_code": "974",
  "flag": "./flags/qat.png"
}, {
  "country_code": "XK",
  "name": "Republic of Kosovo",
  "calling_code": "383",
  "flag": "./flags/kos.png"
}, {
  "country_code": "RE",
  "name": "Réunion",
  "calling_code": "262",
  "flag": "./flags/reu.png"
}, {
  "country_code": "RO",
  "name": "Romania",
  "calling_code": "40",
  "flag": "./flags/rou.png"
}, {
  "country_code": "RU",
  "name": "Russian Federation",
  "calling_code": "7",
  "flag": "./flags/rus.png"
}, {
  "country_code": "RW",
  "name": "Rwanda",
  "calling_code": "250",
  "flag": "./flags/rwa.png"
}, {
  "country_code": "BL",
  "name": "Saint Barthélemy",
  "calling_code": "590",
  "flag": "./flags/blm.png"
}, {
  "country_code": "SH",
  "name": "Saint Helena, Ascension and Tristan da Cunha",
  "calling_code": "290",
  "flag": "./flags/shn.png"
}, {
  "country_code": "KN",
  "name": "Saint Kitts and Nevis",
  "calling_code": "1869",
  "flag": "./flags/kna.png"
}, {
  "country_code": "LC",
  "name": "Saint Lucia",
  "calling_code": "1758",
  "flag": "./flags/lca.png"
}, {
  "country_code": "MF",
  "name": "Saint Martin (French part)",
  "calling_code": "590",
  "flag": "./flags/maf.png"
}, {
  "country_code": "PM",
  "name": "Saint Pierre and Miquelon",
  "calling_code": "508",
  "flag": "./flags/spm.png"
}, {
  "country_code": "VC",
  "name": "Saint Vincent and the Grenadines",
  "calling_code": "1784",
  "flag": "./flags/vct.png"
}, {
  "country_code": "WS",
  "name": "Samoa",
  "calling_code": "685",
  "flag": "./flags/wsm.png"
}, {
  "country_code": "SM",
  "name": "San Marino",
  "calling_code": "378",
  "flag": "./flags/smr.png"
}, {
  "country_code": "ST",
  "name": "Sao Tome and Principe",
  "calling_code": "239",
  "flag": "./flags/stp.png"
}, {
  "country_code": "SA",
  "name": "Saudi Arabia",
  "calling_code": "966",
  "flag": "./flags/sau.png"
}, {
  "country_code": "SN",
  "name": "Senegal",
  "calling_code": "221",
  "flag": "./flags/sen.png"
}, {
  "country_code": "RS",
  "name": "Serbia",
  "calling_code": "381",
  "flag": "./flags/srb.png"
}, {
  "country_code": "SC",
  "name": "Seychelles",
  "calling_code": "248",
  "flag": "./flags/syc.png"
}, {
  "country_code": "SL",
  "name": "Sierra Leone",
  "calling_code": "232",
  "flag": "./flags/sle.png"
}, {
  "country_code": "SG",
  "name": "Singapore",
  "calling_code": "65",
  "flag": "./flags/sgp.png"
}, {
  "country_code": "SX",
  "name": "Sint Maarten (Dutch part)",
  "calling_code": "1721",
  "flag": "./flags/sxm.png"
}, {
  "country_code": "SK",
  "name": "Slovakia",
  "calling_code": "421",
  "flag": "./flags/svk.png"
}, {
  "country_code": "SI",
  "name": "Slovenia",
  "calling_code": "386",
  "flag": "./flags/svn.png"
}, {
  "country_code": "SB",
  "name": "Solomon Islands",
  "calling_code": "677",
  "flag": "./flags/slb.png"
}, {
  "country_code": "SO",
  "name": "Somalia",
  "calling_code": "252",
  "flag": "./flags/som.png"
}, {
  "country_code": "ZA",
  "name": "South Africa",
  "calling_code": "27",
  "flag": "./flags/zaf.png"
}, {
  "country_code": "GS",
  "name": "South Georgia and the South Sandwich Islands",
  "calling_code": "500",
  "flag": "./flags/sgs.png"
}, {
  "country_code": "KR",
  "name": "Korea (Republic of)",
  "calling_code": "82",
  "flag": "./flags/kor.png"
}, {
  "country_code": "SS",
  "name": "South Sudan",
  "calling_code": "211",
  "flag": "./flags/ssd.png"
}, {
  "country_code": "ES",
  "name": "Spain",
  "calling_code": "34",
  "flag": "./flags/esp.png"
}, {
  "country_code": "LK",
  "name": "Sri Lanka",
  "calling_code": "94",
  "flag": "./flags/lka.png"
}, {
  "country_code": "SD",
  "name": "Sudan",
  "calling_code": "249",
  "flag": "./flags/sdn.png"
}, {
  "country_code": "SR",
  "name": "Suriname",
  "calling_code": "597",
  "flag": "./flags/sur.png"
}, {
  "country_code": "SJ",
  "name": "Svalbard and Jan Mayen",
  "calling_code": "4779",
  "flag": "./flags/sjm.png"
}, {
  "country_code": "SZ",
  "name": "Swaziland",
  "calling_code": "268",
  "flag": "./flags/swz.png"
}, {
  "country_code": "SE",
  "name": "Sweden",
  "calling_code": "46",
  "flag": "./flags/swe.png"
}, {
  "country_code": "CH",
  "name": "Switzerland",
  "calling_code": "41",
  "flag": "./flags/che.png"
}, {
  "country_code": "SY",
  "name": "Syrian Arab Republic",
  "calling_code": "963",
  "flag": "./flags/syr.png"
}, {
  "country_code": "TW",
  "name": "Taiwan",
  "calling_code": "886",
  "flag": "./flags/twn.png"
}, {
  "country_code": "TJ",
  "name": "Tajikistan",
  "calling_code": "992",
  "flag": "./flags/tjk.png"
}, {
  "country_code": "TZ",
  "name": "Tanzania, United Republic of",
  "calling_code": "255",
  "flag": "./flags/tza.png"
}, {
  "country_code": "TH",
  "name": "Thailand",
  "calling_code": "66",
  "flag": "./flags/tha.png"
}, {
  "country_code": "TL",
  "name": "Timor-Leste",
  "calling_code": "670",
  "flag": "./flags/tls.png"
}, {
  "country_code": "TG",
  "name": "Togo",
  "calling_code": "228",
  "flag": "./flags/tgo.png"
}, {
  "country_code": "TK",
  "name": "Tokelau",
  "calling_code": "690",
  "flag": "./flags/tkl.png"
}, {
  "country_code": "TO",
  "name": "Tonga",
  "calling_code": "676",
  "flag": "./flags/ton.png"
}, {
  "country_code": "TT",
  "name": "Trinidad and Tobago",
  "calling_code": "1868",
  "flag": "./flags/tto.png"
}, {
  "country_code": "TN",
  "name": "Tunisia",
  "calling_code": "216",
  "flag": "./flags/tun.png"
}, {
  "country_code": "TR",
  "name": "Turkey",
  "calling_code": "90",
  "flag": "./flags/tur.png"
}, {
  "country_code": "TM",
  "name": "Turkmenistan",
  "calling_code": "993",
  "flag": "./flags/tkm.png"
}, {
  "country_code": "TC",
  "name": "Turks and Caicos Islands",
  "calling_code": "1649",
  "flag": "./flags/tca.png"
}, {
  "country_code": "TV",
  "name": "Tuvalu",
  "calling_code": "688",
  "flag": "./flags/tuv.png"
}, {
  "country_code": "UG",
  "name": "Uganda",
  "calling_code": "256",
  "flag": "./flags/uga.png"
}, {
  "country_code": "UA",
  "name": "Ukraine",
  "calling_code": "380",
  "flag": "./flags/ukr.png"
}, {
  "country_code": "AE",
  "name": "United Arab Emirates",
  "calling_code": "971",
  "flag": "./flags/are.png"
}, {
  "country_code": "GB",
  "name": "United Kingdom of Great Britain and Northern Ireland",
  "calling_code": "44",
  "flag": "./flags/gbr.png"
}, {
  "country_code": "US",
  "name": "United States of America",
  "calling_code": "1",
  "flag": "./flags/usa.png"
}, {
  "country_code": "UY",
  "name": "Uruguay",
  "calling_code": "598",
  "flag": "./flags/ury.png"
}, {
  "country_code": "UZ",
  "name": "Uzbekistan",
  "calling_code": "998",
  "flag": "./flags/uzb.png"
}, {
  "country_code": "VU",
  "name": "Vanuatu",
  "calling_code": "678",
  "flag": "./flags/vut.png"
}, {
  "country_code": "VE",
  "name": "Venezuela (Bolivarian Republic of)",
  "calling_code": "58",
  "flag": "./flags/ven.png"
}, {
  "country_code": "VN",
  "name": "Viet Nam",
  "calling_code": "84",
  "flag": "./flags/vnm.png"
}, {
  "country_code": "WF",
  "name": "Wallis and Futuna",
  "calling_code": "681",
  "flag": "./flags/wlf.png"
}, {
  "country_code": "EH",
  "name": "Western Sahara",
  "calling_code": "212",
  "flag": "./flags/esh.png"
}, {
  "country_code": "YE",
  "name": "Yemen",
  "calling_code": "967",
  "flag": "./flags/yem.png"
}, {
  "country_code": "ZM",
  "name": "Zambia",
  "calling_code": "260",
  "flag": "./flags/zmb.png"
}, {
  "country_code": "ZW",
  "name": "Zimbabwe",
  "calling_code": "263",
  "flag": "./flags/zwe.png"
}];