"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _session = require("@/api/session");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _moment = _interopRequireDefault(require("moment"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.reportlist = [];
    this.feedlist = [];
    this.feedtype = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.dialogVisibility = false;
    this.dialogVisible = false;
    this.dialogLoading = false;
    this.statusDialogVisible = false;
    this.statusDialogLoading = false;
    this.fullscreenLoading = false;
    this.dialogDriverLicenseVisible = false;
    this.dialogBusinessLicenseVisible = false;
    this.dialogViewMoreDetail = false;
    this.status = 0;
    this.showCreatedDate = false;
    this.reason = '';
    // activeTab: any = 'inspiration';
    this.userData = Object.assign({}, _session.defaultSessionData);
    this.postCount = [];
    this.userDetail = [];
    this.drawer = false;
    this.drawerTitle = 'session Details';
    this.activeTab = 'today';
    this.updateDialogVisible = false;
    //  private todayDate:any;
    this.listQuery = {
      sort: 'id,DESC',
      page: 1,
      limit: 10,
      enabled: true,
      status: 0,
      // type:1,
      //  status: undefined as string | number | undefined,
      //  date: undefined as string | undefined,
      filter: {
        enabled: 'eq',
        title: '$contL',
        userSessionMasterId: 'eq',
        // date :undefined as string | undefined,
        status: 'eq',
        'admin.fullName': '$contL',
        'user.fullName': '$contL'
      }
    };
    this.eventQuery = {
      page: 1,
      type: 1,
      feedId: undefined,
      filter: {
        feedId: '$eq',
        enabled: 'eq',
        type: 'eq'
      }
    };
    this.countQuery = {
      // page: 1,
      // type: 1,
      // userId: undefined,
      filter: {
        // userId: "$eq",
        enabled: 'eq'
        // type: "eq",
      }
    };
    this.reportQuery = {
      userId: undefined,
      filter: {
        userId: '$eq'
      }
    };
    this.languageQuery = {
      sort: 'id,DESC',
      userId: undefined,
      filter: {
        userId: 'eq',
        enabled: 'eq'
      }
    };
    // inspiration;
    // hire designer
    // service provider
    this.reasonList = [];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
    this.statusList = [{
      label: 'Upcoming',
      value: 0
    }, {
      label: 'Today',
      value: 1
    }, {
      label: 'Completed',
      value: 2
    }, {
      label: 'Cancelled(User)',
      value: 3
    }, {
      label: 'Cancelled(Admin)',
      value: 4
    }];
    this.sortOptions = [{
      label: 'Id, ASC',
      value: 'id,ASC'
    }, {
      label: 'Id, DESC',
      value: 'id,DESC'
    }];
    this.todayDate = this.formatDate(new Date());
    this.trainerData = {
      status: null
    };
  }
  formatLocalTime(timestamp) {
    // Parse the timestamp
    var momentDate = _moment.default.utc(timestamp);
    // Convert to local time
    var localDate = momentDate.local();
    // Format the date as desired, e.g., 'YYYY-MM-DD HH:mm:ss'
    return localDate.format('h:mm A').toString();
  }
  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  handleClick(tab, event) {
    console.log('tab', tab);
    this.activeTab = tab.name;
    // let year = date.getFullYear();
    // let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    // let day = String(date.getDate()).padStart(2, '0');
    // var formattedDate = `${day}-${month}-${year}`;
    this.todayDate = this.formatDate(new Date());
    switch (tab.name) {
      case tab.name = 'today':
        {
          this.listQuery.filter.date = '$eq';
          this.listQuery.from = this.todayDate;
          this.getList(tab.name);
          break;
        }
      case tab.index = 'upcoming':
        {
          this.listQuery.status = 0;
          this.getList(tab.index);
          break;
        }
      case tab.index = 'completed':
        {
          this.listQuery.status = 1;
          this.getList(tab.index);
          break;
        }
      case tab.index = 'cancelled':
        {
          this.listQuery.status = 2;
          this.getList(tab.index);
          break;
        }
      case tab.index = 'cancelled-admin':
        {
          this.listQuery.status = 3;
          this.getList(tab.index);
          break;
        }
    }
  }
  created() {
    var _this$$route$query;
    if ((_this$$route$query = this.$route.query) !== null && _this$$route$query !== void 0 && _this$$route$query.page) {
      var _this$$route$query2;
      this.listQuery.page = Number((_this$$route$query2 = this.$route.query) === null || _this$$route$query2 === void 0 ? void 0 : _this$$route$query2.page);
    }
    this.getList(this.activeTab);
  }
  async getList(tabName) {
    try {
      this.listLoading = true;
      this.$router.push({
        path: this.$route.fullPath,
        query: {
          page: String(this.listQuery.page)
        }
      });
      if (tabName == 'today') {
        this.listQuery.date = this.todayDate;
        this.listQuery.status = 0;
        this.listQuery.filter.date = '$eq';
      } else if (tabName === 'upcoming') {
        delete this.listQuery.date;
        delete this.listQuery.filter.date;
        this.listQuery.status = 0;
      } else if (tabName === 'completed') {
        delete this.listQuery.date;
        delete this.listQuery.filter.date;
        this.listQuery.status = 1;
      } else if (tabName === 'cancelled') {
        delete this.listQuery.date;
        delete this.listQuery.filter.date;
        this.listQuery.status = 2;
      } else if (tabName === 'cancelled-admin') {
        delete this.listQuery.date;
        delete this.listQuery.filter.date;
        this.listQuery.status = 3;
      } else {
        delete this.listQuery.status;
        delete this.listQuery.filter.status;
        this.listQuery.date = this.todayDate;
        this.listQuery.filter.date = '$eq';
      }
      if (this.$route.params.id) {
        this.listQuery.userSessionMasterId = Number(this.$route.params.id);
      } else {
        delete this.listQuery.userSessionMasterId;
      }
      const data = await (0, _session.getsessions)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  OpenStatus(row) {
    this.userData = row;
    this.statusDialogVisible = true;
  }
  // private handleClose(done: any) {
  //   this.$confirm("Are you sure to close this dialog?")
  //     .then((_) => {
  //       done();
  //     })
  //     .catch((_) => {});
  // }
  async getAllList() {
    try {
      this.listLoading = true;
      const data = await (0, _session.getsessions)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getSingleUsers(id) {
    const data = await (0, _session.getsessionsById)(id);
    this.userDetail = data;
    this.drawer = true;
    this.languageQuery.userId = id;
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc('table.deleteConfirmation', undefined, {
        name: data.name
      }), this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => {
        (0, _session.deletesessions)(data.id).then(deleted => {
          this.getList(this.activeTab);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      });
    }
  }
  routeToReschedule(userTransactionId, sessionId) {
    this.$router.push(`/session/reschedule/${userTransactionId}/${sessionId}`);
  }
  async handleClose() {
    this.drawer = false;
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList(this.activeTab);
  }
  FeedData(Id) {
    // this.listQuery.Id = this.list.feedId;
    //  this.listQuery.Id = this.list.feedId;
    console.log('user id is:', Id);
  }
  // private changeStatus(row: any) {
  //   this.trainerData = row;
  //   this.updateDialogVisible = true;
  // }
  async cancelSession(id, cancelledBy) {
    this.$confirm('This will cancel the session. Continue?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(async () => {
      debugger;
      console.log('session id: ', id);
      const payload = {
        cancelledBy: cancelledBy,
        sessionId: id
      };
      console.log('payload: ', payload);
      try {
        this.fullscreenLoading = true;
        const res = await (0, _session.cancelSession)(payload);
        this.$message({
          type: 'info',
          message: res.message
        });
        this.getList(this.activeTab);
        this.fullscreenLoading = false;
        this.updateDialogVisible = false;
      } catch (error) {
        this.fullscreenLoading = false;
        this.updateDialogVisible = false;
        throw error;
      }
    }).catch(() => {
      this.$message({
        type: 'error',
        message: 'Could not cancel the session'
      });
    });
    // const data = {
    //   status: 3
    // }
    // this.fullscreenLoading = true
    // await updatesessions(id, data).then((res: any) => {
    //   this.$message({
    //     message: 'Status Updated.',
    //     type: 'success'
    //   })
    //   this.getList(this.activeTab)
    //   this.fullscreenLoading = false
    //   this.updateDialogVisible = false
    // })
  }
  async updateStatus(id) {
    this.$confirm('This will Cancel the sessiokn. Continue?', 'Warning', {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.$message({
        type: 'success',
        message: 'Delete completed'
      });
    }).catch(() => {
      this.$message({
        type: 'info',
        message: 'Delete canceled'
      });
    });
    // const data = {
    //   status: 3
    // }
    // this.fullscreenLoading = true
    // await updatesessions(id, data).then((res: any) => {
    //   this.$message({
    //     message: 'Status Updated.',
    //     type: 'success'
    //   })
    //   this.getList(this.activeTab)
    //   this.fullscreenLoading = false
    //   this.updateDialogVisible = false
    // })
  }
  handleDownload() {
    this.downloadLoading = true;
    this.list.forEach(order => {
      order.type = order.type == 0 ? 'Student' : 'Off-Campus';
    });
    const tHeader = [' ID', 'First Name', 'Last Name', 'Contact Number',
    // 'Email',
    'CollegeName', 'Gender', 'User Types'];
    const filterVal = ['id', 'firstName', 'lastName', 'contactNumber',
    // 'email',
    'college.collegeName', 'gender', 'type'];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, 'session Details');
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'sessionList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;