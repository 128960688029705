import { render, staticRenderFns } from "./BarCharts.vue?vue&type=template&id=4ea89ba4"
import script from "./BarCharts.vue?vue&type=script&lang=ts"
export * from "./BarCharts.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\algoocean\\askknatural-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ea89ba4')) {
      api.createRecord('4ea89ba4', component.options)
    } else {
      api.reload('4ea89ba4', component.options)
    }
    module.hot.accept("./BarCharts.vue?vue&type=template&id=4ea89ba4", function () {
      api.rerender('4ea89ba4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/dashboard/admin/components/BarCharts.vue"
export default component.exports