"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    class: _vm.className,
    style: {
      height: _vm.height,
      width: _vm.width
    }
  });
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;