"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: "10"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Total Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.totalTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Booked Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.bookedTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Completed Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.completedTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Upcoming Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.upcomingTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel By Admin Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelByAdminTrainingSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Pending Training Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.pendingTrainingSessionCount) + " ")])], 1), _c("hr")], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Total Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.totalNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Booked Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.bookedNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Completed Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.completedNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Upcoming Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.upcomingNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Cancel By Admin Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.cancelByAdminNutritionistSessionCount) + " ")])], 1), _c("hr"), _c("el-row", [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(" Pending Nutritionist Session Count: ")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.sessionList.pendingNutritionistSessionCount) + " ")])], 1), _c("hr")], 1)], 1)], 1), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.slotBookingLoading,
      expression: "slotBookingLoading"
    }],
    staticClass: "source",
    attrs: {
      "element-loading-text": "Booking in Progress...Please Wait"
    }
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Date",
      prop: "startDate"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "date",
      placeholder: "Date"
    },
    model: {
      value: _vm.postForm.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "startDate", $$v);
      },
      expression: "postForm.startDate"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Trainer or Nutrionist",
      prop: "type"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      clearable: "",
      placeholder: "Trainer or Nutrionist",
      disabled: ""
    },
    model: {
      value: _vm.postForm.type,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "type", $$v);
      },
      expression: "postForm.type"
    }
  }, _vm._l(_vm.typeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Rescheduled by",
      prop: "type"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      clearable: "",
      placeholder: "Rescheduled by"
    },
    model: {
      value: _vm.postForm.rescheduledBy,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "rescheduledBy", $$v);
      },
      expression: "postForm.rescheduledBy"
    }
  }, _vm._l(["Admin", "User"], function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.slotsSearchLoading,
      expression: "slotsSearchLoading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.getSlots
    }
  }, [_vm._v(" Search Available Slots ")])], 1)], 1), this.slotList.length != 0 ? _c("section", [_c("h3", [_vm._v("Slots Lists")]), this.slotList.length > 0 ? _c("section", {
    staticStyle: {
      padding: "30px",
      "border-radius": "20px",
      "background-color": "white"
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.slotList,
      border: false,
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      fixed: "",
      prop: "fullName",
      label: "Trainer Full Name",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-popover", {
          attrs: {
            placement: "top-start",
            title: "Trainer Details",
            width: "300",
            trigger: "hover"
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center",
            padding: "10px"
          }
        }, [_c("img", {
          staticStyle: {
            width: "100px",
            height: "100px",
            "border-radius": "50%"
          },
          attrs: {
            src: scope.row.profile,
            alt: "Trainer Profile"
          }
        }), _c("div", {
          staticStyle: {
            "margin-top": "10px"
          }
        }, [_c("strong", [_vm._v(_vm._s(scope.row.fullName))]), _c("br"), _vm._v(" Expertise: " + _vm._s(scope.row.expertise)), _c("br"), _vm._v(" Role: " + _vm._s(scope.row.role)), _c("br")])]), _c("div", {
          staticStyle: {
            cursor: "pointer"
          },
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [_c("el-row", {
          attrs: {
            type: "flex",
            align: "middle"
          }
        }, [_c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("img", {
          staticStyle: {
            width: "40px",
            height: "40px",
            "border-radius": "50%"
          },
          attrs: {
            src: scope.row.profile,
            alt: "Trainer Profile"
          }
        })]), _c("el-col", {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            span: 20
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.fullName))])])], 1)], 1)])];
      }
    }], null, false, 805395718)
  }), _c("el-table-column", {
    attrs: {
      prop: "slots",
      label: "Slots",
      width: "800"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "170px 170px 170px 1fr",
            "grid-gap": "30px",
            padding: "10px"
          }
        }, _vm._l(scope.row.slots, function (slot) {
          return _c("div", {
            key: slot.id,
            staticStyle: {
              cursor: "pointer"
            }
          }, [slot.sessions.length > 0 ? _c("el-popover", {
            attrs: {
              placement: "top-start",
              title: "Slots Conflicts",
              width: "600",
              trigger: "hover"
            }
          }, [_c("el-table", {
            attrs: {
              data: slot.sessions,
              "max-height": "400"
            }
          }, [_c("el-table-column", {
            attrs: {
              width: "50",
              property: "id",
              label: "id"
            }
          }), _c("el-table-column", {
            attrs: {
              width: "100",
              property: "slotId",
              label: "slot Id"
            }
          }), _c("el-table-column", {
            attrs: {
              width: "150",
              property: "date",
              label: "Date"
            }
          }), _c("el-table-column", {
            attrs: {
              width: "300",
              align: "left",
              label: "User Details",
              prop: ""
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (scope) {
                var _scope$row$user, _scope$row$user2, _scope$row$user3;
                return [_c("el-row", [_c("el-col", {
                  attrs: {
                    span: 8
                  }
                }, [_vm._v(" Name: ")]), _c("el-col", {
                  attrs: {
                    span: 16
                  }
                }, [_vm._v(" " + _vm._s((_scope$row$user = scope.row.user) === null || _scope$row$user === void 0 ? void 0 : _scope$row$user.fullName) + " ")])], 1), _c("el-row", [_c("el-col", {
                  attrs: {
                    span: 8
                  }
                }, [_vm._v(" Email: ")]), _c("el-col", {
                  attrs: {
                    span: 16
                  }
                }, [_vm._v(" " + _vm._s((_scope$row$user2 = scope.row.user) === null || _scope$row$user2 === void 0 ? void 0 : _scope$row$user2.email) + " ")])], 1), _c("el-row", [_c("el-col", {
                  attrs: {
                    span: 8
                  }
                }, [_vm._v(" Contact: ")]), _c("el-col", {
                  attrs: {
                    span: 16
                  }
                }, [_vm._v(" " + _vm._s((_scope$row$user3 = scope.row.user) === null || _scope$row$user3 === void 0 ? void 0 : _scope$row$user3.contactNumber) + " ")])], 1)];
              }
            }], null, true)
          }), _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("table.actions"),
              width: "140"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function (scope) {
                return [_c("el-button", {
                  attrs: {
                    size: "small",
                    type: "danger",
                    icon: "el-icon-delete"
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(scope.row);
                    }
                  }
                })];
              }
            }], null, true)
          })], 1), _c("el-tag", {
            staticStyle: {
              color: "black",
              margin: "0px 10px",
              "font-size": "15px"
            },
            attrs: {
              slot: "reference",
              type: slot.sessions.length > 0 ? "warning" : "success"
            },
            slot: "reference"
          }, [_vm._v(" " + _vm._s(slot.start_time + " " + "-" + " " + slot.end_time) + " ")])], 1) : _c("el-tag", {
            staticStyle: {
              color: "black",
              margin: "0px 10px",
              "font-size": "15px"
            },
            attrs: {
              type: slot.sessions.length > 0 ? "warning" : "success"
            },
            on: {
              click: function ($event) {
                return _vm.handleBookSlot({
                  slotData: slot,
                  trainerData: {
                    trainerId: scope.row.id,
                    name: scope.row.fullName
                  }
                });
              }
            }
          }, [_vm._v(" " + _vm._s(slot.start_time + " " + "-" + " " + slot.end_time) + " ")])], 1);
        }), 0)];
      }
    }], null, false, 3798122149)
  })], 1)], 1) : _c("div", [_vm._v(" No Available Slots ")])]) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;