"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatesaveRecipiesstatus = exports.updatesaveRecipiesAddress = exports.updatesaveRecipies = exports.getsaveRecipiescount = exports.getsaveRecipiesDetailById = exports.getsaveRecipiesById = exports.getsaveRecipies = exports.deletesaveRecipies = exports.defaultSaveRecipeData = exports.addsaveRecipies = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSaveRecipeData = exports.defaultSaveRecipeData = {
  id: 0,
  enabled: true,
  priority: 0,
  recipieId: null,
  userId: null
};
const getsaveRecipies = params => (0, _request.default)({
  url: 'save-recipie',
  method: 'get',
  params
});
exports.getsaveRecipies = getsaveRecipies;
const addsaveRecipies = data => (0, _request.default)({
  url: 'save-recipie',
  method: 'post',
  data
});
exports.addsaveRecipies = addsaveRecipies;
const getsaveRecipiescount = () => (0, _request.default)({
  url: 'save-recipie/count-user',
  method: 'get'
});
exports.getsaveRecipiescount = getsaveRecipiescount;
const getsaveRecipiesById = id => (0, _request.default)({
  url: `save-recipie/detail/${id}`,
  method: 'get'
});
exports.getsaveRecipiesById = getsaveRecipiesById;
const getsaveRecipiesDetailById = id => (0, _request.default)({
  url: `save-recipie/${id}`,
  method: 'get'
});
exports.getsaveRecipiesDetailById = getsaveRecipiesDetailById;
const updatesaveRecipies = (id, data) => (0, _request.default)({
  url: `save-recipie/${id}`,
  method: 'patch',
  data
});
exports.updatesaveRecipies = updatesaveRecipies;
const updatesaveRecipiesAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updatesaveRecipiesAddress = updatesaveRecipiesAddress;
const updatesaveRecipiesstatus = data => (0, _request.default)({
  url: `save-recipie/update-status`,
  method: 'post',
  data
});
exports.updatesaveRecipiesstatus = updatesaveRecipiesstatus;
const deletesaveRecipies = adminname => (0, _request.default)({
  url: `save-recipie/${adminname}`,
  method: 'delete'
});
exports.deletesaveRecipies = deletesaveRecipies;