"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateNotifications = exports.sendChatNotifications = exports.getNotificationsById = exports.getNotifications = exports.getFilters = exports.deleteNotifications = exports.defaultNotificationData = exports.createNotifications = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultNotificationData = exports.defaultNotificationData = {
  id: 0,
  enabled: true,
  title: '',
  message: '',
  image: '',
  type: 0,
  userId: null,
  topic: '',
  sendToAll: true,
  priority: 0
};
const getNotifications = params => (0, _request.default)({
  url: '/notifications',
  method: 'get',
  params
});
exports.getNotifications = getNotifications;
const getNotificationsById = id => (0, _request.default)({
  url: `/notifications/${id}`,
  method: 'get'
});
exports.getNotificationsById = getNotificationsById;
const updateNotifications = (id, data) => (0, _request.default)({
  url: `/notifications/${id}`,
  method: 'patch',
  data
});
exports.updateNotifications = updateNotifications;
const deleteNotifications = id => (0, _request.default)({
  url: `/notifications/${id}`,
  method: 'delete'
});
exports.deleteNotifications = deleteNotifications;
const createNotifications = data => (0, _request.default)({
  url: '/notifications/',
  method: 'post',
  data
});
exports.createNotifications = createNotifications;
const getFilters = data => (0, _request.default)({
  url: '/notifications/fetch',
  method: 'post',
  data
});
//push notifications for chats
exports.getFilters = getFilters;
const sendChatNotifications = data => (0, _request.default)({
  url: '/Notifications/send-message-notification',
  method: 'post',
  data
});
exports.sendChatNotifications = sendChatNotifications;