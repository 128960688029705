"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatestorystatus = exports.updatestoryAddress = exports.updatestory = exports.register = exports.logstory = exports.logout = exports.login = exports.getstorycount = exports.getstoryReportbyGender = exports.getstoryDetailById = exports.getstoryByIdAddress = exports.getstoryById = exports.getstoryByEmail = exports.getstory = exports.getLogs = exports.getCompany = exports.deletestory = exports.defaultStoryData = exports.checkstoryExist = exports.checkEmailExist = exports.addstory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultStoryData = exports.defaultStoryData = {
  id: 0,
  enabled: true,
  isDeleted: false,
  name: '',
  createdAt: '',
  adminId: null,
  media: '',
  viewCount: null,
  likeCount: null,
  viewedUserIds: [],
  viewedAdminIds: [],
  likeByUserIds: [],
  priority: 0
};
const getstory = params => (0, _request.default)({
  url: 'story',
  method: 'get',
  params
});
exports.getstory = getstory;
const addstory = data => (0, _request.default)({
  url: 'story',
  method: 'post',
  data
});
exports.addstory = addstory;
const getstorycount = () => (0, _request.default)({
  url: 'story/count-user',
  method: 'get'
});
exports.getstorycount = getstorycount;
const checkstoryExist = data => (0, _request.default)({
  url: 'story/username-check',
  method: 'post',
  data
});
exports.checkstoryExist = checkstoryExist;
const checkEmailExist = data => (0, _request.default)({
  url: 'story/email-check',
  method: 'post',
  data
});
exports.checkEmailExist = checkEmailExist;
const getstoryById = id => (0, _request.default)({
  url: `story/detail/${id}`,
  method: 'get'
});
exports.getstoryById = getstoryById;
const getstoryDetailById = id => (0, _request.default)({
  url: `story/${id}`,
  method: 'get'
});
exports.getstoryDetailById = getstoryDetailById;
const getstoryByIdAddress = id => (0, _request.default)({
  url: `story/detail/${id}`,
  method: 'get'
});
exports.getstoryByIdAddress = getstoryByIdAddress;
const getstoryByEmail = adminemail => (0, _request.default)({
  url: `story/${adminemail}`,
  method: 'get'
});
exports.getstoryByEmail = getstoryByEmail;
const updatestory = (id, data) => (0, _request.default)({
  url: `story/${id}`,
  method: 'patch',
  data
});
exports.updatestory = updatestory;
const updatestoryAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updatestoryAddress = updatestoryAddress;
const updatestorystatus = data => (0, _request.default)({
  url: `story/update-status`,
  method: 'post',
  data
});
exports.updatestorystatus = updatestorystatus;
const deletestory = adminname => (0, _request.default)({
  url: `story/${adminname}`,
  method: 'delete'
});
exports.deletestory = deletestory;
const login = data => (0, _request.default)({
  url: 'story/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: 'story/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: 'story/add',
  method: 'post',
  data
});
exports.register = register;
const getCompany = params => (0, _request.default)({
  url: 'story-roles',
  method: 'get',
  params
});
exports.getCompany = getCompany;
const logstory = data => (0, _request.default)({
  url: '/user-logs',
  method: 'post',
  data
});
exports.logstory = logstory;
const getLogs = params => (0, _request.default)({
  url: '/user-logs',
  method: 'get',
  params
});
exports.getLogs = getLogs;
const getstoryReportbyGender = params => (0, _request.default)({
  url: 'story/gender-count',
  method: 'get',
  params
});
exports.getstoryReportbyGender = getstoryReportbyGender;