"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _vuePropertyDecorator = require("vue-property-decorator");
var _language = require("@/api/language");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = Object.assign({}, _language.defaultLanguageData);
    this.loading = false;
    this.additionalLoading = false;
    this.uploadingstatus = false;
    this.percentage = 0;
    this.tags = [];
    this.customColor = '#409eff';
    this.customColors = [{
      color: '#f56c6c',
      percentage: 20
    }, {
      color: '#e6a23c',
      percentage: 40
    }, {
      color: '#5cb87a',
      percentage: 60
    }, {
      color: '#1989fa',
      percentage: 80
    }, {
      color: '#6f7ad3',
      percentage: 100
    }];
    this.serviceList = [];
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter language',
        trigger: 'blur'
      }],
      shortCode: [{
        required: true,
        message: 'Please enter short code',
        trigger: 'blur'
      }, {
        pattern: '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
        message: 'Short code should contain characters only',
        trigger: ['blur', 'change']
      }, {
        pattern: '^[a-zA-Z0-9_]',
        message: "Space not allowed at start of short code",
        trigger: ["blur", "change"]
      }],
      url: [{
        required: true,
        message: 'Please upload JSON file',
        trigger: 'blur'
      }, {
        pattern: '(.*?)\.(json)$',
        message: "Only json file upload",
        trigger: ["blur", "change"]
      }]
    };
  }
  aa(abc) {
    this.percentage = abc;
  }
  pdfUrlClose(tags) {
    // this.postForm.url=''
    // this.postForm.tags=[]
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    // this.fetchServices()
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _language.defaultLanguageData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _language.getLanguageById)(id);
      this.postForm = data;
      // Just for test
      // const title = this.postForm.name
      // Set tagsview title
      // this.setTagsViewTitle(title)
      // Set page title
      // this.setPageTitle(title)
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  beforeUpload(file) {
    const doc = file.type == 'application/json' ? true : false;
    if (!doc) {
      this.$message.error('Only json file upload');
    }
    return doc;
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
  }
  async uploadAdditionalFile(param) {
    var fileObj = param.file;
    var form = new FormData();
    form.append('file', fileObj);
    var config = {
      onUploadProgress: progressEvent => {
        this.percentage = Number(Math.round(progressEvent.loaded * 100 / progressEvent.total));
        this.uploadingstatus = true;
        // console.log(percentage1)
        // this.aa(percentage1);
        // this.percentage
      }
    };
    await (0, _common.uploadFile)(form, event => {
      this.additionalLoading = true;
      this.uploadingstatus = true;
      this.percentage = Number(Math.round(event.loaded * 100 / event.total));
      console.log(event);
    }).then(res => {
      // this.postForm.url = res.toString()
      this.additionalLoading = false;
      this.uploadingstatus = false;
    });
    this.additionalLoading = false;
  }
  // private async fetchServices() {
  //   const data: any = await getService(getQuery({
  //     enabled: true,
  //     filter: {
  //       enabled: 'eq'
  //     }
  //   }))
  //   this.serviceList = data
  // }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _language.updateLanguage)(this.postForm.id, this.postForm);
      } else {
        await (0, _language.addLanguage)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'language added successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/language/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'languageDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;