"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vue2Dropzone = _interopRequireDefault(require("vue2-dropzone"));
require("vue2-dropzone/dist/vue2Dropzone.min.css");
var _vuePropertyDecorator = require("vue-property-decorator");
var _settings = require("@/store/modules/settings");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  get dropzoneOptions() {
    return {
      url: this.url,
      thumbnailWidth: this.thumbnailWidth,
      thumbnailHeight: this.thumbnailHeight,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFilesize,
      autoProcessQueue: this.autoProcessQueue,
      addRemoveLinks: this.addRemoveLinks,
      dictDefaultMessage: this.dictDefaultMessage,
      dictFallbackMessage: this.dictFallbackMessage,
      dictRemoveFile: this.dictRemoveFile,
      dictMaxFilesExceeded: this.dictMaxFilesExceeded
    };
  }
  get themeColor() {
    return _settings.SettingsModule.theme;
  }
  // You can add more Event handler, see: https://rowanwins.github.io/vue-dropzone/docs/dist/#/events
  dropzoneSuccess(file, response) {
    this.$emit('dropzone-success', file, response);
  }
  dropzoneRemovedFile(file, error, xhr) {
    this.$emit('dropzone-removed-file', file, error, xhr);
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "id", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "url", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 200
})], default_1.prototype, "thumbnailHeight", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 200
})], default_1.prototype, "thumbnailWidth", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 4
})], default_1.prototype, "maxFiles", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 5
})], default_1.prototype, "maxFilesize", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: true
})], default_1.prototype, "autoProcessQueue", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: true
})], default_1.prototype, "addRemoveLinks", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'Drop files here to upload'
})], default_1.prototype, "dictDefaultMessage", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'Your broswer does not support dropzone.js'
})], default_1.prototype, "dictFallbackMessage", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'Remove'
})], default_1.prototype, "dictRemoveFile", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'Max Files Exceeded'
})], default_1.prototype, "dictMaxFilesExceeded", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Dropzone',
  components: {
    VueDropzone: _vue2Dropzone.default
  }
})], default_1);
var _default = exports.default = default_1;