"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-upload", {
    staticClass: "upload-demo",
    attrs: {
      drag: "",
      action: "https://jsonplaceholder.typicode.com/posts/",
      "on-preview": _vm.handlePreview,
      "on-remove": _vm.handleRemove,
      "http-request": _vm.uploadImage,
      "file-list": _vm.fileList,
      "before-upload": _vm.onBeforeUpload,
      limit: 1,
      "on-exceed": _vm.onLimitExceed,
      accept: "video/mp4,video/mp4"
    },
    on: {
      mouseover: function ($event) {
        _vm.showVideoPreview = true;
      },
      mouseleave: function ($event) {
        _vm.showVideoPreview = false;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v("Drop file here or "), _c("em", [_vm._v("click to upload")])])]), _vm.showVideoPreview && _vm.dialogImageUrl ? _c("div", {
    staticClass: "hover-video-preview"
  }, [_c("video", {
    staticStyle: {
      "max-width": "100%",
      "max-height": "200px"
    },
    attrs: {
      controls: "",
      src: _vm.dialogImageUrl
    }
  })]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;