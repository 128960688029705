"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.dialogImageUrl = "";
    this.dialogVisible = false;
    this.fileList = [];
  }
  created() {
    if (this.formField != null && this.formField != "") {
      this.fileList.push({
        name: '',
        url: this.formField
      });
    }
  }
  async uploadImage(files) {
    const data = new FormData();
    data.append('file', files.file);
    await (0, _common.uploadFile)(data, event => {
      console.log(event);
    }).then(res => {
      this.formField = res;
      this.$emit('handle-upload', res);
    });
  }
  onBeforeUpload(file) {
    const isIMAGE = file.type == 'image/jpeg' || file.type == 'image/gif' || file.type == 'image/png' ? true : false;
    if (!isIMAGE) {
      this.$message.error('Upload file can only be in picture format!');
    }
    return isIMAGE;
  }
  onLimitExceed() {
    this.$message.error('Limit excedeed');
  }
  handleRemove(file, fileList) {
    this.fileList = [];
    this.$emit('handle-remove');
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ""
})], default_1.prototype, "value", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "formField", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)()], default_1.prototype, "height", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)()], default_1.prototype, "width", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "UploadImage"
})], default_1);
var _default = exports.default = default_1;