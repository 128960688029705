"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatecountry = exports.updateOrder = exports.getcountryInfo = exports.getcountryCount = exports.getcountryById = exports.getcountryByEmail = exports.getcountry = exports.deletecountry = exports.defaultCountryData = exports.addcountry = exports.StoreStorecountry = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCountryData = exports.defaultCountryData = {
  id: 0,
  enabled: true,
  name: ''
};
const getcountry = params => (0, _request.default)({
  url: '/countries',
  method: 'get',
  params
});
exports.getcountry = getcountry;
const getcountryById = id => (0, _request.default)({
  url: `/countries/${id}`,
  method: 'get'
});
exports.getcountryById = getcountryById;
const getcountryInfo = data => (0, _request.default)({
  url: '/countries/me',
  method: 'post',
  data
});
exports.getcountryInfo = getcountryInfo;
const getcountryByEmail = Storecountryemail => (0, _request.default)({
  url: `/countries/${Storecountryemail}`,
  method: 'get'
});
exports.getcountryByEmail = getcountryByEmail;
const updatecountry = (id, data) => (0, _request.default)({
  url: `/countries/${id}`,
  method: 'patch',
  data
});
exports.updatecountry = updatecountry;
const StoreStorecountry = (id, data) => (0, _request.default)({
  url: `/countries/${id}`,
  method: 'patch',
  data
});
exports.StoreStorecountry = StoreStorecountry;
const deletecountry = Storecountryname => (0, _request.default)({
  url: `/countries/${Storecountryname}`,
  method: 'delete'
});
exports.deletecountry = deletecountry;
const addcountry = data => (0, _request.default)({
  url: '/countries/',
  method: 'post',
  data
});
exports.addcountry = addcountry;
const getcountryCount = () => (0, _request.default)({
  url: '/countries/count',
  method: 'get'
});
exports.getcountryCount = getcountryCount;
const updateOrder = data => (0, _request.default)({
  url: '/countries/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;