"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("vue-dropzone", {
    attrs: {
      id: _vm.id,
      options: _vm.dropzoneOptions,
      "use-custom-slot": true
    },
    on: {
      "vdropzone-removed-file": _vm.dropzoneRemovedFile,
      "vdropzone-success": _vm.dropzoneSuccess
    }
  }, [_c("div", {
    staticClass: "dropzone-custom-content"
  }, [_c("h3", {
    staticClass: "dropzone-custom-title",
    style: {
      color: _vm.themeColor
    }
  }, [_vm._v(" Drag and drop to upload content! ")]), _c("div", {
    staticClass: "subtitle"
  }, [_vm._v(" ...or click to select a file from your computer ")])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;