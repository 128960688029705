"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const GoalRouter = {
  path: '/goal',
  component: _index.default,
  redirect: 'noredirect',
  name: 'goalManagement.title',
  meta: {
    roles: ['superadmin'],
    title: 'goalManagement.title',
    icon: 'create-dashboard'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/goal/list.vue'))),
    name: 'goalManagement.goal.manage',
    meta: {
      title: 'goalManagement.title',
      noCache: true,
      icon: 'create-dashboard',
      header: 'List of all categories',
      info: 'All goal details are available here. You can search categories by applying filters on fields like goal name. After clicking on goal name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/goal/manage.vue'))),
    name: 'goalManagement.goal.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'goalManagement.goal.manage',
      noCache: true,
      activeMenu: '/goal/list',
      hidden: true,
      header: 'Edit goal',
      info: 'You can edit goal here'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/goal/manage.vue'))),
    name: 'goalManagement.goal.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'goalManagement.goal.manage',
      noCache: true,
      activeMenu: '/goal/list',
      hidden: true,
      header: 'Add goal',
      info: 'You can create goal here'
    }
  }]
};
var _default = exports.default = GoalRouter;