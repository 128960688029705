"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _echarts = _interopRequireDefault(require("echarts"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueClassComponent = require("vue-class-component");
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
var _admins = require("@/api/admins");
const animationDuration = 6000;
let default_1 = class default_1 extends (0, _vueClassComponent.mixins)(_resize.default) {
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  async initChart() {
    const data = await (0, _admins.getAdminStats)();
    // const barChartData = data.leads
    const yData = [0, 0, 0, 0, 20, 10, 0, 0, 0, 0];
    const xData = ['may', 'june'];
    this.chart = _echarts.default.init(this.$el, 'macarons');
    this.chart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: 10,
        left: '2%',
        right: '2%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        axisTick: {
          alignWithLabel: true
        }
      }],
      yAxis: [{
        type: 'value',
        axisTick: {
          show: false
        }
      }],
      series: [{
        name: 'Influencer Leads',
        type: 'bar',
        stack: 'vistors',
        // barWidth: '60%',
        data: yData,
        animationDuration
      }
      // {
      //   name: 'pageB',
      //   type: 'bar',
      //   stack: 'vistors',
      //   // barWidth: '60%',
      //   data: [80, 52, 200, 334, 390, 330, 220],
      //   animationDuration
      // },
      // {
      //   name: 'pageC',
      //   type: 'bar',
      //   stack: 'vistors',
      //   // barWidth: '60%',
      //   data: [30, 52, 200, 334, 390, 330, 220],
      //   animationDuration
      // }
      ]
    });
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'chart'
})], default_1.prototype, "className", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '100%'
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '300px'
})], default_1.prototype, "height", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: []
})], default_1.prototype, "months", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: []
})], default_1.prototype, "barChartData", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'BarCharts'
})], default_1);
var _default = exports.default = default_1;