"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatecity = exports.updateOrder = exports.getcityInfo = exports.getcityCount = exports.getcityById = exports.getcityByEmail = exports.getcity = exports.deletecity = exports.defaultCitiesData = exports.addcity = exports.StoreStorecity = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCitiesData = exports.defaultCitiesData = {
  id: 0,
  enabled: true,
  name: '',
  countryId: 1,
  stateId: 0,
  tags: '',
  city_code: '',
  coordinates: ''
};
const getcity = params => (0, _request.default)({
  url: '/cities',
  method: 'get',
  params
});
exports.getcity = getcity;
const getcityById = id => (0, _request.default)({
  url: `/cities/${id}`,
  method: 'get'
});
exports.getcityById = getcityById;
const getcityInfo = data => (0, _request.default)({
  url: '/cities/me',
  method: 'post',
  data
});
exports.getcityInfo = getcityInfo;
const getcityByEmail = Storecityemail => (0, _request.default)({
  url: `/cities/${Storecityemail}`,
  method: 'get'
});
exports.getcityByEmail = getcityByEmail;
const updatecity = (id, data) => (0, _request.default)({
  url: `/cities/${id}`,
  method: 'patch',
  data
});
exports.updatecity = updatecity;
const StoreStorecity = (id, data) => (0, _request.default)({
  url: `/cities/${id}`,
  method: 'patch',
  data
});
exports.StoreStorecity = StoreStorecity;
const deletecity = Storecityname => (0, _request.default)({
  url: `/cities/${Storecityname}`,
  method: 'delete'
});
exports.deletecity = deletecity;
const addcity = data => (0, _request.default)({
  url: '/cities/',
  method: 'post',
  data
});
exports.addcity = addcity;
const getcityCount = () => (0, _request.default)({
  url: '/cities/count',
  method: 'get'
});
exports.getcityCount = getcityCount;
const updateOrder = data => (0, _request.default)({
  url: '/cities/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;