"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _notifications = require("@/api/notifications/notifications");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("@/utils");
var _countries = require("@/api/locations/countries");
var _states = require("@/api/locations/states");
var _cities = require("@/api/locations/cities");
var _index = _interopRequireDefault(require("@/components/FileUpload/index.vue"));
var _common = require("@/api/common");
var _notifications2 = require("@/api/notifications");
var _index2 = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.companyList = [];
    this.countryList = [];
    this.stateList = [];
    this.renderKey = 0;
    this.cityList = [];
    this.pincodeList = [];
    this.platformList = ['android', 'ios'];
    this.pageList = ['Home'];
    this.appVersionList = [];
    this.formData = Object.assign({}, _notifications2.defaultNotificationData);
    this.loading = false;
    this.rules = {
      title: [{
        required: true,
        trigger: 'blur'
      }, {
        pattern: '^[a-zA-Z0-9_]',
        message: "Space not allow at start of title",
        trigger: ["blur", "change"]
      }],
      message: [{
        required: true,
        trigger: 'blur'
      }, {
        pattern: '^[a-zA-Z0-9_]',
        message: "Space not allow at start of message",
        trigger: ["blur", "change"]
      }],
      page: [{
        required: true,
        trigger: 'blur'
      }],
      link: [{
        trigger: 'blur'
      }, {
        pattern: /[-a-zA-Z0-9@%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        message: 'Invalid URL format',
        trigger: ['blur', 'change']
      }]
    };
    // private sendToAllToggled() {
    //   if (this.formData.sendToAll) {
    //     this.$confirm(
    //       'Are you sure want to send notification to All users?',
    //       'Attention!',
    //       {
    //         confirmButtonText: "Yes, I know what I'm doing",
    //         confirmButtonClass: 'el-button--success',
    //         cancelButtonText: this.$tc('table.cancel'),
    //         cancelButtonClass: 'el-button--danger',
    //         type: 'error'
    //       }
    //     )
    //       .then((confirm) => {
    //         // let it confirm
    //         // clear other dropdowns
    //         this.formData.countryId = null
    //         this.formData.stateId = null
    //         this.formData.cityId = null
    //         // delete this.formData.country
    //         // delete this.formData.state
    //         // delete this.formData.city
    //       })
    //       .catch((_) => {
    //         this.formData.sendToAll = false
    //       })
    //   }
    // }
  }
  updateImage(res) {
    this.formData.image = res;
  }
  removeImage() {
    this.formData.image = '';
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.getCountryList();
    this.getAppVersionList();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.formData = Object.assign({}, _notifications2.defaultNotificationData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _notifications.getNotificationsById)(id);
      this.formData = data;
      // this.image.push({
      //   url: data.image
      // })
      // if (data.stateId) {
      //   this.getCityList(data.stateId)
      // }
      // if (data.cityId) {
      //   this.getPincodeList(data.cityId)
      // }
      // Just for test
      const title = this.formData.title ? this.formData.title : '';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        // this.formData.country = { id: this.formData.countryId }
        // this.formData.state = { id: this.formData.stateId }
        // this.formData.city = { id: this.formData.cityId }
        await (0, _notifications.updateNotifications)(this.formData.id, this.formData);
      } else {
        // delete this.formData.country
        // delete this.formData.state
        // delete this.formData.city
        await (0, _notifications.createNotifications)(this.formData);
      }
      this.$notify({
        title: 'Success',
        message: 'Notification saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/notifications/list');
    } catch (err) {
      this.loading = false;
    }
  }
  async getAppVersionList() {
    try {
      this.appVersionList = await (0, _common.getAppVersions)();
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getCountryList() {
    try {
      this.countryList = await (0, _countries.getCountries)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getStateList(countryId) {
    try {
      this.stateList = await (0, _states.getStates)((0, _utils.getQuery)({
        enabled: true,
        select: 'id,name',
        sort: 'name,ASC',
        countryId,
        filter: {
          enabled: 'eq',
          countryId: 'eq'
        }
      }));
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getCityList(stateId) {
    try {
      this.cityList = await (0, _cities.getCities)((0, _utils.getQuery)({
        enabled: true,
        select: 'id,name',
        sort: 'name,ASC',
        stateId,
        filter: {
          enabled: 'eq',
          stateId: 'eq'
        }
      }));
    } catch (err) {
      Promise.reject(err);
    }
  }
  // private async getPincodeList(cityId: number) {
  //   try {
  //     this.pincodeList = await getPincodes(
  //       getQuery({
  //         enabled: true,
  //         select: 'id,pincodeName,pincode',
  //         sort: 'pincode,ASC',
  //         cityId,
  //         filter: {
  //           enabled: 'eq',
  //           cityId: 'eq'
  //         }
  //       })
  //     )
  //   } catch (err) {
  //     Promise.reject(err)
  //   }
  // }
  imageUpload(file) {
    this.formData.image = file.response;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'NotificationsDetail',
  components: {
    FileUpload: _index.default,
    UploadImage: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;