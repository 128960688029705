import { render, staticRenderFns } from "./CategoryDetail.vue?vue&type=template&id=63372171&scoped=true"
import script from "./CategoryDetail.vue?vue&type=script&lang=ts"
export * from "./CategoryDetail.vue?vue&type=script&lang=ts"
import style0 from "./CategoryDetail.vue?vue&type=style&index=0&id=63372171&lang=scss"
import style1 from "./CategoryDetail.vue?vue&type=style&index=1&id=63372171&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63372171",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\algoocean\\askknatural-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63372171')) {
      api.createRecord('63372171', component.options)
    } else {
      api.reload('63372171', component.options)
    }
    module.hot.accept("./CategoryDetail.vue?vue&type=template&id=63372171&scoped=true", function () {
      api.rerender('63372171', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/category/components/CategoryDetail.vue"
export default component.exports