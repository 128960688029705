"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class extends _vuePropertyDecorator.Vue {
  mounted() {
    this.initResizeEvent();
    this.initSidebarResizeEvent();
  }
  beforeDestroy() {
    this.destroyResizeEvent();
    this.destroySidebarResizeEvent();
  }
  activated() {
    this.initResizeEvent();
    this.initSidebarResizeEvent();
  }
  deactivated() {
    this.destroyResizeEvent();
    this.destroySidebarResizeEvent();
  }
  chartResizeHandler() {
    if (this.chart) {
      this.chart.resize();
    }
  }
  sidebarResizeHandler(e) {
    if (e.propertyName === 'width') {
      this.chartResizeHandler();
    }
  }
  initResizeEvent() {
    if (this.chartResizeHandler) {
      window.addEventListener('resize', this.chartResizeHandler);
    }
  }
  destroyResizeEvent() {
    if (this.chartResizeHandler) {
      window.removeEventListener('resize', this.chartResizeHandler);
    }
  }
  initSidebarResizeEvent() {
    this.sidebarElm = document.getElementsByClassName('sidebar-container')[0];
    if (this.sidebarElm) {
      this.sidebarElm.addEventListener('transitionend', this.sidebarResizeHandler);
    }
  }
  destroySidebarResizeEvent() {
    if (this.sidebarElm) {
      this.sidebarElm.removeEventListener('transitionend', this.sidebarResizeHandler);
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ResizeMixin'
})], default_1);
var _default = exports.default = default_1;