"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateDocumentMaster = exports.getDocumentMasterInfo = exports.getDocumentMasterCount = exports.getDocumentMasterById = exports.getDocumentMaster = exports.deleteDocumentMaster = exports.defaultDocumentMasterData = exports.addDocumentMaster = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultDocumentMasterData = exports.defaultDocumentMasterData = {
  id: 0,
  enabled: true,
  name: ''
};
const getDocumentMaster = params => (0, _request.default)({
  url: '/document-master',
  method: 'get',
  params
});
exports.getDocumentMaster = getDocumentMaster;
const getDocumentMasterById = id => (0, _request.default)({
  url: `/document-master/${id}`,
  method: 'get'
});
exports.getDocumentMasterById = getDocumentMasterById;
const getDocumentMasterInfo = data => (0, _request.default)({
  url: '/document-master/me',
  method: 'post',
  data
});
exports.getDocumentMasterInfo = getDocumentMasterInfo;
const updateDocumentMaster = (id, data) => (0, _request.default)({
  url: `/document-master/${id}`,
  method: 'patch',
  data
});
exports.updateDocumentMaster = updateDocumentMaster;
const deleteDocumentMaster = id => (0, _request.default)({
  url: `/document-master/${id}`,
  method: 'delete'
});
exports.deleteDocumentMaster = deleteDocumentMaster;
const addDocumentMaster = data => (0, _request.default)({
  url: '/document-master/',
  method: 'post',
  data
});
exports.addDocumentMaster = addDocumentMaster;
const getDocumentMasterCount = () => (0, _request.default)({
  url: '/document-master/count',
  method: 'get'
});
exports.getDocumentMasterCount = getDocumentMasterCount;
const updateOrder = data => (0, _request.default)({
  url: '/document-master/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;