// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.theme-message,\n.theme-picker-dropdown {\n  z-index: 99999 !important;\n}\n.theme-picker .el-color-picker__trigger {\n  height: 26px !important;\n  width: 26px !important;\n  padding: 2px;\n}\n.theme-picker-dropdown .el-color-dropdown__link-btn {\n  display: none;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#000",
	"menuText": "#fff",
	"menuActiveText": "#90d86a"
};
module.exports = exports;
