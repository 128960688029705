"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.firebaseConfig = void 0;
const firebaseConfig = exports.firebaseConfig = {
  databaseURL: "https://ask-natural-default-rtdb.firebaseio.com",
  apiKey: "AIzaSyC9b4LcTJ7IMJ2F5T8ex7SKvqw_grpt6Pg",
  authDomain: "ask-natural.firebaseapp.com",
  projectId: "ask-natural",
  storageBucket: "ask-natural.appspot.com",
  messagingSenderId: "977805484649",
  appId: "1:977805484649:web:4871b0ff141d78f907f8f8",
  measurementId: "G-12GCRR277P"
};