"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateCelebrity = exports.getCelebrityInfo = exports.getCelebrityCount = exports.getCelebrityById = exports.getCelebrityByEmail = exports.getCelebrity = exports.deleteCelebrity = exports.defaultCelebrityData = exports.addCelebrity = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCelebrityData = exports.defaultCelebrityData = {
  id: 0,
  enabled: true,
  video: '',
  image: '',
  priority: 0
};
const getCelebrity = params => (0, _request.default)({
  url: '/celebrity',
  method: 'get',
  params
});
exports.getCelebrity = getCelebrity;
const getCelebrityById = id => (0, _request.default)({
  url: `/celebrity/${id}`,
  method: 'get'
});
exports.getCelebrityById = getCelebrityById;
const getCelebrityInfo = data => (0, _request.default)({
  url: '/celebrity/me',
  method: 'post',
  data
});
exports.getCelebrityInfo = getCelebrityInfo;
const getCelebrityByEmail = Celebrityemail => (0, _request.default)({
  url: `/celebrity/${Celebrityemail}`,
  method: 'get'
});
exports.getCelebrityByEmail = getCelebrityByEmail;
const updateCelebrity = (id, data) => (0, _request.default)({
  url: `/celebrity/${id}`,
  method: 'patch',
  data
});
exports.updateCelebrity = updateCelebrity;
const deleteCelebrity = id => (0, _request.default)({
  url: `/celebrity/${id}`,
  method: 'delete'
});
exports.deleteCelebrity = deleteCelebrity;
const addCelebrity = data => (0, _request.default)({
  url: '/celebrity/',
  method: 'post',
  data
});
exports.addCelebrity = addCelebrity;
const getCelebrityCount = params => (0, _request.default)({
  url: '/celebrity/count',
  method: 'get',
  params
});
exports.getCelebrityCount = getCelebrityCount;
const updateOrder = data => (0, _request.default)({
  url: '/celebrity/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;