"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _state = require("@/api/state");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = require("@/utils/index");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.showImageUpload = false;
    this.params = {
      some_params: 'your_params_goes_here'
    };
    this.postForm = Object.assign({}, _state.defaultStateData);
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter state name',
        trigger: 'blur'
      }, {
        pattern: '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
        message: 'State name should contain characters only',
        trigger: ['blur', 'change']
      }
      //  {
      //     pattern: '^[a-zA-Z0-9_]',
      //     message: "Space not allow at start of name",
      //     trigger: ["blur", "change"],
      //   }, 
      ]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _state.defaultStateData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _state.getstateById)(id);
      this.postForm = data;
      // Just for test
      //  const title = this.postForm.email
      // Set tagsview title
      //  this.setTagsViewTitle(title)
      // Set page title
      //  this.setPageTitle(title)
    } catch (err) {
      console.error(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        console.error('Submit Error!');
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      this.postForm.name = (0, _index.arrangeLetters)(this.postForm.name);
      if (this.isEdit) {
        await (0, _state.updatestate)(this.postForm.id, this.postForm);
      } else {
        await (0, _state.addstate)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'state saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/state/list');
    } catch (err) {
      this.loading = false;
      // this.$message(err.message)
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'stateDetail',
  components: {}
})], default_1);
var _default = exports.default = default_1;