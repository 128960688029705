"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vue = _interopRequireDefault(require("vue"));
var _vuePropertyDecorator = require("vue-property-decorator");
let MessageWrapper = class MessageWrapper extends _vue.default {
  // Computed property for message wrapper class
  get wrapperClass() {
    return {
      "message-wrapper": true,
      "message-sent": this.isSender,
      "message-received": !this.isSender
    };
  }
  // Computed property for message class based on message type
  get messageClass() {
    switch (this.message.type) {
      case 0:
        return "message-text";
      case 1:
        return "message-image";
      case 2:
        return "message-audio";
      case 3:
        return "message-video";
      case 4:
        return "message-file";
      default:
        return "message-unknown";
    }
  }
  // Method to handle getting message content based on type
  getMessageContent(msg) {
    switch (msg.type) {
      case 0:
        return msg.text || '';
      // Return 'text' for type 0
      case 1:
        return msg.image || '';
      // Return 'image' for type 1
      case 2:
        return msg.audio || '';
      // Return 'audio' for type 2
      case 3:
        return msg.video || '';
      // Return 'video' for type 3
      case 4:
        return msg.pdf || '';
      // Return 'pdf' for type 4
      default:
        return '';
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], MessageWrapper.prototype, "message", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], MessageWrapper.prototype, "isSender", void 0);
MessageWrapper = (0, _tslib.__decorate)([_vuePropertyDecorator.Component], MessageWrapper);
var _default = exports.default = MessageWrapper;