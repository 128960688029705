"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-upload", {
    attrs: {
      action: "https://jsonplaceholder.typicode.com/posts/",
      "list-type": "picture-card",
      "on-preview": _vm.handlePictureCardPreview,
      "on-remove": _vm.handleRemove,
      "http-request": _vm.uploadImage,
      "file-list": _vm.fileList,
      "before-upload": _vm.onBeforeUpload,
      limit: 1,
      "on-exceed": _vm.onLimitExceed,
      accept: "image/x-png,image/gif,image/jpeg"
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]), _vm.height && _vm.width != null ? _c("span", {
    staticClass: "dimensionText"
  }, [_vm._v("*Only Image of dimension " + _vm._s(_vm.height + " * " + _vm.width) + " is accepted")]) : _vm._e(), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;