"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-card", {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("About me")])]), _c("div", {
    staticClass: "user-profile"
  }, [_c("div", {
    staticClass: "box-center"
  }, [_c("pan-thumb", {
    attrs: {
      image: _vm.user.avatar,
      height: "100px",
      width: "100px",
      hoverable: false
    }
  }, [_c("img", {
    attrs: {
      src: _vm.user.avatar
    }
  })])], 1), _c("div", {
    staticClass: "box-center"
  }, [_c("div", {
    staticClass: "user-name text-center"
  }, [_vm._v(" " + _vm._s(_vm.user.name) + " ")]), _c("div", {
    staticClass: "user-role text-center text-muted"
  }, [_vm._v(" " + _vm._s(_vm._f("uppercaseFirstChar")(_vm.user.roles)) + " ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;