"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      name: "title",
      required: "",
      placeholder: "title",
      "show-word-limit": ""
    },
    model: {
      value: _vm.formData.title,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Message",
      prop: "message"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2,
      autosize: {
        minRows: 2,
        maxRows: 4
      },
      name: "message",
      placeholder: "message",
      "show-word-limit": "",
      required: ""
    },
    model: {
      value: _vm.formData.message,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "message", $$v);
      },
      expression: "formData.message"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Image",
      prop: "image"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      formField: _vm.formData.image
    },
    on: {
      "handle-upload": _vm.updateImage,
      "handle-remove": _vm.removeImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 1000 * 1000 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    staticStyle: {
      background: "#90d86a",
      color: "white"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;