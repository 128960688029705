"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatecomment = exports.updateOrder = exports.getCommentCount = exports.getCommentById = exports.getComment = exports.deletecomment = exports.defaultCommentData = exports.addcomment = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCommentData = exports.defaultCommentData = {
  id: 0,
  userId: 0,
  postsId: 0,
  comment: ''
};
const getComment = params => (0, _request.default)({
  url: '/comment',
  method: 'get',
  params
});
exports.getComment = getComment;
const getCommentById = id => (0, _request.default)({
  url: `/comment/${id}`,
  method: 'get'
});
exports.getCommentById = getCommentById;
const updatecomment = (id, data) => (0, _request.default)({
  url: `/comment/${id}`,
  method: 'patch',
  data
});
exports.updatecomment = updatecomment;
const deletecomment = id => (0, _request.default)({
  url: `/comment/${id}`,
  method: 'delete'
});
exports.deletecomment = deletecomment;
const addcomment = data => (0, _request.default)({
  url: '/comment/',
  method: 'post',
  data
});
exports.addcomment = addcomment;
const getCommentCount = () => (0, _request.default)({
  url: '/comment/count',
  method: 'get'
});
exports.getCommentCount = getCommentCount;
const updateOrder = data => (0, _request.default)({
  url: '/comment/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;