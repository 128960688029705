"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("img", {
    staticClass: "login-logo",
    staticStyle: {
      width: "100%",
      height: "100%",
      position: "fixed"
    },
    attrs: {
      src: require("@/assets/img/askbg.png")
    }
  }), _c("div", {
    staticClass: "horizontal-line1"
  }), _c("div", {
    attrs: {
      id: "caret"
    }
  }), _c("div", {
    staticClass: "boxed"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-item": "center",
      "justify-content": "space-around",
      "margin-top": "20%"
    }
  }), _c("div", {
    staticClass: "card1"
  }, [_vm.sentotp ? _c("el-button", {
    staticClass: "back-arrow",
    staticStyle: {
      position: "absolute",
      top: "10px",
      left: "10px",
      "font-size": "25px",
      border: "none",
      "z-index": "1000"
    },
    attrs: {
      icon: "el-icon-back",
      circle: ""
    },
    on: {
      click: _vm.back
    }
  }) : _vm._e(), _c("div", {}, [_vm._m(0), _vm._m(1), !_vm.sentotp ? _c("span", {
    staticClass: "textMessage",
    attrs: {
      tyle: "text-align: center; margin-bottom: 12px"
    }
  }, [_vm._v("Please enter your contact number & get started")]) : _c("span", {
    staticClass: "textMessage",
    attrs: {
      tyle: "text-align: center; margin-bottom: 12px"
    }
  }, [_vm._v("Please enter otp sent to contact number +91 " + _vm._s(_vm.loginForm.contactNumber))]), !_vm.sentotp ? _c("el-form", {
    ref: "loginForm",
    staticClass: "login-form",
    staticStyle: {
      margin: "5%"
    },
    attrs: {
      model: _vm.loginForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("section", {
    staticClass: "input-main"
  }, [_c("div", {
    staticClass: "countrycode-col"
  }, [_c("el-form-item", {
    staticClass: "countrycode-parent",
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      prop: "dialCode"
    }
  }, [_c("el-select", {
    model: {
      value: _vm.loginForm.dialCode,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "dialCode", $$v);
      },
      expression: "loginForm.dialCode"
    }
  }, _vm._l(_vm.phone, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: "+" + item.calling_code,
        value: item.calling_code
      }
    });
  }), 1)], 1)], 1), _c("div", {
    staticClass: "contact-col"
  }, [_c("el-form-item", {
    attrs: {
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    ref: "contactNumber",
    attrs: {
      placeholder: "Enter contact number",
      type: "number"
    },
    model: {
      value: _vm.loginForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "contactNumber", $$v);
      },
      expression: "loginForm.contactNumber"
    }
  })], 1)], 1)]), _c("el-button", {
    staticStyle: {
      width: "100%",
      background: "#008000",
      color: "white"
    },
    attrs: {
      type: ""
    },
    on: {
      click: function ($event) {
        return _vm.handleLogin();
      }
    }
  }, [_vm._v("GET OTP")]), _c("div")], 1) : _c("el-form", {
    ref: "verifyOtpForm",
    staticClass: "login-form",
    staticStyle: {
      margin: "5%",
      position: "relative"
    },
    attrs: {
      model: _vm.verifyOtpForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("el-form-item", [_c("div", {
    staticStyle: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "center"
    }
  }, [_c("el-input", {
    staticClass: "inputContact",
    attrs: {
      placeholder: "Enter otp",
      maxlength: "4",
      type: "number"
    },
    model: {
      value: _vm.verifyOtpForm.otp,
      callback: function ($$v) {
        _vm.$set(_vm.verifyOtpForm, "otp", $$v);
      },
      expression: "verifyOtpForm.otp"
    }
  })], 1)]), _c("el-button", {
    staticStyle: {
      width: "100%",
      background: "#008000",
      border: "#5cabda"
    },
    attrs: {
      type: "primary"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleVerifyOTP(_vm.verifyOtpForm.otp);
      }
    }
  }, [_c("b", [_vm._v("Verify")])]), _c("div", {
    staticStyle: {
      "align-items": "center",
      "justify-content": "space-between",
      "margin-top": "10px",
      "text-align": "center"
    }
  }, [_c("span", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.resentLoading,
      expression: "resentLoading"
    }],
    staticClass: "otp-button",
    staticStyle: {
      "margin-right": "0px",
      "font-size": "12px",
      background: "transparent"
    },
    on: {
      click: _vm.handleLogin
    }
  }, [_c("b", {
    staticStyle: {
      color: "#004578",
      background: "background: #222222",
      "text-decoration": "underline",
      cursor: "pointer"
    }
  }, [_vm._v("Resend OTP")])])])], 1)], 1)], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticStyle: {
      display: "flex",
      "align-item": "center",
      "justify-content": "space-around",
      "margin-top": "10%"
    }
  }, [_c("img", {
    staticClass: "login-logo1",
    staticStyle: {
      width: "20%"
    },
    attrs: {
      src: require("@/assets/img/asknatural.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("h2", {
    staticStyle: {
      "text-align": "center",
      "margin-bottom": "24px"
    }
  }, [_vm._v(" Welcome To Askknatural "), _c("br")]);
}];
render._withStripped = true;