// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.slots-container {\n  width: 70%;\n  display: flex;\n  justify-content: start;\n}\n.slots-container .slot-time {\n  margin-bottom: 20px;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#000",
	"menuText": "#fff",
	"menuActiveText": "#90d86a"
};
module.exports = exports;
