"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _users = require("@/api/users");
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _utils = require("@/utils");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index3 = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _router = _interopRequireDefault(require("./../../../router"));
var _country = require("@/api/country");
var _language = require("@/api/language");
var _workoutmaster = require("@/api/workoutmaster");
var _diseases = require("@/api/diseases");
var _admins = require("@/api/admins");
// import { getColleges } from "@/api/college";
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = Object.assign({}, _users.defaultUserData);
    this.loading = false;
    this.list = [];
    this.showImageUpload = false;
    this.renderKey = 0;
    this.countryId = 1;
    this.countryList = [];
    this.languageList = [];
    this.workOutList = [];
    this.diseaseList = [];
    this.trainerList = [];
    this.nutrationistList = [];
    this.userTypeList = [{
      label: 'In Campus',
      value: 1
    }, {
      label: 'Off Campus',
      value: 0
    }];
    this.genderList = [{
      label: 'Male',
      value: 0
    }, {
      label: 'Female',
      value: 1
    }, {
      label: 'Don’t wish to dislose',
      value: 2
    }];
    this.heighTypeList = [{
      label: 'FT',
      value: 0
    }, {
      label: 'CM',
      value: 1
    }];
    this.weightTypeList = [{
      label: 'KG',
      value: 0
    }, {
      label: 'LB',
      value: 1
    }];
    this.image = 'https://via.placeholder.com/154';
    this.listQuery = {
      sort: 'id,DESC',
      enabled: true,
      filter: {
        enabled: 'eq'
      }
    };
    this.trainerlistQuery = {
      sort: 'id,DESC',
      adminsRoleId: 2,
      enabled: true,
      filter: {
        enabled: 'eq',
        adminsRoleId: 'eq'
      }
    };
    this.nutrationistlistQuery = {
      sort: 'id,DESC',
      adminsRoleId: 3,
      enabled: true,
      filter: {
        enabled: 'eq',
        adminsRoleId: 'eq'
      }
    };
    this.rules = {
      fullName: [{
        required: true,
        message: 'Please Enter Full Name',
        trigger: ['blur', 'change']
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
        message: 'Name should contain characters only',
        trigger: ['blur', 'change']
      }],
      lastName: [{
        required: true,
        message: 'Please Enter Last Name',
        trigger: ['blur', 'change']
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: '^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$',
        message: 'Name should contain characters only',
        trigger: ['blur', 'change']
      }],
      email: [{
        required: true,
        message: 'Please enter email',
        trigger: 'blur'
      }, {
        type: 'email',
        message: 'Please enter correct email',
        trigger: ['blur', 'change']
      }],
      contactNumber: [{
        required: true,
        message: 'Please enter mobile number',
        trigger: 'blur'
      }
      // {
      //   trigger: ['blur', 'change'],
      //   pattern: '[123456789][0-9]*$',
      //   message: 'Mobile number should contain number only'
      // },
      // {
      //   max: 10,
      //   min: 10,
      //   message: "Length should be of 10 Digits",
      //   trigger: ["blur", "change"],
      // },
      ],
      // profilePicture: [
      //   {
      //     required: true,
      //     message: 'Profile picture is required'
      //   }
      // ],
      gender: [{
        required: true,
        message: 'Please Select Gender',
        trigger: ['blur', 'change']
      }],
      age: [{
        required: true,
        message: 'Please Enter Age',
        trigger: ['blur', 'change']
      }],
      // height: [
      //   {
      //     required: true,
      //     message: "Please Enter Height",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // heightType: [
      //   {
      //     required: true,
      //     message: "Please Select HeightType",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // weight: [
      //   {
      //     required: true,
      //     message: "Please Enter Weight",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // weightType: [
      //   {
      //     required: true,
      //     message: "Please Select WeightType",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // countryId: [
      //   {
      //     required: true,
      //     message: "Please Select Country",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // cycleFrom: [
      //   {
      //     required: true,
      //     message: "Please Select Date of Cycle From",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // cycleTo: [
      //   {
      //     required: true,
      //     message: "Please Select Date of Cycle To",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // languageIds: [
      //   {
      //     required: true,
      //     message: "Please Select Language",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // workoutIds: [
      //   {
      //     required: true,
      //     message: "Please Select Workout",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // diseaseIds: [
      //   {
      //     required: true,
      //     message: "Please Select Disease",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      type: [{
        required: true,
        message: 'Please Select User Type',
        trigger: ['blur', 'change']
      }],
      collegeId: [{
        required: true,
        message: 'Please Select College Name',
        trigger: ['blur', 'change']
      }],
      pincode: [{
        required: true,
        message: 'Pincode is required'
      }],
      companyName: [{
        required: true,
        message: 'Company Name is required'
      }],
      companyBio: [{
        required: true,
        message: 'Company Bio is required'
      }],
      companyContactNumber: [{
        required: true,
        message: 'Company Contact Number is required'
      }],
      companyAddress: [{
        required: true,
        message: 'Company Address is required'
      }]
    };
    this.collegelist = [];
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onClose() {
    this.showImageUpload = false;
  }
  deleteImage() {
    this.postForm.profilePicture = '';
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'img.' + blob.type.split('/')[1], {
        type: 'image/png'
      });
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.profilePicture = res;
      });
    });
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.getCountryData();
    this.getLanguageData();
    this.getWorkOutData();
    this.getDiseasesData();
    this.getTrainerList();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _users.defaultUserData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async getCountryData() {
    const data = await (0, _country.getcountry)((0, _utils.getQuery)(this.listQuery));
    this.countryList = data;
  }
  async getLanguageData() {
    const data = await (0, _language.getLanguages)((0, _utils.getQuery)(this.listQuery));
    this.languageList = data;
  }
  async getWorkOutData() {
    const data = await (0, _workoutmaster.getWorkoutMaster)((0, _utils.getQuery)(this.listQuery));
    this.workOutList = data;
  }
  async getDiseasesData() {
    const data = await (0, _diseases.getdiseases)((0, _utils.getQuery)(this.listQuery));
    this.diseaseList = data;
  }
  async getTrainerList() {
    try {
      this.loading = true;
      const data = await (0, _admins.getAdmins)((0, _utils.getQuery)(this.nutrationistlistQuery));
      const data1 = await (0, _admins.getAdmins)((0, _utils.getQuery)(this.trainerlistQuery));
      this.nutrationistList = data;
      this.trainerList = data1;
      this.loading = false;
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchData(id) {
    try {
      const data = await (0, _users.getUserDetailById)(id);
      this.renderKey++;
      // this.postForm.profilePicture=data.profilePicture;
      this.postForm = data;
      // this.getCollegeList();
      this.postForm.profilePicture = this.postForm.profilePicture.trim();
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  updateProfilePictureImage(res) {
    this.postForm.profilePicture = res;
  }
  removeProfilePictureImage() {
    this.postForm.profilePicture = '';
  }
  submitForm() {
    console.log('submit fom');
    this.$refs.postForm.validate(valid => {
      console.log('valid', valid);
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.renderKey--;
    this.$refs.postForm.resetFields();
    this.postForm.profilePicture = '';
  }
  async saveForm() {
    debugger;
    try {
      var _this$postForm$langua, _this$postForm$workou, _this$postForm$diseas;
      this.postForm.profilePicture = this.postForm.profilePicture;
      (_this$postForm$langua = this.postForm.languageIds) === null || _this$postForm$langua === void 0 || _this$postForm$langua.forEach(res => {
        this.postForm.language.push({
          id: res
        });
      });
      (_this$postForm$workou = this.postForm.workoutIds) === null || _this$postForm$workou === void 0 || _this$postForm$workou.forEach(res => {
        this.postForm.workout.push({
          id: res
        });
      });
      (_this$postForm$diseas = this.postForm.diseaseIds) === null || _this$postForm$diseas === void 0 || _this$postForm$diseas.forEach(res => {
        this.postForm.disease.push({
          id: res
        });
      });
      this.loading = true;
      //  this.postForm.college = { id: this.postForm.collegeId };
      if (this.isEdit) {
        await (0, _users.updateUser)(this.postForm.id, this.postForm).then(res => {
          console.log('update res: ', res);
          _router.default.push('/users/list');
          this.resetForm();
        }).catch(e => {
          console.error('update user network error', e);
        });
      } else {
        await (0, _users.addUser)(this.postForm).then(res => {
          if (res.responseCode === 300) {
            this.loading = false;
            this.$notify({
              title: 'Danger',
              message: 'This contact number is already exist.',
              type: 'warning',
              duration: 2000
            });
          }
          if (res.responseCode === 301) {
            this.loading = false;
            this.$notify({
              title: 'Danger',
              message: 'This email is already exist.',
              type: 'warning',
              duration: 2000
            });
          }
          if (res.responseCode === 204 || res.responseCode === 200 || res.responseCode === 201) {
            this.loading = false;
            this.$notify({
              title: 'Success',
              message: 'User saved successfully',
              type: 'success',
              duration: 2000
            });
            _router.default.push('/users/list');
          }
        }).catch(err => {
          console.log(err);
        });
      }
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'UserDetail',
  components: {
    PanThumb: _index2.default,
    UploadImage: _index3.default,
    AvatarUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;