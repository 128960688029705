"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const usersPlanRouter = {
  path: '/usersPlan',
  component: _index.default,
  redirect: 'noredirect',
  name: 'users Management',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'usersManagement.title',
    icon: 'users-alt',
    hidden: true
  },
  children: [
  // {
  //   path: 'list/:id(\\d+)',
  //   component: () => import('@/views/userPlan/list.vue'),
  //   name: 'users',
  //   meta: {
  //     title: 'usersManagement.title',
  //     noCache: true,
  //     icon: 'users-alt'
  //   }
  // },
  {
    path: 'list/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/userPlan/manage.vue'))),
    name: 'Edit Admin',
    props: {
      isEdit: true
    },
    meta: {
      title: 'usersManagement.users.manage',
      noCache: true,
      activeMenu: '/usersPackage/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/userSession/manage.vue'))),
    name: 'Add users',
    props: {
      isEdit: false
    },
    meta: {
      title: 'userManagement.users.manage',
      noCache: true,
      activeMenu: '/users/list',
      hidden: true
    }
  }]
};
var _default = exports.default = usersPlanRouter;