"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vueImageCropUpload = _interopRequireDefault(require("vue-image-crop-upload"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.languageTypeList = {
      en: 'en'
    };
  }
  get show() {
    return this.value;
  }
  set show(value) {
    this.$emit('input', value);
  }
  get language() {
    return this.languageTypeList[_app.AppModule.language];
  }
  srcFileSet(fileName, fileType, fileSize) {
    this.$emit('src-file-set', fileName, fileType, fileSize);
  }
  cropSuccess(imgDataUrl, field) {
    this.$emit('crop-success', imgDataUrl, field);
  }
  cropUploadSuccess(jsonData, field) {
    this.$emit('crop-upload-success', jsonData, field);
  }
  cropUploadFail(status, field) {
    this.$emit('crop-upload-fail', status, field);
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "value", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "url", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  required: true
})], default_1.prototype, "field", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 300
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 300
})], default_1.prototype, "height", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: () => {}
})], default_1.prototype, "params", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: () => {}
})], default_1.prototype, "headers", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AvatarUpload',
  components: {
    ImageCropUpload: _vueImageCropUpload.default
  }
})], default_1);
var _default = exports.default = default_1;