"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatestate = exports.updateOrder = exports.getstateInfo = exports.getstateCount = exports.getstateById = exports.getstateByEmail = exports.getstate = exports.deletestate = exports.defaultStateData = exports.addstate = exports.StoreStorestate = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultStateData = exports.defaultStateData = {
  id: 0,
  enabled: true,
  name: '',
  countryId: 1
};
const getstate = params => (0, _request.default)({
  url: '/states',
  method: 'get',
  params
});
exports.getstate = getstate;
const getstateById = id => (0, _request.default)({
  url: `/states/${id}`,
  method: 'get'
});
exports.getstateById = getstateById;
const getstateInfo = data => (0, _request.default)({
  url: '/states/me',
  method: 'post',
  data
});
exports.getstateInfo = getstateInfo;
const getstateByEmail = Storestateemail => (0, _request.default)({
  url: `/states/${Storestateemail}`,
  method: 'get'
});
exports.getstateByEmail = getstateByEmail;
const updatestate = (id, data) => (0, _request.default)({
  url: `/states/${id}`,
  method: 'patch',
  data
});
exports.updatestate = updatestate;
const StoreStorestate = (id, data) => (0, _request.default)({
  url: `/states/${id}`,
  method: 'patch',
  data
});
exports.StoreStorestate = StoreStorestate;
const deletestate = Storestatename => (0, _request.default)({
  url: `/states/${Storestatename}`,
  method: 'delete'
});
exports.deletestate = deletestate;
const addstate = data => (0, _request.default)({
  url: '/states/',
  method: 'post',
  data
});
exports.addstate = addstate;
const getstateCount = () => (0, _request.default)({
  url: '/states/count',
  method: 'get'
});
exports.getstateCount = getstateCount;
const updateOrder = data => (0, _request.default)({
  url: '/states/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;