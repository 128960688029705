"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateContactUs = exports.getFilters = exports.getContactUsById = exports.getContactUs = exports.deleteContactUs = exports.defaultContactUsData = exports.createContactUs = exports.addContactUs = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultContactUsData = exports.defaultContactUsData = {
  id: 0,
  enabled: true,
  name: '',
  number: '',
  email: '',
  message: ''
};
const getContactUs = params => (0, _request.default)({
  url: '/contact-us',
  method: 'get',
  params
});
exports.getContactUs = getContactUs;
const addContactUs = data => (0, _request.default)({
  url: '/contact-us',
  method: 'post',
  data
});
exports.addContactUs = addContactUs;
const getContactUsById = id => (0, _request.default)({
  url: `/contact-us/${id}`,
  method: 'get'
});
exports.getContactUsById = getContactUsById;
const updateContactUs = (id, data) => (0, _request.default)({
  url: `/contact-us/${id}`,
  method: 'patch',
  data
});
exports.updateContactUs = updateContactUs;
const deleteContactUs = id => (0, _request.default)({
  url: `/contact-us/${id}`,
  method: 'delete'
});
exports.deleteContactUs = deleteContactUs;
const createContactUs = data => (0, _request.default)({
  url: '/contact-us/',
  method: 'post',
  data
});
exports.createContactUs = createContactUs;
const getFilters = data => (0, _request.default)({
  url: '/contact-us/fetch',
  method: 'post',
  data
});
exports.getFilters = getFilters;