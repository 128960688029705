"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _adminsRoles = require("@/api/adminsRoles");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("../../store/modules/admin");
var _trainer = require("@/api/trainer");
var _trainingExpertise = require("@/api/training-expertise");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.roleList = [];
    this.list = [];
    this.trainingExpertiseList = [];
    this.createdBy = Object.assign({}, _admins.defaultAdminData);
    this.listLoading = true;
    this.downloadLoading = false;
    this.avatar = "https://via.placeholder.com/300*300";
    this.myRole = _admin.AdminModule.roles;
    this.activeTab = "first";
    this.updateDialogVisible = false;
    this.updateRoleVisible = false;
    this.drawer = false;
    this.drawerTitle = "Trainer Details";
    this.updateLoading = false;
    this.status = 0;
    this.registeredAs = 0;
    this.userDetail = null;
    this.listQuery = {
      page: 1,
      limit: 10,
      id: _admin.AdminModule.id,
      fullName: undefined,
      email: undefined,
      contactNumber: undefined,
      adminsRoleId: 2,
      status: 0,
      sort: "id,DESC",
      filter: {
        id: "ne",
        fullName: "$contL",
        email: "$contL",
        contactNumber: "$contL",
        status: "eq",
        enabled: "eq",
        adminsRoleId: "eq",
        registeredAs: "eq"
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: "Ascending",
      key: "id,ASC"
    }, {
      label: "Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    //  private roleList = [
    //   { id: 1, value: 'superadmin' },
    //   { id: 2, value: 'admin' }
    // ];
    this.statusList = [{
      label: "Pending",
      value: 0
    }, {
      label: "Approved",
      value: 1
    }, {
      label: "Rejected",
      value: 2
    }];
    this.assignRoleList = [{
      label: "Trainer",
      value: 0
    }, {
      label: "Nutrationalist",
      value: 1
    }];
    this.trainerData = {
      status: null
    };
    this.AssignRoleData = {
      registeredAs: null
    };
    this.assignRoleOptions = [{
      key: 0,
      value: "Trainer"
    }, {
      key: 1,
      value: "Nutritionist"
    }];
    //  @AsyncComputed()
    //   async helloWorld() {
    //       return "Hello World";
    //   }
    // : {
    //   myResolvedValue: {
    //     get () {
    //       return new Promise((resolve, reject) => {
    //         setTimeout(() => resolve('*Fancy* Resolved Value!'), 1000)
    //       })
    //     },
    //     default: 'No fanciness'
    //   }
    // }
  }
  handleClick(tab, event) {
    switch (tab.index) {
      case tab.index = "0":
        {
          this.listQuery.status = 0;
          this.getList();
          break;
        }
      case tab.index = "1":
        {
          this.listQuery.status = 1;
          this.getList();
          break;
        }
      case tab.index = "2":
        {
          this.listQuery.status = 2;
          this.getList();
          break;
        }
    }
  }
  async handleClose() {
    this.drawer = false;
  }
  async handleCloseDialog() {
    this.updateDialogVisible = false;
  }
  async handleRoleClose() {
    this.updateRoleVisible = false;
  }
  created() {
    this.getList();
    this.getTrainingExpertise();
    // this.fetchRoles();
    this.avatar = "https://via.placeholder.com/300*300";
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _admins.getAdmins)((0, _index2.getQuery)(this.listQuery));
      var listTemp = data.data;
      this.list = listTemp;
      this.total = data.total;
      const callList = [];
      // listTemp.forEach(async(admin:any, index: any, arr: any) => {
      //   const a = arr
      //   if (admin.createdBy != null) {
      //     callList.push(getAdminById(admin.createdBy))
      //   }
      // })
      // Axios.all(callList).then((data: any) => {
      //   // data.forEach((response: any, index: any) => {
      //   //   listTemp[index].createdByFullName = response.fullName
      //   // })
      //   this.list = listTemp
      // })
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getTrainingExpertise() {
    try {
      this.listLoading = true;
      const data = await (0, _trainingExpertise.getTrainingExpertises)((0, _index2.getQuery)({}));
      this.trainingExpertiseList = data;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async fetchRoles() {
    try {
      // var role = this.myRole[0]
      // if (role === 'superadmin') {
      this.roleList = await (0, _adminsRoles.getAdminsRoles)((0, _index2.getQuery)({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
      // }
      // if (role === 'Admin') {
      //   this.roleList = await getAdminsRoles(
      //     getQuery({
      //       enabled: true,
      //       sort: 'name,ASC',
      //       id: 3,
      //       filter: {
      //         enabled: 'eq',
      //         id: 'eq'
      //       }
      //     }))
      // }
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchAdmin(id) {
    const data = await (0, _admins.getAdmins)(id);
    this.createdBy = data;
    return this.createdBy.fullName;
  }
  //   getAdminsById(id:any) {
  //     return new Promise((resolve) => {
  //   getAdminById(id);
  // });
  //   // return await getAdminById(id).;
  //   //  user.fullName;
  // }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _admins.deleteAdmin)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  changeStatus(row) {
    this.trainerData = row;
    this.updateDialogVisible = true;
  }
  changeRole(row) {
    this.AssignRoleData = row;
    this.updateRoleVisible = true;
  }
  async updateStatus() {
    const data = {
      status: this.trainerData.status,
      trainingExpertiseMasterIds: this.trainerData.trainingExpertiseMasterIds
    };
    this.updateLoading = true;
    await (0, _trainer.updateTrainer)(this.trainerData.id, data).then(res => {
      this.$message({
        message: "Status Updated.",
        type: "success"
      });
      this.getList();
      this.updateLoading = false;
      this.updateDialogVisible = false;
    });
  }
  async updateRoleStatus() {
    console.log(this.AssignRoleData.registeredAs, "registeredAs");
    console.log(this.AssignRoleData.id, "id");
    const data = {
      registeredAs: this.AssignRoleData.registeredAs
    };
    this.updateLoading = true;
    await (0, _admins.updateAdmin)(this.AssignRoleData.id, data).then(res => {
      this.$message({
        message: "Status Updated.",
        type: "success"
      });
      this.getList();
      this.updateLoading = false;
      this.updateRoleVisible = false;
    });
  }
  async getDetails(id) {
    const data = await (0, _trainer.getTrainerById)(id);
    this.userDetail = data;
    this.drawer = true;
    // this.languageQuery.userId = id;
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Contact Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "email", "contactNumber", "enabled", "createdTimestamp"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.adminManagement.admins.title"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "AdminList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;