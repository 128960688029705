"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _echarts = _interopRequireDefault(require("echarts"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueClassComponent = require("vue-class-component");
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
const animationDuration = 3000;
let default_1 = class default_1 extends (0, _vueClassComponent.mixins)(_resize.default) {
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  initChart() {
    this.chart = _echarts.default.init(this.$el, 'macarons');
    this.chart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      radar: {
        radius: '66%',
        center: ['50%', '42%'],
        splitNumber: 8,
        splitArea: {
          areaStyle: {
            color: 'rgba(127,95,132,.3)',
            opacity: 1,
            shadowBlur: 45,
            shadowColor: 'rgba(0,0,0,.5)',
            shadowOffsetX: 0,
            shadowOffsetY: 15
          }
        },
        indicator: [{
          name: 'Sales',
          max: 10000
        }, {
          name: 'Administration',
          max: 20000
        }, {
          name: 'Information Techology',
          max: 20000
        }, {
          name: 'Customer Support',
          max: 20000
        }, {
          name: 'Development',
          max: 20000
        }, {
          name: 'Marketing',
          max: 20000
        }]
      },
      legend: {
        left: 'center',
        bottom: '10',
        data: ['Videos', 'Style', 'Teams']
      },
      series: [{
        type: 'radar',
        symbolSize: 0,
        areaStyle: {
          shadowBlur: 13,
          shadowColor: 'rgba(0,0,0,.2)',
          shadowOffsetX: 0,
          shadowOffsetY: 10,
          opacity: 1
        },
        data: [{
          value: [5000, 7000, 12000, 11000, 15000, 14000],
          name: 'Videos'
        }, {
          value: [4000, 9000, 15000, 15000, 13000, 11000],
          name: 'Style'
        }, {
          value: [5500, 11000, 12000, 15000, 12000, 12000],
          name: 'Teams'
        }],
        animationDuration: animationDuration
      }]
    });
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'chart'
})], default_1.prototype, "className", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '100%'
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '300px'
})], default_1.prototype, "height", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'RadarChart'
})], default_1);
var _default = exports.default = default_1;