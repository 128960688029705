"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _category = require("@/api/category");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/Dropzone/index.vue"));
var _common = require("@/api/common");
var _index2 = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index3 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
// import { getColleges, deleteCollege, updateCollege, getCollegeById, addCollege } from '@/api/college'

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.dialogVisibility = false;
    this.showImageUpload = false;
    this.showSketchUpload = false;
    this.sketch = 'https://via.placeholder.com/300';
    this.image = 'https://via.placeholder.com/300';
    this.resetImage = 'https://via.placeholder.com/300';
    this.params = {
      some_params: 'your_params_goes_here'
    };
    this.headers = {
      smail: '*_~'
    };
    this.postForm = Object.assign({}, _category.defaultCategoryData);
    this.loading = false;
    this.rules = {
      collegeName: [{
        required: true,
        message: 'Please enter College name',
        trigger: 'blur'
      }, {
        min: 3,
        max: 150,
        message: 'Length should be 3 to 150',
        trigger: 'blur'
      }, {
        pattern: '^[a-zA-Z]',
        message: 'No Special Characters allowed',
        trigger: ['blur', 'change']
      }, {
        pattern: '^[a-zA-Z]{3,}(?: [a-zA-Z]+)?(?: [a-zA-Z]+)?$',
        message: 'Spaces are not allowed',
        trigger: ['blur', 'change']
      }]
      // image: [
      //   {
      //     message: 'Please enter image',
      //     required: true,
      //     trigger: 'blur'
      //   }
      // ]
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  toggleSketch() {
    this.showSketchUpload = !this.showSketchUpload;
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'img.' + blob.type.split('/')[1], {
        type: 'image/png'
      });
      console.log('blob files is:', file);
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.image = res;
      });
    });
  }
  onClose() {
    this.showImageUpload = false;
  }
  onSketchClose() {
    this.showSketchUpload = false;
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _category.defaultCategoryData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    // try {
    //   const data: any = await getCollegeById(id)
    //   this.image = data.image
    //   this.sketch = data.sketchImage
    //   this.postForm = data
    // } catch (err) {
    //   console.error(err)
    // }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        console.error('Submit Error!');
        return false;
      }
    });
  }
  resetForm() {
    this.image = this.resetImage;
    this.$refs.postForm.resetFields();
    this.postForm.image = '';
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        // await updateCollege(this.postForm.id, this.postForm)
      } else {
        // await addCollege(this.postForm)
      }
      this.$notify({
        title: 'Success',
        message: 'College saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/college/list');
    } catch (err) {
      this.loading = false;
      this.$message(err.message);
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'CategoryDetail',
  components: {
    Dropzone: _index.default,
    AvatarUpload: _index2.default,
    PanThumb: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;