"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _setting = require("@/api/setting");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("./../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/Dropzone/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index3 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _vueSimpleRangeSlider = _interopRequireDefault(require("vue-simple-range-slider"));
require("vue-simple-range-slider/dist/vueSimpleRangeSlider.css");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.planList = [];
    this.trainerVersionList = [];
    this.typeList = ["Agent", "Development Officer", "SBA", "CLIA", "Other"];
    this.showImageUpload = false;
    this.image = "https://wpimg.wallstcn.com/577965b9-bb9e-4e02-9f0c-095b41417191";
    this.params = {
      some_params: "your_params_goes_here"
    };
    this.headers = {
      smail: "*_~"
    };
    this.countryList = [];
    this.stateList = [];
    this.cityList = [];
    this.planName = "";
    this.trainerInputValue = "";
    this.trainerInputVisible = false;
    this.userVersionList = [];
    this.userVersionInputValue = "";
    this.userVersionInputVisible = false;
    this.validateRequire = (rule, value, callback) => {
      if (value === "") {
        if (rule.field === "imageURL") {
          this.$message({
            message: "Upload cover image is required",
            type: "error"
          });
        } else {
          this.$message({
            message: rule.field + " is required",
            type: "error"
          });
        }
        callback(new Error(rule.field + " is required"));
      } else {
        callback();
      }
    };
    this.postForm = Object.assign({}, _setting.defaultSettingData);
    this.loading = false;
    this.rules = {
      version: [{
        required: true,
        message: "Version is required",
        trigger: "blur"
      }],
      iosVersion: [{
        required: true,
        message: "ios Version is required",
        trigger: "blur"
      }],
      appName: [{
        required: true,
        message: "App name is Required",
        trigger: "blur"
      }],
      whatappNumber: [{
        required: true,
        message: "Whatsapp Number is Required",
        trigger: "blur"
      }, {
        trigger: ["blur", "change"],
        pattern: "[123456789][0-9]*$",
        message: "Mobile number should contain number only"
      }, {
        max: 10,
        min: 10,
        message: "Length should be of 10 Digits",
        trigger: ["blur", "change"]
      }],
      appstoreLink: [{
        required: true,
        message: "Appstore Link is Required",
        trigger: "blur"
      }],
      aboutUsLink: [{
        required: true,
        message: "About us Link is Required",
        trigger: "blur"
      }],
      contactUsLink: [{
        required: true,
        message: "Email is Required",
        trigger: "blur"
      }],
      termsAndConditionsLink: [{
        required: true,
        message: "Terms and conditions link is Required",
        trigger: "blur"
      }],
      privacyPolicyLink: [{
        required: true,
        message: "privacy policy link is required",
        trigger: "blur"
      }],
      shareAppMessage: [{
        required: true,
        message: "Shareapp messageis required",
        trigger: "blur"
      }],
      contactNumber: [{
        required: true,
        message: "Contact Number is required",
        trigger: "blur"
      }, {
        trigger: ["blur", "change"],
        pattern: "[123456789][0-9]*$",
        message: "Mobile number should contain number only"
      }, {
        max: 10,
        min: 10,
        message: "Length should be of 10 Digits",
        trigger: ["blur", "change"]
      }]
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  onClose() {
    this.showImageUpload = false;
  }
  get lang() {
    return _app.AppModule.language;
  }
  // get filteredStates() {
  //   return this.stateList
  //     .filter
  //     // (p: any) => p.countryId === this.postForm.countryId
  //     ()
  // }
  // get filteredCities() {
  //   return this.cityList
  //     .filter
  //     // (p: any) =>
  //     // p.countryId === this.postForm.countryId &&
  //     // p.stateId === this.postForm.stateId
  //     ()
  // }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt("1"));
    } else {
      this.postForm = Object.assign({}, _setting.defaultSettingData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _setting.getSettingById)(id);
      this.postForm = data;
      this.trainerVersionList = data.trainervalidVersions;
      this.userVersionList = data.userValidVersions;
      // Just for test
      //  const title = this.postForm.email
      // Set tagsview title
      //  this.setTagsViewTitle(title)
      // Set page title
      //  this.setPageTitle(title)
    } catch (err) {
      console.error(err);
    }
  }
  handleClose(tag, isTrainer) {
    if (isTrainer) {
      this.trainerVersionList.splice(this.trainerVersionList.indexOf(tag), 1);
    } else {
      this.userVersionList.splice(this.userVersionList.indexOf(tag), 1);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        console.error("Submit Error!");
        return false;
      }
    });
  }
  handleInputConfirm(isTrainer) {
    if (isTrainer) {
      let inputValue = this.trainerInputValue;
      if (inputValue) {
        this.trainerVersionList.push(inputValue);
      }
      this.trainerInputVisible = false;
      this.trainerInputValue = "";
    } else {
      let inputValue = this.userVersionInputValue;
      if (inputValue) {
        this.userVersionList.push(inputValue);
      }
      this.userVersionInputVisible = false;
      this.userVersionInputValue = "";
    }
  }
  showInput(isTrainer) {
    if (isTrainer) {
      this.trainerInputVisible = true;
    } else {
      this.userVersionInputVisible = true;
    }
  }
  resetForm() {
    this.$refs.postForm.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _setting.updateSetting)(this.postForm.id, this.postForm);
      } else {
        await (0, _setting.addSetting)(this.postForm);
      }
      this.$notify({
        title: "Success",
        message: "Setting saved successfully",
        type: "success",
        duration: 2000
      });
      _router.default.push("/dashboard");
    } catch (err) {
      this.loading = false;
      console.error(err);
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "SettingDetail",
  components: {
    Dropzone: _index.default,
    AvatarUpload: _index2.default,
    PanThumb: _index3.default,
    VueSimpleRangeSlider: _vueSimpleRangeSlider.default
  }
})], default_1);
var _default = exports.default = default_1;