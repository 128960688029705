"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTrainingExpertise = exports.getTrainingExpertisesCount = exports.getTrainingExpertises = exports.getTrainingExpertiseById = exports.deleteTrainingExpertise = exports.defaultTrainingExpertiseData = exports.addTrainingExpertise = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultTrainingExpertiseData = exports.defaultTrainingExpertiseData = {
  id: 0,
  enabled: true,
  name: '',
  image: ''
};
const getTrainingExpertises = params => (0, _request.default)({
  url: '/training-expertise',
  method: 'get',
  params
});
exports.getTrainingExpertises = getTrainingExpertises;
const getTrainingExpertiseById = id => (0, _request.default)({
  url: `/training-expertise/${id}`,
  method: 'get'
});
exports.getTrainingExpertiseById = getTrainingExpertiseById;
const updateTrainingExpertise = (id, data) => (0, _request.default)({
  url: `/training-expertise/${id}`,
  method: 'patch',
  data
});
exports.updateTrainingExpertise = updateTrainingExpertise;
const deleteTrainingExpertise = StoreTrainingExpertisename => (0, _request.default)({
  url: `/training-expertise/${StoreTrainingExpertisename}`,
  method: 'delete'
});
exports.deleteTrainingExpertise = deleteTrainingExpertise;
const addTrainingExpertise = data => (0, _request.default)({
  url: '/training-expertise',
  method: 'post',
  data
});
exports.addTrainingExpertise = addTrainingExpertise;
const getTrainingExpertisesCount = params => (0, _request.default)({
  url: '/training-expertise/count',
  method: 'get',
  params
});
exports.getTrainingExpertisesCount = getTrainingExpertisesCount;