"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const TrainingExpertiseRouter = {
  path: '/trainingExpertise',
  component: _index.default,
  redirect: 'noredirect',
  name: 'trainingExpertiseManagement.title',
  meta: {
    roles: ['superadmin'],
    title: 'trainingExpertiseManagement.title',
    icon: 'create-dashboard'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/training-expertise/list.vue'))),
    name: 'trainingExpertiseManagement.trainingExpertise.manage',
    meta: {
      title: 'trainingExpertiseManagement.title',
      noCache: true,
      icon: 'create-dashboard',
      header: 'List of all categories',
      info: 'All trainingExpertise details are available here. You can search categories by applying filters on fields like trainingExpertise name. After clicking on trainingExpertise name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/training-expertise/manage.vue'))),
    name: 'trainingExpertiseManagement.trainingExpertise.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'trainingExpertiseManagement.trainingExpertise.manage',
      noCache: true,
      activeMenu: '/trainingExpertise/list',
      hidden: true,
      header: 'Edit trainingExpertise',
      info: 'You can edit trainingExpertise here'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/training-expertise/manage.vue'))),
    name: 'trainingExpertiseManagement.trainingExpertise.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'trainingExpertiseManagement.trainingExpertise.manage',
      noCache: true,
      activeMenu: '/trainingExpertise/list',
      hidden: true,
      header: 'Add trainingExpertise',
      info: 'You can create trainingExpertise here'
    }
  }]
};
var _default = exports.default = TrainingExpertiseRouter;