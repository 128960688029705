"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "upload-container"
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      accept: _vm.accept,
      action: "",
      "http-request": _vm.uploadFile,
      "before-remove": _vm.beforeRemove,
      "before-upload": _vm.beforeFileUpload,
      "on-error": _vm.onFileTransferError,
      "on-success": _vm.onFileTransferSuccess,
      "file-list": _vm.fileList,
      "max-size": _vm.maxSize,
      "list-type": "picture",
      multiple: _vm.multiple
    }
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    }
  }, [_vm._v(" Click to Upload ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;