"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "space-around"
    }
  }, [_c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      md: 20,
      lg: 20,
      xl: 20
    }
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("div", {
    staticClass: "items input-width"
  }, [_c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Group Name"
    },
    model: {
      value: _vm.groupName,
      callback: function ($$v) {
        _vm.groupName = $$v;
      },
      expression: "groupName"
    }
  })], 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item items",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")])], 1)]), _c("el-col", {
    attrs: {
      xs: 24,
      sm: 24,
      md: 6,
      lg: 6,
      xl: 6
    }
  }, [_c("el-row", {
    staticStyle: {
      "margin-top": "auto"
    },
    attrs: {
      type: "flex",
      justify: "end"
    }
  }, [_c("router-link", {
    attrs: {
      to: "/inbox/group-add"
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" Create New Group ")])], 1)], 1)], 1)], 1), _c("section", {
    staticClass: "table-container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "dragTable",
    attrs: {
      data: _vm.list,
      fit: "",
      "highlight-current-row": "",
      "show-header": false
    }
  }, [_c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("div", {
          on: {
            click: function ($event) {
              return _vm.handleGroupClick(scope.row);
            }
          }
        }, [_c("el-row", {
          attrs: {
            type: "flex",
            justify: "space-between"
          }
        }, [_c("el-col", {
          attrs: {
            xs: 24,
            sm: 24,
            md: 13,
            lg: 13,
            xl: 13
          }
        }, [_c("el-row", {
          attrs: {
            type: "flex",
            justify: "space-around"
          }
        }, [_c("el-col", {
          attrs: {
            span: 5
          }
        }, [scope.row.image ? _c("img", {
          staticClass: "chat-list-img",
          attrs: {
            src: scope.row.image
          }
        }) : _c("img", {
          staticClass: "chat-list-img",
          attrs: {
            src: require("@/assets/img/8-01.jpg")
          }
        })]), _c("el-col", {
          attrs: {
            span: 19
          }
        }, [_c("el-row", [_c("el-col", [_c("p", {
          staticClass: "group-details",
          staticStyle: {
            "font-size": "medium",
            "font-weight": "600"
          }
        }, [_vm._v(" " + _vm._s(scope.row.groupName) + " ")])]), _c("el-col", {
          staticStyle: {
            width: "60%"
          },
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 21
          }
        }, [_c("p", {
          staticClass: "group-details"
        }, [scope.row.lastMessage == "image" ? [_vm._v(" " + _vm._s(scope.row.lastUserName) + " : "), _c("i", {
          staticClass: "el-icon-picture"
        })] : scope.row.lastMessage == "video" ? [_vm._v(" " + _vm._s(scope.row.lastUserName) + ":"), _c("i", {
          staticClass: "el-icon-video-camera"
        })] : scope.row.lastMessage == "audio" ? [_vm._v(" " + _vm._s(scope.row.lastUserName) + ":"), _c("i", {
          staticClass: "el-icon-microphone"
        })] : scope.row.lastMessage === "pdf" ? [_vm._v(" " + _vm._s(scope.row.lastUserName) + ":"), _c("i", {
          staticClass: "el-icon-document"
        })] : [scope.row.lastUserName && scope.row.lastMessage ? _c("span", [_vm._v(" " + _vm._s(`${scope.row.lastUserName} : ${scope.row.lastMessage}`) + " ")]) : _vm._e()]], 2)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [scope.row.unreadCountCurrentUser > 0 ? _c("span", {
          staticClass: "notification",
          attrs: {
            id: "channel" + scope.row.id
          }
        }, [_vm._v(" " + _vm._s(scope.row.unreadCountCurrentUser))]) : _vm._e()])], 1)], 1), _c("el-col", [_c("p", {
          staticClass: "group-details",
          staticStyle: {
            color: "gray"
          }
        }, [_vm._v(" " + _vm._s(scope.row.lastMessageTime) + " ")])])], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;