"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    class: _vm.wrapperClass
  }, [_c("div", {
    class: _vm.messageClass
  }, [_vm.messageContent ? [_vm.message.type === 0 ? _c("div", [_vm._v(_vm._s(_vm.message.text))]) : _vm.message.type === 1 ? _c("img", {
    attrs: {
      src: _vm.message.image,
      alt: "Image message"
    }
  }) : _vm.message.type === 2 ? _c("audio", {
    attrs: {
      src: _vm.message.aucdio,
      controls: ""
    }
  }) : _vm.message.type === 3 ? _c("video", {
    attrs: {
      src: _vm.message.video,
      controls: ""
    }
  }) : _vm.message.type === 4 ? _c("a", {
    attrs: {
      href: _vm.message.pdf,
      target: "_blank",
      download: ""
    }
  }, [_vm._v(" Download PDF ")]) : _c("div", [_vm._v("Unsupported message type")])] : _vm._e()], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;