"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _diseases = require("@/api/diseases");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _keys = require("@/api/keys");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.langId = 0;
    this.serviceLoading = false;
    this.serviceList = [];
    this.list = [];
    this.listLoading = true;
    this.newList = [];
    this.oldList = [];
    this.downloadLoading = false;
    this.showSortingOption = false;
    this.renderComponent = true;
    this.showCreatedDate = false;
    this.loading = false;
    this.additionalLoading = false;
    this.uploadingstatus = false;
    this.addAccess = true;
    this.listQuery = {
      page: 1,
      limit: 10,
      sort: "id,ASC",
      filter: {
        name: "$contL",
        enabled: "eq"
      }
    };
    this.sortOptions = [{
      label: " Ascending",
      key: "id,ASC"
    }, {
      label: " Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  created() {
    // if (this.$route.path == '/orderMenu/' + this.$route.path.split('/').pop() + '/language/' + this.$route.path.split('/').pop()) {
    //   var id:any = this.$route.path.split('/').pop()
    //   this.listQuery.serviceId = id
    //   this.addAccess = false
    // }
    this.listQuery.page = 1;
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _diseases.getdiseases)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async onPriortyChange() {
    if (this.showSortingOption) {
      this.fetchAllRecored(true);
    } else {
      this.renderComponent = false;
      this.sortable.options.disabled = true;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
  async fetchAllRecored(checkForFirstTime) {
    try {
      this.listLoading = true;
      const data = await (0, _diseases.getdiseases)((0, _index2.getQuery)({
        page: 1,
        limit: 100000,
        sort: "priority,ASC",
        filter: {
          enabled: "eq"
        }
      }));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
      if (checkForFirstTime) {
        this.oldList = this.list.map(v => v.id);
        this.newList = this.oldList.slice();
        this.$nextTick(this.setSort);
        ;
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  setSort() {
    const temp = this.$refs.dragTable;
    const el = temp.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: "sortable-ghost",
      setData: function (dataTransfer) {
        dataTransfer.setData("Text", "");
      },
      onEnd: evt => {
        const targetRow = this.list.splice(Number(evt.oldIndex), 1)[0];
        this.list.splice(Number(evt.newIndex), 0, targetRow);
        const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
        this.newList.splice(evt.newIndex, 0, tempIndex);
        console.log(this.newList);
        this.updateOrder();
      }
    });
  }
  async updateOrder() {
    try {
      const updateCheck = await (0, _diseases.updateOrder)(this.newList);
      this.fetchAllRecored(false);
    } catch (error) {}
  }
  async uploadAdditionalFile(param) {
    var fileObj = param.file;
    var form = new FormData();
    form.append("csv", fileObj);
    await (0, _diseases.uploadExcel)(this.langId, form).then(res => {
      this.$message({
        type: "success",
        message: "Language Updated Succesfully"
      });
    });
    this.additionalLoading = false;
  }
  // async uploadCsv(files: any) {
  //   const data = new FormData()
  //   data.append('csv', files.file)
  //   await uploadExcel(this.langId,data)
  //   .then((res)=>{
  //     
  //     console.log(res);
  //   })
  // }
  beforeUpload(file) {
    const doc = file.type == "application/json" ? true : false;
    if (!doc) {
      this.$message.error("Only json file upload");
    }
    return doc;
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  handleDelete(data) {
    if (data) {
      this.$confirm('Do you want to delete this banner?', this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => {
        (0, _diseases.deletediseases)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      });
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  async handleDownload(languageid, languageName) {
    this.downloadLoading = true;
    const filterData = [];
    let query = {
      languageId: languageid,
      enabled: true,
      sort: "keyId,ASC",
      filter: {
        languageId: "eq"
      }
    };
    let updatedKeyValue = [];
    const dataKeyV = await (0, _keys.getKeysValue)((0, _index2.getQuery)(query));
    const keysData = await (0, _keys.getKeyss)({
      sort: "id,ASC"
    });
    dataKeyV.forEach((key, index) => {
      filterData.push({
        id: keysData[index].id,
        key: keysData[index].name,
        defaultValue: key.defaultValue,
        languageValue: key.languageValue
      });
    });
    const tHeader = ["keyId", "key", "defaultValue", "languageValue"];
    const filterVal = ["id", "key", "defaultValue", "languageValue"];
    const data = (0, _index2.formatJson)(filterVal, filterData);
    (0, _excel.exportJson2Excel)(tHeader, data, languageName);
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "languageList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;