"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Image",
      prop: "image"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      formField: _vm.postForm.image
    },
    on: {
      "handle-upload": _vm.updateImage,
      "handle-remove": _vm.removeImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 1000 * 1000 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Video",
      prop: "video"
    }
  }, [_c("upload-video", {
    key: _vm.renderKey,
    attrs: {
      formField: _vm.postForm.video
    },
    on: {
      "handle-upload": _vm.updateCelebrityMedia,
      "handle-remove": _vm.removeCelebrityMedia
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image or Video of dimension 1000 * 1000 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;