// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\";\n/* Variables */\n\n/* Mixins */\n.head-tab {\n  display: flex;\n  flex-direction: row;\n  justify-content: start;\n}\n.head-tab :nth-child(1) {\n  justify-content: end;\n}\n.el-tabs__item {\n  font-size: 18px;\n  height: 30px;\n}\n.tab-container {\n  display: flex;\n  justify-content: space-evenly;\n  margin: auto;\n}\n.tab-container div {\n  font-size: 16px;\n}\n.edit-input {\n  padding-right: 100px;\n}\n.dialog {\n  width: 20%;\n}\n.el-tabs__nav {\n  height: 60px;\n}\n.tab-icon {\n  width: 40px;\n  margin: 5px;\n}\n.userButton {\n  cursor: pointer;\n}\n.cancel-btn {\n  position: absolute;\n  right: 15px;\n  top: 10px;\n}\n.el-icon-s-order {\n  font-size: 30px;\n  height: 50px;\n}\n.el-icon-s-claim {\n  font-size: 30px;\n  height: 50px;\n}\n.el-icon-s-release {\n  font-size: 30px;\n  height: 50px;\n}\n.el-icon-refresh {\n  font-size: 30px;\n  height: 50px;\n}\n.card-body {\n  padding: 16px;\n}\n\n/* Style for each detail item */\n.detail-item {\n  margin-bottom: 10px;\n  line-height: 1.6;\n  font-size: 14px;\n}\n\n/* Bold labels for better readability */\n.detail-item b {\n  font-weight: 600;\n  margin-right: 5px;\n}\n\n/* Style for the list of languages, workouts, and diseases */\n.skills-list {\n  list-style-type: none;\n  padding-left: 20px;\n  margin: 8px 0;\n}\n.skills-list li {\n  position: relative;\n}\n.skills-list li::before {\n  content: \"•\";\n  position: absolute;\n  left: -15px;\n}\n.kyc-image-container {\n  margin-bottom: 20px; /* Space between image containers */\n  text-align: left; /* Align text to the left */\n}\n.kyc-image {\n  display: block; /* Images are block-level to take up the full width of the container */\n  max-width: 100%; /* Ensures the image is fully contained within its parent */\n  height: auto; /* Keeps the aspect ratio of the image */\n  margin-top: 5px; /* Space between label and image */\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#000",
	"menuText": "#fff",
	"menuActiveText": "#90d86a"
};
module.exports = exports;
