"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const planRouter = {
  path: '/plan',
  component: _index.default,
  redirect: 'noredirect',
  name: 'plan Management',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'planManagement.title',
    icon: 'schedule'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */'@/views/plan/list.vue'))),
    name: 'plan',
    meta: {
      title: 'planManagement.title',
      noCache: true,
      icon: 'schedule'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/plan/manage.vue'))),
    name: 'Edit Admin',
    props: {
      isEdit: true
    },
    meta: {
      title: 'planManagement.plan.manage',
      noCache: true,
      activeMenu: '/plan/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/plan/manage.vue'))),
    name: 'Add plan',
    props: {
      isEdit: false
    },
    meta: {
      title: 'planManagement.plan.manage',
      noCache: true,
      activeMenu: '/plan/list',
      hidden: true
    }
  }]
};
var _default = exports.default = planRouter;