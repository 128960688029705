"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _country = require("@/api/country");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.newList = [];
    this.oldList = [];
    this.showSortingOption = false;
    this.renderComponent = true;
    this.listQuery = {
      page: 1,
      limit: 10,
      sort: 'id,DESC',
      filter: {
        name: '$contL',
        enabled: 'eq'
      }
    };
    this.sortOptions = [{
      label: 'country Ascending',
      key: 'id,ASC'
    }, {
      label: 'country Descending',
      key: 'id,DESC'
    }];
    // private visibilityOptions = [
    //   { key: true, value: 'On' },
    //   { key: false, value: 'Off' }
    // ];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  created() {
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _country.getcountry)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  async visibiltyUpdate(id, visibility) {
    const data = await (0, _country.getcountryById)(id);
    data.visibility = visibility;
    (0, _country.updatecountry)(id, data);
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc('table.deleteConfirmation', undefined, {
        name: data.name
      }), this.$tc('table.delete'), {
        confirmButtonText: this.$tc('table.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(() => {
        (0, _country.deletecountry)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$tc('table.deleteCanceled')
        });
      });
    }
  }
  async getAllList() {
    try {
      this.listLoading = true;
      const data = await (0, _country.getcountry)((0, _index2.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async onPriortyChange() {
    if (this.showSortingOption) {
      this.fetchAllRecored(true);
    } else {
      this.renderComponent = false;
      this.sortable.options.disabled = true;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
  async fetchAllRecored(checkForFirstTime) {
    try {
      this.listLoading = true;
      const data = await (0, _country.getcountry)((0, _index2.getQuery)({
        page: 1,
        limit: 100000,
        sort: 'priority,ASC',
        filter: {
          enabled: 'eq'
        }
      }));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
      if (checkForFirstTime) {
        this.oldList = this.list.map(v => v.id);
        this.newList = this.oldList.slice();
        this.$nextTick(this.setSort);
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  setSort() {
    const temp = this.$refs.dragTable;
    const el = temp.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: 'sortable-ghost',
      setData: function (dataTransfer) {
        dataTransfer.setData('Text', '');
      },
      onEnd: evt => {
        const targetRow = this.list.splice(Number(evt.oldIndex), 1)[0];
        this.list.splice(Number(evt.newIndex), 0, targetRow);
        const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
        this.newList.splice(evt.newIndex, 0, tempIndex);
        console.log(this.newList);
        this.updateOrder();
      }
    });
  }
  async updateOrder() {
    try {
      const updateCheck = await (0, _country.updateOrder)(this.newList);
      this.fetchAllRecored(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  async handleDownload(paid) {
    this.downloadLoading = true;
    const fliterData = [];
    const page = this.listQuery.page;
    const limit = this.listQuery.limit;
    this.listQuery.page = 1;
    this.listQuery.limit = 100000;
    const dataAll = await (0, _country.getcountry)((0, _index2.getQuery)(this.listQuery));
    const listAll = dataAll.data;
    this.listQuery.page = page;
    this.listQuery.limit = limit;
    const tHeader = ['id', 'fullName', 'email', 'contactNumber', 'type', 'isMonthlyNewsletter', 'planExipiryDate'];
    const filterVal = ['id', 'fullName', 'email', 'contactNumber', 'type', 'isMonthlyNewsletter', 'planExipiryDate'];
    const data = (0, _index2.formatJson)(filterVal, fliterData);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.userManagement.country.title'));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'countryList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;