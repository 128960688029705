"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _vuePropertyDecorator = require("vue-property-decorator");
var _keys = require("@/api/keys");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
// import vueJsonEditor from "vue-json-editor";
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = Object.assign({}, _keys.defaultKeysValueData);
    this.loading = false;
    this.additionalLoading = false;
    this.uploadingstatus = false;
    this.serviceList = [];
    this.rules = {
      languageName: [{
        required: true,
        message: "Please enter JSON file",
        trigger: "blur"
      }, {
        pattern: "^[a-zA-Z0-9_]",
        message: "Space not allowed at start of JSON file",
        trigger: ["blur", "change"]
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _keys.defaultKeysValueData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _keys.getKeysValueById)(id);
      this.postForm = data;
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  onReaderLoad(event) {
    console.log(event.target.result);
    var obj = JSON.parse(event.target.result);
  }
  ViewFile(event) {
    var reader = new FileReader();
    // reader.onload = this.onReaderLoad();
    reader.readAsText(event.target.files[0]);
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _keys.updateKeysValue)(this.postForm.id, this.postForm).then(res => {
          this.$notify({
            title: "Success",
            message: "language added successfully",
            type: "success",
            duration: 2000
          });
          _router.default.push("/language/key-value/" + this.$route.query.languageId);
        });
      } else {
        // await createKeys(this.postForm);
      }
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "keyValueDetail",
  components: {
    uploadFile: _common.uploadFile
  }
})], default_1);
var _default = exports.default = default_1;