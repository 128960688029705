"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateposttatus = exports.updatepostAddress = exports.updatepost = exports.register = exports.logout = exports.login = exports.logPost = exports.getpostcount = exports.getpostReportbyGender = exports.getpostDetailById = exports.getpostByIdAddress = exports.getpostById = exports.getpostByEmail = exports.getpost = exports.getLogs = exports.getCompany = exports.deletepost = exports.defaultPostData = exports.checkpostExist = exports.checkEmailExist = exports.addpost = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultPostData = exports.defaultPostData = {
  id: 0,
  enabled: true,
  isDeleted: false,
  title: '',
  priority: 0,
  media: '',
  likeByUserIds: [],
  likeByAdminIds: [],
  adminId: null,
  userId: null,
  likeCount: 0,
  commentCount: 0
};
const getpost = params => (0, _request.default)({
  url: '/post',
  method: 'get',
  params
});
exports.getpost = getpost;
const addpost = data => (0, _request.default)({
  url: '/post',
  method: 'post',
  data
});
exports.addpost = addpost;
const getpostcount = () => (0, _request.default)({
  url: '/post/count-user',
  method: 'get'
});
exports.getpostcount = getpostcount;
const checkpostExist = data => (0, _request.default)({
  url: '/post/username-check',
  method: 'post',
  data
});
exports.checkpostExist = checkpostExist;
const checkEmailExist = data => (0, _request.default)({
  url: '/post/email-check',
  method: 'post',
  data
});
exports.checkEmailExist = checkEmailExist;
const getpostById = id => (0, _request.default)({
  url: `/post/detail/${id}`,
  method: 'get'
});
exports.getpostById = getpostById;
const getpostDetailById = id => (0, _request.default)({
  url: `/post/${id}`,
  method: 'get'
});
exports.getpostDetailById = getpostDetailById;
const getpostByIdAddress = id => (0, _request.default)({
  url: `/post/detail/${id}`,
  method: 'get'
});
exports.getpostByIdAddress = getpostByIdAddress;
const getpostByEmail = adminemail => (0, _request.default)({
  url: `/post/${adminemail}`,
  method: 'get'
});
exports.getpostByEmail = getpostByEmail;
const updatepost = (id, data) => (0, _request.default)({
  url: `/post/${id}`,
  method: 'patch',
  data
});
exports.updatepost = updatepost;
const updatepostAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updatepostAddress = updatepostAddress;
const updateposttatus = data => (0, _request.default)({
  url: `/post/update-status`,
  method: 'post',
  data
});
exports.updateposttatus = updateposttatus;
const deletepost = adminname => (0, _request.default)({
  url: `/post/${adminname}`,
  method: 'delete'
});
exports.deletepost = deletepost;
const login = data => (0, _request.default)({
  url: '/post/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: '/post/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/post/add',
  method: 'post',
  data
});
exports.register = register;
const getCompany = params => (0, _request.default)({
  url: '/post-roles',
  method: 'get',
  params
});
exports.getCompany = getCompany;
const logPost = data => (0, _request.default)({
  url: '/user-logs',
  method: 'post',
  data
});
exports.logPost = logPost;
const getLogs = params => (0, _request.default)({
  url: '/user-logs',
  method: 'get',
  params
});
exports.getLogs = getLogs;
const getpostReportbyGender = params => (0, _request.default)({
  url: '/post/gender-count',
  method: 'get',
  params
});
exports.getpostReportbyGender = getpostReportbyGender;