"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAdminsRole = exports.getRoutes = exports.getAdminsRoles = exports.getAdminsRoleById = exports.deleteAdminsRole = exports.defaultAdminsRoleData = exports.createAdminsRole = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultAdminsRoleData = exports.defaultAdminsRoleData = {
  id: 0,
  enabled: true,
  name: ''
};
const getAdminsRoles = params => (0, _request.default)({
  url: '/admins-roles',
  method: 'get',
  params
});
exports.getAdminsRoles = getAdminsRoles;
const getAdminsRoleById = id => (0, _request.default)({
  url: `/admins-roles/${id}`,
  method: 'get'
});
exports.getAdminsRoleById = getAdminsRoleById;
const updateAdminsRole = (id, data) => (0, _request.default)({
  url: `/admins-roles/${id}`,
  method: 'patch',
  data
});
exports.updateAdminsRole = updateAdminsRole;
const deleteAdminsRole = id => (0, _request.default)({
  url: `/admins-roles/${id}`,
  method: 'delete'
});
exports.deleteAdminsRole = deleteAdminsRole;
const createAdminsRole = data => (0, _request.default)({
  url: '/admins-roles/',
  method: 'post',
  data
});
exports.createAdminsRole = createAdminsRole;
const getRoutes = params => (0, _request.default)({
  url: '/routes',
  method: 'get',
  params
});
exports.getRoutes = getRoutes;