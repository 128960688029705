"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'component': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M0 0h54.9v54.9H0V0zm0 73.1h54.9V128H0V73.1zm73.1 0H128V128H73.1V73.1zM100.6 55a27.4 27.4 0 100-54.9 27.4 27.4 0 000 54.9z"/>'
  }
});