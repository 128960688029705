"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateReminder = exports.updateOrder = exports.getReminderInfo = exports.getReminderCount = exports.getReminderById = exports.getReminderByEmail = exports.getReminder = exports.deleteReminder = exports.defaultRemendersData = exports.addReminder = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultRemendersData = exports.defaultRemendersData = {
  id: 0,
  enabled: true,
  userId: null,
  time: '',
  categories: [],
  isSunday: false,
  isMonday: false,
  isTuesday: false,
  isWednesday: false,
  isThursday: false,
  isFriday: false,
  isSaturday: false,
  repeat: false,
  status: 0,
  priority: 0
};
const getReminder = params => (0, _request.default)({
  url: '/reminder',
  method: 'get',
  params
});
exports.getReminder = getReminder;
const getReminderById = id => (0, _request.default)({
  url: `/reminder/${id}`,
  method: 'get'
});
exports.getReminderById = getReminderById;
const getReminderInfo = data => (0, _request.default)({
  url: '/reminder/me',
  method: 'post',
  data
});
exports.getReminderInfo = getReminderInfo;
const getReminderByEmail = Reminderemail => (0, _request.default)({
  url: `/reminder/${Reminderemail}`,
  method: 'get'
});
exports.getReminderByEmail = getReminderByEmail;
const updateReminder = (id, data) => (0, _request.default)({
  url: `/reminder/${id}`,
  method: 'patch',
  data
});
exports.updateReminder = updateReminder;
const deleteReminder = id => (0, _request.default)({
  url: `/reminder/${id}`,
  method: 'delete'
});
exports.deleteReminder = deleteReminder;
const addReminder = data => (0, _request.default)({
  url: '/reminder/',
  method: 'post',
  data
});
exports.addReminder = addReminder;
const getReminderCount = params => (0, _request.default)({
  url: '/reminder/count',
  method: 'get',
  params
});
exports.getReminderCount = getReminderCount;
const updateOrder = data => (0, _request.default)({
  url: '/reminder/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;