"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _plan = require("@/api/plan");
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _utils = require("@/utils");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index3 = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _router = _interopRequireDefault(require("../../../router"));
var _country = require("@/api/country");
var _vue2Editor = require("vue2-editor");
var _planMaster = require("@/api/planMaster");
// import { getColleges } from "@/api/college";

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = Object.assign({}, _plan.defaultPlanData);
    this.loading = false;
    this.list = [];
    this.showImageUpload = false;
    this.renderKey = 0;
    this.countryId = 1;
    this.countryList = [];
    this.languageList = [];
    this.workOutList = [];
    this.diseaseList = [];
    this.versionList = [];
    this.inputValue = "";
    this.includeList = [];
    this.inputVisible = false;
    this.userTypeList = [{
      label: "In Campus",
      value: 1
    }, {
      label: "Off Campus",
      value: 0
    }];
    this.genderList = [{
      label: "Male",
      value: 0
    }, {
      label: "Female",
      value: 1
    }, {
      label: "Others",
      value: 2
    }];
    this.heighTypeList = [{
      label: "FT",
      value: 0
    }, {
      label: "CM",
      value: 1
    }];
    this.weightTypeList = [{
      label: "KG",
      value: 0
    }, {
      label: "LB",
      value: 1
    }];
    this.image = "https://via.placeholder.com/154";
    this.listQuery = {
      sort: "id,DESC",
      enabled: true,
      filter: {
        enabled: "eq"
      }
    };
    this.rules = {
      title: [{
        required: true,
        message: "Please Enter Title",
        trigger: ["blur", "change"]
      }],
      lastName: [{
        required: true,
        message: "Please Enter Last Name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      email: [{
        required: true,
        message: "Please enter email",
        trigger: "blur"
      }, {
        type: "email",
        message: "Please enter correct email",
        trigger: ["blur", "change"]
      }],
      noOfMonths: [{
        required: true,
        message: "Please Enter Number of Months",
        trigger: "blur"
      }],
      noOfDays: [{
        required: true,
        message: "Please Enter Number of Days",
        trigger: "blur"
      }],
      price: [{
        required: true,
        message: "Please Enter Price",
        trigger: "blur"
      }],
      totalSession: [{
        required: true,
        message: "Please Enter Total Session",
        trigger: "blur"
      }],
      totalNutrientSession: [{
        required: true,
        message: "Please Enter Total Nutrient Session",
        trigger: "blur"
      }],
      totalTrainingSession: [{
        required: true,
        message: "Please Enter Total Training Session",
        trigger: "blur"
      }],
      totalPLanFreeze: [{
        required: true,
        message: "Please Enter Total No of days Freeze",
        trigger: "blur"
      }],
      noOfDaysReschedule: [{
        required: true,
        message: "Please Enter Total No of days Reschedule",
        trigger: "blur"
      }],
      noOfDaysCancelation: [{
        required: true,
        message: "Please Enter Total No of days cancellation",
        trigger: "blur"
      }],
      description: [{
        required: true,
        message: "Please Enter Description",
        trigger: "blur"
      }],
      // tags: [
      //   {
      //     required: true,
      //     message: "Please Enter Tags",
      //     trigger: "blur",
      //   },
      // ],
      // image: [
      //   {
      //     required: true,
      //     message: 'Profile picture is required'
      //   }
      // ],
      planTypeIds: [{
        required: true,
        message: "Please Select Includes",
        trigger: ["blur", "change"]
      }],
      gender: [{
        required: true,
        message: "Please Select Gender",
        trigger: ["blur", "change"]
      }],
      image: [{
        required: true,
        message: "Please Select Background Images",
        trigger: ["blur", "change"]
      }],
      innerImage: [{
        required: true,
        message: "Please Select Inner Images",
        trigger: ["blur", "change"]
      }],
      age: [{
        required: true,
        message: "Please Enter Age",
        trigger: ["blur", "change"]
      }],
      height: [{
        required: true,
        message: "Please Enter Height",
        trigger: ["blur", "change"]
      }],
      heightType: [{
        required: true,
        message: "Please Select HeightType",
        trigger: ["blur", "change"]
      }],
      weight: [{
        required: true,
        message: "Please Enter Weight",
        trigger: ["blur", "change"]
      }],
      weightType: [{
        required: true,
        message: "Please Select WeightType",
        trigger: ["blur", "change"]
      }],
      countryId: [{
        required: true,
        message: "Please Select Country",
        trigger: ["blur", "change"]
      }],
      cycleFrom: [{
        required: true,
        message: "Please Select Date of Cycle From",
        trigger: ["blur", "change"]
      }],
      cycleTo: [{
        required: true,
        message: "Please Select Date of Cycle To",
        trigger: ["blur", "change"]
      }],
      languageIds: [{
        required: true,
        message: "Please Select Language",
        trigger: ["blur", "change"]
      }],
      workoutIds: [{
        required: true,
        message: "Please Select Workout",
        trigger: ["blur", "change"]
      }],
      diseaseIds: [{
        required: true,
        message: "Please Select Disease",
        trigger: ["blur", "change"]
      }],
      // type: [
      //   {
      //     required: true,
      //     message: "Please Select Plan Type",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      collegeId: [{
        required: true,
        message: "Please Select College Name",
        trigger: ["blur", "change"]
      }],
      pincode: [{
        required: true,
        message: "Pincode is required"
      }],
      companyName: [{
        required: true,
        message: "Company Name is required"
      }],
      companyBio: [{
        required: true,
        message: "Company Bio is required"
      }],
      companynoOfMonths: [{
        required: true,
        message: "Company Contact Number is required"
      }],
      companyAddress: [{
        required: true,
        message: "Company Address is required"
      }]
    };
    this.collegelist = [];
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onClose() {
    this.showImageUpload = false;
  }
  deleteImage() {
    this.postForm.image = "";
  }
  updateBannerImage(res) {
    this.postForm.innerImage = res;
  }
  removeBannerImage() {
    this.postForm.innerImage = '';
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], "img." + blob.type.split("/")[1], {
        type: "image/png"
      });
      data.append("file", file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.image = res;
      });
    });
  }
  changeSession() {
    this.postForm.totalSession = parseInt(this.postForm.totalTrainingSession) + parseInt(this.postForm.totalNutrientSession);
  }
  get lang() {
    return _app.AppModule.language;
  }
  handleRemoveTag(tag) {
    console.log('Tag removed:', tag);
    if (tag == 1) {
      this.postForm.totalSession = parseInt(this.postForm.totalTrainingSession);
      this.postForm.totalNutrientSession = 0;
    }
    if (tag == 2) {
      this.postForm.totalSession = parseInt(this.postForm.totalNutrientSession);
      this.postForm.totalTrainingSession = 0;
    }
    // else{
    //   this.postForm.totalTrainingSession =0;
    //   this.postForm.totalNutrientSession =0;
    //   this.postForm.totalSession =0;
    // }
    // Perform any additional logic here
  }
  created() {
    // this.getCountryData();
    // this.getLanguageData();
    //  this.getWorkOutData();
    //  this.getDiseasesData();
    this.getIncludeList();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _plan.defaultPlanData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async getCountryData() {
    const data = await (0, _country.getcountry)((0, _utils.getQuery)(this.listQuery));
    this.countryList = data;
  }
  async getIncludeList() {
    try {
      const data = await (0, _planMaster.getIncludeMaster)((0, _utils.getQuery)(this.listQuery));
      this.includeList = data;
      // this.total = data.total;
      // this.listLoading = false;
    } catch (error) {
      // this.listLoading = false;
    }
  }
  async fetchData(id) {
    try {
      const data = await (0, _plan.getPlanDetailById)(id);
      this.renderKey++;
      // this.postForm.image=data.image;
      this.postForm = data;
      if (data.tags == null) {
        this.postForm.tags = [];
        this.versionList = [];
      } else {
        this.postForm.tags = data.tags;
        this.versionList = this.postForm.tags;
      }
      // this.getCollegeList();
      this.postForm.image = this.postForm.image.trim();
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  updateimageImage(res) {
    this.postForm.image = res;
  }
  removeimageImage() {
    this.postForm.image = "";
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.renderKey--;
    this.$refs.postForm.resetFields();
    this.postForm.image = "";
  }
  handleClose(tag) {
    this.versionList.splice(this.versionList.indexOf(tag), 1);
  }
  handleInputConfirm() {
    let inputValue = this.inputValue;
    if (inputValue) {
      this.versionList.push(inputValue);
    }
    this.inputVisible = false;
    this.inputValue = "";
  }
  showInput() {
    this.inputVisible = true;
  }
  async saveForm() {
    try {
      this.postForm.image = this.postForm.image;
      this.loading = true;
      if (this.isEdit) {
        this.postForm.tags = this.versionList;
        await (0, _plan.updatePlan)(this.postForm.id, this.postForm).then(res => {
          _router.default.push("/plan/list");
          this.resetForm();
        });
      } else {
        this.postForm.tags = this.versionList;
        await (0, _plan.addPlan)(this.postForm);
      }
      this.$notify({
        title: "Success",
        message: "Plan saved successfully",
        type: "success",
        duration: 2000
      });
      _router.default.push("/plan/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "PlanDetail",
  components: {
    PanThumb: _index2.default,
    UploadImage: _index3.default,
    AvatarUpload: _index.default,
    VueEditor: _vue2Editor.VueEditor
  }
})], default_1);
var _default = exports.default = default_1;