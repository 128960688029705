"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _users = require("@/api/users");
var _moment = _interopRequireDefault(require("moment"));
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _admin = require("@/store/modules/admin");
var _vue = require("vue");
var _common = require("@/api/common");
var _axios = _interopRequireDefault(require("axios"));
var _router = _interopRequireDefault(require("@/router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _country = require("@/api/country");
var _language = require("@/api/language");
var _utils = require("@/utils");
var _index3 = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _tagsView = require("@/store/modules/tags-view");
var _workoutmaster = require("@/api/workoutmaster");
var _diseases = require("@/api/diseases");
// import { library } from "@fortawesome/fontawesome-svg-core";
// import {
//   faHatWizard,
//   faMobile,
//   faMobileAlt,
//   faMobileButton,
//   faMobileRetro,
// } from "@fortawesome/free-solid-svg-icons";
// import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.activeName = "about";
    this.viewMore = false;
    this.userId = null;
    this.userDetail = null;
    this.value = (0, _vue.ref)(new Date());
    this.compositeCardDialogue = false;
    this.availibilitycalendarDialog = false;
    this.compositeImages = [];
    this.elm = document.getElementById("composite");
    this.profileLoading = false;
    this.drawer = false;
    this.direction = "rtl";
    this.srcList = []; //to store images array from chat
    this.compositeloading = false;
    this.db = null;
    this.messages = [];
    this.message = "";
    this.audioChunks = []; //to store audio files in chats
    this.type = 0;
    this.dataCell = [];
    this.chatLoading = false;
    this.observer = null;
    this.userExperinces = [];
    this.userSelfAudi = [];
    this.introlinkconverted = [];
    //variable to upload images
    this.dialogImageUrl = "";
    this.image = "https://via.placeholder.com/154";
    this.fileList = [];
    this.editList = [];
    this.uploadImageDialog = false;
    this.imagelist = [];
    this.dialogVisible = false;
    this.uploadingimage = true;
    this.confirmuploadbtn = false;
    this.renderKey = 0;
    this.loading = false;
    // private postForm: IpostFormData = {
    //   image: [],
    // };
    this.languageList = [];
    this.countryList = [];
    this.genderList = [{
      label: "Male",
      value: 0
    }, {
      label: "Female",
      value: 1
    }, {
      label: "Don’t wish to dislose",
      value: 2
    }];
    this.heighTypeList = [{
      label: "FT",
      value: 0
    }, {
      label: "CM",
      value: 1
    }];
    this.weightTypeList = [{
      label: "KG",
      value: 0
    }, {
      label: "LB",
      value: 1
    }];
    this.listQuery = {
      sort: "id,DESC",
      enabled: true,
      filter: {
        enabled: "eq"
      }
    };
    this.workOutList = [];
    this.diseaseList = [];
    this.postForm = Object.assign({}, _users.defaultUserData);
    this.chatNotification = {
      id: 0,
      userName: "",
      message: "",
      type: 0,
      groupChatId: "",
      profilePicture: ""
    };
  }
  created() {
    this.getCountryData();
    this.getLanguageData();
    this.getWorkOutData();
    this.getDiseasesData();
    this.fetchData(Number(this.$route.params.id));
    this.UserId = Number(this.$route.params.id);
    //   if (this.isEdit) {
    //     const id = this.$route.params && this.$route.params.id;
    //     this.fetchData(parseInt(id));
    //   } else {
    //     this.postForm = Object.assign({}, defaultUserData);
    //   }
    //   this.tempTagView = Object.assign({}, this.$route);
  }
  async getWorkOutData() {
    const data = await (0, _workoutmaster.getWorkoutMaster)((0, _utils.getQuery)(this.listQuery));
    this.workOutList = data;
  }
  async getDiseasesData() {
    const data = await (0, _diseases.getdiseases)((0, _utils.getQuery)(this.listQuery));
    this.diseaseList = data;
    console.log("workOutList List is:", this.diseaseList);
  }
  mounted() {
    this.profileLoading = true;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 900) {
      this.direction = "btt";
    } else {
      this.direction = "rtl";
    }
    //this.profileLoading = false;
  }
  onResize() {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 900) {
      this.direction = "btt";
    } else {
      this.direction = "rtl";
    }
  }
  scrollBottom() {
    let lastChatBox = document.querySelectorAll(".message");
    // lastChatBox = lastChatBox[lastChatBox.length - 1];
    lastChatBox.scrollIntoView({
      behavior: "smooth"
    });
  }
  async getUser(id) {
    this.profileLoading = true;
    const data = await (0, _users.getUserDetailById)(id);
    //  console.log("friends is",data.friendRequestIds?.length)
    this.userDetail = data;
    this.userDetail.profilePicture = data.profilePicture;
    console.log("user details", this.userDetail);
    this.userDetail.ageConverted = "";
    if ((0, _moment.default)().diff((0, _moment.default)(this.userDetail.dob, "DD-MM-YYYY"), "years") < 1) {
      this.userDetail.ageConverted = (0, _moment.default)().diff((0, _moment.default)(this.userDetail.dob, "DD-MM-YYYY"), "years") + "." + (0, _moment.default)().diff((0, _moment.default)(this.userDetail.dob, "DD-MM-YYYY"), "months");
    } else {
      this.userDetail.ageConverted = (0, _moment.default)().diff((0, _moment.default)(this.userDetail.dob, "DD-MM-YYYY"), "years");
    }
    // if (this.userDetail.auditionLink !== null) {
    //   this.userDetail.auditionLink.forEach((link: any) => {
    //     this.userSelfAudi.push({ link: link.link, title: link.title });
    //   });
    // }
    // if (this.userDetail.introductionLink !== null) {
    //   this.userDetail.introductionLink.forEach((link: any) => {
    //   });
    // }
    // if (this.userDetail.notAvailable.length != 0) {
    //   this.availibility = this.userDetail.notAvailable;
    // }
    // this.gridData = this.userDetail.otherSkills.slice(7);
    this.profileLoading = false;
  }
  updateProfilePictureImage(res) {
    this.postForm.profilePicture = res;
  }
  removeProfilePictureImage() {
    this.postForm.profilePicture = "";
  }
  sentOrReceived(msg) {
    return msg.idFrom == _admin.AdminModule.id.toString() ? "sent" : "received";
    // return userUID === this.user.uid ? "sent" : "received";
  }
  sentOrReceivedUserImage(msg) {
    return msg.idFrom == _admin.AdminModule.id.toString() ? _admin.AdminModule.avatar : this.userDetail.profilePicture;
    //
    //
  }
  handleClick(tab, event) {
    switch (tab.name) {
      case "about":
        {
          break;
        }
      case "physicaldetails":
        {
          break;
        }
      case "photo":
        {
          break;
        }
      case "video":
        {
          break;
        }
      case "voiceartist":
        {
          break;
        }
      case "experience":
        {
          break;
        }
    }
  }
  handleCancel() {
    this.fileList = [];
    // this.postForm.image = [];
    this.imagelist = [];
    let imgDragger = document.querySelector(".el-upload--picture-card ");
    imgDragger.style.display = "block";
  }
  handleDrawerClose(done) {
    this.drawer = false;
  }
  saveAs(uri, filename) {
    var link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = uri;
      link.download = filename;
      //Firefox requires the link to be in the body
      document.body.appendChild(link);
      //simulate click
      link.click();
      //remove the link when done
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  }
  // private async uploadImages(file: any, fileList: any) {
  //   const img = new Image();
  //   img.onload = () => {
  //     this.fileList = fileList;
  //     this.editList.push(file);
  //     let imgDragger: any = document.querySelector(".el-upload--picture-card ");
  //     if (this.fileList.length >= 3) {
  //       imgDragger.style.display = "none";
  //     } else {
  //       imgDragger.style.display = "block";
  //     }
  //   };
  //   img.src = file.url;
  // }
  beforeUpload(file) {
    let _this = this;
    const isSize = new Promise(function (resolve, reject) {
      let width = 654;
      let height = 270;
      let img = new Image();
      img.onload = function () {
        let valid = img.width === width && img.height === height;
        valid ? resolve() : reject();
      };
      img.src = file.src;
    }).then(() => {
      return file;
    }, () => {
      _this.$message.error("The image size is limited to 654 x 270, the size cannot exceed 1MB");
      return Promise.reject();
    });
    return isSize;
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPG) {
      this.$message.error("Avatar picture must be JPG format!");
    }
    if (!isLt2M) {
      this.$message.error("Avatar picture size can not exceed 2MB!");
    }
    return isJPG && isLt2M;
  }
  resetForm() {
    this.renderKey--;
    this.$refs.postForm.resetFields();
    this.postForm.profilePicture = "";
  }
  async finalUpload() {
    // (this.isEdit)
    this.confirmuploadbtn = true;
    this.chatLoading = true;
    this.uploadImageDialog = false;
    this.dialogVisible = false;
    this.confirmuploadbtn = false;
    this.confirmuploadbtn = true;
    const formList = [];
    const callList = [];
    this.editList.forEach(file => {
      var fileObj = file.raw;
      var form = new FormData();
      form.append("file", fileObj);
      formList.push(form);
    });
    await formList.forEach(async form => {
      callList.push((0, _common.uploadFile)(form, async event => {
        // console.log(event);
      }));
    });
    // for (let i = 0; i < 3; i++) {
    //   callList.push(
    //     uploadFile(formList[i], async (event: ProgressEvent) => {
    //       // console.log(event);
    //     })
    //   );
    // }
    await _axios.default.all(callList).then(async res => {
      res.forEach(response => {
        this.imagelist.push(response);
      });
    });
  }
  async getLanguageData() {
    const data = await (0, _language.getLanguages)((0, _utils.getQuery)(this.listQuery));
    this.languageList = data;
  }
  async getCountryData() {
    const data = await (0, _country.getcountry)((0, _utils.getQuery)(this.listQuery));
    this.countryList = data;
  }
  async fetchData(id) {
    try {
      const data = await (0, _users.getUserDetailById)(id);
      ;
      this.renderKey++;
      // this.postForm.profilePicture=data.profilePicture;
      this.postForm = data;
      // this.getCollegeList();
      this.postForm.profilePicture = this.postForm.profilePicture.trim();
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  // private handleRemove(file: any) {
  //   this.fileList.forEach((photo: any, index: any) => {
  //     if (photo.name === file.name) {
  //       this.fileList.splice(index, 1);
  //       this.imagelist.splice(index, 1);
  //     }
  //   });
  //   let imgDragger: any = document.querySelector(".el-upload--picture-card ");
  //   if (this.fileList.length >= 3) {
  //     imgDragger.style.display = "none";
  //   } else {
  //     imgDragger.style.display = "block";
  //   }
  // }
  updateImage(res) {
    // ;
    // this.postForm.image.push(res);
  }
  handleClose() {
    this.uploadImageDialog = false;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.postForm.profilePicture = this.postForm.profilePicture;
      this.postForm.languageIds.forEach(res => {
        this.postForm.language.push({
          id: res
        });
        console.log("responce is:", this.postForm.language);
      });
      this.postForm.workoutIds.forEach(res => {
        this.postForm.workout.push({
          id: res
        });
      });
      this.postForm.diseaseIds.forEach(res => {
        this.postForm.disease.push({
          id: res
        });
      });
      //  this.postForm.college = { id: this.postForm.collegeId };
      if (this.isEdit) {
        await (0, _users.updateUser)(this.postForm.id, this.postForm).then(res => {
          _router.default.push("/users/list");
          this.resetForm();
        });
      } else {
        await (0, _users.addUser)(this.postForm).then(res => {
          if (res.responseCode === 300) {
            this.loading = false;
            this.$notify({
              title: "Danger",
              message: "This contact number is already exist.",
              type: "warning",
              duration: 2000
            });
          }
          if (res.responseCode === 301) {
            this.loading = false;
            this.$notify({
              title: "Danger",
              message: "This email is already exist.",
              type: "warning",
              duration: 2000
            });
          }
          if (res.responseCode === 204 || res.responseCode === 200 || res.responseCode === 201) {
            this.loading = false;
            this.$notify({
              title: "Success",
              message: "User saved successfully",
              type: "success",
              duration: 2000
            });
            _router.default.push("/users/list");
          }
        }).catch(err => {
          console.log(err);
        });
      }
    } catch (err) {
      this.loading = false;
    }
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  uploadImageBeforeUpload(file) {
    const file1 = file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png" ? true : false;
    if (!file1) {
      this.$message.error("Only Image file upload");
    }
    return file1;
  }
  // dynamic css methods
  dynamicwidth() {
    let wid = window.innerWidth;
    if (wid < 500) {
      return "width:100%";
    } else {
      return "width:60%";
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "userProfile",
  components: {
    PanThumb: _index2.default,
    UploadImage: _index3.default,
    AvatarUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;