"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStoryView = exports.getStoryViewInfo = exports.getStoryViewById = exports.getStoryViewByEmail = exports.getStoryView = exports.getStoryLike = exports.deleteStoryView = exports.defaultStoryData = exports.StoreStoreStoryView = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultStoryData = exports.defaultStoryData = {
  id: 0,
  enabled: true,
  userId: null,
  storyId: null
};
const getStoryView = params => (0, _request.default)({
  url: '/story-view',
  method: 'get',
  params
});
exports.getStoryView = getStoryView;
const getStoryViewById = id => (0, _request.default)({
  url: `/story-view/${id}`,
  method: 'get'
});
exports.getStoryViewById = getStoryViewById;
const getStoryViewInfo = data => (0, _request.default)({
  url: '/story-view/me',
  method: 'post',
  data
});
exports.getStoryViewInfo = getStoryViewInfo;
const getStoryViewByEmail = StoreStoryViewemail => (0, _request.default)({
  url: `/story-view/${StoreStoryViewemail}`,
  method: 'get'
});
exports.getStoryViewByEmail = getStoryViewByEmail;
const updateStoryView = (id, data) => (0, _request.default)({
  url: `/story-view/${id}`,
  method: 'patch',
  data
});
exports.updateStoryView = updateStoryView;
const StoreStoreStoryView = (id, data) => (0, _request.default)({
  url: `/story-view/${id}`,
  method: 'patch',
  data
});
exports.StoreStoreStoryView = StoreStoreStoryView;
const deleteStoryView = StoreStoryViewname => (0, _request.default)({
  url: `/story-view/${StoreStoryViewname}`,
  method: 'delete'
});
exports.deleteStoryView = deleteStoryView;
const getStoryLike = params => (0, _request.default)({
  url: '/like-story',
  method: 'get',
  params
});
exports.getStoryLike = getStoryLike;