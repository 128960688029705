"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _excel = require("@/utils/excel");
var _utils = require("@/utils");
var _index2 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _contactFollowup = require("@/api/contactFollowup");
var _admin = require("@/store/modules/admin");
var _admins = require("@/api/admins");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.userId = 0;
    this.updateDialogVisible = false;
    this.assignDialogVisible = false;
    this.myRole = _admin.AdminModule.roles;
    this.listQuery = {
      page: 1,
      limit: 10,
      sort: 'id,DESC',
      userId: this.userId,
      contactedDate: '',
      nextDate: '',
      filter: {
        userId: 'eq',
        message: '$contL',
        contactedDate: 'gte',
        nextDate: 'lte'
      }
    };
    this.adminQuery = {
      page: 1,
      limit: 20,
      sort: "id,DESC",
      fullName: "",
      title: "",
      date: "",
      filter: {
        "user.fullName": "$contL",
        "time.title": "$contL",
        date: "$contL",
        enabled: "eq"
      }
    };
    this.assignTolist = [];
    this.assignData = {
      assignedToId: null,
      status: 0
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: 'ID Ascending',
      key: 'id,ASC'
    }, {
      label: 'ID Descending',
      key: 'id,DESC'
    }];
    // private contactList = [
    //       { label: 'Contacted', value: 'Contacted' },
    //       { label: 'Not-Contacted', value: 'Not-Contacted' }
    //     ];
    // private interestList = [
    //       { label: 'Interested', value: 'Interested' },
    //       { label: 'Not-Interested', value: 'Not-Interested' }
    //     ];
    this.enableTypeOptions = [{
      key: true,
      value: 'Enable'
    }, {
      key: false,
      value: 'Disabled'
    }];
  }
  async adminList() {
    try {
      this.listLoading = true;
      const data = await (0, _admins.getAdmins)((0, _utils.getQuery)(this.adminQuery));
      this.assignTolist = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  AssignTo(row) {
    this.assignData1 = row;
    // this.updateDialogVisible = false;
    this.assignDialogVisible = true;
  }
  async assignStatus() {
    console.log(this.assignData, "status");
    console.log(this.assignData1.id, "id");
    this.assignData.assignedTo = {
      id: this.assignData.assignedToId
    };
    // let data = {
    //   status: this.assignData.status,
    // };
    this.listLoading = true;
    await (0, _contactFollowup.updateContactFollowup)(this.assignData1.id, this.assignData).then(res => {
      this.$message({
        message: "Assigned Updated.",
        type: "success"
      });
      this.getList();
      this.listLoading = false;
      this.assignDialogVisible = false;
    });
  }
  created() {
    this.userId = parseInt(this.$route.params && this.$route.params.id);
    this.listQuery.userId = this.userId;
    this.getList();
    this.adminList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _contactFollowup.getContactFollowups)((0, _utils.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  removePTags(message) {
    // Remove <p> tags from the start and end of the message
    return message.replace(/^<p>|<\/p>$/g, '');
  }
  async updateContact(id, isContacted) {
    const data = await (0, _contactFollowup.getContactFollowupById)(id);
    data.isContacted = isContacted;
    await (0, _contactFollowup.updateContactFollowup)(id, data);
  }
  async updateInterest(id, isInterested) {
    const data = await (0, _contactFollowup.getContactFollowupById)(id);
    data.isInterested = isInterested;
    await (0, _contactFollowup.updateContactFollowup)(id, data);
  }
  contactedUtc(field) {
    if (field == null) {
      this.listQuery.contactedDate = '';
      this.handleFilter();
    } else {
      this.listQuery.contactedDate = field.toISOString();
      this.handleFilter();
    }
  }
  nextUtc(field) {
    if (field == null) {
      this.listQuery.nextDate = '';
      this.handleFilter();
    } else {
      this.listQuery.nextDate = field.toISOString();
      this.handleFilter();
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['ID', 'Full Name', 'Email', 'Contact Number', 'Status', 'timestamp'];
    const filterVal = ['id', 'fullName', 'email', 'contactNumber', 'enabled', 'createdTimestamp'];
    const data = (0, _utils.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.contactManagement.contacts.title'));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ContactFollowList',
  components: {
    Pagination: _index.default,
    Enabled: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;