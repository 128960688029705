"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _c("breadcrumb", {
    staticClass: "breadcrumb-container",
    attrs: {
      id: "breadcrumb-container"
    }
  }), _c("div", {
    staticClass: "right-menu"
  }, [_c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.avatar + "?imageView2/1/w/80/h/80"
    }
  }), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("router-link", {
    attrs: {
      to: "/profile/"
    }
  }, [_c("el-dropdown-item", [_vm._v(_vm._s(_vm.$t("navbar.profile")))])], 1), _c("router-link", {
    attrs: {
      to: "/"
    }
  }, [_c("el-dropdown-item", [_vm._v(_vm._s(_vm.$t("navbar.dashboard")))])], 1), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: _vm.logout
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.logOut")))])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;