"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _common = require("@/api/common");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  beforeRemove(file, fileList) {
    if (this.multiple) {
      return true;
    }
    return fileList.length > 1; // for single file
  }
  beforeFileUpload(file) {
    const extenstion = file.name.substr(file.name.lastIndexOf('.'));
    const acceptable = this.accept.split(',').map(function (accept) {
      return accept.trim();
    });
    const isAllowedType = acceptable.indexOf(extenstion) > -1;
    const isLessThanMaxSize = file.size / 1024 / 1024 < this.maxSize;
    if (!isAllowedType) {
      this.$message.error('file type not permitted!');
    }
    if (!isLessThanMaxSize) {
      this.$message.error('Icon size can not exceed 2MB!');
    }
    return isAllowedType && isLessThanMaxSize;
  }
  onFileTransferError(err, file, fileList) {
    console.log(err); // mubasshir
  }
  onFileTransferSuccess(response, file, fileList) {
    this.fileList.push(file);
    // if single file then we remove other file
    if (!this.multiple && this.fileList.length > 1) {
      this.fileList.shift();
    }
    // send change
    this.$emit('change', file);
  }
  uploadFile(file) {
    const data = new FormData();
    data.append('file', file.file);
    return (0, _common.uploadFile)(data, event => {
      console.log(event); // mubasshir
    });
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '.jpg, .jpeg, .png'
})], default_1.prototype, "accept", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "multiple", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 2
})], default_1.prototype, "maxSize", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: []
})], default_1.prototype, "fileList", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'FileUpload'
})], default_1);
var _default = exports.default = default_1;