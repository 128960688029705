"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTransaction = exports.getUserBalance = exports.getTransactions = exports.getTransactionById = exports.getRoutes = exports.deletetTransaction = exports.defaultTransactionData = exports.createTransaction = exports.addTransactionTransfer = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultTransactionData = exports.defaultTransactionData = {
  id: 0,
  enabled: true,
  type: 0,
  userId: null,
  transactionId: null,
  planId: null,
  isDeleted: false,
  price: null,
  status: 0,
  response: '',
  noOfDays: null,
  noOfDaysCancelation: null,
  noOfDaysReschedule: null,
  totalNutrientSession: null,
  totalNutritionFollowUp: null,
  totalSession: null,
  totalTrainingSession: null,
  isCouplePlan: false,
  planTypeIds: []
};
const getTransactions = params => (0, _request.default)({
  url: '/user-transactions',
  method: 'get',
  params
});
exports.getTransactions = getTransactions;
const getTransactionById = id => (0, _request.default)({
  url: `/user-transactions/${id}`,
  method: 'get'
});
exports.getTransactionById = getTransactionById;
const addTransactionTransfer = (id, data) => (0, _request.default)({
  url: `/user-transactions/transfer/${id}`,
  method: 'post',
  data
});
exports.addTransactionTransfer = addTransactionTransfer;
const updateTransaction = (id, data) => (0, _request.default)({
  url: `/user-transactions/${id}`,
  method: 'patch',
  data
});
exports.updateTransaction = updateTransaction;
const deletetTransaction = id => (0, _request.default)({
  url: `/user-transactions/${id}`,
  method: 'delete'
});
exports.deletetTransaction = deletetTransaction;
const createTransaction = data => (0, _request.default)({
  url: '/user-transactions/',
  method: 'post',
  data
});
exports.createTransaction = createTransaction;
const getRoutes = params => (0, _request.default)({
  url: '/routes',
  method: 'get',
  params
});
exports.getRoutes = getRoutes;
const getUserBalance = id => (0, _request.default)({
  url: `/user-transactions/balance/${id}`,
  method: 'get'
});
exports.getUserBalance = getUserBalance;