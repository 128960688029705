"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _bokingenquiry = require("@/api/bokingenquiry");
var _admins = require("@/api/admins");
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _utils = require("@/utils");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index3 = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _router = _interopRequireDefault(require("../../../router"));
var _users = require("@/api/users");
var _timemaster = require("@/api/timemaster");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = Object.assign({}, _bokingenquiry.defaultBookingData);
    this.userData = Object.assign({}, _users.defaultUserData);
    this.loading = false;
    this.userloading = false;
    this.list = [];
    this.showImageUpload = false;
    this.renderKey = 0;
    this.countryId = 1;
    this.countryList = [];
    this.slotsList = [];
    this.userList = [];
    this.adminList = [];
    this.addUserDialog = false;
    this.languageList = [];
    this.workOutList = [];
    this.diseaseList = [];
    this.userTypeList = [{
      label: "In Campus",
      value: 1
    }, {
      label: "Off Campus",
      value: 0
    }];
    this.genderList = [{
      label: "Male",
      value: 0
    }, {
      label: "Female",
      value: 1
    }, {
      label: "Others",
      value: 2
    }];
    this.PrefranceList = [{
      label: "Video Call",
      value: 0
    }, {
      label: "Voice Call",
      value: 1
    }];
    this.heighTypeList = [{
      label: "FT",
      value: 0
    }, {
      label: "CM",
      value: 1
    }];
    this.weightTypeList = [{
      label: "KG",
      value: 0
    }, {
      label: "LB",
      value: 1
    }];
    this.bookingDate = {
      disabledDate(time) {
        var date = new Date();
        var previousDate = date.setDate(date.getDate() - 1);
        return time.getTime() < previousDate;
      }
    };
    this.image = "https://via.placeholder.com/154";
    this.listQuery = {
      sort: "id,DESC",
      enabled: true,
      filter: {
        enabled: "eq"
      }
    };
    this.userRules = {
      fullName: [{
        required: true,
        message: "Please Enter Full Name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      lastName: [{
        required: true,
        message: "Please Enter Last Name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      email: [{
        required: true,
        message: "Please enter email",
        trigger: "blur"
      }, {
        type: "email",
        message: "Please enter correct email",
        trigger: ["blur", "change"]
      }],
      contactNumber: [{
        required: true,
        message: "Please enter contact number",
        trigger: "blur"
      }],
      // profilePicture: [
      //   {
      //     required: true,
      //     message: 'Profile picture is required'
      //   }
      // ],
      // gender: [
      //   {
      //     required: true,
      //     message: "Please Select Gender",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // age: [
      //   {
      //     required: true,
      //     message: "Please Enter Age",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // height: [
      //   {
      //     required: true,
      //     message: "Please Enter Height",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // heightType: [
      //   {
      //     required: true,
      //     message: "Please Select HeightType",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // weight: [
      //   {
      //     required: true,
      //     message: "Please Enter Weight",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // weightType: [
      //   {
      //     required: true,
      //     message: "Please Select WeightType",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // countryId: [
      //   {
      //     required: true,
      //     message: "Please Select Country",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // cycleFrom: [
      //   {
      //     required: true,
      //     message: "Please Select Date of Cycle From",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // cycleTo: [
      //   {
      //     required: true,
      //     message: "Please Select Date of Cycle To",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // languageIds: [
      //   {
      //     required: true,
      //     message: "Please Select Language",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // workoutIds: [
      //   {
      //     required: true,
      //     message: "Please Select Workout",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      // diseaseIds: [
      //   {
      //     required: true,
      //     message: "Please Select Disease",
      //     trigger: ["blur", "change"],
      //   },
      // ],
      type: [{
        required: true,
        message: "Please Select User Type",
        trigger: ["blur", "change"]
      }],
      collegeId: [{
        required: true,
        message: "Please Select College Name",
        trigger: ["blur", "change"]
      }],
      pincode: [{
        required: true,
        message: "Pincode is required"
      }],
      companyName: [{
        required: true,
        message: "Company Name is required"
      }],
      companyBio: [{
        required: true,
        message: "Company Bio is required"
      }],
      companyContactNumber: [{
        required: true,
        message: "Company Contact Number is required"
      }],
      companyAddress: [{
        required: true,
        message: "Company Address is required"
      }]
    };
    this.rules = {
      name: [{
        required: true,
        message: "Please Enter Full Name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      lastName: [{
        required: true,
        message: "Please Enter Last Name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      email: [{
        required: true,
        message: "Please enter email",
        trigger: "blur"
      }, {
        type: "email",
        message: "Please enter correct email",
        trigger: ["blur", "change"]
      }],
      contactNumber: [{
        required: true,
        message: "Please enter mobile number",
        trigger: "blur"
      }],
      // profilePicture: [
      //   {
      //     required: true,
      //     message: 'Profile picture is required'
      //   }
      // ],
      gender: [{
        required: true,
        message: "Please Select Gender",
        trigger: ["blur", "change"]
      }],
      age: [{
        required: true,
        message: "Please Enter Age",
        trigger: ["blur", "change"]
      }],
      height: [{
        required: true,
        message: "Please Enter Height",
        trigger: ["blur", "change"]
      }],
      heightType: [{
        required: true,
        message: "Please Select HeightType",
        trigger: ["blur", "change"]
      }],
      weight: [{
        required: true,
        message: "Please Enter Weight",
        trigger: ["blur", "change"]
      }],
      weightType: [{
        required: true,
        message: "Please Select WeightType",
        trigger: ["blur", "change"]
      }],
      countryId: [{
        required: true,
        message: "Please Select Country",
        trigger: ["blur", "change"]
      }],
      assignedToId: [{
        required: true,
        message: "Please assign ",
        trigger: ["blur", "change"]
      }],
      cycleFrom: [{
        required: true,
        message: "Please Select Date of Cycle From",
        trigger: ["blur", "change"]
      }],
      cycleTo: [{
        required: true,
        message: "Please Select Date of Cycle To",
        trigger: ["blur", "change"]
      }],
      languageIds: [{
        required: true,
        message: "Please Select Language",
        trigger: ["blur", "change"]
      }],
      workoutIds: [{
        required: true,
        message: "Please Select Workout",
        trigger: ["blur", "change"]
      }],
      diseaseIds: [{
        required: true,
        message: "Please Select Disease",
        trigger: ["blur", "change"]
      }],
      date: [{
        required: true,
        message: "Please Select Date",
        trigger: ["blur", "change"]
      }],
      timeId: [{
        required: true,
        message: "Please Select Time",
        trigger: ["blur", "change"]
      }],
      type: [{
        required: true,
        message: "Please Select Prefrance",
        trigger: ["blur", "change"]
      }],
      collegeId: [{
        required: true,
        message: "Please Select College Name",
        trigger: ["blur", "change"]
      }],
      pincode: [{
        required: true,
        message: "Pincode is required"
      }],
      companyName: [{
        required: true,
        message: "Company Name is required"
      }],
      companyBio: [{
        required: true,
        message: "Company Bio is required"
      }],
      companyContactNumber: [{
        required: true,
        message: "Company Contact Number is required"
      }],
      companyAddress: [{
        required: true,
        message: "Company Address is required"
      }]
    };
    this.adminQuery = {
      sort: 'id,DESC',
      enabled: true,
      filter: {
        enabled: 'eq'
      }
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onClose() {
    this.showImageUpload = false;
  }
  // private deleteImage() {
  //   this.postForm.profilePicture = "";
  // }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], "img." + blob.type.split("/")[1], {
        type: "image/png"
      });
      data.append("file", file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        // this.postForm.profilePicture = res;
      });
    });
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.getSloatsData();
    this.getUsersData();
    this.getAssignToData();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _bokingenquiry.defaultBookingData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async getSloatsData() {
    const data = await (0, _timemaster.getTimeMaster)((0, _utils.getQuery)(this.listQuery));
    this.slotsList = data;
  }
  async getUsersData() {
    const data = await (0, _users.getUsers)((0, _utils.getQuery)(this.listQuery));
    this.userList = data;
  }
  async getAssignToData() {
    const data = await (0, _admins.getAdmins)((0, _utils.getQuery)(this.adminQuery));
    this.adminList = data;
  }
  async fetchData(id) {
    try {
      const data = await (0, _bokingenquiry.getBookingById)(id);
      this.renderKey++;
      // this.postForm.profilePicture=data.profilePicture;
      this.postForm = data;
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  updateProfilePictureImage(res) {
    // this.postForm.profilePicture = res;
  }
  removeProfilePictureImage() {
    // this.postForm.profilePicture = "";
  }
  submitUserForm() {
    this.$refs.userData.validate(valid => {
      if (valid) {
        this.saveUserForm();
      } else {
        return false;
      }
    });
  }
  async saveUserForm() {
    try {
      this.userloading = true;
      //  this.postForm.college = { id: this.postForm.collegeId };
      await (0, _users.addUser)(this.userData).then(res => {
        if (res.responseCode === 300) {
          this.userloading = false;
          this.$notify({
            title: "Danger",
            message: "This contact number is already exist.",
            type: "warning",
            duration: 2000
          });
        }
        if (res.responseCode === 301) {
          this.userloading = false;
          this.$notify({
            title: "Danger",
            message: "This email is already exist.",
            type: "warning",
            duration: 2000
          });
        }
        if (res.responseCode === 204 || res.responseCode === 200 || res.responseCode === 201) {
          this.userloading = false;
          this.$notify({
            title: "Success",
            message: "User saved successfully",
            type: "success",
            duration: 2000
          });
          this.getUsersData();
          this.addUserDialog = false;
          this.userloading = false;
          // router.push("/users/list");
        }
      }).catch(err => {
        console.log(err);
      });
    } catch (err) {
      this.userloading = false;
    }
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.renderKey--;
    this.$refs.postForm.resetFields();
    // this.postForm.profilePicture = "";
  }
  async saveForm() {
    //  this.postForm.userId=parseInt(this.$route.params.id);
    this.postForm.status = 0;
    // let formattedDate = moment(this.postForm.date).format('DD-MM-YYYY');
    //       this.postForm.date = formattedDate;
    // const date1 = new Date(this.postForm.date);
    // let day = String(date1.getDate()).padStart(2, "0");
    // let month = String(date1.getMonth() + 1).padStart(2, "0"); // January is 0!
    // let year = date1.getFullYear();
    // let formattedDate = `${day}-${month}-${year}`;
    // this.postForm.date = formattedDate;
    // console.log(formattedDate);
    try {
      this.loading = true;
      this.postForm.user = {
        id: this.postForm.userId
      };
      this.postForm.time = {
        id: this.postForm.timeId
      };
      this.postForm.assignedTo = {
        id: this.postForm.assignedToId
      };
      if (this.isEdit) {
        await (0, _bokingenquiry.updateBooking)(this.postForm.id, this.postForm).then(res => {
          _router.default.push("/booking/list");
          this.resetForm();
        });
      } else {
        await (0, _bokingenquiry.addBooking)(this.postForm);
      }
      this.$notify({
        title: "Success",
        message: "Booking Enquiry saved successfully",
        type: "success",
        duration: 2000
      });
      _router.default.push("/booking/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "UserDetail",
  components: {
    PanThumb: _index2.default,
    UploadImage: _index3.default,
    AvatarUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;