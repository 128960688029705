"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _admin = _interopRequireDefault(require("./modules/admin"));
var _users = _interopRequireDefault(require("./modules/users"));
var _category = _interopRequireDefault(require("./modules/category"));
var _state = _interopRequireDefault(require("./modules/state"));
var _city = _interopRequireDefault(require("./modules/city"));
var _country = _interopRequireDefault(require("./modules/country"));
var _language = _interopRequireDefault(require("./modules/language"));
var _setting = _interopRequireDefault(require("./modules/setting"));
var _notifications = _interopRequireDefault(require("./modules/notifications"));
var _documentmaster = _interopRequireDefault(require("./modules/documentmaster"));
var _contactus = _interopRequireDefault(require("./modules/contactus"));
var _banner = _interopRequireDefault(require("./modules/banner"));
var _workoutmaster = _interopRequireDefault(require("./modules/workoutmaster"));
var _diseases = _interopRequireDefault(require("./modules/diseases"));
var _trainers = _interopRequireDefault(require("./modules/trainers"));
var _comments = _interopRequireDefault(require("./modules/comments"));
var _celebrity = _interopRequireDefault(require("./modules/celebrity"));
var _plan = _interopRequireDefault(require("./modules/plan"));
var _post = _interopRequireDefault(require("./modules/post"));
var _recipies = _interopRequireDefault(require("./modules/recipies"));
var _session = _interopRequireDefault(require("./modules/session"));
var _userTransection = _interopRequireDefault(require("./modules/userTransection"));
var _article = _interopRequireDefault(require("./modules/article"));
var _story = _interopRequireDefault(require("./modules/story"));
var _nutritionist = _interopRequireDefault(require("./modules/nutritionist"));
var _timemaster = _interopRequireDefault(require("./modules/timemaster"));
var _bookingEnquiry = _interopRequireDefault(require("./modules/bookingEnquiry"));
var _userProfile = _interopRequireDefault(require("./modules/userProfile"));
var _userPackage = _interopRequireDefault(require("./modules/userPackage"));
var _userSession = _interopRequireDefault(require("./modules/userSession"));
var _usercase = _interopRequireDefault(require("./modules/usercase"));
var _userPlan = _interopRequireDefault(require("./modules/userPlan"));
var _userEnrollment = _interopRequireDefault(require("./modules/userEnrollment"));
var _contactfollowup = _interopRequireDefault(require("./modules/contactfollowup"));
var _planTypeMaster = _interopRequireDefault(require("./modules/planTypeMaster"));
var _followup = _interopRequireDefault(require("./modules/followup"));
var _professionMaster = _interopRequireDefault(require("./modules/professionMaster"));
var _adminRole = _interopRequireDefault(require("./modules/adminRole"));
var _sessionMaster = _interopRequireDefault(require("./modules/sessionMaster"));
var _trainingExpertise = _interopRequireDefault(require("./modules/training-expertise"));
var _goal = _interopRequireDefault(require("./modules/goal"));
var _inbox = _interopRequireDefault(require("./modules/inbox"));
/* Layout */

/* Router modules */

_vue.default.use(_vueRouter.default);
/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/
/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['superadmin','admin','executive']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/
/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
const constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _index.default,
  meta: {
    hidden: true
  },
  children: [{
    path: '/redirect/:path*',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "redirect" */'@/views/redirect/index.vue')))
  }]
}, {
  path: '/login',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */'@/views/login/index.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/auth-redirect',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "auth-redirect" */'@/views/login/auth-redirect.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/404',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "404" */'@/views/error-page/404.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/401',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "401" */'@/views/error-page/401.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "dashboard" */'@/views/dashboard/index.vue'))),
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: _index.default,
  redirect: '/profile/index',
  meta: {
    hidden: true
  },
  children: [{
    path: 'index',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "profile" */'@/views/profile/index.vue'))),
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
const asyncRoutes = exports.asyncRoutes = [/** when your routing map is too long, you can split it into small modules **/
// adminRouter,
// usersRouter,
// leadRouter,
// ProductRouter,
{
  path: '/manageadmin',
  redirect: 'noredirect',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "dashboard" */'@/views/admin/empty.vue'))),
  name: 'master',
  meta: {
    title: 'adminMasterManagement.title',
    icon: 'sitemap'
  },
  children: [_admin.default, _adminRole.default, _trainers.default, _nutritionist.default]
}, {
  path: '/employeemasters',
  redirect: 'noredirect',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "dashboard" */'@/views/admin/empty.vue'))),
  name: 'master',
  meta: {
    title: 'enquiryMasterManagement.title',
    icon: 'sitemap',
    roles: ['superadmin']
  },
  children: [_bookingEnquiry.default, _contactus.default, _followup.default]
}, {
  path: '/employeemasters1',
  redirect: 'noredirect',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "dashboard" */'@/views/admin/empty.vue'))),
  name: 'master',
  meta: {
    title: 'followupMasterManagement.title',
    icon: 'sitemap',
    roles: ['superadmin']
  },
  children: [_article.default, _banner.default, _post.default, _story.default, _recipies.default, _celebrity.default]
},
// transactionRouter,
// bookingRouter,
//  contactusRouter,
// followupRouter,
// articleRouter,
// savearticleRouter,
_users.default, _category.default, _usercase.default, _inbox.default,
// bannerRouter,
// trainerRouter,
// nutritionistRouter,
// planRouter,
_userTransection.default,
// postRouter,
// storyRouter,
// recipieRouter,
_session.default, _comments.default,
// celebrityRouter,
_notifications.default, _state.default, _city.default, {
  path: '/masters',
  redirect: 'noredirect',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "dashboard" */'@/views/admin/empty.vue'))),
  name: 'master',
  meta: {
    title: 'masterManagement.title',
    icon: 'sitemap'
  },
  children: [_plan.default, _language.default, _workoutmaster.default, _trainingExpertise.default, _goal.default, _diseases.default, _documentmaster.default, _professionMaster.default, _planTypeMaster.default, _timemaster.default, _country.default]
}, _setting.default, _userProfile.default, _userPackage.default, _userSession.default,
// usersCaseRouter,
_userPlan.default, _userEnrollment.default, _contactfollowup.default, _sessionMaster.default,
// DocumentRouter,
{
  path: '*',
  redirect: '/404',
  meta: {
    hidden: true
  }
}];
const createRouter = () => new _vueRouter.default({
  // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
});
const router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;