"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _trainer = require("@/api/trainer");
var _pxlcode = require("@/api/pxlcode");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _adminsRoles = require("@/api/adminsRoles");
var _utils = require("../../../utils");
var _index = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _admin = require("../../../store/modules/admin");
var _index2 = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _lodash = require("lodash");
var _documentmaster = require("@/api/documentmaster");
var _workoutmaster = require("@/api/workoutmaster");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.postForm = Object.assign({}, _trainer.defaultTrainerData);
    this.myRole = _admin.AdminModule.roles;
    this.loading = false;
    this.showImageUpload = false;
    this.renderKey = 0;
    this.avatar = "https://via.placeholder.com";
    this.pxlCodeList = [];
    this.documentlist = [];
    this.workoutlist = [];
    this.genderList = [{
      label: "Male",
      value: 0
    }, {
      label: "Female",
      value: 1
    }, {
      label: "Others",
      value: 2
    }];
    this.rules = {
      fullName: [{
        required: true,
        message: "Please enter first name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Last Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      lastName: [{
        required: true,
        message: "Please enter last name",
        trigger: ["blur", "change"]
      },
      // { min: 3, message: 'Length should be greater than 3', trigger: ['blur', 'change'] },
      {
        pattern: "^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$",
        message: "Last Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      contactNumber: [{
        required: true,
        message: "Please enter mobile number",
        trigger: "blur"
      }, {
        trigger: ["blur", "change"],
        pattern: "[123456789][0-9]*$",
        message: "Contact number should contain number only"
      }, {
        max: 10,
        min: 10,
        message: "Length should be of 10 Digits",
        trigger: ["blur", "change"]
      }],
      aadharNo: [{
        required: true,
        message: "Please enter Aadhar Number",
        trigger: "blur"
      }, {
        trigger: ["blur", "change"],
        pattern: "[123456789][0-9]*$",
        message: "Contact number should contain number only"
      }, {
        max: 12,
        min: 12,
        message: "Length should be of 12 Digits",
        trigger: ["blur", "change"]
      }],
      email: [{
        required: true,
        message: "please enter e-mail",
        trigger: "blur"
      },
      // {
      //   min: 10,
      //   max: 50,
      //   message: 'Length should be between 10-50 characters',
      //   trigger: ['blur', 'change']
      // },
      {
        type: "email",
        message: "Please enter correct email address",
        trigger: ["blur", "change"]
      }],
      password: [{
        required: true,
        message: "Please enter password",
        trigger: "blur"
      }, {
        min: 8,
        message: "Minimum length should be 8",
        trigger: ["blur", "change"]
      }, {
        pattern: "^[a-zA-Z0-9_]",
        message: "Space not allow at start of password ",
        trigger: ["blur", "change"]
      }],
      adminsRoleId: [{
        required: true,
        message: "Please select your role",
        trigger: ["blur", "change"]
      }],
      documentId: [{
        required: true,
        message: "Please select Document",
        trigger: ["blur", "change"]
      }],
      workoutIds: [{
        required: true,
        message: "Please select Training Expertise",
        trigger: ["blur", "change"]
      }],
      gender: [{
        required: true,
        message: "Please select gender",
        trigger: ["blur", "change"]
      }],
      about: [{
        required: true,
        message: "About is Required",
        trigger: ["blur", "change"]
      }],
      employeeCode: [{
        required: true,
        message: "Please enter employee uf code",
        trigger: ["blur", "change"]
      }]
    };
    this.listQuery = {
      sort: 'id,DESC',
      enabled: true,
      filter: {
        enabled: 'eq'
      }
    };
    this.pxlcodeQuery = {
      page: 1,
      sort: "id,ASC",
      enabled: true,
      filter: {
        enabled: "eq"
      }
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  // private onCropUploadSuccess(jsonData: any, field: string) {
  //   this.showImageUpload = false
  //   this.image = jsonData.files[field]
  // }
  onClose() {
    this.showImageUpload = false;
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.fetchRoles();
    this.getDocumentList();
    this.getWorkOutList();
    // this.fetchPxlCodeList();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _trainer.defaultTrainerData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _trainer.getTrainerById)(id);
      this.postForm = data;
      this.renderKey++;
      this.avatar = data.avatar;
      const title = this.postForm.email;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchRoles() {
    try {
      // var role = this.myRole[0]
      // if (role === 'superadmin') {
      this.roleList = await (0, _adminsRoles.getAdminsRoles)((0, _utils.getQuery)({
        enabled: true,
        sort: "name,ASC",
        filter: {
          enabled: "eq"
        }
      }));
      // }
      // if (role === 'Admin') {
      //   this.roleList = await getAdminsRoles(
      //     getQuery({
      //       enabled: true,
      //       sort: 'name,ASC',
      //       id: 3,
      //       filter: {
      //         enabled: 'eq',
      //         id: 'eq'
      //       }
      //     }))
      // }
    } catch (err) {
      Promise.reject(err);
    }
  }
  updateProfilePictureImage(res) {
    this.postForm.profile = res;
  }
  removeProfilePictureImage() {
    this.postForm.profile = "";
  }
  // private handleChange(pxlCodeId:any){
  //   if(pxlCodeId != ''){
  //     this.postForm.pxlCode = {id:pxlCodeId}
  //   }else{
  //     this.postForm.pxlCode = {id:null}
  //   }
  // }
  // private cropSuccess(imgDataUrl: string, field: string) {
  //   this.avatar = imgDataUrl
  //   const data = new FormData()
  //   fetch(imgDataUrl)
  //     .then((res) => res.blob())
  //     .then((blob) => {
  //       const file = new File([blob], 'img.' + blob.type.split('/')[1], {
  //         type: 'image/png'
  //       })
  //       data.append('file', file)
  //       uploadFile(data, (event: ProgressEvent) => {
  //         console.log(event)
  //       }).then((res: any) => {
  //         this.postForm.avatar = res
  //       })
  //     })
  // }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  async fetchPxlCodeList() {
    this.pxlCodeList = await (0, _pxlcode.getPxlCode)((0, _utils.getQuery)(this.pxlcodeQuery));
    // this.pxlCodeList = data;
    console.log(this.pxlCodeList);
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  async getDocumentList() {
    const data = await (0, _documentmaster.getDocumentMaster)((0, _utils.getQuery)(this.listQuery));
    this.documentlist = data;
  }
  async getWorkOutList() {
    const data = await (0, _workoutmaster.getWorkoutMaster)((0, _utils.getQuery)(this.listQuery));
    this.workoutlist = data;
  }
  submitForm() {
    // if (this.postForm.avatar === '') {
    //   this.postForm.avatar = this.avatar
    // }
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  updateAadharFrontImage(res) {
    this.postForm.aadharFromInage = res;
  }
  updateAadharBackImage(res) {
    this.postForm.aadharBackInage = res;
  }
  removeAadharBackImage(res) {
    this.postForm.aadharBackInage = '';
  }
  removeAadharFrontImage(res) {
    this.postForm.aadharBackInage = '';
  }
  updateDocumentFrontImage(res) {
    this.postForm.documentFromImage = res;
  }
  updateDocumentBackImage(res) {
    this.postForm.documentBackImage = res;
  }
  resetForm() {
    this.renderKey--;
    this.$refs.postForm.resetFields();
    this.postForm.profile = "";
  }
  async saveForm() {
    try {
      this.postForm.email = (0, _lodash.toLower)(this.postForm.email);
      this.postForm.document = {
        id: this.postForm.documentId
      };
      this.postForm.workoutIds.forEach(res => {
        this.postForm.workout.push({
          id: res
        });
      });
      this.loading = true;
      if (this.isEdit) {
        // this.postForm.adminsRole.id = this.postForm.adminsRoleId;
        this.postForm.email = (0, _lodash.toLower)(this.postForm.email);
        await (0, _trainer.updateTrainer)(this.postForm.id, this.postForm);
      } else {
        await (0, _trainer.register)(this.postForm);
      }
      this.$notify({
        title: "Success",
        message: "Trainer saved successfully",
        type: "success",
        duration: 2000
      });
      _router.default.push("/trainer/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "AdminDetail",
  components: {
    // AvatarUpload,
    UploadImage: _index2.default,
    PanThumb: _index.default
  }
})], default_1);
var _default = exports.default = default_1;