"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadExcel = exports.updateOrder = exports.updateLanguage = exports.getRoutes = exports.getLanguages = exports.getLanguageById = exports.deleteLanguage = exports.defaultLanguageData = exports.createLanguage = exports.addLanguage = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultLanguageData = exports.defaultLanguageData = {
  id: 0,
  name: ''
};
const getLanguages = params => (0, _request.default)({
  url: '/language-master',
  method: 'get',
  params
});
exports.getLanguages = getLanguages;
const getLanguageById = id => (0, _request.default)({
  url: `/language-master/${id}`,
  method: 'get'
});
exports.getLanguageById = getLanguageById;
const updateLanguage = (id, data) => (0, _request.default)({
  url: `/language-master/${id}`,
  method: 'patch',
  data
});
exports.updateLanguage = updateLanguage;
const createLanguage = data => (0, _request.default)({
  url: '/language-master/add',
  method: 'post',
  data
});
exports.createLanguage = createLanguage;
const addLanguage = data => (0, _request.default)({
  url: '/language-master',
  method: 'post',
  data
});
exports.addLanguage = addLanguage;
const getRoutes = params => (0, _request.default)({
  url: '/routes',
  method: 'get',
  params
});
exports.getRoutes = getRoutes;
const updateOrder = data => (0, _request.default)({
  url: '/language-master/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;
const uploadExcel = (id, data) => (0, _request.default)({
  url: `/language-master-key-value/upload/${id}`,
  method: 'post',
  data
});
// export const deleteLanguage = (data:any) =>
// request({
//   url: `/language-master-key-value/deleteLanguage`,
//   method: 'post',
//   data
// })
exports.uploadExcel = uploadExcel;
const deleteLanguage = data => (0, _request.default)({
  url: `/language-master/${data}`,
  method: 'delete'
});
exports.deleteLanguage = deleteLanguage;