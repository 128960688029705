"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _timemaster = require("@/api/timemaster");
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _vuePropertyDecorator = require("vue-property-decorator");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _vue2Editor = require("vue2-editor");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.postForm = Object.assign({}, _timemaster.defaultTimeMasterData);
    this.loading = false;
    this.rules = {
      title: [{
        required: true,
        message: 'Time is Required',
        trigger: ['blur,change']
      }]
    };
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _timemaster.defaultTimeMasterData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _timemaster.getTimeMasterById)(id);
      this.postForm = data;
      // Just for test
      const title = 'Documents';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    // this.postForm.aboutUsLink=""
    // this.postForm.termsAndConditionsLink=""
    // this.postForm.privacyPolicyLink=""
    this.$refs.postForm.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _timemaster.updateTimeMaster)(this.postForm.id, this.postForm);
      } else {
        await (0, _timemaster.addTimeMaster)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'Time saved successfully',
        type: 'success',
        duration: 2000
      });
      this.loading = false;
      _router.default.push('/timemaster/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DocumentDetail',
  components: {
    PanThumb: _index2.default,
    AvatarUpload: _index.default,
    VueEditor: _vue2Editor.VueEditor
  }
})], default_1);
var _default = exports.default = default_1;