"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const usersCaseRouter = {
  path: "/usersCase",
  component: _index.default,
  redirect: "noredirect",
  name: "users Management",
  meta: {
    roles: ["superadmin", "admin"],
    title: "userCaseManagement.title",
    icon: "users-alt"
  },
  children: [{
    path: "list",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/userCase/list.vue"))),
    name: "users",
    meta: {
      title: "userCaseManagement.title",
      noCache: true,
      icon: "users-alt"
    }
  }, {
    path: "list1/:id(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/userCase/list.vue"))),
    name: "users",
    meta: {
      title: "userCaseManagement.title",
      noCache: true,
      icon: "users-alt",
      hidden: true
    }
  }, {
    path: "edit/:caseId(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/userCase/manage.vue"))),
    name: "Edit Admin",
    props: {
      isEdit: true
    },
    meta: {
      title: "userCaseManagement.usersCase.editPlan",
      noCache: true,
      activeMenu: "/usersPackage/list",
      hidden: true
    }
  }, {
    path: "add/:id(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/userCase/manage.vue"))),
    name: "Add Subscription",
    props: {
      isEdit: false
    },
    meta: {
      title: "userCaseManagement.usersCase.manage",
      noCache: true,
      activeMenu: "/usersPackage/list",
      hidden: true
    }
  }, {
    path: "add",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/userCase/manage.vue"))),
    name: "Add Subscription",
    props: {
      isEdit: false
    },
    meta: {
      title: "userCaseManagement.usersCase.manage",
      noCache: true,
      activeMenu: "/usersPackage/list",
      hidden: true
    }
  }]
};
var _default = exports.default = usersCaseRouter;