"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const sessionRouter = {
  path: "/session",
  component: _index.default,
  redirect: "noredirect",
  name: "sessions Management",
  meta: {
    roles: ["superadmin", "admin"],
    title: "sessionManagement.title",
    icon: "table"
  },
  children: [{
    path: "list",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */"@/views/session/list.vue"))),
    name: "session",
    meta: {
      title: "sessionManagement.title",
      noCache: true,
      icon: "table"
    }
  }, {
    path: "list1/:id(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "admins-list" */"@/views/session/list.vue"))),
    name: "session",
    meta: {
      title: "sessionManagement.title",
      noCache: true,
      hidden: true,
      icon: "table"
    }
  }, {
    path: "edit/:id(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/session/manage.vue"))),
    name: "Edit Admin",
    props: {
      isEdit: true
    },
    meta: {
      title: "sessionManagement.sessions.manage",
      noCache: true,
      activeMenu: "/session/list",
      hidden: true
    }
  }, {
    path: "reschedule/:trId(\\d+)/:ssId(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/session/components/Reschedule.vue"))),
    name: "Reschedule",
    props: {
      isEdit: true
    },
    meta: {
      title: "sessionManagement.session.reschedule",
      noCache: true,
      activeMenu: "/session/reschedule",
      hidden: true
    }
  }, {
    path: "add",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/session/manage.vue"))),
    name: "Add session",
    props: {
      isEdit: false
    },
    meta: {
      title: "sessionManagement.sessions.manage",
      noCache: true,
      activeMenu: "/session/list",
      hidden: true
    }
  }, {
    path: "session-slots/:id(\\d+)",
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require("@/views/session/sessionList.vue"))),
    name: "Session slots",
    props: {
      isEdit: false
    },
    meta: {
      title: "sessionManagement.session.sessionSlots",
      noCache: true,
      activeMenu: "/session/list",
      hidden: true
    }
  }]
};
var _default = exports.default = sessionRouter;