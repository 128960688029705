"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "80",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      label: "Enable/Disable",
      width: "140",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Enable/Disable",
            placement: "top-start"
          }
        }, [_c("enabled", {
          key: scope.row.id,
          attrs: {
            url: "/session /" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        })], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "Session Details"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Title :")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v(_vm._s(scope.row.title))])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Date:")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("b", [_vm._v(" " + _vm._s(scope.row.date))])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("From :")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("span", [_vm._v(_vm._s(_vm.formatLocalTime(scope.row.from)) + " ")])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("To :")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("span", [_vm._v(_vm._s(_vm.formatLocalTime(scope.row.to)) + " ")])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Otp :")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("span", [_vm._v(_vm._s(scope.row.otp) + " ")])])], 1)], 1), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", {
          staticStyle: {
            "text-align": "left"
          },
          attrs: {
            type: "flex",
            justify: "start"
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("b", [_vm._v("Google Link :")])]), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_c("span", [_c("u", [_c("a", {
          attrs: {
            href: scope.row.link,
            target: "_blank"
          }
        }, [_vm._v(_vm._s(scope.row.link))])])])])], 1)], 1)], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "User Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$user, _scope$row$user2, _scope$row$user3;
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" User Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user = scope.row.user) === null || _scope$row$user === void 0 ? void 0 : _scope$row$user.fullName) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Email: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user2 = scope.row.user) === null || _scope$row$user2 === void 0 ? void 0 : _scope$row$user2.email) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Contact Number: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$user3 = scope.row.user) === null || _scope$row$user3 === void 0 ? void 0 : _scope$row$user3.contactNumber) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "400",
      align: "left",
      label: "Admin Details",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        var _scope$row$admin, _scope$row$admin2, _scope$row$admin3;
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Admin Name: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$admin = scope.row.admin) === null || _scope$row$admin === void 0 ? void 0 : _scope$row$admin.fullName) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Email: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$admin2 = scope.row.admin) === null || _scope$row$admin2 === void 0 ? void 0 : _scope$row$admin2.email) + " ")])], 1), _c("el-row", [_c("el-col", {
          attrs: {
            span: 8
          }
        }, [_vm._v(" Contact Number: ")]), _c("el-col", {
          attrs: {
            span: 16
          }
        }, [_vm._v(" " + _vm._s((_scope$row$admin3 = scope.row.admin) === null || _scope$row$admin3 === void 0 ? void 0 : _scope$row$admin3.contactNumber) + " ")])], 1)];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.actions"),
      width: "270px",
      "class-name": "fixed-width"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            type: "flex",
            justify: "space-around"
          }
        }, [_c("el-col", {
          attrs: {
            span: 9
          }
        }, [_c("el-button", {
          attrs: {
            type: "danger",
            size: "small"
          },
          on: {
            click: function ($event) {
              return _vm.cancelSession(scope.row.id, "ADMIN");
            }
          }
        }, [_vm._v(" Cancel by Admin ")])], 1), _c("el-col", {
          attrs: {
            span: 9
          }
        }, [_c("el-button", {
          attrs: {
            type: "danger",
            size: "small"
          },
          on: {
            click: function ($event) {
              return _vm.cancelSession(scope.row.id, "USER");
            }
          }
        }, [_vm._v(" Cancel by User ")])], 1)], 1), _c("el-row", {
          staticStyle: {
            "margin-top": "5px"
          },
          attrs: {
            type: "flex",
            justify: "center"
          }
        }, [_c("el-button", {
          attrs: {
            type: "Primary",
            size: "",
            icon: ""
          },
          on: {
            click: function ($event) {
              return _vm.routeToReschedule(scope.row.userTransactionId, scope.row.id);
            }
          }
        }, [_vm._v(" Reschedule ")])], 1)];
      }
    }])
  }), _vm.showCreatedDate ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      width: "150",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: _vm._f("moment")(scope.row.createdTimestamp, `MMMM Do YYYY, h:mm:ss a`),
            placement: "top-start"
          }
        }, [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "from")) + " "), _c("div", [_vm._v("( " + _vm._s(scope.row.createdByFullName) + " )")])])])];
      }
    }], null, false, 940132993)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;