"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _story = require("@/api/story");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _storyview = require("@/api/storyview");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.storyViewList = [];
    this.storyLikeList = [];
    this.data1 = [];
    this.createdBy = Object.assign({}, _story.defaultStoryData);
    this.fullscreenLoading = false;
    this.downloadLoading = false;
    // private myRole = AdminModule.roles;
    this.showSortingOption = false;
    // private renderComponent: boolean = true;
    // private eventSubscriberList = [];
    // private gridData = [];
    this.dialogTableVisible = false;
    // private ftype: any;
    this.collegelist = [];
    this.showFullDescription = false;
    this.updateDialogVisible = false;
    this.activeName = "2";
    this.ViewUsersdrawer = false;
    this.LikesUsersdrawer = false;
    this.direction = "rtl";
    this.activeTab = "first";
    this.status = 0;
    this.isUser = true;
    this.listQuery = {
      page: 1,
      limit: 10,
      fullName: undefined,
      email: undefined,
      status: 0,
      type: 0,
      filter: {
        "user.fullName": "$contL",
        status: "eq",
        type: "eq",
        enabled: "eq"
      }
    };
    this.viewStoryQuery = {
      page: 1,
      limit: 10,
      storyId: undefined,
      filter: {
        storyId: "eq"
      }
    };
    this.likeStoryQuery = {
      page: 1,
      limit: 10,
      storyId: undefined,
      filter: {
        storyId: "eq"
      }
    };
    this.commentQuery = {
      page: 1,
      limit: 100,
      sort: "id,DESC",
      postsId: null,
      filter: {
        postsId: "eq",
        enabled: "eq"
      }
    };
    this.collegelistQuery = {
      sort: "id,DESC",
      enabled: true,
      collegeId: undefined,
      filter: {
        enabled: "eq",
        collegeId: "eq"
      }
    };
    this.eventQuery = {
      page: 1,
      postsId: undefined,
      filter: {
        postsId: "$eq",
        enabled: "eq"
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: "Name Ascending",
      key: "fullName,ASC"
    }, {
      label: "Name Descending",
      key: "fullName,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.planPostOptions = [{
      key: 0,
      value: "Plan"
    }, {
      key: 1,
      value: "Post"
    }];
    this.postData = {
      status: null
    };
    this.statusList = [{
      label: "Pending",
      value: 0
    }, {
      label: "Approved",
      value: 1
    }, {
      label: "Rejected",
      value: 2
    }];
    this.usserSubscribeList = [];
    this.commentList = [];
    this.drawerTitle = "";
  }
  showComment(id) {
    console.log("active tag name --->", this.activeName);
    const ids = String(id);
    this.activeName = ids;
  }
  created() {
    // if (this.$route.params && this.$route.params.id) {
    //   this.listQuery.userId = Number(this.$route.params.id);
    // }
    this.getstoryList();
  }
  handleChange() {
    if (this.isUser) {
      this.listQuery.type = 0;
    } else {
      this.listQuery.type = 1;
    }
    this.getstoryList();
  }
  handleClick(tab, event) {
    switch (tab.index) {
      case tab.index = "0":
        {
          this.listQuery.status = 0;
          this.getstoryList();
          break;
        }
      case tab.index = "1":
        {
          this.listQuery.status = 1;
          this.getstoryList();
          break;
        }
      case tab.index = "2":
        {
          this.listQuery.status = 2;
          this.getstoryList();
          break;
        }
    }
  }
  async getstoryList() {
    try {
      // this.fullscreenLoading = true;
      this.list = [];
      const data = await (0, _story.getstory)((0, _index2.getQuery)(this.listQuery));
      data.data.forEach(res => {
        res.joinedUser = 0;
      });
      this.list = data.data;
      this.total = data.total;
      this.fullscreenLoading = false;
      // this.viewStoryQuery.filter.storyId='eq'
    } catch (error) {
      this.fullscreenLoading = false;
    }
  }
  async getstoryviewList(data) {
    ;
    try {
      this.list = [];
      this.viewStoryQuery.storyId = data;
      const data11 = await (0, _storyview.getStoryView)((0, _index2.getQuery)(this.viewStoryQuery));
      this.storyViewList = data11.data;
      this.total = data11.total;
      this.drawerTitle = "Users Story Views";
      this.ViewUsersdrawer = true;
      this.fullscreenLoading = false;
    } catch (error) {
      this.fullscreenLoading = false;
    }
    this.getstoryList();
  }
  async getstoryLikeList(data) {
    ;
    try {
      this.list = [];
      this.likeStoryQuery.storyId = data;
      const data11 = await (0, _storyview.getStoryLike)((0, _index2.getQuery)(this.likeStoryQuery));
      this.storyLikeList = data11.data;
      this.total = data11.total;
      this.drawerTitle = "Users Liked Story";
      this.LikesUsersdrawer = true;
      this.fullscreenLoading = false;
    } catch (error) {
      this.fullscreenLoading = false;
    }
    this.getstoryList();
  }
  // private async fetchcomment(data: any) {
  //   this.drawerTitle = data.description;
  //   this.commentQuery.postsId = data.id;
  //   const commentData: any = await getComment(getQuery(this.commentQuery));
  //   this.commentList = commentData.data;
  //   this.drawer = true;
  // }
  handleFilter() {
    this.listQuery.page = 1;
    this.getstoryList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
  }
  async visibiltyUpdate(id, feature) {
    const data = await (0, _story.getstoryById)(id);
    console.log("GetFeed data based on id:", data);
    data.feature = feature;
    (0, _story.updatestory)(id, data);
  }
  async handleIsDelete(row) {
    this.$confirm("This will permanently delete the file. Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning"
    }).then(async () => {
      const data = row;
      data.isDeleted = true;
      await (0, _story.updatestory)(row.id, data);
      this.$message({
        type: "success",
        message: "Delete completed"
      });
      this.getstoryList();
    }).catch(() => {
      this.$message({
        type: "info",
        message: "Delete canceled"
      });
    });
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        const constdata = {
          postsId: data.id,
          userId: data.userId
        };
        // deletestory(constdata);
        // this.$message({
        //   type: "error",
        //   message: this.$tc("record deleted succesfully"),
        // });
        this.getstoryList();
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  async handleClose() {
    this.ViewUsersdrawer = false;
  }
  async handleClose1() {
    this.LikesUsersdrawer = false;
  }
  changeStatus(row) {
    ;
    this.postData = row;
    this.updateDialogVisible = true;
  }
  handaleUserSubscribeList(data) {
    this.dialogTableVisible = true;
    this.usserSubscribeList = data;
  }
  async updateStatus() {
    console.log(this.postData.status, "status");
    console.log(this.postData.id, "id");
    ;
    let data = {
      status: this.postData.status
    };
    this.fullscreenLoading = true;
    await (0, _story.updatestory)(this.postData.id, data).then(res => {
      this.$message({
        message: "Status Updated.",
        type: "success"
      });
      this.getstoryList();
      this.fullscreenLoading = false;
      this.updateDialogVisible = false;
    });
  }
  handleDownload() {
    this.downloadLoading = true;
    this.list.forEach(order => {
      order.payoutStatus = order.payoutStatus == 0 ? "Un-Paid" : "Paid";
      // order.couponCode=order.couponCode.code
    });
    const tHeader = [" ID", "First Name", "Last Name", "CollegeName", "Description", "Totla Comments"];
    const filterVal = ["id", "user.firstName", "user.lastName", "college.collegeName", "description", "commentCount"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, "Feeds Details");
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "AdminList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;