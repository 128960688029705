"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserTransactionStatus = exports.updateUserTransactionAddress = exports.updateUserTransaction = exports.getUserTransactioncount = exports.getUserTransactionDetailById = exports.getUserTransactionByIdAddress = exports.getUserTransactionById = exports.getUserTransaction = exports.deleteUserTransaction = exports.defaultUserTransectionData = exports.checkUserTransactionExist = exports.addUserTransaction = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultUserTransectionData = exports.defaultUserTransectionData = {
  id: 0,
  enabled: true,
  type: 0,
  userId: null,
  transactionId: null,
  price: 0,
  planId: null,
  status: 0,
  response: '',
  createdBy: 0,
  createdTimestamp: '',
  updatedBy: 0,
  updatedTimestamp: ''
};
const getUserTransaction = params => (0, _request.default)({
  url: '/user-transactions',
  method: 'get',
  params
});
exports.getUserTransaction = getUserTransaction;
const addUserTransaction = data => (0, _request.default)({
  url: '/user-transactions/add',
  method: 'post',
  data
});
exports.addUserTransaction = addUserTransaction;
const getUserTransactioncount = () => (0, _request.default)({
  url: '/user-transactions/count-user',
  method: 'get'
});
exports.getUserTransactioncount = getUserTransactioncount;
const checkUserTransactionExist = data => (0, _request.default)({
  url: '/user-transactions/username-check',
  method: 'post',
  data
});
exports.checkUserTransactionExist = checkUserTransactionExist;
const getUserTransactionById = id => (0, _request.default)({
  url: `/user-transactions/detail/${id}`,
  method: 'get'
});
exports.getUserTransactionById = getUserTransactionById;
const getUserTransactionDetailById = id => (0, _request.default)({
  url: `/user-transactions/${id}`,
  method: 'get'
});
exports.getUserTransactionDetailById = getUserTransactionDetailById;
const getUserTransactionByIdAddress = id => (0, _request.default)({
  url: `/user-transactions/detail/${id}`,
  method: 'get'
});
exports.getUserTransactionByIdAddress = getUserTransactionByIdAddress;
const updateUserTransaction = (id, data) => (0, _request.default)({
  url: `/user-transactions/${id}`,
  method: 'patch',
  data
});
exports.updateUserTransaction = updateUserTransaction;
const updateUserTransactionAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updateUserTransactionAddress = updateUserTransactionAddress;
const updateUserTransactionStatus = data => (0, _request.default)({
  url: `/user-transactions/update-status`,
  method: 'post',
  data
});
exports.updateUserTransactionStatus = updateUserTransactionStatus;
const deleteUserTransaction = adminname => (0, _request.default)({
  url: `/user-transactions/${adminname}`,
  method: 'delete'
});
exports.deleteUserTransaction = deleteUserTransaction;