"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const SettingRouter = {
  path: '/setting',
  component: _index.default,
  redirect: 'noredirect',
  name: 'settingManagement.title',
  meta: {
    title: 'settingManagement.title',
    icon: 'sliders-v-alt',
    roles: ['superadmin']
  },
  children: [
  // {
  //   path: 'list',
  //   component: () => import('@/views/setting/list.vue'),
  //   name: 'settingManagement.setting.manage',
  //   meta: {
  //     title: 'settingManagement.title',
  //     noCache: true,
  //     hidden:true,
  //     icon: 'sliders-v-alt'
  //   }
  // },
  {
    path: 'edit',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/setting/manage.vue'))),
    name: 'settingManagement.setting.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'settingManagement.setting.manage',
      noCache: true,
      activeMenu: '/setting/list',
      icon: 'sliders-v-alt',
      header: 'App Setting',
      info: 'All the app settings can be changed from this module'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/setting/manage.vue'))),
    name: 'settingManagement.setting.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'settingManagement.setting.manage',
      noCache: true,
      activeMenu: '/setting/list',
      hidden: true
    }
  }]
};
var _default = exports.default = SettingRouter;