"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("image-crop-upload", {
    attrs: {
      field: _vm.field,
      url: _vm.url,
      width: _vm.width,
      height: _vm.height,
      params: _vm.params,
      headers: _vm.headers,
      "lang-type": _vm.language,
      "with-credentials": true,
      "img-format": "png"
    },
    on: {
      "src-file-set": _vm.srcFileSet,
      "crop-success": _vm.cropSuccess,
      "crop-upload-success": _vm.cropUploadSuccess,
      "crop-upload-fail": _vm.cropUploadFail
    },
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  });
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;