"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _common = require("@/api/common");
var _vuePropertyDecorator = require("vue-property-decorator");
var _article = require("@/api/article");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _admin = require("@/store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.postForm = Object.assign({}, _article.defaultArticaleData);
    this.loading = false;
    this.additionalLoading = false;
    this.usersList = [];
    this.contactNumber = '';
    this.email = '';
    this.balance = null;
    this.showImageUpload = false;
    this.renderKey = 0;
    this.image = 'https://via.placeholder.com/';
    // private typeList = [
    //   { label: "Credit", value: 1 },
    //   { label: "Debit", value: 0 },
    // ];
    this.rules = {
      title: [{
        required: true,
        message: "Please Enter Title",
        trigger: ['blur', 'change']
      }],
      desc: [{
        required: true,
        message: "Please Enter Description",
        trigger: ['blur', 'change']
      }]
    };
  }
  updateArticleImage(res) {
    this.postForm.image = res;
  }
  removeArticleImage() {
    this.postForm.image = '';
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'img.' + blob.type.split('/')[1], {
        type: 'image/png'
      });
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        console.log("IMage:", res);
        this.postForm.image = res.toString();
      });
    });
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _article.defaultArticaleData);
    }
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _article.getArticleById)(id);
      this.postForm = data;
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        ;
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
    this.email = '';
    this.contactNumber = '';
    this.balance = null;
  }
  async saveForm() {
    try {
      this.postForm.adminId = _admin.AdminModule.id;
      // this.postForm.amount=(this.postForm.amount)
      // this.postForm.transactionId=this.postForm.transactionId
      this.loading = true;
      if (this.isEdit) {
        await (0, _article.updateArticle)(this.postForm.id, this.postForm);
      } else {
        ;
        await (0, _article.addArticle)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'Article added successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/article/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "transactionDetail",
  components: {
    UploadImage: _index.default
  }
})], default_1);
var _default = exports.default = default_1;