"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updatePxlCode = exports.updateOrder = exports.getPxlCodeInfo = exports.getPxlCodeCount = exports.getPxlCodeById = exports.getPxlCode = exports.deletePxlCode = exports.defaultPxlCodeData = exports.addPxlCode = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultPxlCodeData = exports.defaultPxlCodeData = {
  id: 0,
  enabled: true,
  name: ''
};
const getPxlCode = params => (0, _request.default)({
  url: '/pxlCode',
  method: 'get',
  params
});
exports.getPxlCode = getPxlCode;
const getPxlCodeById = id => (0, _request.default)({
  url: `/pxlCode/${id}`,
  method: 'get'
});
exports.getPxlCodeById = getPxlCodeById;
const getPxlCodeInfo = data => (0, _request.default)({
  url: '/pxlCode/me',
  method: 'post',
  data
});
exports.getPxlCodeInfo = getPxlCodeInfo;
const updatePxlCode = (id, data) => (0, _request.default)({
  url: `/pxlCode/${id}`,
  method: 'patch',
  data
});
exports.updatePxlCode = updatePxlCode;
const deletePxlCode = id => (0, _request.default)({
  url: `/pxlCode/${id}`,
  method: 'delete'
});
exports.deletePxlCode = deletePxlCode;
const addPxlCode = data => (0, _request.default)({
  url: '/pxlCode/',
  method: 'post',
  data
});
exports.addPxlCode = addPxlCode;
const getPxlCodeCount = () => (0, _request.default)({
  url: '/pxlCode/count',
  method: 'get'
});
exports.getPxlCodeCount = getPxlCodeCount;
const updateOrder = data => (0, _request.default)({
  url: '/pxlCode/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;