"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateProfessionOrder = exports.updateProfessionMaster = exports.getProfessionMasterInfo = exports.getProfessionMasterCount = exports.getProfessionMasterById = exports.getProfessionMaster = exports.deleteProfessionMaster = exports.defaultProfessionMasterData = exports.addProfessionMaster = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultProfessionMasterData = exports.defaultProfessionMasterData = {
  id: 0,
  enabled: true,
  title: ''
};
const getProfessionMaster = params => (0, _request.default)({
  url: '/profession-master',
  method: 'get',
  params
});
exports.getProfessionMaster = getProfessionMaster;
const getProfessionMasterById = id => (0, _request.default)({
  url: `/profession-master/${id}`,
  method: 'get'
});
exports.getProfessionMasterById = getProfessionMasterById;
const getProfessionMasterInfo = data => (0, _request.default)({
  url: '/profession-master/me',
  method: 'post',
  data
});
exports.getProfessionMasterInfo = getProfessionMasterInfo;
const updateProfessionMaster = (id, data) => (0, _request.default)({
  url: `/profession-master/${id}`,
  method: 'patch',
  data
});
exports.updateProfessionMaster = updateProfessionMaster;
const deleteProfessionMaster = id => (0, _request.default)({
  url: `/profession-master/${id}`,
  method: 'delete'
});
exports.deleteProfessionMaster = deleteProfessionMaster;
const addProfessionMaster = data => (0, _request.default)({
  url: '/profession-master/',
  method: 'post',
  data
});
exports.addProfessionMaster = addProfessionMaster;
const getProfessionMasterCount = () => (0, _request.default)({
  url: '/profession-master/count',
  method: 'get'
});
exports.getProfessionMasterCount = getProfessionMasterCount;
const updateProfessionOrder = data => (0, _request.default)({
  url: '/profession-master/order',
  method: 'post',
  data
});
exports.updateProfessionOrder = updateProfessionOrder;