"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _keys = require("@/api/keys");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
// import { getService } from '@/api/service'
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.serviceLoading = false;
    this.serviceList = [];
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.showCreatedDate = false;
    this.addAccess = true;
    this.listQuery = {
      page: 1,
      sort: "id,DESC",
      languageValue: "",
      defaultValue: "",
      languageId: this.$route.params.id,
      filter: {
        languageId: "eq",
        languageValue: "$contL",
        defaultValue: "$contL"
      }
    };
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  created() {
    if (this.$route.path == "/orderMenu/" + this.$route.path.split("/").pop() + "/language/" + this.$route.path.split("/").pop()) {
      var id = this.$route.path.split("/").pop();
      // this.listQuery.serviceId = id
      this.addAccess = false;
    }
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _keys.getKeysValue)((0, _index2.getQuery)(this.listQuery));
      this.list = data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  // private async fetchService(query: any) {
  //   try {
  //     if (query !== '') {
  //       this.serviceLoading = true
  //       this.serviceList = await getService(
  //         getQuery({
  //           enabled: true,
  //           serviceId: 2,
  //           sort: 'name,ASC',
  //           s: '{"name": { "$contL": "' + query + '"}}',
  //           filter: {
  //             enabled: 'eq',
  //             serviceId: 'eq'
  //           }
  //         })
  //       )
  //       this.serviceLoading = false
  //     } else {
  //       this.serviceList = []
  //     }
  //   } catch (error) {
  //     this.$message(error)
  //   }
  // }
  // private handleDelete(data: any) {
  //   if (data) {
  //     this.$confirm(
  //       this.$tc("table.deleteConfirmation", undefined, { name: data.name }),
  //       this.$tc("table.delete"),
  //       {
  //         confirmButtonText: this.$tc("table.delete"),
  //         confirmButtonClass: "el-button--danger",
  //         cancelButtonText: "Cancel",
  //         type: "error",
  //       }
  //     )
  //       .then(() => {
  //         deleteKeys(data.id).then((deleted) => {
  //           this.getList();
  //         });
  //       })
  //       .catch(() => {
  //         this.$message({
  //           type: "info",
  //           message: this.$tc("table.deleteCanceled"),
  //         });
  //       });
  //   }
  // }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Name", "Status", "timestamp"];
    const filterVal = ["id", "name", "enabled", "createdTimestamp"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.adminManagement.roles.title"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "languageList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;