"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const commentRouter = {
  path: '/comment',
  component: _index.default,
  redirect: 'noredirect',
  name: 'commentManagement.comment.title',
  meta: {
    roles: ['superadmin'],
    title: 'commentManagement.title',
    icon: 'map-marker',
    hidden: true
  },
  children: [{
    path: 'list/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/comment/list.vue'))),
    name: 'commentManagement.comment.manage',
    meta: {
      title: 'commentManagement.comment.title',
      noCache: true,
      icon: 'map-marker',
      header: 'List of all comment',
      info: 'All comment details are available here. You can search comment by applying filters on fields like comment name. After clicking on comment name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/comment/manage.vue'))),
    name: 'commentManagement.comment.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'commentManagement.comment.add',
      noCache: true,
      icon: 'comment-alt',
      header: 'List of all college',
      info: 'Add Colleges.',
      hidden: true
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/comment/manage.vue'))),
    name: 'commentManagement.comment.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'commentManagement.comment.edit',
      noCache: true,
      icon: 'comment-alt',
      header: 'List of all Users',
      info: 'Add Users.',
      hidden: true
    }
  }]
};
var _default = exports.default = commentRouter;