"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("./../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _adminsRoles = require("@/api/adminsRoles");
var _utils = require("../../../utils");
var _contactFollowup = require("@/api/contactFollowup");
var _index = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _vue2Editor = require("vue2-editor");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.showImageUpload = false;
    this.image = 'https://via.placeholder.com/154';
    this.postForm = Object.assign({}, _contactFollowup.defaultContactFollowupData);
    this.loading = false;
    this.statusList = [{
      label: 'Fresh Lead',
      value: 1
    }, {
      label: 'Rejected',
      value: 2
    }, {
      label: 'Pending',
      value: 3
    }];
    this.rules = {
      contactedDate: [{
        required: true,
        trigger: 'blur',
        message: 'Contacted Date is required'
      }],
      message: [{
        required: true,
        trigger: 'blur',
        message: 'Message is required'
      }],
      nextDate: [{
        required: true,
        trigger: 'blur',
        message: 'Next Date is required'
      }]
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  onClose() {
    this.showImageUpload = false;
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.fetchRoles();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _contactFollowup.defaultContactFollowupData);
      this.postForm.userId = parseInt(this.$route.params && this.$route.params.id);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _contactFollowup.getContactFollowupById)(id);
      this.postForm = data;
      // this.image = data.image
      // Just for test
      const title = 'image';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchRoles() {
    try {
      this.roleList = await (0, _adminsRoles.getAdminsRoles)((0, _utils.getQuery)({
        enabled: true,
        sort: 'name,ASC',
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
    this.image = 'https://via.placeholder.com/154';
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        // this.postForm.adminsRole.id = this.postForm.adminsRoleId
        await (0, _contactFollowup.updateContactFollowup)(this.postForm.id, this.postForm);
      } else {
        // delete this.postForm.adminsRole
        await (0, _contactFollowup.addContactFollowup)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'Contact Followup saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/ContactFollowup/list/' + this.postForm.userId);
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ContactFollowupDetail',
  components: {
    PanThumb: _index.default,
    AvatarUpload: _index2.default,
    VueEditor: _vue2Editor.VueEditor
  }
})], default_1);
var _default = exports.default = default_1;