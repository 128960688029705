"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateBooking = exports.getFilters = exports.getBookingById = exports.getBooking = exports.deleteBooking = exports.defaultBookingData = exports.createBooking = exports.addBooking = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultBookingData = exports.defaultBookingData = {
  id: 0,
  enabled: true,
  type: null,
  date: '',
  timeId: null,
  assignedToId: null,
  assignedTo: {},
  userId: null,
  status: null,
  priority: 0,
  user: null,
  time: null,
  link: ''
};
const getBooking = params => (0, _request.default)({
  url: '/booking',
  method: 'get',
  params
});
exports.getBooking = getBooking;
const addBooking = data => (0, _request.default)({
  url: '/booking',
  method: 'post',
  data
});
exports.addBooking = addBooking;
const getBookingById = id => (0, _request.default)({
  url: `/booking/${id}`,
  method: 'get'
});
exports.getBookingById = getBookingById;
const updateBooking = (id, data) => (0, _request.default)({
  url: `/booking/${id}`,
  method: 'patch',
  data
});
exports.updateBooking = updateBooking;
const deleteBooking = id => (0, _request.default)({
  url: `/booking/${id}`,
  method: 'delete'
});
exports.deleteBooking = deleteBooking;
const createBooking = data => (0, _request.default)({
  url: '/booking/',
  method: 'post',
  data
});
exports.createBooking = createBooking;
const getFilters = data => (0, _request.default)({
  url: '/booking/fetch',
  method: 'post',
  data
});
exports.getFilters = getFilters;