"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const usersProfileRouter = {
  path: '/userprofile',
  component: _index.default,
  redirect: 'noredirect',
  name: 'userProfileManagement.userprofile.title',
  meta: {
    roles: ['superadmin'],
    title: 'userProfileManagement.title',
    icon: 'users-alt',
    hidden: true
  },
  children: [{
    path: 'profile',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/userProfile/profile.vue'))),
    name: 'userProfileManagement.userProfile.manage',
    meta: {
      title: 'userProfileManagement.userProfile.title',
      noCache: true,
      icon: 'users-alt',
      header: 'List of all Users',
      info: 'All player details are available here.  You can search player information by applying filters on fields like username, email, mobile number, gender etc. Green dot represents active player. Red dot represents disabled player'
    }
  }, {
    path: 'profile/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/userProfile/profile.vue'))),
    name: 'userProfileManagement.userProfile.manage',
    meta: {
      title: 'userProfileManagement.userProfile.title',
      noCache: true,
      hidden: true,
      icon: 'users-alt',
      header: 'List of all Users',
      info: 'All player details are available here.  You can search player information by applying filters on fields like username, email, mobile number, gender etc. Green dot represents active player. Red dot represents disabled player'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/userProfile/manage.vue'))),
    name: 'userProfileManagement.users.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'userProfileManagement.userProfile.add',
      noCache: true,
      icon: 'users-alt',
      header: 'List of all Users',
      info: 'Add Users.',
      hidden: true
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/userProfile/manage.vue'))),
    name: 'userProfileManagement.users.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'userProfileManagement.userProfile.edit',
      noCache: true,
      icon: 'users-alt',
      header: 'List of all Users',
      info: 'Add Users.',
      hidden: true
    }
  }]
};
var _default = exports.default = usersProfileRouter;