"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _admin = require("@/store/modules/admin");
var _index = _interopRequireDefault(require("@/components/Breadcrumb/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/ErrorLog/index.vue"));
var _index3 = _interopRequireDefault(require("@/components/Hamburger/index.vue"));
var _index4 = _interopRequireDefault(require("@/components/HeaderSearch/index.vue"));
var _index5 = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
var _index6 = _interopRequireDefault(require("@/components/Screenfull/index.vue"));
var _index7 = _interopRequireDefault(require("@/components/SizeSelect/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  get sidebar() {
    return _app.AppModule.sidebar;
  }
  get device() {
    return _app.AppModule.device.toString();
  }
  get avatar() {
    if (_admin.AdminModule.avatar.length === 0) {
      return 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif';
    }
    return _admin.AdminModule.avatar;
  }
  toggleSideBar() {
    _app.AppModule.ToggleSideBar(false);
  }
  async logout() {
    await _admin.AdminModule.LogOut();
    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Navbar',
  components: {
    Breadcrumb: _index.default,
    ErrorLog: _index2.default,
    Hamburger: _index3.default,
    HeaderSearch: _index4.default,
    LangSelect: _index5.default,
    Screenfull: _index6.default,
    SizeSelect: _index7.default
  }
})], default_1);
var _default = exports.default = default_1;