"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "sidebar-logo-container",
    class: {
      collapse: _vm.collapse
    }
  }, [_c("transition", {
    attrs: {
      name: "sidebarLogoFade"
    }
  }, [_vm.collapse ? _c("router-link", {
    key: "collapse",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("img", {
    staticClass: "sidebar-logo",
    staticStyle: {
      width: "50px"
    },
    attrs: {
      src: require("@/assets/img/asknatural.png")
    }
  })]) : _c("router-link", {
    key: "expand",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("img", {
    staticClass: "sidebar-logo",
    staticStyle: {
      width: "100px"
    },
    attrs: {
      src: require("@/assets/img/asknatural.png")
    }
  })])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;