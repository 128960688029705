"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUserStatus = exports.updateUserAddress = exports.updateUser = exports.register = exports.logout = exports.login = exports.logPost = exports.getUserscount = exports.getUsers = exports.getUserReportbyGender = exports.getUserDetailById = exports.getUserByIdAddress = exports.getUserById = exports.getUserByEmail = exports.getLogs = exports.getCompany = exports.deleteUser = exports.defaultUserData = exports.checkUserExist = exports.checkEmailExist = exports.addUser = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultUserData = exports.defaultUserData = {
  id: 0,
  enabled: true,
  isDeleted: false,
  fullName: '',
  dialCode: '',
  countryCode: '',
  contactNumber: '',
  countryId: null,
  languageIds: [],
  workoutIds: [],
  language: [{}],
  workout: [{}],
  disease: [{}],
  gender: null,
  age: null,
  profilePicture: '',
  weightType: null,
  weight: null,
  heightType: null,
  height: null,
  idealWeightType: null,
  idealWeight: null,
  cycleFrom: '',
  cycleTo: '',
  diseaseIds: [],
  adminIds: [],
  plan: null,
  planIds: [],
  planId: null,
  priority: 0,
  noOfDays: null,
  title: '',
  accessToken: '',
  createdBy: 0,
  redirectionOption: null,
  redirectionValue: null
};
const getUsers = params => (0, _request.default)({
  url: '/users',
  method: 'get',
  params
});
exports.getUsers = getUsers;
const addUser = data => (0, _request.default)({
  url: '/users/add',
  method: 'post',
  data
});
exports.addUser = addUser;
const getUserscount = () => (0, _request.default)({
  url: '/users/count-user',
  method: 'get'
});
exports.getUserscount = getUserscount;
const checkUserExist = data => (0, _request.default)({
  url: '/users/username-check',
  method: 'post',
  data
});
exports.checkUserExist = checkUserExist;
const checkEmailExist = data => (0, _request.default)({
  url: '/users/email-check',
  method: 'post',
  data
});
exports.checkEmailExist = checkEmailExist;
const getUserById = id => (0, _request.default)({
  url: `/users/detail/${id}`,
  method: 'get'
});
exports.getUserById = getUserById;
const getUserDetailById = id => (0, _request.default)({
  url: `/users/${id}`,
  method: 'get'
});
exports.getUserDetailById = getUserDetailById;
const getUserByIdAddress = id => (0, _request.default)({
  url: `/users/detail/${id}`,
  method: 'get'
});
exports.getUserByIdAddress = getUserByIdAddress;
const getUserByEmail = adminemail => (0, _request.default)({
  url: `/users/${adminemail}`,
  method: 'get'
});
exports.getUserByEmail = getUserByEmail;
const updateUser = (id, data) => (0, _request.default)({
  url: `/users/${id}`,
  method: 'patch',
  data
});
exports.updateUser = updateUser;
const updateUserAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updateUserAddress = updateUserAddress;
const updateUserStatus = data => (0, _request.default)({
  url: `/users/update-status`,
  method: 'post',
  data
});
exports.updateUserStatus = updateUserStatus;
const deleteUser = adminname => (0, _request.default)({
  url: `/users/${adminname}`,
  method: 'delete'
});
exports.deleteUser = deleteUser;
const login = data => (0, _request.default)({
  url: '/users/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: '/users/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/users/add',
  method: 'post',
  data
});
exports.register = register;
const getCompany = params => (0, _request.default)({
  url: '/users-roles',
  method: 'get',
  params
});
exports.getCompany = getCompany;
const logPost = data => (0, _request.default)({
  url: '/user-logs',
  method: 'post',
  data
});
exports.logPost = logPost;
const getLogs = params => (0, _request.default)({
  url: '/user-logs',
  method: 'get',
  params
});
exports.getLogs = getLogs;
const getUserReportbyGender = params => (0, _request.default)({
  url: '/users/gender-count',
  method: 'get',
  params
});
exports.getUserReportbyGender = getUserReportbyGender;