"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadExcel = exports.updatediseases = exports.updateOrder = exports.getdiseasesById = exports.getdiseases = exports.getRoutes = exports.deletediseases = exports.defaultdiseasesData = exports.creatediseases = exports.adddiseases = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultdiseasesData = exports.defaultdiseasesData = {
  id: 0,
  name: ''
};
const getdiseases = params => (0, _request.default)({
  url: '/diseases',
  method: 'get',
  params
});
exports.getdiseases = getdiseases;
const getdiseasesById = id => (0, _request.default)({
  url: `/diseases/${id}`,
  method: 'get'
});
exports.getdiseasesById = getdiseasesById;
const updatediseases = (id, data) => (0, _request.default)({
  url: `/diseases/${id}`,
  method: 'patch',
  data
});
exports.updatediseases = updatediseases;
const creatediseases = data => (0, _request.default)({
  url: '/diseases/add',
  method: 'post',
  data
});
exports.creatediseases = creatediseases;
const adddiseases = data => (0, _request.default)({
  url: '/diseases',
  method: 'post',
  data
});
exports.adddiseases = adddiseases;
const getRoutes = params => (0, _request.default)({
  url: '/routes',
  method: 'get',
  params
});
exports.getRoutes = getRoutes;
const updateOrder = data => (0, _request.default)({
  url: '/diseases/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;
const uploadExcel = (id, data) => (0, _request.default)({
  url: `/diseases-key-value/upload/${id}`,
  method: 'post',
  data
});
// export const deletediseases = (data:any) =>
// request({
//   url: `/diseases-key-value/deletediseases`,
//   method: 'post',
//   data
// })
exports.uploadExcel = uploadExcel;
const deletediseases = data => (0, _request.default)({
  url: `/diseases/${data}`,
  method: 'delete'
});
exports.deletediseases = deletediseases;