"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Profile Picture",
      prop: "profile"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      formField: _vm.postForm.profile
    },
    on: {
      "handle-upload": _vm.updateProfilePictureImage,
      "handle-remove": _vm.removeProfilePictureImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Full Name",
      prop: "fullName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "fullName",
      required: "",
      placeholder: "First Name",
      "show-word-limit": ""
    },
    model: {
      value: _vm.postForm.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "fullName", $$v);
      },
      expression: "postForm.fullName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "email",
      required: "",
      placeholder: "Email",
      "auto-complete": "off"
    },
    model: {
      value: _vm.postForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "email", $$v);
      },
      expression: "postForm.email"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Mobile Number",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    staticClass: "inputContact",
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "contactNumber",
      required: "",
      minlength: "10",
      placeholder: "Mobile Number"
    },
    model: {
      value: _vm.postForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "contactNumber", $$v);
      },
      expression: "postForm.contactNumber"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Instagram Id",
      prop: "instaUrl"
    }
  }, [_c("el-input", {
    staticClass: "inputContact",
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "instaUrl",
      required: "",
      minlength: "10",
      placeholder: "Instagram Id"
    },
    model: {
      value: _vm.postForm.instaUrl,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "instaUrl", $$v);
      },
      expression: "postForm.instaUrl"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "About",
      prop: "about"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "about",
      type: "textarea",
      required: "",
      placeholder: "About",
      "show-word-limit": ""
    },
    model: {
      value: _vm.postForm.about,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "about", $$v);
      },
      expression: "postForm.about"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Gender",
      prop: "gender"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "gender",
      placeholder: "Select"
    },
    model: {
      value: _vm.postForm.gender,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "gender", $$v);
      },
      expression: "postForm.gender"
    }
  }, _vm._l(_vm.genderList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Role",
      prop: "adminsRoleId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "adminsRoleId",
      placeholder: "Select"
    },
    model: {
      value: _vm.postForm.adminsRoleId,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "adminsRoleId", $$v);
      },
      expression: "postForm.adminsRoleId"
    }
  }, _vm._l(_vm.roleList, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Document Name",
      prop: "documentId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    model: {
      value: _vm.postForm.documentId,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "documentId", $$v);
      },
      expression: "postForm.documentId"
    }
  }, _vm._l(_vm.documentlist, function (item) {
    return _c("el-option", {
      key: item.name,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Training Expertise",
      prop: "workoutIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.workoutIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "workoutIds", $$v);
      },
      expression: "postForm.workoutIds"
    }
  }, _vm._l(_vm.workoutlist, function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Aadhaar Number",
      prop: "aadharNo"
    }
  }, [_c("el-input", {
    staticClass: "KycDetaisl",
    staticStyle: {
      width: "50%"
    },
    attrs: {
      placeholder: "Enter number"
    },
    model: {
      value: _vm.postForm.aadharNo,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "aadharNo", $$v);
      },
      expression: "postForm.aadharNo"
    }
  })], 1), _c("div", {
    staticClass: "aadhaar-side"
  }, [_c("h4", {
    staticStyle: {
      color: "#333",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" AAdhaar Front Side ")]), _c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      prop: "aadharFront",
      formField: _vm.postForm.aadharFromInage
    },
    on: {
      "handle-upload": _vm.updateAadharFrontImage,
      "handle-remove": _vm.removeAadharFrontImage
    }
  })], 1), _c("div", {
    staticClass: "aadhaar-side"
  }, [_c("h4", {
    staticStyle: {
      color: "#333",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Aadhar Back Side ")]), _c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      prop: "aadharBack",
      formField: _vm.postForm.aadharBackInage
    },
    on: {
      "handle-upload": _vm.updateAadharBackImage,
      "handle-remove": _vm.removeAadharBackImage
    }
  })], 1), _c("div", {
    staticClass: "aadhaar-side"
  }, [_c("h4", {
    staticStyle: {
      color: "#333",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Documents Front Side ")]), _c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      prop: "aadharFront"
    },
    on: {
      "handle-upload": _vm.updateDocumentFrontImage,
      "handle-remove": _vm.removeAadharFrontImage
    }
  })], 1), _c("div", {
    staticClass: "aadhaar-side"
  }, [_c("h4", {
    staticStyle: {
      color: "#333",
      "font-size": "16px",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" Document Back Side ")]), _c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      prop: "aadharBack",
      formField: _vm.postForm.documentBackImage
    },
    on: {
      "handle-upload": _vm.updateDocumentBackImage,
      "handle-remove": _vm.removeAadharBackImage
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;