"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTimeMaster = exports.updateOrder = exports.getTimeMasterInfo = exports.getTimeMasterCount = exports.getTimeMasterById = exports.getTimeMaster = exports.deleteTimeMaster = exports.defaultTimeMasterData = exports.addTimeMaster = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultTimeMasterData = exports.defaultTimeMasterData = {
  id: 0,
  enabled: true,
  title: '',
  priority: 0
};
const getTimeMaster = params => (0, _request.default)({
  url: '/time-master',
  method: 'get',
  params
});
exports.getTimeMaster = getTimeMaster;
const getTimeMasterById = id => (0, _request.default)({
  url: `/time-master/${id}`,
  method: 'get'
});
exports.getTimeMasterById = getTimeMasterById;
const getTimeMasterInfo = data => (0, _request.default)({
  url: '/time-master/me',
  method: 'post',
  data
});
exports.getTimeMasterInfo = getTimeMasterInfo;
const updateTimeMaster = (id, data) => (0, _request.default)({
  url: `/time-master/${id}`,
  method: 'patch',
  data
});
exports.updateTimeMaster = updateTimeMaster;
const deleteTimeMaster = id => (0, _request.default)({
  url: `/time-master/${id}`,
  method: 'delete'
});
exports.deleteTimeMaster = deleteTimeMaster;
const addTimeMaster = data => (0, _request.default)({
  url: '/time-master/',
  method: 'post',
  data
});
exports.addTimeMaster = addTimeMaster;
const getTimeMasterCount = () => (0, _request.default)({
  url: '/time-master/count',
  method: 'get'
});
exports.getTimeMasterCount = getTimeMasterCount;
const updateOrder = data => (0, _request.default)({
  url: '/time-master/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;