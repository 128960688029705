"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-width": "220px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Background Image",
      prop: "image",
      "label-width": "200px"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      "form-field": _vm.postForm.image
    },
    on: {
      "handle-upload": _vm.updateimageImage,
      "handle-remove": _vm.removeimageImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Inner Image",
      prop: "innerImage"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      formField: _vm.postForm.innerImage
    },
    on: {
      "handle-upload": _vm.updateBannerImage,
      "handle-remove": _vm.removeBannerImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 1000 * 1000 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Program Name",
      prop: "title"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "title",
      placeholder: "Title"
    },
    model: {
      value: _vm.postForm.title,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "title", $$v);
      },
      expression: "postForm.title"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Number Of Days",
      prop: "noOfDays"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "number",
      name: "noOfDays",
      placeholder: "Number Of Days"
    },
    model: {
      value: _vm.postForm.noOfDays,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "noOfDays", $$v);
      },
      expression: "postForm.noOfDays"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Includes",
      prop: "planTypeIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      clearable: true,
      multiple: ""
    },
    on: {
      "remove-tag": _vm.handleRemoveTag
    },
    model: {
      value: _vm.postForm.planTypeIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "planTypeIds", $$v);
      },
      expression: "postForm.planTypeIds"
    }
  }, _vm._l(_vm.includeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _vm.postForm.planTypeIds[0] == 1 || _vm.postForm.planTypeIds[1] == 1 ? _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Total nutrition session",
      prop: "totalNutrientSession"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "totalNutrientSession",
      type: "number",
      placeholder: "Total nutrition session"
    },
    on: {
      input: function ($event) {
        return _vm.changeSession();
      }
    },
    model: {
      value: _vm.postForm.totalNutrientSession,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "totalNutrientSession", $$v);
      },
      expression: "postForm.totalNutrientSession"
    }
  })], 1) : _vm._e(), _vm.postForm.planTypeIds[0] == 2 || _vm.postForm.planTypeIds[1] == 2 ? _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Total training session",
      prop: "totalTrainingSession"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "totalTrainingSession",
      type: "number",
      placeholder: "Total training session"
    },
    on: {
      input: function ($event) {
        return _vm.changeSession();
      }
    },
    model: {
      value: _vm.postForm.totalTrainingSession,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "totalTrainingSession", $$v);
      },
      expression: "postForm.totalTrainingSession"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Total sessions",
      prop: "totalSession"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "number",
      name: "noOfDays",
      readonly: "true",
      placeholder: "Total sessions"
    },
    model: {
      value: _vm.postForm.totalSession,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "totalSession", $$v);
      },
      expression: "postForm.totalSession"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "No. of days for reschedule ",
      prop: "noOfDaysReschedule"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "number",
      name: "noOfDaysReschedule",
      placeholder: "No. of days for reschedule "
    },
    model: {
      value: _vm.postForm.noOfDaysReschedule,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "noOfDaysReschedule", $$v);
      },
      expression: "postForm.noOfDaysReschedule"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "No. of days for cancellation",
      prop: "noOfDaysCancelation"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "noOfDaysCancelation",
      type: "number",
      placeholder: "No. of days for cancellation "
    },
    model: {
      value: _vm.postForm.noOfDaysCancelation,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "noOfDaysCancelation", $$v);
      },
      expression: "postForm.noOfDaysCancelation"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Perks",
      prop: "description"
    }
  }, [_c("vue-editor", {
    attrs: {
      required: "",
      name: "description"
    },
    model: {
      value: _vm.postForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "description", $$v);
      },
      expression: "postForm.description"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;