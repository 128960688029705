"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateGoal = exports.getGoalCount = exports.getGoalById = exports.getGoal = exports.deleteGoal = exports.defaultGoalData = exports.addGoal = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultGoalData = exports.defaultGoalData = {
  id: 0,
  enabled: true,
  name: '',
  image: ''
};
const getGoal = params => (0, _request.default)({
  url: '/goal-master',
  method: 'get',
  params
});
exports.getGoal = getGoal;
const getGoalById = id => (0, _request.default)({
  url: `/goal-master/${id}`,
  method: 'get'
});
exports.getGoalById = getGoalById;
const updateGoal = (id, data) => (0, _request.default)({
  url: `/goal-master/${id}`,
  method: 'patch',
  data
});
exports.updateGoal = updateGoal;
const deleteGoal = StoreGoalname => (0, _request.default)({
  url: `/goal-master/${StoreGoalname}`,
  method: 'delete'
});
exports.deleteGoal = deleteGoal;
const addGoal = data => (0, _request.default)({
  url: '/goal-master',
  method: 'post',
  data
});
exports.addGoal = addGoal;
const getGoalCount = params => (0, _request.default)({
  url: '/goal-master/count',
  method: 'get',
  params
});
exports.getGoalCount = getGoalCount;