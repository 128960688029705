"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("a", {
    staticClass: "link--mallki",
    class: _vm.className,
    attrs: {
      href: "#"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), _c("span", {
    attrs: {
      "data-letters": _vm.text
    }
  }), _c("span", {
    attrs: {
      "data-letters": _vm.text
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;