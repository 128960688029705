"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _utils = require("@/utils");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index3 = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _router = _interopRequireDefault(require("../../../router"));
var _transaction = require("@/api/transaction");
var _plan = require("@/api/plan");
var _planMaster = require("@/api/planMaster");
var _users = require("@/api/users");
// import { getColleges } from "@/api/college";

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.postForm = Object.assign({}, _transaction.defaultTransactionData);
    this.loading = false;
    this.list = [];
    this.showImageUpload = false;
    this.renderKey = 0;
    this.transectionList = [];
    this.listLoading = true;
    this.planlist = [];
    this.isUserAdd = false;
    this.userTypeList = [{
      label: 'In Campus',
      value: 1
    }, {
      label: 'Off Campus',
      value: 0
    }];
    this.genderList = [{
      label: 'Male',
      value: 0
    }, {
      label: 'Female',
      value: 1
    }, {
      label: "Don't wish to dislose",
      value: 2
    }];
    this.currencyTypeList = [{
      label: "USD",
      value: "USD"
    }, {
      label: "AED",
      value: "AED"
    }, {
      label: "AFN",
      value: "AFN"
    }, {
      label: "ALL",
      value: "ALL"
    }, {
      label: "AMD",
      value: "AMD"
    }, {
      label: "ANG",
      value: "ANG"
    }, {
      label: "AOA",
      value: "AOA"
    }, {
      label: "ARS",
      value: "ARS"
    }, {
      label: "AUD",
      value: "AUD"
    }, {
      label: "AWG",
      value: "AWG"
    }, {
      label: "AZN",
      value: "AZN"
    }, {
      label: "BAM",
      value: "BAM"
    }, {
      label: "BBD",
      value: "BBD"
    }, {
      label: "BDT",
      value: "BDT"
    }, {
      label: "BGN",
      value: "BGN"
    }, {
      label: "BIF",
      value: "BIF"
    }, {
      label: "BMD",
      value: "BMD"
    }, {
      label: "BND",
      value: "BND"
    }, {
      label: "BOB",
      value: "BOB"
    }, {
      label: "BRL",
      value: "BRL"
    }, {
      label: "BSD",
      value: "BSD"
    }, {
      label: "BWP",
      value: "BWP"
    }, {
      label: "BYN",
      value: "BYN"
    }, {
      label: "BZD",
      value: "BZD"
    }, {
      label: "CAD",
      value: "CAD"
    }, {
      label: "CDF",
      value: "CDF"
    }, {
      label: "CHF",
      value: "CHF"
    }, {
      label: "CLP",
      value: "CLP"
    }, {
      label: "CNY",
      value: "CNY"
    }, {
      label: "COP",
      value: "COP"
    }, {
      label: "CRC",
      value: "CRC"
    }, {
      label: "CVE",
      value: "CVE"
    }, {
      label: "CZK",
      value: "CZK"
    }, {
      label: "DJF",
      value: "DJF"
    }, {
      label: "DKK",
      value: "DKK"
    }, {
      label: "DOP",
      value: "DOP"
    }, {
      label: "DZD",
      value: "DZD"
    }, {
      label: "EGP",
      value: "EGP"
    }, {
      label: "ETB",
      value: "ETB"
    }, {
      label: "EUR",
      value: "EUR"
    }, {
      label: "FJD",
      value: "FJD"
    }, {
      label: "FKP",
      value: "FKP"
    }, {
      label: "GBP",
      value: "GBP"
    }, {
      label: "GEL",
      value: "GEL"
    }, {
      label: "GIP",
      value: "GIP"
    }, {
      label: "GMD",
      value: "GMD"
    }, {
      label: "GNF",
      value: "GNF"
    }, {
      label: "GTQ",
      value: "GTQ"
    }, {
      label: "GYD",
      value: "GYD"
    }, {
      label: "HKD",
      value: "HKD"
    }, {
      label: "HNL",
      value: "HNL"
    }, {
      label: "HTG",
      value: "HTG"
    }, {
      label: "HUF",
      value: "HUF"
    }, {
      label: "IDR",
      value: "IDR"
    }, {
      label: "ILS",
      value: "ILS"
    }, {
      label: "INR",
      value: "INR"
    }, {
      label: "ISK",
      value: "ISK"
    }, {
      label: "JMD",
      value: "JMD"
    }, {
      label: "JPY",
      value: "JPY"
    }, {
      label: "KES",
      value: "KES"
    }, {
      label: "KGS",
      value: "KGS"
    }, {
      label: "KHR",
      value: "KHR"
    }, {
      label: "KMF",
      value: "KMF"
    }, {
      label: "KRW",
      value: "KRW"
    }, {
      label: "KYD",
      value: "KYD"
    }, {
      label: "KZT",
      value: "KZT"
    }, {
      label: "LAK",
      value: "LAK"
    }, {
      label: "LBP",
      value: "LBP"
    }, {
      label: "LKR",
      value: "LKR"
    }, {
      label: "LRD",
      value: "LRD"
    }, {
      label: "LSL",
      value: "LSL"
    }, {
      label: "MAD",
      value: "MAD"
    }, {
      label: "MDL",
      value: "MDL"
    }, {
      label: "MGA",
      value: "MGA"
    }, {
      label: "MKD",
      value: "MKD"
    }, {
      label: "MMK",
      value: "MMK"
    }, {
      label: "MNT",
      value: "MNT"
    }, {
      label: "MOP",
      value: "MOP"
    }, {
      label: "MUR",
      value: "MUR"
    }, {
      label: "MVR",
      value: "MVR"
    }, {
      label: "MWK",
      value: "MWK"
    }, {
      label: "MXN",
      value: "MXN"
    }, {
      label: "MYR",
      value: "MYR"
    }, {
      label: "MZN",
      value: "MZN"
    }, {
      label: "NAD",
      value: "NAD"
    }, {
      label: "NGN",
      value: "NGN"
    }, {
      label: "NIO",
      value: "NIO"
    }, {
      label: "NOK",
      value: "NOK"
    }, {
      label: "NPR",
      value: "NPR"
    }, {
      label: "NZD",
      value: "NZD"
    }, {
      label: "PAB",
      value: "PAB"
    }, {
      label: "PEN",
      value: "PEN"
    }, {
      label: "PGK",
      value: "PGK"
    }, {
      label: "PHP",
      value: "PHP"
    }, {
      label: "PKR",
      value: "PKR"
    }, {
      label: "PLN",
      value: "PLN"
    }, {
      label: "PYG",
      value: "PYG"
    }, {
      label: "QAR",
      value: "QAR"
    }, {
      label: "RON",
      value: "RON"
    }, {
      label: "RSD",
      value: "RSD"
    }, {
      label: "RUB",
      value: "RUB"
    }, {
      label: "RWF",
      value: "RWF"
    }, {
      label: "SAR",
      value: "SAR"
    }, {
      label: "SBD",
      value: "SBD"
    }, {
      label: "SCR",
      value: "SCR"
    }, {
      label: "SEK",
      value: "SEK"
    }, {
      label: "SGD",
      value: "SGD"
    }, {
      label: "SHP",
      value: "SHP"
    }, {
      label: "SLE",
      value: "SLE"
    }, {
      label: "SOS",
      value: "SOS"
    }, {
      label: "SRD",
      value: "SRD"
    }, {
      label: "STD",
      value: "STD"
    }, {
      label: "SZL",
      value: "SZL"
    }, {
      label: "THB",
      value: "THB"
    }, {
      label: "TJS",
      value: "TJS"
    }, {
      label: "TOP",
      value: "TOP"
    }, {
      label: "TRY",
      value: "TRY"
    }, {
      label: "TTD",
      value: "TTD"
    }, {
      label: "TWD",
      value: "TWD"
    }, {
      label: "TZS",
      value: "TZS"
    }, {
      label: "UAH",
      value: "UAH"
    }, {
      label: "UGX",
      value: "UGX"
    }, {
      label: "UYU",
      value: "UYU"
    }, {
      label: "UZS",
      value: "UZS"
    }, {
      label: "VND",
      value: "VND"
    }, {
      label: "VUV",
      value: "VUV"
    }, {
      label: "WST",
      value: "WST"
    }, {
      label: "XAF",
      value: "XAF"
    }, {
      label: "XCD",
      value: "XCD"
    }, {
      label: "XOF",
      value: "XOF"
    }, {
      label: "XPF",
      value: "XPF"
    }, {
      label: "YER",
      value: "YER"
    }, {
      label: "ZAR",
      value: "ZAR"
    }, {
      label: "ZMW",
      value: "ZMW"
    }];
    this.selectDays = [{
      label: 'Sunday ',
      value: 0
    }, {
      label: 'Monday',
      value: 1
    }, {
      label: 'Tuesday',
      value: 2
    }, {
      label: 'Wednesday',
      value: 3
    }, {
      label: 'Thursday',
      value: 4
    }, {
      label: 'Friday',
      value: 5
    }, {
      label: 'Saturday',
      value: 6
    }];
    this.image = 'https://via.placeholder.com/154';
    this.listQuery = {
      sort: 'id,DESC',
      enabled: true,
      filter: {
        enabled: 'eq'
      }
    };
    this.planQuery = {
      sort: 'id,DESC',
      page: 1,
      limit: 10,
      enabled: true,
      price: undefined,
      filter: {
        enabled: 'eq',
        title: '$contL',
        price: '$contL'
      }
    };
    this.challengesQuery = {
      sort: 'id,DESC',
      enabled: true,
      userId: this.$route.params.id,
      filter: {
        userId: 'eq',
        enabled: 'eq'
      }
    };
    this.transectionQuery = {
      userId: this.$route.params.id,
      filter: {
        userId: 'eq'
      }
    };
    this.includeList = [];
    this.usersList = [];
    this.rules = {
      noOfDays: [{
        required: true,
        message: 'Please  Number of Days',
        trigger: ['blur', 'change']
      }],
      isCouplePlan: [{
        required: true,
        message: 'Couple plan is requires',
        trigger: ['blur', 'change']
      }],
      planId: [{
        required: true,
        message: 'Please Select Plan',
        trigger: ['blur', 'change']
      }],
      userId: [{
        required: true,
        message: 'Please Select User',
        trigger: ['blur', 'change']
      }],
      days: [{
        required: true,
        message: 'Please Select Day',
        trigger: ['blur', 'change']
      }],
      price: [{
        required: true,
        message: 'Please Enter Amount',
        trigger: ['blur', 'change']
      }],
      totalNutrientSession: [{
        required: true,
        message: 'Please Enter total nutrient session',
        trigger: ['blur', 'change']
      }],
      totalTrainingSession: [{
        required: true,
        message: 'Please Enter total training session',
        trigger: ['blur', 'change']
      }],
      totalSession: [{
        required: true,
        message: 'Please Enter total session',
        trigger: ['blur', 'change']
      }],
      noOfDaysReschedule: [{
        required: true,
        message: 'Please Enter no of days reschedule',
        trigger: ['blur', 'change']
      }],
      noOfDaysCancelation: [{
        required: true,
        message: 'Please Enter no of days cancelation',
        trigger: ['blur', 'change']
      }],
      planTypeIds: [{
        required: true,
        message: 'Please select plant type',
        trigger: ['blur', 'change']
      }],
      currencyType: [{
        required: true,
        message: 'Please select currency type',
        trigger: ['blur', 'change']
      }]
    };
  }
  async getIncludeList() {
    try {
      const data = await (0, _planMaster.getIncludeMaster)((0, _utils.getQuery)(this.listQuery));
      this.includeList = data;
    } catch (error) {
      // this.listLoading = false;
    }
  }
  async fetchUsers() {
    try {
      const data = await (0, _users.getUsers)((0, _utils.getQuery)(this.listQuery));
      this.usersList = data;
    } catch (err) {
      Promise.reject(err);
    }
  }
  handleRemoveTag(tag) {
    if (tag == 1) {
      this.postForm.totalSession = Number(this.postForm.totalTrainingSession);
      this.postForm.totalNutrientSession = 0;
    }
    if (tag == 2) {
      this.postForm.totalSession = Number(this.postForm.totalNutrientSession);
      this.postForm.totalTrainingSession = 0;
    }
  }
  get lang() {
    return _app.AppModule.language;
  }
  async created() {
    if (this.isEdit) {
      console.log('params', this.$route.params);
      const id = this.$route.params && this.$route.params.caseId;
      console.log('case id: ', id);
      // const transactionId = this.transectionList[0].id
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _transaction.defaultTransactionData);
      if (this.$route.params.id) {
        this.isUserAdd = false;
        this.postForm.userId = Number(this.$route.params.id);
      } else {
        this.isUserAdd = true;
      }
    }
    this.tempTagView = Object.assign({}, this.$route);
    this.fetchUsers();
    this.getIncludeList();
    await this.getUserTransection();
    await this.getPlanLList();
  }
  async getUserTransection() {
    const data = await (0, _transaction.getTransactions)((0, _utils.getQuery)(this.transectionQuery));
    this.transectionList = data;
  }
  async fetchData(id) {
    try {
      const data = await (0, _transaction.getTransactionById)(id);
      this.renderKey++;
      this.postForm = data;
    } catch (err) {
      Promise.reject(err);
    }
  }
  async getPlanLList() {
    try {
      const data = await (0, _plan.getPlans)((0, _utils.getQuery)(this.planQuery));
      this.planlist = data.data;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  updatePlanData() {
    const selectedPlan = this.planlist.find(plan => plan.id === this.postForm.planId);
    if (selectedPlan) {
      this.postForm.noOfDays = selectedPlan.noOfDays;
      this.postForm.noOfDaysCancelation = selectedPlan.noOfDaysCancelation;
      this.postForm.totalSession = selectedPlan.totalSession;
      this.postForm.noOfDaysReschedule = selectedPlan.noOfDaysReschedule;
      console.log(selectedPlan, 'plan123');
      this.postForm.planTypeIds = selectedPlan.planTypeIds;
      this.postForm.totalNutrientSession = selectedPlan.totalNutrientSession;
      this.postForm.totalTrainingSession = selectedPlan.totalTrainingSession;
    }
  }
  changeSession() {
    this.postForm.totalSession = Number(this.postForm.totalNutrientSession) + Number(this.postForm.totalTrainingSession);
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  handleFilter() {
    // this.listQuery.page = 1;
    // this.getList();
  }
  resetForm() {
    this.renderKey--;
    this.$refs.postForm.resetFields();
    // this.postForm.profilePicture = "";
  }
  async saveForm() {
    try {
      this.loading = true;
      //  this.postForm.college = { id: this.postForm.collegeId };
      if (this.isEdit) {
        await (0, _transaction.updateTransaction)(this.postForm.id, this.postForm).then(res => {
          _router.default.push('/usersCase/list1/ ' + this.postForm.userId);
          this.resetForm();
        });
      } else {
        this.postForm.userId = Number(this.postForm.userId);
        await (0, _transaction.createTransaction)(this.postForm);
        this.loading = false;
        this.$notify({
          title: 'Success',
          message: 'Transaction add successfully',
          type: 'success',
          duration: 2000
        });
        if (this.isUserAdd) {
          this.$router.push('/usersCase/list');
        } else {
          _router.default.push('/usersCase/list1/' + this.postForm.userId);
        }
      }
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
      // }
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'UserDetail',
  components: {
    PanThumb: _index2.default,
    UploadImage: _index3.default,
    AvatarUpload: _index.default
  }
})], default_1);
var _default = exports.default = default_1;