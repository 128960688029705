"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _validate = require("@/utils/validate");
var _index = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
var _admins = require("@/api/admins");
var _country_codes = require("@/components/dialcountrydata/country_codes");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.isLogin = false;
    this.sentotp = false;
    this.otpinput = false;
    this.phone = _country_codes.ccdata;
    this.centerDialogVisible = false;
    this.validateUsername = (rule, value, callback) => {
      if (!(0, _validate.isValidEmail)(value)) {
        callback(new Error("Please enter valid contact number"));
      } else {
        callback();
      }
    };
    this.validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("The password can not be less than 6 digits"));
      } else {
        callback();
      }
    };
    this.validateEmail = (rule, value, callback) => {
      if (!(0, _validate.isValidEmail)(value)) {
        callback(new Error("Please enter valid email"));
      } else {
        callback();
      }
    };
    this.loginForm = {
      contactNumber: "",
      dialCode: "91"
    };
    this.resetForm = {
      contactNumber: ""
    };
    this.verifyOtpForm = {
      contactNumber: "",
      otp: null
    };
    this.changePasswordForm = {
      password: "",
      confirmPassword: "",
      userId: 0,
      sessionId: ""
    };
    this.loginRules = {
      email: [{
        validator: this.validateUsername,
        trigger: "blur"
      }],
      contactNumber: [{
        required: true,
        trigger: "blur"
      }],
      otp: [{
        required: true,
        trigger: "blur",
        message: "Password is required"
      }]
    };
    this.resetRules = {
      email: [{
        validator: this.validateEmail,
        trigger: "blur"
      }]
    };
    this.changePassRules = {
      otp: [{
        validator: this.validatePassword,
        trigger: "blur",
        message: "Password is required"
      }],
      confirmPassword: [{
        validator: this.validatePassword,
        trigger: "blur",
        message: "Confirm password is required"
      }]
    };
    this.passwordType = "password";
    this.confirmPasswordType = "password";
    this.resetloading = false;
    this.showDialog = false;
    this.changePasswordDialog = false;
    this.changePasswordloading = false;
    this.loading = false;
    this.otherQuery = {};
  }
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("sessionId");
    const id = urlParams.get("id");
    window.addEventListener("keydown", e => {
      if (e.key == "Enter") {
        this.handleLogin();
        e.preventDefault();
      }
    });
    // var paramsArray:any = window.location.href.split('?')[1].split('&')
    // const code = paramsArray[1].split('=')[1]
    // const id = paramsArray[0].split('=')[1]
    // if (code != null && id != null) {
    //   this.changePasswordForm.userId = parseInt(id)
    //   this.changePasswordForm.sessionId = code
    //   this.checkLink()
    // }
  }
  // const urlParams = new URLSearchParams(window.location.search)
  // const code = urlParams.get('sessionId')
  // const id = urlParams.get('id')
  // if (code != null && id != null) {
  //   this.changePasswordForm.userId = parseInt(id)
  //   this.changePasswordForm.sessionId = code
  //   this.checkLink()
  // }
  // }
  async checkLink() {
    try {
      await (0, _admins.changePasswordLink)(this.changePasswordForm).then(res => {
        if (res.responseCode == 300 && _admin.AdminModule.id == null) {
          this.$message({
            message: "Oops! Link expired",
            type: "error"
          });
        } else if (res.responseCode == 200) {
          this.changePasswordDialog = true;
        } else {}
      });
    } catch (error) {
      this.$message(error.message);
    }
  }
  back() {
    this.sentotp = false;
    this.loading = false;
  }
  onRouteChange(route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }
  mounted() {
    //   if (this.loginForm.email === '') {
    //     (this.$refs.email as Input).focus()
    //   } else if (this.loginForm.password === '') {
    //     (this.$refs.password as Input).focus()
    //   }
    // }
    // if (this.loginForm.email === '') {
    //   (this.$refs.email as Input).focus()
    // } else if (this.loginForm.password === '') {
    //   (this.$refs.password as Input).focus()
    // }
  }
  closeDialog() {
    this.centerDialogVisible = false;
    this.resetForm.contactNumber = "";
  }
  async changePassword() {
    this.$refs.changePasswordForm.validate(async valid => {
      if (this.changePasswordForm.password === this.changePasswordForm.confirmPassword) {
        if (valid) {
          this.changePasswordloading = true;
          try {
            await (0, _admins.changePassword)(this.changePasswordForm);
            this.$notify({
              title: "Success",
              message: "Password changed successfully",
              type: "success",
              duration: 3000
            });
            this.changePasswordloading = false;
            this.changePasswordDialog = false;
          } catch (error) {
            this.changePasswordloading = false;
            this.$message(error.message);
          }
        } else {
          this.$message({
            message: "Enter all details",
            type: "error"
          });
          return false;
        }
      } else {
        this.$message({
          message: "Password doesn't match",
          type: "error"
        });
        return false;
      }
    });
  }
  async forgotPassword() {
    this.$refs.resetForm.validate(async valid => {
      if (valid) {
        this.resetloading = true;
        try {
          var data = await (0, _admins.forgotPassword)(this.resetForm);
          if (data.responseCode == 200) {
            this.$notify({
              title: "Success",
              message: "Reset link has been sent to your mail",
              type: "success",
              duration: 3000
            });
            this.centerDialogVisible = false;
            // this.resetForm.email = ''
          } else {
            this.$message({
              message: "Email does not exists",
              type: "error"
            });
          }
          this.resetloading = false;
        } catch (error) {
          this.resetloading = false;
        }
      } else {
        this.$message({
          message: "Invalid email",
          type: "error"
        });
        return false;
      }
    });
  }
  showPwd() {
    if (this.passwordType === "password") {
      this.passwordType = "";
    } else {
      this.passwordType = "password";
    }
    this.$nextTick(() => {
      this.$refs.password.focus();
    });
  }
  showConfirmPwd() {
    if (this.confirmPasswordType === "password") {
      this.confirmPasswordType = "";
    } else {
      this.confirmPasswordType = "password";
    }
    this.$nextTick(() => {
      this.$refs.confirmPassword.focus();
    });
  }
  async handleVerifyOTP(otp) {
    this.verifyOtpForm.contactNumber = this.loginForm.contactNumber;
    this.verifyOtpForm.otp = otp;
    let data2 = {
      contactNumber: this.verifyOtpForm.contactNumber,
      otp: otp,
      dialCode: "91"
    };
    await _admin.AdminModule.verifyOtp(data2).then(res => {
      if (res == 200) {
        this.$router.push("/dashboard");
      } else if (res == 300) {
        this.$notify.info({
          title: "Info",
          message: "OTP verified successfully. Please Register"
        });
        this.sentotp = false;
        this.otpinput = true;
        //  let con = data2.contactNumber;
        // this.postForm.contactNumber = con;
      } else if (res == 400) {
        this.$message({
          message: "Invalid OTP",
          type: "error"
        });
      } else if (res == 500) {
        this.$message({
          message: "User Disabled",
          type: "error"
        });
      } else if (res == 404) {
        this.$message({
          message: "Forbidden Access",
          type: "error"
        });
      } else if (res == 600) {
        this.$message({
          message: "Forbidden Access",
          type: "error"
        });
      } else {
        this.$message({
          message: "Access Denied",
          type: "error"
        });
      }
    });
  }
  async handleLogin() {
    var _this$$refs$loginForm;
    (_this$$refs$loginForm = this.$refs.loginForm) === null || _this$$refs$loginForm === void 0 || _this$$refs$loginForm.validate(async valid => {
      if (valid) {
        // (this.$refs.loginForm as ElForm).validate(async(valid: boolean) => {
        //   if (valid) {
        let data1 = {
          contactNumber: this.loginForm.contactNumber,
          dialCode: "91"
        };
        // await sendOtp(data1).then((res: any) => {
        //   if (res.responseCode === 200) {
        //     this.$message({
        //       message: "OTP send sucessfully",
        //       type: "success",
        //     });
        //     this.sentotp = true;
        //     this.isLogin = true;
        //     this.loading = false;
        //   } else if (res == 300) {
        //     this.otpinput = true;
        //   } else {
        //     this.sentotp = false;
        //     this.loginForm.contactNumber = "";
        //     this.$message({
        //       message: "Forbidden Access",
        //       type: "success",
        //     });
        //   }
        // });
        this.sentotp = true;
        this.isLogin = true;
        this.loading = false;
      } else {
        this.$message({
          message: "Invalid contact",
          type: "error"
        });
        return false;
      }
    });
  }
  clickme(val) {
    console.log(val);
    this.isLogin = true;
    this.otpinput = true;
  }
  clickme1() {
    this.isLogin = false;
    this.otpinput = false;
  }
  async handleResentOTP() {
    let data = {
      contactNumber: this.loginForm.contactNumber,
      dialCode: "91"
    };
    this.loading = true;
    await (0, _admins.sendOtp)(data).then(res => {
      if (res.responseCode == 200) {
        this.$message({
          message: "OTP send sucessfully",
          type: "success"
        });
      } else if (res.responseCode == 400) {
        this.$message({
          message: "Invalid OTP",
          type: "error"
        });
      }
    });
  }
  getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {});
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)("$route", {
  immediate: true
})], default_1.prototype, "onRouteChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "Login",
  components: {
    LangSelect: _index.default
  }
})], default_1);
var _default = exports.default = default_1;