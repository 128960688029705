"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _utils = require("../../../utils");
var _workoutmaster = require("@/api/workoutmaster");
var _common = require("@/api/common");
var _category = require("@/api/category");
var _index = _interopRequireDefault(require("@/components/UploadImage/index.vue"));
var _axios = _interopRequireDefault(require("axios"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.redirectionValue = [];
    this.redirectionOption = [{
      id: 0,
      name: 'Category Page'
    }];
    this.showImageUpload = false;
    this.renderKey = 0;
    this.image = 'https://via.placeholder.com/';
    this.postForm = Object.assign({}, _workoutmaster.defaultWorkoutMasterData);
    this.loading = false;
    this.fileList = [];
    this.dialogVisible = false;
    this.imageEdited = false;
    this.editList = [];
    this.dialogImageUrl = "";
    this.rules = {
      // image: [
      //   {
      //     message: 'Please enter image',
      //     required: true,
      //     trigger: 'blur'
      //   }
      // ],
      name: [{
        message: 'Please enter name',
        required: true,
        trigger: 'blur'
      }]
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  updateWorkoutMasterImage(res) {
    this.postForm.image = res;
  }
  removeWorkoutMasterImage() {
    this.postForm.image = '';
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  onClose() {
    this.showImageUpload = false;
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'img.' + blob.type.split('/')[1], {
        type: 'image/png'
      });
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.image = res.toString();
      });
    });
  }
  beforeUpload(file) {
    let _this = this;
    const isSize = new Promise(function (resolve, reject) {
      let width = 654;
      let height = 270;
      let img = new Image();
      img.onload = function () {
        let valid = img.width === width && img.height === height;
        valid ? resolve() : reject();
      };
      img.src = file.src;
    }).then(() => {
      return file;
    }, () => {
      _this.$message.error("The image size is limited to 654 x 270, the size cannot exceed 1MB");
      return Promise.reject();
    });
    return isSize;
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPG) {
      this.$message.error("Avatar picture must be JPG format!");
    }
    if (!isLt2M) {
      this.$message.error("Avatar picture size can not exceed 2MB!");
    }
    return isJPG && isLt2M;
  }
  async uploadImages(file, fileList) {
    const img = new Image();
    img.onload = () => {
      this.fileList = fileList;
      if (this.isEdit) {
        this.imageEdited = true;
        this.editList.push(file);
      }
    };
    img.src = file.url;
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  handleRemove(file) {
    this.fileList.forEach((photo, index) => {
      if (photo.name === file.name) {
        this.fileList.splice(index, 1);
        this.postForm.image.splice(index, 1); // this.photos.splice(index, 1)
      }
    });
  }
  updatesImage(res) {
    this.postForm.image.push(res);
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _workoutmaster.defaultWorkoutMasterData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _workoutmaster.getWorkoutMasterById)(id);
      this.postForm = data;
      this.renderKey++;
      this.image = data.image;
      data.image.forEach((image, index) => {
        this.fileList.push({
          name: "image1" + index,
          url: image
        });
      });
      // this.fetchValues()
      // Just for test
      const title = 'image';
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchValues() {
    this.redirectionValue = await (0, _category.getCategorys)((0, _utils.getQuery)({
      enabled: true,
      isDeleted: false,
      filter: {
        enabled: 'eq',
        isDeleted: 'eq'
      }
    }));
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
    this.renderKey--;
    this.postForm.image = '';
  }
  async saveForm() {
    try {
      await this.finalUpload();
      this.loading = true;
      if (this.isEdit) {
        await (0, _workoutmaster.updateWorkoutMaster)(this.postForm.id, this.postForm);
      } else {
        await (0, _workoutmaster.addWorkoutMaster)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'Workout Master saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/workoutmaster/list');
    } catch (err) {
      this.loading = false;
    }
  }
  async finalUpload() {
    if (!this.isEdit) {
      const formList = [];
      const callList = [];
      this.fileList.forEach(file => {
        var fileObj = file.raw;
        var form = new FormData();
        form.append("file", fileObj);
        formList.push(form);
      });
      await formList.forEach(async form => {
        callList.push((0, _common.uploadFile)(form, async event => {}));
      });
      await _axios.default.all(callList).then(res => {
        this.postForm.image = res;
      });
    } else if (this.isEdit) {
      const formList = [];
      const callList = [];
      this.editList.forEach(file => {
        var fileObj = file.raw;
        var form = new FormData();
        form.append("file", fileObj);
        formList.push(form);
      });
      await formList.forEach(async form => {
        callList.push((0, _common.uploadFile)(form, async event => {}));
      });
      await _axios.default.all(callList).then(async res => {
        res.forEach(response => {
          this.postForm.image.push(response);
        });
      });
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'WorkoutMasterDetail',
  components: {
    // PanThumb,
    // AvatarUpload,
    UploadImage: _index.default
  }
})], default_1);
var _default = exports.default = default_1;