"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _contactus = require("@/api/contactus");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _contactFollowup = require("@/api/contactFollowup");
var _moment = _interopRequireDefault(require("moment"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.followuplist = [];
    this.listLoading = true;
    this.newList = [];
    this.oldList = [];
    this.showSortingOption = false;
    this.renderComponent = true;
    this.downloadLoading = false;
    this.activeTab = "first";
    this.today = (0, _moment.default)().toISOString();
    this.listQuery = {
      page: 1,
      limit: 20,
      sort: "id,DESC",
      message: "",
      name: "",
      email: "",
      contactNumber: "",
      contactedDate: "",
      filter: {
        contactedDate: new Date(),
        name: "$contL",
        email: "$contL",
        contactNumber: "$contL",
        enabled: "eq"
      }
    };
    this.followupQuery = {
      page: 1,
      limit: 10,
      sort: "id,DESC",
      fullName: undefined,
      contactNumber: undefined,
      email: undefined,
      contactedDate: this.today,
      filter: {
        contactedDate: "$lte",
        "user.fullName": "$contL",
        "user.contactNumber": "$contL",
        "user.email": "$contL"
      }
    };
    this.dueFollowQuery = {
      page: 1,
      limit: 10,
      sort: "id,DESC",
      fullName: undefined,
      contactNumber: undefined,
      email: undefined,
      contactedDate: this.today,
      filter: {
        contactedDate: "$gte",
        "user.fullName": "$contL",
        "user.contactNumber": "$contL",
        "user.email": "$contL"
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.filters = [];
  }
  created() {
    this.getList();
  }
  removePTags(message) {
    // Remove <p> tags from the start and end of the message
    return message.replace(/^<p>|<\/p>$/g, "");
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _contactFollowup.getContactFollowups)((0, _index2.getQuery)(this.followupQuery));
      const data1 = await (0, _contactFollowup.getContactFollowups)((0, _index2.getQuery)(this.dueFollowQuery));
      this.list = data.data;
      this.followuplist = data1.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getFilters() {
    this.filters = await (0, _contactus.getFilters)((0, _index2.getQuery)({
      sort: "name,ASC"
    }));
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  async onPriortyChange() {
    if (this.showSortingOption) {
      this.fetchAllRecored(true);
    } else {
      this.renderComponent = false;
      this.sortable.options.disabled = true;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
  async fetchAllRecored(checkForFirstTime) {
    try {
      this.listLoading = true;
      const data = await (0, _contactus.getContactUs)((0, _index2.getQuery)({
        page: 1,
        limit: 100000,
        sort: "priority,ASC",
        filter: {
          enabled: "eq",
          isDeleted: "eq"
        }
      }));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
      if (checkForFirstTime) {
        this.oldList = this.list.map(v => v.id);
        this.newList = this.oldList.slice();
        this.$nextTick(this.setSort);
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  setSort() {
    const temp = this.$refs.dragTable;
    const el = temp.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: "sortable-ghost",
      setData: function (dataTransfer) {
        dataTransfer.setData("Text", "");
      },
      onEnd: evt => {
        const targetRow = this.list.splice(Number(evt.oldIndex), 1)[0];
        this.list.splice(Number(evt.newIndex), 0, targetRow);
        const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
        this.newList.splice(evt.newIndex, 0, tempIndex);
        console.log(this.newList);
      }
    });
  }
  //   private async updateOrder() {
  //     try {
  //       const updateCheck = await updateOrder(this.newList)
  //       this.fetchAllRecored(false)
  //       // eslint-disable-next-line no-empty
  //     } catch (error) {}
  //   }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _contactus.deleteContactUs)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Contact Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "email", "contactNumber", "enabled", "createdTimestamp"];
    const data = (0, _index2.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.enquiry.description"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "EnquiryList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;