"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-width": "220px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Profile Picture",
      prop: "profilePicture",
      "label-width": "200px"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      "form-field": _vm.postForm.profilePicture
    },
    on: {
      "handle-upload": _vm.updateProfilePictureImage,
      "handle-remove": _vm.removeProfilePictureImage
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "First Name",
      prop: "fullName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "fullName",
      placeholder: "Full Name"
    },
    model: {
      value: _vm.postForm.fullName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "fullName", $$v);
      },
      expression: "postForm.fullName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Contact Number",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      maxlength: "10",
      name: "contactNumber",
      placeholder: "Contact Number"
    },
    model: {
      value: _vm.postForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "contactNumber", $$v);
      },
      expression: "postForm.contactNumber"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Age",
      prop: "age"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      maxlength: "10",
      name: "age",
      placeholder: "Age"
    },
    model: {
      value: _vm.postForm.age,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "age", $$v);
      },
      expression: "postForm.age"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Gender",
      prop: "gender"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    model: {
      value: _vm.postForm.gender,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "gender", $$v);
      },
      expression: "postForm.gender"
    }
  }, _vm._l(_vm.genderList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Country",
      prop: "countryId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      clearable: ""
    },
    model: {
      value: _vm.postForm.countryId,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "countryId", $$v);
      },
      expression: "postForm.countryId"
    }
  }, _vm._l(_vm.countryList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select Height Type",
      prop: "heightType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    model: {
      value: _vm.postForm.heightType,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "heightType", $$v);
      },
      expression: "postForm.heightType"
    }
  }, _vm._l(_vm.heighTypeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Height",
      prop: "height"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "height",
      placeholder: "Height"
    },
    model: {
      value: _vm.postForm.height,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "height", $$v);
      },
      expression: "postForm.height"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select Width Type",
      prop: "weightType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    model: {
      value: _vm.postForm.weightType,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "weightType", $$v);
      },
      expression: "postForm.weightType"
    }
  }, _vm._l(_vm.weightTypeList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Weight",
      prop: "weight"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "weight",
      placeholder: "Weight"
    },
    model: {
      value: _vm.postForm.weight,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "weight", $$v);
      },
      expression: "postForm.weight"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Menstruation Cycle From",
      prop: "cycleFrom"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "date",
      placeholder: "Pick a day"
    },
    model: {
      value: _vm.postForm.cycleFrom,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "cycleFrom", $$v);
      },
      expression: "postForm.cycleFrom"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Menstruation Cycle To",
      prop: "cycleTo"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      type: "date",
      placeholder: "Pick a day"
    },
    model: {
      value: _vm.postForm.cycleTo,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "cycleTo", $$v);
      },
      expression: "postForm.cycleTo"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select Language",
      prop: "languageIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.languageIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "languageIds", $$v);
      },
      expression: "postForm.languageIds"
    }
  }, _vm._l(_vm.languageList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select Workout",
      prop: "workoutIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.workoutIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "workoutIds", $$v);
      },
      expression: "postForm.workoutIds"
    }
  }, _vm._l(_vm.workOutList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Select Diseases",
      prop: "diseaseIds"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      multiple: "",
      clearable: ""
    },
    model: {
      value: _vm.postForm.diseaseIds,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "diseaseIds", $$v);
      },
      expression: "postForm.diseaseIds"
    }
  }, _vm._l(_vm.diseaseList, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.name,
        value: item.id
      }
    });
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;