"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const stateRouter = {
  path: '/state',
  component: _index.default,
  redirect: 'noredirect',
  name: 'stateManagement.title',
  meta: {
    roles: ['superadmin', 'creator'],
    title: 'stateManagement.title',
    icon: 'map',
    hidden: true
  },
  children: [{
    path: 'list/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/state/list.vue'))),
    name: ' stateManagement.state.manage',
    meta: {
      title: 'stateManagement.state.manage',
      noCache: true,
      icon: 'map',
      activeMenu: '/state/list',
      header: 'List of all categories',
      info: 'All States details are available here. You can search categories by applying filters on fields like States name. After clicking on States name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/state/manage.vue'))),
    name: 'stateManagement.state.edit',
    props: {
      isEdit: true
    },
    meta: {
      title: 'stateManagement.state.edit',
      noCache: true,
      activeMenu: '/state/list',
      hidden: true,
      header: 'Edit States',
      info: 'You can edit States here'
    }
  }, {
    path: 'add/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/state/manage.vue'))),
    name: 'stateManagement.state.add',
    props: {
      isEdit: false
    },
    meta: {
      title: 'stateManagement.state.add',
      noCache: true,
      activeMenu: '/state/list',
      hidden: true,
      header: 'Add State',
      info: 'You can create States here'
    }
  }]
};
var _default = exports.default = stateRouter;