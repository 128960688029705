"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      label: "Name",
      placeholder: "Name"
    },
    model: {
      value: _vm.postForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "name", $$v);
      },
      expression: "postForm.name"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Image",
      prop: "image"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader photo-responsive",
    staticStyle: {
      margin: "10px 0px"
    },
    attrs: {
      name: "vehicleImage",
      "on-change": _vm.uploadImages,
      "list-type": "picture-card",
      action: "https://jsonplaceholder.typicode.com/posts/",
      accept: "image/jpeg,image/png",
      "before-upload": _vm.beforeUpload,
      "auto-upload": true,
      "file-list": _vm.fileList,
      drag: "",
      multiple: ""
    },
    on: {
      "handle-upload": _vm.updatesImage
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function ({
        file
      }) {
        return _c("div", {}, [_c("img", {
          staticClass: "el-upload-list__item-thumbnail",
          attrs: {
            src: file.url,
            alt: ""
          }
        }), _c("span", {
          staticClass: "el-upload-list__item-actions"
        }, [_c("span", {
          staticClass: "el-upload-list__item-preview",
          on: {
            click: function ($event) {
              return _vm.handlePictureCardPreview(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-zoom-in"
        })]), !_vm.disabled ? _c("span", {
          staticClass: "el-upload-list__item-delete",
          on: {
            click: function ($event) {
              return _vm.handleRemove(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-delete"
        })]) : _vm._e()])]);
      }
    }]),
    model: {
      value: _vm.postForm.image,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "image", $$v);
      },
      expression: "postForm.image"
    }
  }, [_c("i", {
    staticClass: "el-icon-camera",
    attrs: {
      slot: "default"
    },
    slot: "default"
  })]), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;