"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-width": "220px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: " Name",
      prop: "name"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "name",
      placeholder: " Name"
    },
    model: {
      value: _vm.postForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "name", $$v);
      },
      expression: "postForm.name"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "email",
      required: "",
      placeholder: "Email",
      "auto-complete": "off"
    },
    model: {
      value: _vm.postForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "email", $$v);
      },
      expression: "postForm.email"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Contact Number",
      prop: "contactNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      maxlength: "10",
      name: "contactNumber",
      placeholder: "Contact Number"
    },
    model: {
      value: _vm.postForm.contactNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "contactNumber", $$v);
      },
      expression: "postForm.contactNumber"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;