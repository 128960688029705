"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updaterecipiesstatus = exports.updaterecipiesAddress = exports.updaterecipies = exports.getrecipiescount = exports.getrecipiesDetailById = exports.getrecipiesById = exports.getrecipies = exports.deleterecipies = exports.defaultRecipeData = exports.addrecipies = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultRecipeData = exports.defaultRecipeData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  about: '',
  calories: null,
  carbs: null,
  protiens: null,
  fat: null,
  ingredients: [],
  steps: [],
  media: '',
  tips: '',
  adminId: null,
  priority: 0
};
const getrecipies = params => (0, _request.default)({
  url: 'recipie',
  method: 'get',
  params
});
exports.getrecipies = getrecipies;
const addrecipies = data => (0, _request.default)({
  url: 'recipie',
  method: 'post',
  data
});
exports.addrecipies = addrecipies;
const getrecipiescount = () => (0, _request.default)({
  url: 'recipie/count-user',
  method: 'get'
});
exports.getrecipiescount = getrecipiescount;
const getrecipiesById = id => (0, _request.default)({
  url: `recipie/detail/${id}`,
  method: 'get'
});
exports.getrecipiesById = getrecipiesById;
const getrecipiesDetailById = id => (0, _request.default)({
  url: `recipie/${id}`,
  method: 'get'
});
exports.getrecipiesDetailById = getrecipiesDetailById;
const updaterecipies = (id, data) => (0, _request.default)({
  url: `recipie/${id}`,
  method: 'patch',
  data
});
exports.updaterecipies = updaterecipies;
const updaterecipiesAddress = (id, data) => (0, _request.default)({
  url: `/user-addresses/${id}`,
  method: 'patch',
  data
});
exports.updaterecipiesAddress = updaterecipiesAddress;
const updaterecipiesstatus = data => (0, _request.default)({
  url: `recipie/update-status`,
  method: 'post',
  data
});
exports.updaterecipiesstatus = updaterecipiesstatus;
const deleterecipies = adminname => (0, _request.default)({
  url: `recipie/${adminname}`,
  method: 'delete'
});
exports.deleterecipies = deleterecipies;