"use strict";

var _interopRequireDefault = require("D:/algoocean/askknatural-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TagsViewModule = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _store = _interopRequireDefault(require("@/store"));
let TagsView = class TagsView extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.visitedViews = [];
    this.cachedViews = [];
  }
  ADD_VISITED_VIEW(view) {
    var _view$meta;
    if (this.visitedViews.some(v => v.path === view.path)) return;
    this.visitedViews.push(Object.assign({}, view, {
      title: ((_view$meta = view.meta) === null || _view$meta === void 0 ? void 0 : _view$meta.title) || 'no-name'
    }));
  }
  ADD_CACHED_VIEW(view) {
    var _view$meta2;
    if (this.cachedViews.includes(view.title)) return;
    if (!((_view$meta2 = view.meta) !== null && _view$meta2 !== void 0 && _view$meta2.noCache)) {
      this.cachedViews.push(view.title);
    }
  }
  DEL_VISITED_VIEW(view) {
    for (const [i, v] of this.visitedViews.entries()) {
      if (v.path === view.path) {
        this.visitedViews.splice(i, 1);
        break;
      }
    }
  }
  DEL_CACHED_VIEW(view) {
    const index = this.cachedViews.indexOf(view.title);
    index > -1 && this.cachedViews.splice(index, 1);
  }
  DEL_OTHERS_VISITED_VIEWS(view) {
    this.visitedViews = this.visitedViews.filter(v => {
      var _v$meta;
      return ((_v$meta = v.meta) === null || _v$meta === void 0 ? void 0 : _v$meta.affix) || v.path === view.path;
    });
  }
  DEL_OTHERS_CACHED_VIEWS(view) {
    const index = this.cachedViews.indexOf(view.title);
    if (index > -1) {
      this.cachedViews = this.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      this.cachedViews = [];
    }
  }
  DEL_ALL_VISITED_VIEWS() {
    // keep affix tags
    const affixTags = this.visitedViews.filter(tag => {
      var _tag$meta;
      return (_tag$meta = tag.meta) === null || _tag$meta === void 0 ? void 0 : _tag$meta.affix;
    });
    this.visitedViews = affixTags;
  }
  DEL_ALL_CACHED_VIEWS() {
    this.cachedViews = [];
  }
  UPDATE_VISITED_VIEW(view) {
    for (let v of this.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view);
        break;
      }
    }
  }
  addView(view) {
    this.ADD_VISITED_VIEW(view);
    this.ADD_CACHED_VIEW(view);
  }
  addVisitedView(view) {
    this.ADD_VISITED_VIEW(view);
  }
  delView(view) {
    this.DEL_VISITED_VIEW(view);
    this.DEL_CACHED_VIEW(view);
  }
  delCachedView(view) {
    this.DEL_CACHED_VIEW(view);
  }
  delOthersViews(view) {
    this.DEL_OTHERS_VISITED_VIEWS(view);
    this.DEL_OTHERS_CACHED_VIEWS(view);
  }
  delAllViews() {
    this.DEL_ALL_VISITED_VIEWS();
    this.DEL_ALL_CACHED_VIEWS();
  }
  delAllCachedViews() {
    this.DEL_ALL_CACHED_VIEWS();
  }
  updateVisitedView(view) {
    this.UPDATE_VISITED_VIEW(view);
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "ADD_VISITED_VIEW", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "ADD_CACHED_VIEW", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "DEL_VISITED_VIEW", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "DEL_CACHED_VIEW", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "DEL_OTHERS_VISITED_VIEWS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "DEL_OTHERS_CACHED_VIEWS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "DEL_ALL_VISITED_VIEWS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "DEL_ALL_CACHED_VIEWS", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], TagsView.prototype, "UPDATE_VISITED_VIEW", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "addView", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "addVisitedView", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "delView", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "delCachedView", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "delOthersViews", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "delAllViews", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "delAllCachedViews", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], TagsView.prototype, "updateVisitedView", null);
TagsView = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'tagsView'
})], TagsView);
const TagsViewModule = exports.TagsViewModule = (0, _vuexModuleDecorators.getModule)(TagsView);