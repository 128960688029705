"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Group Name",
      prop: "groupName"
    }
  }, [_c("el-input", {
    attrs: {
      name: "groupName",
      required: "",
      placeholder: "Group Name"
    },
    model: {
      value: _vm.postForm.groupName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "groupName", $$v);
      },
      expression: "postForm.groupName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "30px"
    },
    attrs: {
      label: "Group Icon",
      prop: "groupIcon"
    }
  }, [_c("upload-image", {
    key: _vm.renderKey,
    attrs: {
      formField: _vm.postForm.groupIcon
    },
    on: {
      "handle-upload": _vm.updategroupIcon,
      "handle-remove": _vm.removegroupIcon
    }
  }), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Only Image of dimension 300 * 300 is accepted",
      placement: "right-start"
    }
  }, [_c("span", {
    staticClass: "el-icon-question",
    staticStyle: {
      "font-size": "20px"
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Admins",
      prop: "admins"
    }
  }, [_c("el-select", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      multiple: "",
      remote: "",
      filterable: "",
      "remote-method": _vm.remoteMethodAdmin,
      "value-key": "id",
      "collapse-tags": "",
      placeholder: "Select"
    },
    model: {
      value: _vm.selectedAdmins,
      callback: function ($$v) {
        _vm.selectedAdmins = $$v;
      },
      expression: "selectedAdmins"
    }
  }, _vm._l(_vm.adminsList, function (item) {
    return _c("el-option", {
      key: item.id,
      staticStyle: {
        height: "auto",
        width: "100%"
      },
      attrs: {
        label: item.fullName,
        value: item
      }
    }, [_c("el-row", {
      staticStyle: {
        width: "100%"
      }
    }, [_c("el-col", {
      attrs: {
        span: 6,
        gutter: 20
      }
    }, [item.profile ? _c("img", {
      staticClass: "member-img",
      attrs: {
        src: item.profile
      }
    }) : _c("img", {
      staticClass: "member-img",
      attrs: {
        src: require("@/assets/img/8-01.jpg")
      }
    })]), _c("el-col", {
      attrs: {
        span: 10
      }
    }, [_c("el-row", [_c("el-col", {
      staticStyle: {
        color: "#8492a6",
        "font-size": "13px",
        "line-height": "12px"
      }
    }, [_vm._v(" " + _vm._s(item.fullName))]), _c("el-col", {
      staticStyle: {
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v(" " + _vm._s(item.contactNumber) + " ")])], 1)], 1)], 1)], 1);
  }), 1)], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Users",
      prop: "users"
    }
  }, [_c("el-select", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      multiple: "",
      remote: "",
      filterable: "",
      "remote-method": _vm.remoteMethodUser,
      "value-key": "id",
      "collapse-tags": "",
      placeholder: "Select"
    },
    model: {
      value: _vm.selectedUsers,
      callback: function ($$v) {
        _vm.selectedUsers = $$v;
      },
      expression: "selectedUsers"
    }
  }, _vm._l(_vm.usersList, function (item) {
    return _c("el-option", {
      key: item.id,
      staticStyle: {
        height: "auto",
        width: "100%"
      },
      attrs: {
        label: item.fullName,
        value: item
      }
    }, [_c("el-row", {
      staticStyle: {
        width: "100%"
      }
    }, [_c("el-col", {
      attrs: {
        span: 6,
        gutter: 20
      }
    }, [item.profilePicture ? _c("img", {
      staticClass: "member-img",
      attrs: {
        src: item.profilePicture
      }
    }) : _c("img", {
      staticClass: "member-img",
      attrs: {
        src: require("@/assets/img/8-01.jpg")
      }
    })]), _c("el-col", {
      attrs: {
        span: 10
      }
    }, [_c("el-row", [_c("el-col", {
      staticStyle: {
        color: "#8492a6",
        "font-size": "13px",
        "line-height": "12px"
      }
    }, [_vm._v(" " + _vm._s(item.fullName))]), _c("el-col", {
      staticStyle: {
        color: "#8492a6",
        "font-size": "13px"
      }
    }, [_vm._v(" " + _vm._s(item.contactNumber) + " ")])], 1)], 1)], 1)], 1);
  }), 1)], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;